.ct-invalid-character {
  padding: 30px;
  font-size: 16px;
  text-align: center;

  &__button {
    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      background: url("#{$imageURL-local}/character-sheet/builder-icon-white.svg")
        no-repeat;
      background-size: 20px;
      margin-right: 5px;
    }
  }

  @media (min-width: $bp-builder-mobile-transition) {
    padding: 80px 30px 30px;
    max-width: 550px;
    margin: 0 auto;
  }
}
