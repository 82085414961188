.controls {
  display: none;
  justify-content: space-between;
  margin: 0.5rem 1rem 0.125rem;
}

.controls.left {
  transform: scaleX(-1);
  margin-left: 0.5rem;
}

.controls button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  opacity: 0.25;
}

.controls button:hover {
  opacity: 1;
}

.controls svg {
  fill: var(--ttui_grey-50);
  width: 1.125rem;
  height: 1.125rem;
  pointer-events: none;
}

.controls svg > line {
  stroke: var(--ttui_grey-50);
}

button.locked {
  opacity: 0.6;
}

.visibility {
  display: flex;
  gap: 0.75rem;
}

.visibility button {
  opacity: 0.6;
}

.visibility > .closed {
  margin-left: -1rem;
}

.visibility .arrows > svg {
  width: 1.125rem;
  height: 1.125rem;
}

.overlay {
  display: none;
  margin: 0 1rem 0 auto;
}

.placement {
  display: flex;
  gap: 0.375rem;
}

.placement.left {
  transform: scaleX(-1);
}

.overlay .active,
.overlay .active:hover,
.placement .active,
.placement .active:hover {
  opacity: 0.6;
  cursor: default;
}

.hidden {
  display: none;
}

@media screen and (min-width: 1200px) {
  .controls {
    display: flex;
    gap: 0.5rem;
  }
  .visibility {
    margin-left: 0;
  }
}

@media screen and (min-width: 1600px) {
  .overlay {
    display: flex;
    gap: 0.375rem;
  }
}
