.ct-spell-detail {
  .ct-spell-caster {
    @include sidebarSeparator();
  }

  &__level-school {
    font-style: italic;

    &-item {
      & + & {
        margin-left: 3px;
      }
    }
  }

  &__customize {
    @include sidebarSeparator();

    .ct-editor-box {
      margin: 5px 0 10px;
    }
  }

  &__properties {
    @include sidebarSeparator();

    [role="listitem"]:first-child {
      margin-top: 0;
    }
  }

  &__range {
    &-shape {
      vertical-align: top;
      display: inline-flex;
    }

    &-icon {
      top: 0;
      margin-right: 0;
    }
  }

  &__components {
    &-description {
      color: $ct--theme-de-emphasized-color;
      font-size: 12px;
      margin-left: 5px;
    }
  }

  &__description {
    @include sidebarSeparator();
  }

  &__actions {
    @include sidebarSeparator();
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__action {
    margin-right: 5px;
  }

  &__tags {
    @include sidebarSeparator();
  }
}
