.ct-item-detail-abilities {
  &__limited {
    &-uses {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #edeae8;
      display: flex;
      align-items: center;

      &-label {
        font-weight: bold;
        font-family: $condensed;
        margin-right: 5px;
      }
    }
  }

  &__spells {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #edeae8;
  }

  &__spell {
    display: flex;
    margin: 5px 0;

    &-name {
      flex: 1;
      cursor: pointer;
    }
  }
}
