.builder-field {
  margin: 25px 0;

  &-heading {
    font-family: $condensed;
    font-weight: bold;
    font-size: 1rem;
    margin: 10px 0;
    line-height: normal;
  }

  &-value[type="text"],
  &-value[type="number"] {
    width: 100%;
    padding: 10px;
  }
}

.builder-field-toggle {
  &-summary {
  }

  &-heading {
    font-family: $condensed;
    font-weight: bold;
    font-size: 16px;
  }

  &-description {
    font-size: 14px;
    margin-top: 3px;
    color: #555;
  }

  &-input {
    margin-top: 5px;
  }
}

.builder-field-toggles {
  &-summary {
  }

  &-heading {
    font-family: $condensed;
    font-weight: bold;
    font-size: 16px;
  }

  &-description {
    font-size: 14px;
    margin-top: 3px;
    color: #555;
  }

  &-fields {
    margin-top: 15px;
  }

  &-field {
    margin-top: 5px;
    display: flex;
    align-items: center;

    &-label {
      flex: 1;
    }

    &-input {
      margin-right: 10px;
    }
  }
}

.builder-field-select {
  display: flex;
  align-items: center;

  &-summary {
    flex: 1;
  }

  &-description {
    font-size: 14px;
    margin-top: 3px;
    color: #555;
  }

  .builder-field-heading {
    margin: 0;
  }

  &-block {
    display: block;
  }

  &-block &-input {
    margin-top: 5px;
  }
}

.builder-field-update {
  &-animation {
    animation: 2s borderFlash;
  }
}

@keyframes borderFlash {
  from {
    box-shadow: 0 0 2px 2px $ct--dice-callout-color;
  }
  to {
    box-shadow: none;
  }
  99% {
    box-shadow: 0px 0px 2px 2px $ct--dice-callout-color;
  }
}
