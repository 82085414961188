.ct-share-url-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__splash-icon {
    height: 76px;
    width: 62px;
    margin-bottom: 15px;
  }

  &__url {
    margin-bottom: 5px;
    width: 100%;
  }

  & &__input {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  &__clipboard {
    cursor: copy;

    &-content {
      font-weight: bold;
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-share-url-pane {
    &__input {
      @include darkModeInput();
    }
  }
}
