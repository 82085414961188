.ct-spell-caster {
  &__casting {
    display: flex;
    align-items: center;

    &-label {
      font-weight: bold;
      text-transform: uppercase;
      font-family: $condensed;
      margin-right: 5px;
    }

    &-actions {
      flex: 1;
      display: flex;
      align-items: center;

      .ct-theme-button {
        position: relative;
        padding: 0 6px;
        line-height: 26px;
      }
    }

    &-action {
      & + & {
        margin-left: 5px;
      }

      &-count {
        position: absolute;
        background-color: #fff;
        padding: 1px 4px;
        border: 1px solid $ct--theme-color;
        border-radius: 3px;
        color: $ct--theme-color;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
      }

      &-count {
        top: -10px;

        &--limited-use {
          right: 5px;
        }

        &--spellcasting {
          left: 5px;
        }
      }

      &-button {
        &--limited {
          min-width: 70px;
        }
      }
    }

    &-level {
      display: flex;
      align-items: center;
      padding-left: 5px;

      &-label {
        font-weight: bold;
        text-transform: uppercase;
        font-family: $condensed;
        margin-right: 5px;
      }

      &-current {
        &--controls {
          width: 30px;
          text-align: center;
        }
      }

      .ct-theme-button {
        vertical-align: middle;
        padding: 6px 0;
        width: 28px;
        height: 28px;

        &::before {
          content: "";
          display: block;
          margin: 0 auto;
          background: 0 0 transparent
            url("#{$imageURL-local}/sprites/plus_minus-white.svg") no-repeat;
          background-size: auto 12px;
          width: 12px;
          height: 12px;
        }

        &:disabled {
          &::before {
            background-image: url("#{$imageURL-local}/sprites/plus_minus-disabled.svg");
          }
        }
      }

      .ct-button--increase {
        &::before {
          background-position: 0 0;
        }
      }
      .ct-button--decrease {
        &::before {
          background-position: -12px 0;
        }
      }
    }

    &-reset {
      color: $ct--theme-de-emphasized-color;
      font-size: 10px;
      font-family: $condensed;
      margin-left: 3px;
    }
  }

  &__limited {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1;
  }

  &__modifiers {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1;
  }

  &__modifier {
    & + & {
      margin-top: 10px;
    }

    &-restriction {
      color: $ct--theme-de-emphasized-color;
      font-size: 12px;
      display: block;
      margin-top: 2px;
      line-height: 1.2;
    }
  }

  &__higher-items {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1;
  }

  &__higher {
    line-height: 1.3;
    margin-top: 10px;

    &-target {
      &-description {
        color: $ct--theme-de-emphasized-color;
        font-size: 12px;
      }
    }
  }
}

/*

.spell-caster {
    text-align: center;

    &-damage {
    }

    &-healing {
        margin-top: 15px;
        font-size: 16px;
        line-height: 1;
    }

    &-level {
        margin-top: 15px;

        &-label {
            font-size: 11px;
            font-weight: bold;
            vertical-align: middle;
            text-transform: uppercase;
            margin-right: 10px;
        }

        &-current {
            display: inline-block;
            vertical-align: middle;
            width: 30px;
            text-align: center;
            font-size: 20px;
        }

    }

    &-action {
        display: flex;

        .ct-theme-button {
            margin-left: 10px;
            position: relative;

            &:first-child {
                margin-left: 0;
            }
        }

        &-count,
        &-identifier {
            position: absolute;
            background-color: #fff;
            padding: 2px 5px;
            border: 1px solid $ct--theme-color;
            color: $ct--theme-color;
            text-transform: uppercase;
            font-size: 10px;
            border-radius: 3px;
            font-weight: bold;
        }

        &-identifier {
            top: -12px;
            left: 5px;
        }
        &-count {
            top: -12px;
            right: 5px;
        }

        &-limited {
            color: #979aa4;
            font-size: 12px;
        }
    }
}*/
