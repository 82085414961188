.content :global(.characters-statblock) {
  display: none;
}

.detailsLink {
  border-top: 0.063rem solid var(--ttui_grey-200);
  border-bottom: 0.063rem solid var(--ttui_grey-200);
}

.detailsLink a {
  width: 100%;
  justify-content: flex-start;
  text-transform: none;
}

.detailsLink svg:only-child {
  fill: var(--character-builder-blue);
  width: 0.875rem;
  height: 0.875rem;
}
