.collapsible.withHeading .content {
  display: none;
}

.collapsible.withHeading.open .content {
  display: block;
}

.collapsible:not(.withHeading) .content {
  max-height: 92px;
  overflow: hidden;
  position: relative;
}

.collapsible:not(.withHeading) .content:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 53px;
  background: linear-gradient(transparent, white);
}

.collapsible:not(.withHeading).open .content {
  max-height: none;
}

.collapsible:not(.withHeading).open .content:after {
  display: none;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--character-builder-green);
  padding: 0;
  font-size: 0.875rem;
}
