.tagGroup {
  display: flex;
  margin-top: 0.9375rem;
}

.label {
  font-weight: bold;
  font-family: var(--font-condensed);
  text-transform: uppercase;
  margin-right: 0.3125rem;
  margin-top: 0.125rem;
}

.group {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.tag {
  display: inline-block;
  margin-bottom: 0.1875rem;
  margin-right: 0.1875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.0625rem;
  border: 0.0625rem solid #cbc6c3;
  background-color: var(--theme-background-solid);
  box-shadow: inset 0 0 0.125rem 0 #dcd1c7;
  font-size: 0.5625rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: var(--theme-contrast);
}

[data-theme="dark"] .tag {
  border: none;
  color: var(--character-muted-color);
}
