.ct-reset-pane {
  &__intro {
    color: $ct--theme-de-emphasized-color;
  }

  &__recover {
    margin: 25px 0;

    &-heading {
      font-size: 16px;
      font-family: $condensed;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-sources {
      margin: 10px 0;
    }

    &-max-hp {
      margin: 10px 0;
    }
  }

  &__hitdice {
    margin: 25px 0;
  }

  &__hitdie {
    & + & {
      margin-top: 15px;
    }

    &-heading {
      font-size: 14px;
      font-family: $condensed;

      &-class {
        text-transform: uppercase;
        margin-right: 5px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    &-manager {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-dice {
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
        & .integrated-dice__container {
          padding: 10px;
          border-radius: 3px;
          box-shadow: unset;
          border-width: 1px;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

  &__action {
    & + & {
      margin-left: 10px;
    }
  }
}
