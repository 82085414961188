// Remove pageheader from waterdeep
.my-characters-wrapper {
  .page-header__primary {
    display: none;
  }

  .page-header__extras {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  .b-breadcrumb-a {
    display: block;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    padding: 5px 15px;
  }
  nav ul,
  nav ol {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
  }
  nav a {
    text-decoration: none;
  }
  .b-breadcrumb-a .b-breadcrumb-item {
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
  }
  .b-breadcrumb-a .b-breadcrumb-item a,
  .b-breadcrumb-a .b-breadcrumb-item span {
    text-transform: uppercase;
    font-family: "Roboto Condensed", Roboto, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #222;
  }
  .b-breadcrumb-a .b-breadcrumb-item:first-child a::before {
    content: "";
    display: inline-block;
    background: url("https://www.dndbeyond.com/content/skins/waterdeep/images/dnd-beyond-b-red.png")
      no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    vertical-align: top;
  }
  .b-breadcrumb-a .b-breadcrumb-item:first-child a span {
    display: none;
  }
  .b-breadcrumb-a .b-breadcrumb-item + .b-breadcrumb-item::before {
    content: "";
    display: inline-block;
    vertical-align: text-top;
    height: 15px;
    width: 15px;
    background: url("https://www.dndbeyond.com/content/skins/waterdeep/images/icons/breadcrumb-chevron.svg")
      no-repeat;
    background-size: contain;
    margin-left: 12px;
  }
  .b-breadcrumb-a .b-breadcrumb-item:last-child span {
    color: #a5afba;
  }
}
