.page {
  padding: 0.75rem 0.938rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 400;
}

.submitButton {
  margin-top: 1rem;
  width: 100%;
}

.divider {
  border: 0.0313rem solid var(--ttui_grey-200);
  margin: 1.5625rem 0 1rem;
}

.accordion {
  margin-bottom: 0;
}

.accordionHeading {
  font-family: var(--font-condensed);
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.accordion > summary {
  padding-left: 0;
  padding-right: 0;
}

.accordion > summary > div > div > svg {
  fill: var(--ttui_grey-700);
  width: 1.25rem;
  height: 100%;
}

.accordion [class*="_page"],
.accordion [class*="_content"] {
  padding: 0;
}

.accordion > summary:focus-visible {
  border: 0.125rem solid var(--ttui_blue-400);
}

.page :global(.builder-field) {
  margin-top: 1rem;
}
