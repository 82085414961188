@import "@dndbeyond/ttui/components/InfoItem/InfoItem.module.css";

.item[role] {
  margin: 0.375rem 0;
}

.item[role] > p {
  font-size: 0.813rem;
  color: var(--theme-contrast);
  opacity: 0.9;
  line-height: 1.3;
}

.item[role] > p em {
  opacity: 0.45;
  font-size: 0.75rem;
  font-style: normal;
}
