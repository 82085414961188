.ct-item-detail {
  &__intro {
    font-style: italic;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }

    &-subtitle {
      padding-bottom: 10px;
      font-style: unset;
    }
  }

  &__customize {
    @include sidebarSeparator();

    .ct-editor-box {
      margin: 5px 0 10px;
    }
  }

  &__class-customize {
    @include sidebarSeparator();
    display: flex;
    align-items: center;

    &-item {
      margin-right: 15px;
    }

    .character-checkbox {
      vertical-align: top;
    }
  }

  &__properties {
    @include sidebarSeparator();
  }

  &__property {
    display: flex;

    & + & {
      margin-top: 4px;
    }

    &-label {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  &__spell-damage {
    @include sidebarSeparator();

    .ct-item-name {
      padding-right: 0;
    }

    &-group {
      & + & {
        margin-top: 15px;
      }

      &-name {
        font-weight: bold;
        font-family: $condensed;
      }

      &-data-origin {
        margin-left: 5px;
        font-weight: normal;
        color: $ct--theme-de-emphasized-color;
      }

      &-restriction {
        color: $ct--theme-de-emphasized-color;
        font-size: 12px;
        display: block;
        margin-top: 2px;
        line-height: 1.2;
      }
    }
  }

  &__description {
    @include sidebarSeparator();
    overflow-x: hidden;

    &--plain {
      white-space: pre-wrap;
    }
  }

  &__infusion {
    @include sidebarSeparator();
  }

  &__versatile-damage {
    margin-left: 5px;
    color: $ct--theme-de-emphasized-color;
  }

  &__additional-damage {
    line-height: 1;

    &-info {
      font-size: 10px;
      color: $ct--theme-de-emphasized-color;
      margin-left: 5px;
      line-height: 1;
    }
  }

  &__full-image-img {
    max-width: 200px;
    margin: 15px auto;
    display: block;
  }

  &__actions {
    @include sidebarSeparator();
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .site &__action {
    margin: 0 3px;
  }

  .mastery-container {
    display: none;
  }
}
