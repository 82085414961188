$character-progression-summary--color: $ct--theme-de-emphasized-color !default;

.ddbc-character-progression-summary {
  &__level {
    font-size: 10px;
  }

  &__xp {
    &-data {
      color: $character-progression-summary--color;
      font-size: 7px;
      text-align: center;
      margin-top: 3px;
    }
  }
}
