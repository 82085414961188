.ct-subsection-tablet {
  padding: 15px 15px 70px;
  max-width: 700px;
  margin: 0 auto;

  .ct-mobile-divider {
    &:first-child {
      margin-top: 0;
    }
  }
}
