.race-simple {
  &-content {
    display: flex;
    align-items: center;
  }

  &-parent {
    text-transform: uppercase;
    font-size: 18px;
  }

  &-info {
    padding-left: 15px;
    flex: 1;
    line-height: 1.1;
  }

  &-subclass {
    color: $ct--theme-de-emphasized-color;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
  }

  &-preview {
    &-img {
      width: 50px;
      height: 50px;
      border-radius: 3px$builder-image-border-radius;
    }
  }
}
