.origin {
  font-family: var(--font-condensed);
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 2.5rem;
  gap: 0.25rem;
}

.originLabel {
  font-size: 0.625rem;
  color: var(--character-muted-color);
}

.originName {
  font-size: 0.6875rem;
}

.snippet {
  cursor: pointer;
  margin-bottom: 13px;
}

.heading {
  font-weight: bold;
  font-family: var(--font-condensed);
  line-height: 1.2;
}

.headingDarkMode {
  color: white;
}

.headingActivation {
  font-weight: normal;
}

.headingActivationDarkMode {
  color: white;
}

.headingExtra {
  margin-left: 5px;
  font-weight: normal;
  color: var(--character-muted-color);
  font-size: 12px;
  font-family: var(--font-family);
}

.headingExtraDarkMode {
  color: var(--character-dark-mode-text);
}

.metaItem {
  font-weight: normal;
  font-family: var(--font-family);
  font-size: 11px;
  display: inline-block;
  vertical-align: middle;
}

.metaItem::before {
  content: "\2022";
  padding: 0 3px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  color: var(--character-muted-color);
}

.metaItemDarkMode {
  color: var(--character-dark-mode-text);
}

.content {
  font-size: 12px;
}

.extra {
  border-left: 3px solid #d8d8d8;
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.optionsExtra {
  border-left: 3px solid #d8d8d8;
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 4px;
}

.option {
  font-size: 12px;
}

.spell {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.spellSummary {
  margin-right: 5px;
}

.spellDarkMode {
  color: white;
}

.actionLimited {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.actionLimitedLabel {
  margin-right: 5px;
}

.actionLimitedLabelDarkMode {
  color: white;
}

.actionSummaryDarkMode {
  color: white;
}

.limitedUse {
  display: flex;
  align-items: center;
}

.limitedUseUsages {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-right: 5px;
  font-weight: bold;
}

.limitedUseUsage + .limitedUseUsage {
  margin-left: 3px;
}

.limitedUseSep {
  color: var(--character-muted-color);
  margin-right: 5px;
}

.limitedUseExtra {
  width: 100%;
}

.limitedUseDarkMode {
  color: white;
}

.spellsLayoutCompact .spell {
  display: inline-flex;
}

.spellsLayoutCompact .spellSummary {
  margin-right: 0;
}

.spellsLayoutCompact .spellSep {
  margin-right: 5px;
}

.choiceDarkMode {
  color: white;
}
