$marketplace-cta-background: #f5f5f5;
$marketplace-cta-intro-font: $condensed;
$marketplace-cta-button-background: $color-marketplace;
$marketplace-cta-button-background-hover: lighten($color-marketplace, 10%);

.ddbc-marketplace-cta {
  padding: 10px;
  border: 1px solid #eaeaea;
  background: $marketplace-cta-background;
  border-radius: 3px;
  cursor: pointer;

  &__image {
    height: 215px;
    width: 100%;
    background-position: center center;
    background-image: url("https://media.dndbeyond.com/mega-menu/9af3bf38a8f79012bcedea2aae247973.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 10px;
  }

  &__header {
    margin-bottom: 10px;
    font-size: 12px;

    &-intro {
      font-family: $marketplace-cta-intro-font;
      font-style: italic;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &__description {
    @include sidebarSeparator();
    font-size: 15px;
    padding: 10px 0;
  }

  &__cta {
    margin-top: 10px;

    &-button {
      padding: 10px 8px;
      border-radius: 3px;
      background: $marketplace-cta-button-background;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      text-align: center;

      &:hover {
        background: $marketplace-cta-button-background-hover;
      }
    }
  }
}
