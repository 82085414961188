.class-simple {
  &-chosen {
    display: flex;
    align-items: center;

    &-heading {
      padding-left: 15px;
      text-transform: uppercase;
      font-size: 18px;
      flex: 1;
    }

    &-preview {
      &-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}
