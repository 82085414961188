.removeCustomizations {
  border: 0.125rem solid red;
}

.limitedUses {
  border-top: 0.0625rem solid var(--theme-transparent);
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  display: flex;
  align-items: center;
}

.limitedUsesLabel {
  font-weight: bold;
  font-family: var(--font-condensed);
  margin-right: 0.3125rem;
}

.customize {
  border-top: 0.0625rem solid var(--theme-transparent);
  margin-top: 0.625rem;
  padding-top: 0.625rem;
}

.customizeEditorBox {
  margin: 0.3125rem 0 0.625rem;
}

.properties {
  border-top: 0.0625rem solid var(--theme-transparent);
  margin-top: 0.625rem;
  padding-top: 0.625rem;
}

.description {
  border-top: 0.0625rem solid var(--theme-transparent);
  margin-top: 0.625rem;
  padding-top: 0.625rem;
}

.marketplaceCta {
  margin-top: 0.625rem;
}

.actions {
  border-top: 0.0625rem solid var(--theme-transparent);
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  display: flex;
  justify-content: center;
}

.rangeShape {
  vertical-align: top;
  display: inline-flex;
}

.rangeIcon {
  top: 0;
  margin-right: 0;
}
