.ct-description-pane {
  &__entry {
    margin-top: 10px;

    &-content {
      margin-top: 5px;
    }
  }

  &__editor {
    &-label {
      font-weight: bold;
      font-family: $condensed;
      margin-bottom: 2px;
    }

    &--number &-input {
      width: 70px;
    }

    &--text &-input {
      width: 150px;
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-description-pane {
    &__editor {
      &-input,
      &-input:hover {
        @include darkModeInput();
      }
    }
  }
}
