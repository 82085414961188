.ct-proficiency-groups {
  cursor: pointer;

  &__group {
    font-family: $condensed;

    & + & {
      border-top: 1px solid #d8d8d8;
      margin-top: 10px;
      padding-top: 10px;
    }

    &-label {
      color: $ct--theme-de-emphasized-color;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      &--dark-mode {
        color: $ct--theme-dark-mode-text;
      }
    }

    &-item,
    &-items {
      &--dark-mode {
        color: white;
      }
    }
  }
}
