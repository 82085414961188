.ct-infusion-choice-pane {
  &__step {
    @include sidebarSeparator();
    display: flex;

    &-header {
      font-weight: bold;
      font-family: $condensed;
    }

    &-primary {
      flex: 1;
    }

    &-extra {
      align-self: center;
    }
  }

  &__actions {
    @include sidebarSeparator();
    text-align: center;
  }

  &__description {
    @include sidebarSeparator();
  }

  &__ui {
    &-group {
      @include sidebarSeparator();
    }

    &-header {
      font-weight: bold;
      font-family: $condensed;
    }
  }

  &__item {
    // TODO duplicated from equipment manage pane
    > .ct-collapsible__content {
      border: 1px solid #fff;
      padding: 10px;
      margin-top: 5px;
      background: #f9f9f9;
      box-shadow: 0 0 0 1px #e0e0e0;
      margin-bottom: 15px;
    }
  }

  &__inventory {
    &-empty {
      color: $ct--theme-de-emphasized-color;
      font-family: $condensed;
      font-size: 12px;
    }
  }

  &__entry {
    display: flex;

    & + & {
      margin-top: 5px;
    }

    &-primary {
      flex: 1;
    }

    &-extra {
      padding-left: 10px;
    }

    &-header {
      font-weight: bold;
      font-family: $condensed;
    }
  }

  &__marketplace-cta {
    margin-top: 10px;
  }
}
