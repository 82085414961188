.ct-combat {
  width: 517px;

  &__summary {
    display: flex;

    &-group {
      &--initiative,
      &--ac {
        width: 85px;
      }

      &--ac {
        padding-top: 2px;
      }

      &--initiative {
        padding-top: 8px;
      }

      &--statuses {
        flex: 1;
        min-width: 0;
        padding-left: 5px;
      }
    }

    &-label {
      font-family: $condensed;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__statuses {
    position: relative;
    display: flex;
    height: 95px;
    padding: 10px;

    &-group {
      position: relative;
      flex: 1;
      min-width: 0;
      padding: 0 10px;
      overflow: hidden;

      & + & {
        border-left: 1px solid #d8d8d8;
      }
    }
  }

  .ct-combat__summary-group--ac,
  .ct-combat__statuses-group {
    cursor: pointer;
  }

  .ddbc-armor-class-box {
    margin: 0 auto;
  }

  .ct-defenses-summary {
    max-height: 57px;
    overflow: hidden;

    &__group {
      &--single {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .ct-conditions-summary {
    max-height: 57px;
    overflow: hidden;
  }

  @media (min-width: $bp-sheet-desktop-large-transition) {
    width: 623px;

    &__summary {
      &-group {
        &--initiative,
        &--ac {
          width: 105px;
        }

        &--ac {
          padding-top: 0;
        }

        &--initiative {
          padding-top: 2px;
        }
      }
    }

    .ddbc-armor-class-box {
      width: 90px;
      height: 95px;
    }
  }
}
