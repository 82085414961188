.ct-status-summary-mobile {
  width: 82px;

  &__health {
    position: relative;
    border: 1px solid $ct--theme-color;
    border-radius: 2px;
    padding: 4px;
    text-align: center;

    &-label {
      font-size: 7px;
      text-transform: uppercase;
    }
  }

  &__hp {
    font-size: 20px;
    letter-spacing: -1px;
    line-height: 1;
    color: #fff;

    &-sep {
      padding: 0 3px;
    }

    &--has-temp &-max,
    &--has-temp &-current {
      color: $ct--positive-color;
    }
  }

  &__exhaustion {
    display: flex;

    &-label {
      font-size: 10px;
      text-transform: uppercase;
    }

    &-icon {
      width: 24px;

      &::before {
        content: "";
        display: block;
        margin: 2px auto 0;
        width: 20px;
        height: 20px;
        background: 0 0 transparent
          url("#{$imageURL-local}/icons/conditions/white/unconscious.svg")
          no-repeat;
      }
    }
  }

  &__deathsaves {
    display: flex;

    &-icon {
      width: 24px;

      &::before {
        content: "";
        display: block;
        margin: 2px auto 0;
        width: 20px;
        height: 20px;
        background: 0 0 transparent
          url("#{$imageURL-local}/icons/conditions/white/unconscious.svg")
          no-repeat;
      }
    }

    &-group {
      display: flex;
      align-items: center;
    }

    &-label {
      width: 15px;
      font-size: 12px;
      line-height: 1;
      text-transform: uppercase;
    }

    &-marks {
      display: flex;
    }

    &-mark {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid transparent;
      margin-left: 3px;

      &--inactive {
        border-color: #555;
        background-color: #222;
      }
    }

    &--fail &-mark {
      &--active {
        background-color: $ct--negative-color;
      }
    }
    &--success &-mark {
      &--active {
        background-color: $ct--positive-color;
      }
    }
  }
}
