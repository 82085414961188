.ct-mobile-divider {
  position: relative;
  margin: 10px 0 0;

  &--end {
    transform: rotateX(180deg);
    margin: 0;
  }

  &__divider {
    display: flex;
    height: 14px;
  }

  &__edge {
    width: 25px;
    height: 18px;

    &--last {
      transform: rotateY(180deg);
    }
  }

  &__repeat {
    flex: 1;
    height: 18px;

    .ddbc-beveled-edge-repeat-svg {
      width: 100%;
      height: 18px;
    }
  }

  &__label {
    font-weight: bold;
    text-align: center;
    font-family: $condensed;
    text-transform: uppercase;
    font-size: 14px;
    padding-top: 10px;
    background-color: #fff;

    &-text {
      position: relative;
    }

    &--dark-mode {
      background-color: $ct--theme-dark-mode-bg-mobile;
      .ct-mobile-divider__label-text {
        color: white;
      }
    }
  }
}
