.toggle {
  --inactive-color: var(--ttui_grey-400);
  --active-color: var(--ttui_color-primary--main);

  position: relative;
  display: flex;
  width: 2rem;
  height: 0.875rem;
  background-color: color-mix(
    in srgb,
    var(--inactive-color),
    var(--ttui_common-0) 40%
  );
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle:focus-visible:before {
  content: "";
  display: block;
  position: absolute;
  inset: -0.25rem;
  border-radius: 0.375rem;
  outline: var(--outline);
}

.toggle:disabled {
  cursor: not-allowed;
  background-color: color-mix(
    in srgb,
    var(--inactive-color),
    var(--ttui_common-0) 60%
  );
}

.secondary {
  --active-color: var(--ttui_color-secondary--main);
}

.success {
  --active-color: var(--ttui_color-success--main);
}

.info {
  --active-color: var(--ttui_color-info--main);
}

.warning {
  --active-color: var(--ttui_color-warning--main);
}

.error {
  --active-color: var(--ttui_color-error--main);
}

.themed {
  --active-color: var(--theme-color);
}

.toggle.checked {
  background-color: var(--active-color);
  background-color: color-mix(
    in srgb,
    var(--active-color),
    var(--ttui_common-0) 50%
  );
}

.toggle:after {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  left: 0;
  bottom: -0.1875rem;
  background-color: var(--inactive-color);
  border-radius: 50%;
  transition: transform 0.3s, background-color 0.3s;
  box-shadow: 0 0.063rem 0.063rem rgba(0, 0, 0, 0.3);
}

.toggle:disabled:after {
  background-color: color-mix(
    in srgb,
    var(--inactive-color),
    var(--ttui_common-0) 45%
  );
}

.toggle.checked:after {
  transform: translateX(0.75rem);
  background-color: var(--active-color);
}

.toggle.checked:disabled:after {
  background-color: color-mix(
    in srgb,
    var(--active-color),
    var(--ttui_common-0) 45%
  );
}
