.ct-infusion-choice-manager {
  &__header {
    font-weight: bold;
    @include contentGroupSeparatorTop($margin: 15px);
  }

  &__choice {
    margin-top: 10px;

    &--child {
      padding-left: 10px;
    }

    &--todo {
      .ddbc-select {
        border-color: $builder-default-color;
        box-shadow: 0 0 3px rgba($builder-default-color, 0.5);
      }
    }

    &--warning {
      .ddbc-select {
        border-color: $ct--negative-color;
        box-shadow: 0 0 3px rgba($ct--negative-color, 0.5);
      }
    }

    &-warning {
      color: $ct--negative-color;
      margin: 10px 0;
    }

    &-description {
      margin: 10px 0 20px;
    }
  }
}
