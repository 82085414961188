.hp-manager {
  &-label {
    font-weight: bold;
    margin-right: 7px;
    font-family: $condensed;
  }

  &-total {
    text-align: center;
    margin-bottom: 15px;

    &-label {
      font-size: 10px;
      color: #aaa;
      text-transform: uppercase;
    }

    &-value {
      font-weight: bold;
      font-size: 36px;
      line-height: 1;
    }
  }

  &-inputs {
    display: flex;
    max-width: 350px;
    margin: 0 auto 20px;
  }

  &-input {
    padding: 0 5px;
    width: percentage(1/3);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &-readonly {
      &-value {
        text-align: center;
        font-size: 20px;
        line-height: 40px;
      }
    }

    .builder-field {
      margin: 0;
    }

    .builder-field-heading {
      text-align: center;
    }

    .builder-field-value {
      text-align: center;
    }
  }

  &-sources {
    margin-bottom: 20px;

    &-heading {
      font-weight: bold;
    }
  }

  &-info {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;

    .builder-page-subheader {
      text-align: left;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }

  &-hitdice {
    width: 50%;

    &-heading {
      font-weight: bold;
    }
  }

  &-possibilities {
    width: 50%;
  }
}
