$character-tidbits--menu-status-color-open: $ct--theme-color !default;
$character-tidbits--menu-status-color-closed: $ct--theme-color !default;
$character-tidbits--tablet-transition: $bp-sheet-tablet-transition !default;

.ddbc-character-tidbits {
  display: flex;
  align-items: center;

  &--is-interactive {
    cursor: pointer;
  }

  &__body {
    flex: 1;
    min-width: 0;
    z-index: 1;
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__menu-callout {
    margin-left: 16px;

    //i'm sorry but this has to be done until button is less chaotic :(
    .ct-button.ct-theme-button {
      background-color: unset !important;
      color: #fff !important;

      &:hover,
      &:focus,
      &:visited,
      &:active {
        background-color: #111 !important;
      }
    }
  }

  &__progression {
    .ddbc-character-progression-summary {
      flex: 1;
      max-width: 300px;
    }
  }

  .ddbc-character-avatar {
    margin-right: 10px;
  }

  @media (min-width: $character-tidbits--tablet-transition) {
    min-width: 300px;

    .ddbc-character-avatar {
      margin-right: 15px;
    }

    .ddbc-character-summary {
      font-size: 12px;
    }
  }
}
