.ct-simple-quantity {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;

  &__label {
    font-weight: bold;
    margin-right: 5px;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  & &__input {
    width: 60px;
    margin: 0 3px;
    padding-right: 0;
    text-align: center;
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-simple-quantity {
    &__input,
    &__input:hover {
      @include darkModeInput();
    }
  }
}
