.confirmSpeciesHeader {
  display: flex;
  gap: 0.5rem;
  font-size: 0.925rem;
}

.fullName {
  margin: 0;
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.937rem;
  display: flex;
  align-items: center;
}

.image {
  width: 6.75rem;
  height: 6.75rem;
  border-radius: 0.1875rem;
}
