.tabs {
  --border-color: #96bf6b;
  --active-color: var(--ttui_grey-900);

  display: flex;
  gap: 1rem;
  list-style: none;
  padding: 0;
}

.tabButton {
  background: none;
  border: none;
  border-bottom: 0.188rem solid transparent;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.188rem 0;
  color: var(--character-muted-color);
  font-family: "Roboto Condensed", Roboto, Helvetica, sans-serif;
}

.tabButton > svg {
  fill: var(--character-muted-color);
}

.tabButton[aria-checked="true"] {
  border-color: var(--border-color);
  color: var(--active-color);
}

.tabButton[aria-checked="true"] > svg {
  transform: rotate(180deg);
  fill: var(--active-color);
}

.tabButton[aria-checked="true"] > svg.closed {
  transform: rotate(0deg);
  fill: var(--character-muted-color);
}

.tabButton:has(+ .morePopover[open]) {
  border-color: var(--border-color);
  color: var(--active-color);
}

.morePopover {
  border-color: transparent;
}

.moreMenu {
  padding: 0;
  margin: 0;
  list-style: none;
  width: auto;
}

.moreMenu button {
  width: 100%;
  display: flex;
  padding: 0.5rem;
}

.moreMenu button[aria-checked="true"] {
  background: var(--border-color);
}
