.ddbc-damage-type-icon {
  &__img {
    vertical-align: top;
    margin-right: 0;
    margin-left: 3px;
    width: 16px;
    height: 16px;
    display: inline-block;
  }
}
