.ct-tablet-box {
  &__border {
    border-left: 2px solid $ct--theme-color;
    border-right: 2px solid $ct--theme-color;
    margin: -7px -2px;
    border-radius: 2px;
  }

  &__inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 9px/14px;
    &--dark-mode {
      background-color: $ct--theme-dark-mode-bg;
    }
  }

  &__header {
    font-weight: bold;
    text-align: center;
    font-family: $condensed;
    text-transform: uppercase;
    font-size: 14px;
    background-color: #fff;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    &--dark-mode {
      background-color: unset;
    }
  }

  &__content {
  }
}
