.confirmModal {
  border-radius: 0;
  padding: 0;
  max-width: 31.25rem;
  width: 100%;
  height: 70%;
}

.confirmModal[open] {
  display: flex;
  flex-direction: column;
}

.header {
  background: var(--ttui_grey-800);
  color: var(--ttui_common-0);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.937rem;
  flex-shrink: 0;
}

.header h2 {
  margin: 0;
}

.header .closeIcon:only-child {
  fill: var(--ttui_common-0);
  width: 1.25rem;
  height: 1.25rem;
}

.content {
  padding: 1.25rem;
  overflow-y: auto;
  flex-grow: 1;
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-shrink: 0;
}

[class*="Button"].footerButton {
  border-radius: 0;
  color: var(--ttui_common-0);
}
