.checkboxesContainer {
  margin: 1.5rem 0;
}

.checkboxesContainerSidebar {
  margin-top: 0.4rem;
  padding-top: 0.6rem;
  border-top: 1px solid var(--theme-transparent);
}

.summary {
  font-size: 1em;
  line-height: 1.5;
}

.summaryHeadingGroup {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
}

.summaryHeadingGroupSingle {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
}

.summaryHeading {
  font-family: var(--font-condensed);
  font-weight: bold;
  font-size: 1.2em;
}

.summaryHeadingAll {
  font-family: var(--font-condensed);
  font-weight: normal;
  font-size: 1em;
}

.summaryHeadingSidebar {
  font-family: var(--font-condensed);
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
}

.summaryHeadingDefault {
  font-family: var(--font-default);
  font-weight: bold;
  font-size: 1.2em;
  text-transform: uppercase;
}

.summaryHeadingSidebarDark {
  color: var(--ttui_grey-400);
}

.summaryDescription {
  font-size: 1em;
  margin-top: 0.2rem;
  color: var(--ttui_grey-600);
}

.summaryDescriptionSidebar {
  font-size: 1em;
  margin-top: 0.4rem;
  color: var(--theme-contrast);
}

.container {
  margin-top: 0.5rem;
}

.group {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.description {
  font-size: 0.9rem;
  color: var(--ttui_grey-600);
  margin: 0 0 0 2.4rem;
}

.description p {
  margin: 0;
}

.descriptionDefault {
  font-family: var(--font-default);
  font-weight: 400;
  font-size: 1em;
}

.checkbox {
  margin-right: 0.6rem;
}

.groupLabel {
  flex: 1 1;
}

.groupLabelDefault {
  font-family: var(--font-default);
  font-weight: bold;
  font-size: 1.1em;
}

.accordion > summary:focus-visible {
  border: 0.125rem solid var(--ttui_blue-400);
}

.accordion > summary {
  display: flex;
  align-items: flex-start;
  padding: 1rem 0 0 2rem;
  font-weight: bold;
  font-size: 1rem;
}

.accordionHeadingDark > summary {
  color: var(--ttui_grey-400);
}

.accordionSidebarDescription {
  font-size: 1em;
  margin-top: 0.4rem;
  margin-left: -0.5rem;
  color: var(--theme-contrast);
}

.accordion > summary > div > svg {
  margin-left: unset;
}

.accordion > div {
  padding: 0 0 0 2.4rem;
}

.summaryBuilder {
  padding-top: 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

.accordionBuilder {
  padding-left: 0.25rem;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
}

.accordionSidebar > summary {
  font-family: var(--font-condensed);
  font-weight: bold;
  font-size: 1.5em;
  padding: 0;
  text-transform: uppercase;
}

.accordionSidebar > div {
  padding: 0 0 0 0.6rem;
}
