.bar {
  font-weight: bold;
  padding: 0.125rem 0.75rem;
  text-align: center;
  background: var(--ttui_grey-50);
  color: var(--ttui_grey-500);
  border: none;
  cursor: default;
}

.bar[aria-disabled="false"] {
  cursor: pointer;
}

.bar[aria-disabled="false"]:hover {
  background-color: var(--ttui_grey-600);
  color: var(--ttui_grey-100);
}

.bar.active {
  background-color: var(--theme-color);
  color: var(--ttui_common-0);
}

.bar.active[aria-disabled="false"]:hover {
  background-color: color-mix(
    in srgb,
    var(--theme-color),
    var(--ttui_common-1000) 10%
  );
  color: var(--ttui_common-0);
}

.bar.implied {
  background-color: color-mix(
    in srgb,
    var(--theme-color),
    var(--ttui_common-0) 40%
  );
  color: var(--ttui_common-0);
}

.bar.implied[aria-disabled="false"]:hover {
  background-color: color-mix(
    in srgb,
    var(--theme-color),
    var(--ttui_common-0) 20%
  );
}
