.home-manage {
  &__version {
    display: flex;
    justify-content: center;
    width: 100%;
    color: $ct--theme-de-emphasized-color;
    border-top: 1px solid #f1f1f1;
    margin-top: 15px;
    padding: 20px 0;
    font-size: 13px;

    &-label {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  @media (min-width: $bp-builder-mobile-transition) {
    &-primary {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 20px;
      margin-bottom: 20px;

      &-section {
        &-portrait {
          width: 100%;
        }

        &-progression {
          width: 40%;
          padding-left: 30px;
        }
      }
    }

    &-preferences {
      .ddbc-select {
        max-width: 50%;
      }
    }
  }
}
