.ct-vehicle-pane {
  &__header {
    &-lock {
      width: 14px;
      height: 18px;
      vertical-align: top;
      margin-right: 4px;
    }
  }

  &__customize {
    @include sidebarSeparator();
    @include sidebarSeparatorBottom();
  }

  .ct-vehicle-health-adjuster,
  .ct-vehicle-condition-tracker,
  .ct-vehicle-fuel-tracker {
    margin-top: 8px;
  }

  &__block {
    margin-top: 15px;
  }

  &__full-image {
    &-img {
      max-width: 300px;
      margin: 15px auto;
      display: block;
    }
  }

  &__description {
    margin: 10px 0;
  }

  &__actions {
    @include sidebarSeparator();
    display: flex;
    justify-content: center;
  }
}
