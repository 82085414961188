.ct-spells-slot-chooser {
  position: absolute;
  border: 1px solid #ccc;
  padding: 6px;
  z-index: 10;
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  left: 100%;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1px;

  &::before,
  &::after {
    content: "";
    display: block;
    border-right: 7px solid #fff;
    width: 0;
    height: 0;
    position: absolute;
    right: 100%;
    top: 50%;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    transform: translateY(-50%);
  }

  &::before {
    z-index: 2;
  }

  &::after {
    border-right-color: #ccc;
    z-index: 1;
    margin-right: 1px;
  }

  .ct-theme-button {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
