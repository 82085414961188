@import "@dndbeyond/ttui/components/Toast/Toast.module.css";

.toast,
.toast[open] {
  left: 50%;
  transform: scale(1) translateX(-50%);
  padding: 1rem;
}

.closeButton {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: none;
  border: none;
}

.closeIcon {
  width: 0.875rem;
  fill: var(--ttui_color-text--primary);
}

.title {
  font-weight: 500;
  font-size: 1rem;
  margin: 0 0 0.5rem;
}

.message {
  font-size: 0.875rem;
  margin: 0;
}

.success {
  background: var(--ttui_color-success--main);
  border-color: var(--ttui_color-success--main);
  color: var(--ttui_color-text--primary);
}

.warning {
  background: var(--ttui_color-warning--main);
  border-color: var(--ttui_color-warning--main);
  color: var(--ttui_color-text--primary);
}

.error {
  background: var(--ttui_color-error--main);
  border-color: var(--ttui_color-error--main);
  color: var(--ttui_color-error--contrast);
}

.info {
  background: var(--ttui_color-info--main);
  border-color: var(--ttui_color-info--main);
  color: var(--ttui_color-text--primary);
}
