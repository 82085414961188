.summary {
  width: 100%;
  max-height: none;
  display: flex;
  align-items: center;
  padding: 0.625rem;
  background: var(--ttui_common-0);
  border: 0.063rem solid var(--ttui_grey-200);
  border-radius: 0.625rem;
  min-height: 3.313rem;
  gap: 0.937rem;
  overflow: visible;
  text-align: left;
}

.summary:focus {
  border-color: var(--ttui_grey-900);
  box-shadow: 0 0 0.25rem rgba(36, 37, 39, 0.6);
  background-color: var(--ttui_grey-50);
  outline: 0;
}

.image {
  border-radius: 0.188rem;
  width: 2.5rem;
  height: 2.5rem;
}

.header {
  display: flex;
  align-items: center;
}

.heading {
  margin: 0;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.count {
  color: var(--ttui_grey-500);
  font-weight: 400;
}

.icon {
  margin-left: auto;
  width: 1.5rem;
  height: 100%;
  fill: var(--ttui_grey-400);
  stroke: var(--ttui_grey-400);
  transition: transform 0.3s;
  stroke-width: 1.5rem;
}

.details[open] .icon {
  transform: rotate(180deg);
}

.text {
  color: var(--ttui_grey-500);
  font-style: italic;
}

.text,
.metaItems {
  font-size: 0.75rem;
  line-height: 1;
  margin: 0;
}

.metaItems span + span:before {
  content: "•";
  margin: 0 0.25rem;
}

.error {
  color: var(--ttui_red-500);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
  margin-top: 0.25rem;
}
