.ct-subsection-group {
  &-inner {
    position: relative;

    &::after {
      content: "";
      display: block;
      background: center 0 transparent
        url("#{$imageURL-local}/ddb-borders-med.png") no-repeat;
      background-size: 105% 12px;
      position: absolute;
      right: 1px;
      left: 1px;
      bottom: 0;
      height: 12px;
    }
  }

  &-header {
    background-image: url("#{$imageURL-local}/character-sheet/section-group-header.png");
    background-size: 100% 100%;
    text-align: center;
  }

  &-heading {
    text-align: left;
    font-size: 20px;
    font-family: $condensed;
    text-transform: uppercase;
    font-weight: bold;
    padding: 15px 15px;
    color: #fff;
  }

  &-body {
    padding: 15px 0 20px;
    border-left: 1px solid #d4d0ce;
    border-right: 1px solid #d4d0ce;
    background: #fff;
    margin-top: -2px;

    &-inner {
    }
  }
}
