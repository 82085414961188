.numberColorOverride [class*="styles_"] {
  color: var(--ttui_grey-500);
}

.numberColorOverride :not([class*="styles_"]) {
  color: var(--ttui_common-0);
}

.box {
  position: relative;
  cursor: pointer;
  width: 4.375rem;
  margin: 0 auto;
}

.label {
  font-family: var(--font-condensed);
  font-size: 0.8125rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0.1875rem;
  color: var(--ttui_common-1000);
  text-shadow: var(--text-shadow-light);
}

.label.dark {
  text-shadow: var(--text-shadow-dark);
  color: var(--ttui_common-0);
}

.value {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.375rem;
  height: 2.8125rem;
  font-size: 1.625rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -0.0625rem;
  position: relative;
}

.value :global(.ddbc-svg) {
  width: 100%;
  height: 100%;
}

.value :global(.integrated-dice__container) {
  position: relative;
  width: 3.25rem;
  height: 2.125rem;
}

.advantage {
  position: absolute;
  left: -0.0625rem;
  bottom: -0.1875rem;
}

.advantage.mobile {
  left: 50%;
  top: -1.0625rem;
  transform: translateX(-50%);
  bottom: unset;
}

.advantageIcon {
  display: inline-flex;
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.125rem;
  margin-top: -0.125rem;
  border-radius: 0.75rem;
}

.boxMobile {
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-right: 0.625rem;
}

.valueMobile {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1.6875rem;
}

.labelMobile {
  text-transform: uppercase;
  font-family: var(--font-condensed);
  font-weight: bold;
  font-size: 0.6875rem;
}

@media (min-width: 1200px) {
  .box {
    width: 5.625rem;
  }

  .value {
    width: 5.625rem;
    height: 3.4375rem;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .value :global(.integrated-dice__container) {
    width: 2.625rem;
  }
}
