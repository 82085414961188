.editStateIndicator {
  position: absolute;
  padding-top: 0.1rem;
  font: var(--font-condensed);
  font-size: 0.8rem;
  background-color: var(--ttui_pink-600);
  height: 1.25rem;
  width: 100%;
  text-align: center;
  color: var(--ttui_common-0);
}

.editStateIndicatorBuilder {
  position: relative;
  margin-top: -0.29rem;
}

@media (min-width: 768px) {
  .editStateIndicatorBuilder {
    margin-top: -0.35rem;
  }
}

@media (min-width: 1024px) {
  .editStateIndicator {
    transform: translate(-50%, -50%);
    top: 0;
    left: 50%;
    right: 50%;
  }

  .editStateIndicatorBuilder {
    top: 0.6rem;
  }
}
