.detail-choice {
  &-description {
    margin: 10px 0;
  }

  &--todo {
    .ddbc-select {
      border-color: $builder-default-color;
      box-shadow: 0 0 3px rgba($builder-default-color, 0.5);
    }
  }

  &--child {
    padding-left: 10px;
  }

  &-notification {
    font-size: 11px;
    margin-bottom: 3px;
    color: $ct--theme-de-emphasized-color;
  }

  .ddbc-collapsible {
    margin-top: 5px;

    &__heading {
      font-size: 12px;
    }
  }
}
