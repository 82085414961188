.listing-pager {
  display: flex;
  justify-content: center;
  margin: 10px 0;

  &-control,
  &-page {
    box-shadow: inset 0 0 4px 0 #dcd1c7;
    border: solid 1px #cbc6c3;
    background: #fff;
    text-align: center;

    &-link {
      padding: 8px;
      display: block;
    }
  }

  &-page {
    min-width: 33px;

    & + & {
      margin-left: 5px;
    }

    &-active {
      background: $character-default-color;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3);
    }

    &-active &-link {
      color: #fff;
    }
  }

  &-disabled {
    background-color: #f2f2f2;
    box-shadow: inset 0 0 1px 0 #dcd1c7;
    border-color: #e2e2e2;
  }

  &-disabled &-control-link {
    color: #aaa;
  }

  &-control {
    flex-grow: 1;
  }

  &-next {
    margin-left: 5px;
  }

  &-prev {
    margin-right: 5px;
  }
}
