.confirmClassHeader {
  display: flex;
  gap: 0.5rem;
  font-size: 0.925rem;
  justify-content: space-between;
}

.name {
  margin: 0;
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.937rem;
  display: flex;
  align-items: center;
}

.image {
  width: 6.75rem;
  height: 6.75rem;
  border-radius: 0.1875rem;
}

.detailsLink {
  border-top: 0.063rem solid var(--ttui_grey-200);
  border-bottom: 0.063rem solid var(--ttui_grey-200);
}

.detailsLink a {
  width: 100%;
  justify-content: flex-start;
  text-transform: none;
}

.detailsLink svg:only-child {
  fill: var(--character-builder-blue);
  width: 0.875rem;
  height: 0.875rem;
}

.classFeatures {
  margin-top: 0.9375rem;
}

.description {
  font-size: 0.875rem;
}
