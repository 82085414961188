.ct-vehicle-block-action-station {
  &__heading {
    font-style: italic;
    font-weight: bold;
  }

  &__actions {
    .ct-vehicle-block__action {
      display: inline;
    }
  }

  &__attributes {
    border-left: 3px solid darken(#d8d8d8, 10%);
    padding: 5px 16px 5px 8px;
    margin-left: 12px;
    font-size: 14px;
    position: relative;

    &-callout {
      position: absolute;
      right: 0;
    }
  }

  &--interactive {
    cursor: pointer;

    &:hover .ct-manage-icon__icon {
      background-image: url("#{$imageURL-local}/icons/gear.svg");
    }
  }
}
