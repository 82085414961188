.ct-actions {
  &__manage-custom-link {
    float: right;
    margin-top: 2px;
    margin-right: 5px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: $condensed;
  }

  &__attacks {
    &-per-action {
      color: $ct--theme-text-color;
      text-transform: none;
      font-weight: normal;
      &--dark-mode {
        color: $ct--theme-dark-mode-text;
      }
    }

    &-restriction {
      font-size: 12px;
      font-weight: normal;
      text-transform: none;
      color: $ct--theme-de-emphasized-color;
      margin-bottom: 2px;
    }
  }
}
