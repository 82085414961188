.ct-inventory-filter {
  &__interactions {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__callout {
    padding-left: 10px;
  }

  &__box {
    flex: 1;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07) inset;
  }

  &__primary {
    display: flex;
    align-items: center;

    &-group {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    background: transparent center center
      url("#{$imageURL-local}/icons/search-grey.svg") no-repeat;
    background-size: 14px 14px;
    margin-right: 10px;
  }

  &__field {
    flex: 1;
  }

  &__input[type="search"] {
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    width: 100%;
    padding: 0;
    line-height: 24px;

    &:focus {
      border: none;
      box-shadow: none;
      background: transparent;
    }

    &:hover {
      background: transparent;
    }
  }

  &__clear {
    cursor: pointer;
    color: $ct--theme-color;
    font-weight: bold;
    font-family: $condensed;
    text-transform: uppercase;
    margin-left: 10px;
  }

  &__advanced {
    position: relative;
    width: 14px;
    height: 14px;
    margin-left: 10px;
    cursor: pointer;

    &--active {
    }

    &-callout {
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-bottom: 2px;
      border-radius: 3px;
      padding: 1px 4px;
      font-size: 10px;
      transform: translateX(-50%);
      background-color: $ct--theme-color;
      color: #fff;
    }
  }

  &__actives {
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 5px;
  }

  &__active {
    display: flex;
    background-color: $ct--theme-color;
    color: #fff;
    border-radius: 3px;
    padding: 5px 4px;
    font-size: 10px;
    line-height: 11px;
    cursor: pointer;
    font-family: $condensed;
    margin-right: 3px;
    margin-bottom: 3px;

    &:hover {
      background-color: darken($ct--theme-color, 10%);
    }

    &:last-child {
      margin-right: 0;
    }

    &-label {
      margin-right: 4px;
      font-weight: bold;
    }

    &-remove {
      display: flex;
      align-items: center;
      margin-left: 3px;

      &:hover {
        background-color: darken($ct--theme-color, 20%);
      }

      &-icon {
        width: 9px;
        height: 9px;
        background: -18px 0
          url("#{$imageURL-local}/sprites/charsheet-atlas-white.svg") no-repeat;
        background-size: auto 9px;
      }
    }
  }

  &__adv {
    &-filter {
      & + & {
        margin-top: 10px;
      }

      &-label {
        font-weight: bold;
        font-family: $condensed;
        padding: 3px 0;
      }

      &-options {
        display: flex;
        flex-wrap: wrap;
      }

      &-option-group {
        margin: 0 5px 5px 0;
        display: flex;
        align-items: center;
      }

      &-button {
        cursor: pointer;
        background-color: #eaeaea;
        padding: 3px 8px;
        border-radius: 3px;
        display: inline-flex;

        &:hover {
          background-color: darken(#eaeaea, 10%);
        }

        &--active {
          background-color: $ct--theme-color;
          color: #fff;

          &:hover {
            background-color: darken($ct--theme-color, 10%);
          }
        }
      }

      &-option {
        margin-bottom: 5px;
        margin-right: 5px;
      }

      &-checkbox {
        display: flex;
        align-items: center;

        &-label {
          margin-left: 5px;
        }
      }
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-inventory-filter {
    &__box {
      border-color: $ct--theme-dark-mode-text;
    }
    &__input[type="search"] {
      color: $ct--theme-dark-mode-text;
      &::placeholder {
        color: $ct--theme-dark-mode-text;
      }
    }
  }
}
