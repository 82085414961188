.ct-infusions {
  &__infusion {
    cursor: pointer;
    padding: 10px 0;

    & + & {
      border-top: 1px solid #d8d8d8;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &-header {
      font-weight: bold;
      font-family: $condensed;
      font-size: 15px;
      line-height: 1.2;
      margin-bottom: 5px;
    }

    &-item {
      cursor: pointer;

      & + & {
        @include contentGroupSeparatorTop();
        border-top: 1px dotted #eaeaea;
      }
    }

    &-empty {
      color: $ct--theme-de-emphasized-color;
      white-space: nowrap;
    }
  }

  &__empty {
    color: $ct--theme-de-emphasized-color;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    max-width: 250px;
    margin: 0 auto;
  }
}

@media (max-width: $bp-sheet-tablet-transition) {
  .ct-infusions {
    &__infusion {
      &-items {
        @include featureSnippetGrouping();
      }
    }
  }
}

@media (min-width: $bp-sheet-tablet-transition) {
  .ct-infusions {
    &__infusion {
      &-content {
        display: flex;
      }

      &-description {
        flex: 1;
      }

      &-items {
        width: 180px;
        margin-left: 12px;
      }
    }
  }
}
