.ct-notes-mobile {
  .ct-notes {
    @include mobileDividerContentBlock();
  }
}

.ct-character-sheet-mobile--dark-mode {
  .ct-notes-mobile {
    .ct-notes {
      background-color: $ct--theme-dark-mode-bg-mobile;
    }
  }
}
