@import "@dndbeyond/ttui/components/Button/styles/Button.module.css";
@import "@dndbeyond/ttui/components/Button/styles/ButtonVariants.module.css";
@import "@dndbeyond/ttui/components/Button/styles/ButtonSizes.module.css";

/*Themed*/
[class*="Button"].themed {
  border-color: var(--theme-color);
  color: var(--theme-contrast);
}

[class*="Button"].themed:hover,
[class*="Button"].themed:focus-visible {
  background: color-mix(in srgb, var(--theme-contrast), transparent 90%);
  border-color: var(--theme-color);
  color: inherit;
}

[class*="Button"].themed:disabled {
  opacity: 0.8;
}

/*Builder solid blue*/
[class*="Button"].builder:not(:disabled) {
  background: var(--character-builder-blue);
  border: none;
}

[class*="Button"].builder:not(:disabled):hover,
[class*="Button"].builder:not(:disabled):focus-visible {
  background-color: color-mix(
    in srgb,
    var(--character-builder-blue),
    var(--ttui_grey-800) 20%
  );
  border: none;
}

/*Builder text blue*/
[class*="Button"].builder-text:not(:disabled) {
  background: transparent;
  border: none;
  color: var(--character-builder-blue);
}

[class*="Button"].builder-text:not(:disabled):hover,
[class*="Button"].builder-text:not(:disabled):focus-visible {
  background-color: color-mix(
    in srgb,
    var(--character-builder-blue),
    transparent 80%
  );
  border: none;
}

/* Builder Default Green - solid and outline*/
[class*="Button"].builder-green.solid {
  background: var(--character-default-color);
  border-color: var(--character-default-color);
}

[class*="Button"].builder-green.solid:hover,
[class*="Button"].builder-green.solid:focus-visible {
  background-color: color-mix(
    in srgb,
    var(--character-default-color),
    var(--ttui_grey-800) 20%
  );
  border-color: var(--character-default-color);
}
[class*="Button"].builder-green.outline {
  background-color: var(--ttui_common-0);
  border-color: var(--character-default-color);
  color: var(--character-default-color);
}

[class*="Button"].builder-green.outline:hover,
[class*="Button"].builder-green.outline:focus-visible {
  box-shadow: 0 0 2px
    color-mix(in srgb, var(--character-default-color), var(--ttui_grey-800) 20%);
}

/*Force Theme Mode styles*/
[class*="Button"].dark {
  color: var(--ttui_grey-50);
}
[class*="Button"].light {
  color: var(--ttui_grey-900);
}

/*Custom size and themed styles for character app*/
.xx-small {
  font-size: 0.55rem;
  padding: 0.313rem 0.625rem;
  gap: 0.313rem;
  line-height: 1;
  letter-spacing: normal;
  font-family: var(--font-condensed);
}
.xx-small svg {
  height: 0.875rem;
  width: 0.875rem;
}

[class*="Button"].x-small.themed.solid {
  background-color: var(--theme-color);
}

[class*="Button"].x-small.themed.solid:not(.dark) {
  color: var(--ttui_common-0);
}

[class*="Button"].x-small.themed.solid:hover,
[class*="Button"].x-small.themed.solid:focus-visible {
  background-color: color-mix(
    in srgb,
    var(--theme-color),
    var(--ttui_grey-800) 20%
  );
  border-color: color-mix(
    in srgb,
    var(--theme-color),
    var(--ttui_grey-800) 20%
  );
}

[class*="Button"].x-small.themed.outline:not(.dark) {
  color: var(--theme-color);
}

.themed.xx-small {
  color: var(--theme-contrast);
}

.themed.xx-small.outline:not(.dark) {
  --theme-contrast: var(--theme-color);
}

.themed.xx-small.solid:not(.dark) {
  --theme-contrast: var(--ttui_grey-50);
}

.themed.xx-small:hover,
.themed.xx-small:focus-visible {
  background: color-mix(in srgb, var(--theme-color), transparent 80%);
  border-color: var(--theme-color);
}

.themed.xx-small.outline:not(.dark):hover,
.themed.xx-small.outline:not(.dark):focus-visible {
  background: var(--theme-background);
  box-shadow: 0 0 2px
    color-mix(in srgb, var(--theme-color), var(--ttui_grey-800) 20%);
  color: var(--theme-color);
}

.themed.xx-small.solid {
  background: var(--theme-color);
  color: var(--theme-contrast);
}

.themed.xx-small.solid:hover,
.themed.xx-small.solid:focus-visible {
  background: color-mix(in srgb, var(--theme-color), var(--ttui_grey-800) 20%);
  color: var(--theme-contrast);
}
