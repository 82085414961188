.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: "Roboto Condensed", Roboto, Helvetica, sans-serif;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 0.813rem;
  line-height: 0.938rem;
  text-align: center;
  letter-spacing: 0.063rem;
  text-transform: uppercase;
  color: #75838b;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}
