/* These will override any variables from the character sheet theme inside the builder*/

.characterBuilder {
  --theme-color: #c53131;
  --theme-background: #fefefe;
  --theme-background-solid: #fefefe;
  --theme-contrast: var(--ttui_grey-900);
  --theme-transparent: color-mix(in srgb, var(--theme-color), transparent 40%);
  --character-muted-color: var(--ttui_grey-500);
}

[data-theme="dark"] .characterBuilder [class*="styles_itemName"] {
  --rt-color-dark: var(--ttui_grey-800);
  --rt-color-white: var(--ttui_grey-100);
  --color-common: var(--ttui_common-1000);
  --color-uncommon: #1fc219;
  --color-rare: #4990e2;
  --color-veryrare: #9810e0;
  --color-legendary: #fea227;
  --color-artifact: #be8972;
}
