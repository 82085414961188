.modal-collapsible {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #edeae8;

  &-header {
    display: flex;
    cursor: pointer;
  }

  &-heading {
    font-family: $condensed;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    flex: 1;
  }

  &-trigger {
    min-width: 16px;
    height: 16px;
    width: 16px;
    background: transparent 0 0
      url("#{$imageURL-local}/sprites/charsheet-atlas.svg") no-repeat;
    background-size: cover;
    margin-left: 9px;

    .modal-collapsible-opened & {
      background-position: -17px 0;
    }

    .modal-collapsible-collapsed & {
      background-position: 0 0;
    }
  }
}
