.dialog {
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 0.25rem
    color-mix(in srgb, var(--ttui_grey-900), transparent 80%);
  max-height: calc(100% - 4rem);
  max-width: 36rem;
  min-width: 20rem;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
}

.dialog::backdrop {
  background: var(--ttui_grey-900);
  opacity: 0.5;
}
