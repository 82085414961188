$spell-dammage-effect--scaled-color: $builder-default-color !default;
$spell-dammage-effect--font-family: $condensed !default;

.ddbc-spell-damage-effect {
  &--dark-mode {
    color: $ct--theme-dark-mode-text;
  }

  &--scaled {
    color: $spell-dammage-effect--scaled-color;
  }

  .ddbc-damage {
    font-size: 14px;
  }

  &__conditions,
  &__tags {
    font-size: 12px;
    font-family: $spell-dammage-effect--font-family;
    &--dark-mode {
      color: $ct--theme-dark-mode-text;
    }
  }
}
