//Builder vars
$builder-sections-height: 53px;
$todo-nav-height: 40px;

$builder-image-border-radius: 3px;

$bp-builder-mobile-transition: 768px;

$bp-sheet-tablet-transition: 768px;
$bp-sheet-desktop-transition: 1024px;
$bp-sheet-desktop-large-transition: 1200px;

$ct--theme-color: #c53131;
$ct--theme-de-emphasized-color: #75838b; // #979AA4
$ct--theme-border-color: #eaeaea;
$ct--theme-hover-color: #f2f2f2;
$ct--theme-background-color: #fefefe;
$ct--theme-text-color: #000;
$ct--theme-dark-mode-background-color: #10161a;
$ct--theme-dark-mode-text: #a2acb2; // #b0b7bd - updated with TTUI grey-400 equivalent;
$ct--theme-dark-mode-white-opacity-text: rgba(255, 255, 255, 87%);
$ct--theme-dark-mode-bg: #10161adb;
$ct--theme-dark-mode-bg-mobile: #10161a;
$ct--theme-dark-mode-secondary-bg: #202b33;
$ct--theme-dark-mode-border-gray: #616f7a;
$ct--button-positive-color: #35b000;
$ct--positive-color: #40d250;
$ct--dark-mode-positive-color: #00c797;
$ct--negative-color: #d24040;
$ct--dark-mode-negative-color: #c53131;
$ct--modified-color: #1c9aef; //$builder-color
$ct--builder-color: #1c9aef; //$builder-color
$ct--temp-hp-color: #96bf6b;

$ct--dice-callout-color: #1b9af0;
$ct--dice-background-color: #ced9e0;
$ct--dice-border-color: #bfccd6;
$ct--dice-text-color: #394b59;

// ##BUILD_REPLACE## $imageURL-local: "https://test2.dndbeyond.com/Content/Skins/Waterdeep/images":
$ct--ddb-image-url: "https://www.dndbeyond.com";
