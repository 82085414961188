@mixin sidebarSeparator($padding: 10px, $margin: 10px) {
  margin-top: $margin;
  padding-top: $padding;
  border-top: 1px solid #eaeaea;
}

@mixin sidebarSeparatorBottom($padding: 10px, $margin: 10px) {
  margin-bottom: $margin;
  padding-bottom: $padding;
  border-bottom: 1px solid #eaeaea;
}
@mixin sidebarSeparatorTop($padding: 10px, $margin: 10px) {
  margin-top: $margin;
  padding-top: $padding;
  border-top: 1px solid #eaeaea;
}

@mixin sidebarContentBox() {
  border: 1px solid #fff;
  padding: 10px;
  margin-top: 5px;
  background: #f9f9f9;
  box-shadow: 0 0 0 1px #e0e0e0;
}

@mixin featureSnippetGrouping() {
  border-left: 3px solid #d8d8d8;
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
}

@mixin mobileContentSeparatorTop($padding: 10px, $margin: 10px) {
  margin-top: $margin;
  padding-top: $padding;
  border-top: 1px solid #eaeaea;
}

@mixin mobileContentSeparatorBottom($padding: 10px, $margin: 10px) {
  margin-bottom: $margin;
  padding-bottom: $padding;
  border-bottom: 1px solid #eaeaea;
}

@mixin tabletContentSeparatorTop($padding: 10px, $margin: 10px) {
  @include mobileContentSeparatorTop($padding, $margin);
}

@mixin tabletContentSeparatorBottom($padding: 10px, $margin: 10px) {
  @include mobileContentSeparatorBottom($padding, $margin);
}

@mixin contentGroupSeparatorTop($padding: 10px, $margin: 10px) {
  @include mobileContentSeparatorTop($padding, $margin);
}

@mixin contentGroupSeparatorBottom($padding: 10px, $margin: 10px) {
  @include mobileContentSeparatorBottom($padding, $margin);
}

@mixin mobileDividerContentBlock() {
  padding: 10px;
  background: #fff;
}

@mixin interactiveContent($cursor-type: pointer) {
  cursor: $cursor-type;
}

@mixin darkModeInput() {
  &,
  &:hover {
    background-color: $ct--theme-dark-mode-background-color;
    border-color: $ct--theme-dark-mode-border-gray;
    color: $ct--theme-dark-mode-text;
  }
  &:active,
  &:focus {
    background-color: $ct--theme-dark-mode-background-color;
    border-color: $ct--theme-dark-mode-text;
  }
  &:disabled {
    color: $ct--theme-dark-mode-border-gray;
  }
  &::placeholder {
    color: darken($ct--theme-dark-mode-text, 20%);
  }
}
