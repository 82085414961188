.ct-preferences-pane {
  &__field {
    & + & {
      margin-top: 25px;
    }

    &--block {
      display: block;
    }

    &-heading {
      margin: 0;
      display: flex;
      justify-content: space-between;
      @include sidebarSeparator(10px, 10px);
      @include sidebarSeparatorBottom(10px, 10px);
      border-bottom: 0;
    }

    &-summary {
      flex: 1;
    }

    &-description {
      margin-top: 3px;
    }

    &-input {
      margin-top: 5px;
    }

    .ddbc-select {
      width: 50%;
    }

    &-toggles {
      margin-top: 15px;
    }

    &-toggle {
      margin-top: 8px;
      display: flex;
      align-items: center;
    }

    &-toggle &-label {
      flex: 1;
    }

    &-toggle &-input {
      margin-right: 10px;
      margin-top: 0;
    }
  }

  &__actions {
    @include sidebarSeparator();
    display: flex;
    justify-content: center;
  }

  &__action {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__version {
    @include sidebarSeparator();
    display: flex;
    justify-content: center;
    width: 100%;
    color: $ct--theme-de-emphasized-color;
    padding: 20px 0;

    &-label {
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
