.ct-skill-pane {
  &__header {
    display: flex;
    align-items: center;

    &-icon {
      margin-bottom: 2px;
    }

    &-ability {
      color: $ct--theme-de-emphasized-color;
      margin-left: 10px;
    }

    &-modifier {
      margin-left: 5px;
    }

    &-name {
      margin-left: 10px;
    }
  }

  &__customize {
    @include sidebarSeparator();

    .ct-editor-box {
      margin: 5px 0 10px;
    }
  }

  &__dice-adjustments {
    @include sidebarSeparator();
  }

  &__properties {
    @include sidebarSeparator();
  }

  &__description {
    @include sidebarSeparator();
  }
}
