.ct-equipment-shop {
  &__items {
    margin: 10px 0;
  }

  &__item {
    & + & {
      @include sidebarSeparator($padding: 5px, $margin: 5px);
    }

    &-actions {
      display: flex;
      align-items: center;
      @include sidebarSeparator();
    }

    &-action {
      & + & {
        margin-left: 5px;
      }
    }

    &-amount {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      &-label {
        font-weight: bold;
        margin-right: 5px;
      }

      &-controls {
        display: flex;
        align-items: center;
      }

      & &-input {
        width: 60px;
        margin: 0 3px;
        padding-right: 0;
        text-align: center;
      }
    }

    > .ddbc-collapsible__content {
      @include sidebarContentBox();
      margin-bottom: 15px;
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-equipment-shop__item {
    & > .ddbc-collapsible__content {
      background-color: $ct--theme-dark-mode-background-color;
    }
    &-amount-input,
    &-amount-input:hover {
      @include darkModeInput();
    }
  }
}
