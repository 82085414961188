$saving-throws-summary--font-family: $condensed !default;

.ddbc-saving-throws-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  &__ability {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    width: 107px;
    height: 34px;

    &-proficiency {
      width: 5px;
      position: relative;
    }

    &-name {
      flex: 1;
      text-transform: uppercase;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      font-family: $saving-throws-summary--font-family;
      &--dark-mode {
        color: white;
      }
    }

    &-modifier {
      width: 40px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }

    &--str {
      order: 1;
    }

    &--int {
      order: 2;
      justify-content: flex-end;
    }

    &--dex {
      order: 3;
    }

    &--wis {
      order: 4;
      justify-content: flex-end;
    }

    &--con {
      order: 5;
      margin-bottom: 3px;
    }

    &--cha {
      order: 6;
      margin-bottom: 3px;
      justify-content: flex-end;
    }

    &-situational {
      &-icon {
        position: absolute;
        right: -6px;
        top: 0;
        display: inline-flex;
        vertical-align: middle;
        width: 14px;
        height: 14px;
        background-color: #fff;
        border-radius: 9px;
      }
    }

    .ddbc-proficiency-level-icon {
      position: absolute;
      left: -3px;
      top: -50%;
      transform: translate(0, -50%);
    }

    .ddbc-saving-throw-row-box-svg,
    .ddbc-saving-throw-row-small-box-svg {
      width: 100%;
      height: 100%;
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ddbc-saving-throws-summary__ability-situational-icon {
    background-color: unset;
  }
}
