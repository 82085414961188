.ct-character-sheet {
  $border-height: 1px;
  border-top: $border-height solid #333;

  &--dark-mode {
    color: $ct--theme-dark-mode-text;
  }

  &__inner {
    max-width: $site-width;
    margin: 0 auto;
    transition: all 150ms;
  }

  &--failed {
    padding-top: 75px;
  }

  &__failed {
    max-width: 900px;
    padding-top: 260px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    font-size: 20px;
    background: center 20px transparent url("#{$imageURL-local}/errors/500.png")
      no-repeat;
    background-size: 280px;
    text-align: center;

    &--access_denied {
      background-image: url("#{$imageURL-local}/errors/403.png");
    }

    &--not_found {
      background-image: url("#{$imageURL-local}/errors/404.png");
    }

    &-code {
      display: flex;
      align-items: center;
      margin: 10px 0;

      &-label {
        padding-right: 20px;
        text-align: right;
        font-weight: bold;
        font-size: 16px;
      }

      &-value {
        min-width: 0;

        font-family: monospace;
        background: #f2f2f2;
        padding: 3px 10px;
        border: 1px solid #ccc;
        overflow: auto;
        white-space: pre-wrap;
        font-size: 13px;
      }
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  .sync-blocker {
    z-index: 60003;
  }

  // Hiding ugly un-formatted html
  // this class is applied to creature/vehicle plain text stat blocks that render within compendium pages
  .basic-text-frame {
    display: none;
  }

  @media (min-width: $bp-sheet-tablet-transition) {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: $border-height;
      left: 0;
      right: 0;
      height: 104px;
      background-color: #2b2b2a;
    }

    &--invalid::before,
    &--failed::before {
      display: none;
    }

    &__failed {
      background-position: left 30px;
      background-size: 350px;
      padding-left: 350px;
      padding-top: 0;
      min-height: 330px;
      display: flex;
      align-items: center;
      text-align: left;
    }
  }

  @media (min-width: $bp-sheet-desktop-transition) {
    & {
      padding-top: 0.625rem;
    }

    .sync-blocker {
      z-index: 40003;
    }
  }

  @media (min-width: $bp-sheet-desktop-large-transition) {
    .sync-blocker {
      z-index: 13;
    }

    &::before {
      height: 115px;
    }
  }
}
