.xpManagerModal {
  height: fit-content;
  max-width: 25rem;
}

.xpManager [class*="Button"] {
  color: var(--ttui_common-1000);
}

.xpManager h2 {
  text-align: center;
}
