.ct-subsection {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &-content {
      flex-grow: 1;
      font-family: $condensed;
      font-size: 13px;
    }
  }

  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    font-size: 13px;
    text-transform: uppercase;
    font-family: $condensed;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
}
