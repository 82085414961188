.container {
  background: var(--ttui_common-0);
  border-radius: 0.1875rem;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
  margin: 0.625rem 0;
  padding: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  font-family: var(--font-condensed);
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

.preview {
  padding: 0.625rem 0 0;
  white-space: pre-wrap;
}

.dialog {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: none;
  border-radius: 0;
  padding: 0;
  overflow: hidden;
}

.modalHeader {
  background-color: var(--character-builder-black);
  color: var(--ttui_common-0);
  display: flex;
  height: 3.75rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.9375rem;
  margin-bottom: 0.75rem;
}

.modalHeading {
  text-transform: uppercase;
  font-family: var(--font-condensed);
}

.closeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.closeButton svg {
  fill: var(--ttui_common-0);
}

.content {
  padding: 0.5rem 1.5rem;
  overflow-y: auto;
  max-height: calc(100% - 3.75rem);
}

.title {
  font-family: var(--font-condensed);
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 0.625rem;
}

.title > * {
  margin: 0 0 0.375rem;
  font-family: var(--font-condensed);
  font-size: 0.875rem;
}

.textarea > textarea,
.textarea:after {
  font-family: var(--font-sans);
  font-size: 0.9375rem;
  line-height: 1.4063rem;
}

.extra p {
  font-size: 0.9375rem;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .dialog {
    height: 70%;
    width: 31.25rem;
  }
}
