.ct-dice-preference-manager {
  border: 1px solid $ct--theme-color;
  border-radius: 4px;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  &__primary {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    background-color: #393b40;
    cursor: pointer;

    &-info {
      display: flex;
      flex-flow: column;
      margin-right: 16px;

      &-icon {
        display: flex;
      }

      &-version {
        background: $ct--dice-callout-color;
        color: white;
        padding: 0 5px;
        margin: 2px 10px;
        font-size: 11px;
        height: 15px;
      }
    }
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    &-input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .ct-dice-preference-manager__switch-slider {
        background-color: $ct--dice-callout-color;
      }

      &:focus + .ct-dice-preference-manager__switch-slider {
        box-shadow: 0 0 1px $ct--dice-callout-color;
      }

      &:checked + .ct-dice-preference-manager__switch-slider:before {
        transform: translateX(26px);
      }
    }

    &-slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $ct--dice-callout-color;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        border-radius: 50%;
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
      }
    }
  }

  &__link-out {
    background: $black;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    &-label {
      color: #fff;
      font-size: 12px;
    }

    &:hover {
      background-color: lighten(#242528, 3%);
    }

    .ddbc-link-out-svg {
      width: 12px;
      height: 12px;
      margin: 0 10px;
    }
  }

  .ddbc-dice-svg {
    height: 20px;
    width: 18px;
  }
}
