.ct-custom-skill-pane {
  &__header {
    display: flex;
    align-items: center;

    &-icon {
      margin-bottom: 2px;
    }

    &-ability {
      color: $ct--theme-de-emphasized-color;
      margin-left: 10px;
      text-transform: uppercase;
    }

    &-modifier {
      margin-left: 5px;
    }

    &-name {
      margin-left: 10px;
    }
  }

  &__edit {
    @include sidebarSeparator();
  }

  &__properties {
    @include sidebarSeparator();
  }

  &__description {
    @include sidebarSeparator();
    white-space: pre-wrap;
  }

  &__actions {
    @include sidebarSeparator();
    text-align: center;
  }
}
