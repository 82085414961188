@import "@dndbeyond/ttui/components/Checkbox/Checkbox.module.css";

[class*="Checkbox"].themed input:checked {
  background: var(--theme-color) !important;
}

[class*="Checkbox"] input[aria-disabled="true"]:not(:checked) {
  opacity: 0.4;
  background: var(--ttui_grey-300);
  cursor: not-allowed;
}

[class*="Checkbox"] input[aria-disabled="true"]:checked {
  opacity: 0.4;
  cursor: not-allowed;
}

[class*="Checkbox"].themed input[aria-disabled="true"]:not(:checked) {
  opacity: 0.4;
  background: var(--ttui_grey-300);
  cursor: not-allowed;
}

[class*="Checkbox"].themed input:checked[aria-disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed;
}

[class*="Checkbox"].darkMode span:hover,
[class*="Checkbox"].darkMode span:focus-visible {
  background-color: inherit;
}

[class*="Checkbox"].darkMode input:hover,
[class*="Checkbox"].darkMode input:focus-visible {
  background: var(--theme-contrast);
  opacity: 0.5;
}

[class*="Checkbox"].default input:checked {
  background: var(--ttui_color-info--main);
}

[class*="Checkbox"].builder input:checked {
  background: var(--ttui_red-500);
}
