.featList {
  margin: 0.625rem 0;
}

.featList h2 {
  margin: 0;
}

.featList > :global(.ddbc-collapsible) {
  border-bottom: 0.0625rem solid
    color-mix(in srgb, var(--character-muted-color), transparent 50%);
  margin-bottom: 1.25rem;
}

.featList :global(.ddbc-collapsible__content) {
  margin: 0.625rem 0;
}

.featList :global(.ddbc-collapsible__heading) {
  flex-direction: column;
  align-items: flex-start;
}

.unavailableCallout {
  font-weight: normal;
  font-size: 0.6875rem;
  color: var(--character-muted-color);
}
