@mixin overflowEllipsis() {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ct-inventory-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 8px 0;

  & + & {
    border-top: 1px dotted #eaeaea;
  }

  &__meta {
    font-size: 10px;
    color: $ct--theme-de-emphasized-color;
    font-family: $condensed;
    &--dark-mode {
      color: $ct--theme-dark-mode-text;
    }

    &-item {
      & + &::before {
        content: "\2022";
        padding: 0 3px;
      }
    }
  }

  &__action {
    width: 40px;
  }

  &__name {
    width: 200px;
  }

  &__weight {
    width: 50px;
    @include overflowEllipsis();
  }

  &__quantity {
    width: 35px;
    @include overflowEllipsis();
    &--dark-mode {
      color: white;
    }
  }

  &__cost {
    width: 50px;
    @include overflowEllipsis();
    &--dark-mode {
      color: white;
    }
  }

  &__notes {
    flex: 1;
    font-size: 12px;
    color: $ct--theme-de-emphasized-color;
    font-family: $condensed;
  }
}
