.ct-subsection--equipment {
  .tab-list-nav {
    left: -10px;
    right: -10px;
  }
}

.equipment {
  &-list {
    & + & {
      border-top: 1px solid #edeae8;
      margin-top: 20px;
      padding-top: 20px;
    }

    &-header {
      display: flex;
      font-family: $condensed;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;

      &-name {
        flex-grow: 1;
      }
    }

    &-heading {
      display: flex;
      align-items: center;

      &-text {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-icons {
        padding-left: 5px;
      }

      &-icon {
        vertical-align: middle;
        margin: 0;
        top: -5px;
      }
    }

    &-item {
      &-callout {
        display: flex;
        align-items: center;

        &-quantity {
          display: flex;
          align-items: center;

          &-extra {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 62px;
            text-align: right;
            padding-right: 5px;
            display: block;
            line-height: 1;
          }

          &-value {
            font-size: 20px;
          }
        }

        &-action {
          .character-button-small {
            min-width: 45px;
          }
        }

        &-quantity + &-action {
          margin-left: 5px;
        }
      }

      &-cast {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 3px solid #edeae8;
        text-align: center;

        &-action {
          margin-bottom: 14px;
        }
      }

      &-damage {
        font-size: 16px;

        &-effect {
          margin-left: 5px;
        }
      }

      .ability-pool-list + .prop-list {
        border-top: 1px solid #eaeaea;
        padding-top: 20px;
        margin-top: 20px;
      }

      &-desc {
        padding-top: 20px;
        border-top: 1px solid #edeae8;
        overflow-x: hidden;
      }

      .collapsible-header-icon {
        background-color: #f2f2f2;
        border: 1px solid #eaeaea;
        border-radius: 3px;
        background-size: cover;
      }

      &-actions {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 0;
        border-top: 1px solid #f1f1f1;
        padding-top: 10px;
        justify-content: center;
        align-items: center;

        .character-button-small {
          margin: 0 5px;
          min-width: 40px;
        }
      }

      &-consumer {
        width: 100%;
      }

      &-action {
        & + & {
          margin-left: 10px;
        }
      }

      &-remove {
        display: flex;
        align-items: center;
        font-size: 12px;
        cursor: pointer;

        &-icon {
          position: relative;
          width: 24px;
          height: 24px;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -6px;
            margin-top: -6px;
            width: 12px;
            height: 12px;
            background: -26px 0
              url("#{$imageURL-local}/sprites/charsheet-atlas-red.svg")
              no-repeat;
            background-size: 38px 12px;
          }
        }
      }

      &-amount {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;

        &-label {
          font-weight: bold;
          margin-right: 5px;
        }

        &-controls {
          display: flex;
          align-items: center;
        }

        & &-input {
          width: 60px;
          margin: 0 3px;
          padding-right: 0;
          text-align: center;
        }

        .equipment-manager-shop & {
          min-width: 300px;
        }
      }
    }

    .ability-pool-list + .prop-list {
      border-top: 1px solid #edeae8;
      padding-top: 20px;
      margin-top: 20px;
    }
  }
}

.currency-list {
  &-gp-total {
    margin: 10px 0 15px;
    text-align: center;

    &-heading {
      color: $ct--theme-de-emphasized-color;
      font-size: 11px;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &-count {
      font-size: 28px;
      font-weight: bold;
      font-family: $font-family;
      line-height: 1.1;
    }
  }

  &-item {
    &-row {
      position: relative;
      z-index: 2;
      display: flex;
      padding-left: 10px;
      padding-right: 8px;
      align-items: center;
      height: 65px;
      background: url("#{$imageURL-local}/listing-bars/1a.png") no-repeat;
      background-size: 100% 65px;
      transition: background-image 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    }

    &-icon {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }

    &-info {
      flex: 1;
      min-width: 0;
    }

    &-name {
      font-size: 18px;
      font-weight: bold;
      font-family: $font-family;
      line-height: 1.1;
    }

    &-conversion {
      color: $ct--theme-de-emphasized-color;
      font-size: 10px;
      font-family: $font-family;
      text-transform: uppercase;
    }

    &-value {
      // have to override extra properties because of generic site styles :(
      &-input[type="number"] {
        box-shadow: inset 0 0 4px 0 #dcd2c7;
        border: solid 1px #cbc6c3;
        margin: 0;
        padding: 0;
        text-align: center;
        line-height: 38px;
        font-size: 20px;
        width: 80px;
        border-radius: 0;
      }
    }

    &__error {
      display: flex;
      justify-content: center;
      text-align: center;

      &-text {
        font-size: 12px;
        color: $ct--negative-color;
      }
    }
  }
}
