.equipment-manager {
  &-total-weight {
    font-family: $condensed;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;

    &-label {
      margin-right: 5px;
      text-transform: uppercase;
    }
  }

  &-attuned {
    margin: 15px 0;

    &-heading {
      font-family: $condensed;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 5px;
      text-align: center;
    }

    &-slots {
      display: flex;
      flex-wrap: wrap;
      max-width: 360px;
      margin: 0 auto;
    }

    &-slot {
      width: calc(33% - 5px);
      margin-right: 5px;
      margin-bottom: 5px;
      position: relative;
      cursor: pointer;
    }

    &-item {
      &-preview {
        border-radius: 3px;
        border: 1px solid $character-default-color;
        height: 50px;
        background-size: cover;
        background-position: center center;
        background-color: #f2f2f2;
        position: relative;
        z-index: 2;
      }

      &-name {
        font-size: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        background-color: $character-default-color;
        color: #fff;
        padding: 4px 5px 2px;
        text-align: center;
        margin-top: -4px;
        border-radius: 0 0 3px 3px;
      }
    }

    &-add {
      border-radius: 3px;
      border: 1px dashed $character-default-color;
      height: 50px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -8px;
        margin-left: -8px;
        display: block;
        height: 16px;
        width: 16px;
        background: url("#{$imageURL-local}/sprites/charsheet-atlas.svg")
          no-repeat 0 0;
        background-size: cover;
      }
    }
  }

  &-info {
    margin: 15px 0;
    font-family: $condensed;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;

    &-primary {
      flex-grow: 1;
    }
  }

  &-attuner {
    box-shadow: inset 0 0 4px 0 rgba(139, 178, 199, 0.15);
    border: 1px solid #d8dde3;
    margin-bottom: 15px;

    &-group {
      padding: 15px 10px;

      & + & {
        border-top: 1px solid #d8dde3;
      }
    }

    &-list {
      padding: 10px;
    }

    &-item {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.3;
      cursor: pointer;

      & + & {
        margin-top: 10px;
      }
    }

    &-none {
      font-size: 14px;
      color: #ccc;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-radius: 3px;

    & + & {
      border-top: 1px solid #f6f6f4;
    }

    &-name {
      flex-grow: 1;
      font-size: 14px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-status {
      margin-right: 7px;

      .character-button-small {
        width: 46px;
      }
    }

    &-remove {
      position: relative;
      width: 24px;
      height: 24px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -6px;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        background: -26px 0
          url("#{$imageURL-local}/sprites/charsheet-atlas-red.svg") no-repeat;
        background-size: 38px 12px;
      }
    }
  }

  &-add {
    &-show,
    &-hide {
      margin-bottom: 10px;
    }
  }

  &-inventory {
    margin-top: 20px;

    &-group {
      margin-top: 5px;
    }

    &-header {
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #f1f1f1;

      .equipment-manager-inventory-group-opened > & {
        border-left: 3px solid $character-default-color;
      }
    }

    &-heading {
      font-size: 15px;
      font-weight: bold;
      flex: 1;
      display: flex;
      align-items: center;

      &-notification {
        content: "!";
        background: #f31800;
        width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 50%;
        z-index: 3;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin-left: 5px;
      }

      &-extra {
        font-weight: normal;
        margin-left: 5px;
        font-size: 12px;
      }
    }

    &-trigger {
      min-width: 16px;
      height: 16px;
      width: 16px;
      background: transparent 0 0
        url("#{$imageURL-local}/sprites/charsheet-atlas.svg") no-repeat;
      background-size: cover;
      margin-left: 9px;
      cursor: pointer;

      .equipment-manager-inventory-group-opened & {
        background-position: -17px 0;
      }

      .equipment-manager-inventory-group-collapsed & {
        background-position: 0 0;
      }
    }

    &-content {
      margin: 10px 0;
    }
  }

  &-empty {
    padding: 10px;
    text-align: center;
  }

  &-shop {
    &-filters {
      &-toggles {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;
      }
    }

    &-filter {
      margin: 10px 0;

      &-heading {
        font-weight: bold;
        font-size: 14px;
      }

      &-toggle {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;

        .character-checkbox {
          margin-right: 5px;

          &-label {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
    }

    &-levels {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -3px;
    }

    &-level {
      padding: 0 3px 6px;
      width: 25%;

      .character-button-block {
        padding: 9px 5px;
      }
    }
  }
}
