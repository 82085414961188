.source-list {
  & + & {
    margin-top: 20px;
  }

  &-header {
    display: flex;
    font-family: $condensed;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;

    &-name {
      flex-grow: 1;
      text-transform: uppercase;
    }
  }

  &-callout {
    display: flex;
    align-items: center;

    &-extra {
      font-size: 10px;
      text-transform: uppercase;
      max-width: 62px;
      text-align: right;
      padding-right: 5px;
      display: block;
      line-height: 1;
    }

    &-value {
      font-size: 20px;
    }
  }

  .ability-pool-list + .truncated-content {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #f2f2f2;
  }

  .ability-pool-list-single-use + .truncated-content {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}
