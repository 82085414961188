.ddbc-attack-type-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;

  &--weapon-spell {
    width: 20px;
    height: 20px;
  }
}
