$proficiency-level-icon--no-proficiency-border-color: $ct--theme-de-emphasized-color !default;
$proficiency-level-icon--modified-no-proficiency-border-color: $ct--modified-color !default;

.ddbc-proficiency-level-icon {
  width: 10px;
  height: 10px;
  display: inline-flex;
}

.ddbc-no-proficiency-icon {
  border: 1px dotted $proficiency-level-icon--no-proficiency-border-color;
  background-color: #fff;
  border-radius: 50%;

  &--modified {
    border-color: $proficiency-level-icon--modified-no-proficiency-border-color;
  }

  &--dark-mode {
    background-color: #333;
  }
}

.ddbc-twice-proficiency-icon {
  position: relative;

  &__inner {
    width: 17px;
    height: 17px;
    position: absolute;
    left: -3px;
    top: -3px;
  }
}
