.tabFilter {
  background: var(--theme-background-solid);
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.buttons {
  --theme-transparent: color-mix(
    in srgb,
    var(--theme-color),
    var(--theme-background) 70%
  );
  --theme-color-dark: color-mix(
    in srgb,
    var(--theme-color),
    var(--ttui_common-1000) 20%
  );
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.buttons button {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  background: var(--ttui_grey-100);
  border: 0.125rem solid var(--ttui_grey-100);
  border-radius: 0.25rem;
  color: var(--ttui_grey-500);
  cursor: pointer;
  font-family: var(--font-condensed);
  padding: 0.125rem 0.3125rem;
  margin: 0.1875rem 0;
  position: relative;
}

.dark .buttons button {
  background: var(--ttui_common-1000);
  border-color: var(--ttui_common-1000);
  color: var(--ttui_grey-400);
}

.buttons button:hover {
  background: var(--ttui_grey-600);
  border-color: var(--ttui_grey-600);
  color: var(--ttui_grey-100);
}

.dark .buttons button:hover {
  background: var(--theme-transparent);
  border-color: var(--theme-transparent);
  color: var(--ttui_grey-50);
}

.buttons button.active {
  background: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--ttui_grey-50);
}

.buttons button.active:hover {
  background: var(--theme-color-dark);
  border-color: var(--theme-color-dark);
}

.dark .buttons button.active {
  color: var(--ttui_grey-50);
}

.buttons .badge {
  position: absolute;
  top: -0.6875rem;
  left: 50%;
  border-radius: 0.1875rem;
  padding: 0.0625rem 0.25rem;
  font-size: 0.625rem;
  transform: translateX(-50%);
  background-color: var(--theme-color);
  color: var(--ttui_common-0);
  line-height: 1;
}

.content {
  overflow-y: auto;
}

.content::-webkit-scrollbar {
  width: 0.375rem;
}

.content:hover::-webkit-scrollbar-thumb {
  background-color: color-mix(in srgb, var(--theme-contrast), transparent 75%);
}

@media screen and (min-width: 768px) {
  .tabFilter {
    background: transparent;
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  .tabFilter {
    padding: 0;
  }

  .buttons {
    justify-content: flex-start;
  }
}
