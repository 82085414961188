.ct-condition-levels-table {
  &__table {
    td {
      vertical-align: middle;
    }

    &-slot {
      .ct-slot-manager {
        justify-content: center;
      }

      &--implied {
        .ct-slot-manager__slot--used::before {
          opacity: 0.5;
        }
      }

      &--interactive {
        @include interactiveContent();
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
