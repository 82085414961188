.limited {
  &-empty {
    font-size: 16px;
    padding: 10px;
  }

  &-actions {
    display: flex;
    margin-bottom: 15px;
  }

  &-action {
    flex: 1;
    padding: 0 10px;
  }

  &-list {
    & + & {
      margin-top: 20px;
    }

    &-header {
      display: flex;
      font-family: $condensed;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;

      &-name {
        flex-grow: 1;
      }
    }

    &-item {
      &-callout {
        display: flex;
        align-items: center;

        &-extra {
          font-size: 10px;
          text-transform: uppercase;
          max-width: 62px;
          text-align: right;
          padding-right: 5px;
          display: block;
          line-height: 1;
        }

        &-value {
          font-size: 20px;
        }
      }

      &-description {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #f2f2f2;
      }

      &-single-use &-description {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
      }
    }
  }
}
