.ct-inventory {
  &__header {
    color: $ct--theme-de-emphasized-color;
    font-size: 10px;
    font-weight: bold;
    font-family: $condensed;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &__row {
    &-header {
      display: flex;
    }
  }

  &__row-header &__col {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: $condensed;
    padding-bottom: 5px;
  }

  &__col {
    &--active {
      width: 40px;
    }
    &--name {
      width: 200px;
    }
    &--weight {
      width: 50px;
    }
    &--qty {
      width: 35px;
    }
    &--cost {
      width: 50px;
    }
    &--notes {
      flex: 1;
    }
  }

  &__empty {
    color: $ct--theme-de-emphasized-color;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    max-width: 250px;
    margin: 0 auto;
  }

  &__actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0 8px;

    &--collapsed {
      border-top: 1px dotted #eaeaea;
    }
  }

  &__action {
    cursor: pointer;
  }

  &__choose {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    &-actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }

    &-action {
      & + & {
        margin-top: 5px;
      }

      &-sep {
        margin: 5px 0;
        font-family: $condensed;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        color: $ct--theme-de-emphasized-color;
      }
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-inventory__choose-action-sep {
    color: $ct--theme-dark-mode-text;
  }
}
