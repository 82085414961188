$exclusive-checkbox--slot-sep-color: $ct--theme-de-emphasized-color !default;

.ddbc-exclusive-checkbox {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  margin: 5px 0;

  &__slot {
    padding: 10px;
    display: flex;
    position: relative;
    cursor: pointer;

    & + & {
      border-top: 1px dotted #f2f2f2;

      &::before {
        content: "or";
        position: absolute;
        top: -11px;
        left: 33px;
        color: $exclusive-checkbox--slot-sep-color;
        background: #fff;
        line-height: 1;
        padding: 5px;
        text-transform: uppercase;
        font-size: 11px;
      }
    }

    &--selected {
      font-weight: bold;
    }

    &--inactive {
      background: #f2f2f2;
      color: #aaa;
    }
  }

  &--dark-mode {
    background-color: unset;

    .ddbc-exclusive-checkbox__slot--inactive {
      background-color: lighten($ct--theme-dark-mode-bg, 20%);
    }
  }
}
