.ct-spells-mobile {
  @include mobileDividerContentBlock();

  .ct-spells-level-casting {
    flex-direction: column;

    &__slot-groups,
    &__info {
      justify-content: center;
    }
  }

  .ct-spells-level {
    &__spells-col--name {
      flex: 1;
    }
  }

  .ct-spells-spell__name {
    flex: 1;
  }

  .ct-spells-filter {
    &__callout {
      order: 1;
      width: 100%;
      padding-left: 0;
      margin-bottom: 10px;
      text-align: center;
    }

    &__box {
      order: 2;
    }

    &__advanced {
      order: 3;
    }

    &__interactions {
      flex-wrap: wrap;
    }
  }
}

.ct-character-sheet-mobile--dark-mode {
  .ct-spells-mobile {
    background-color: $ct--theme-dark-mode-bg-mobile;
  }
}
