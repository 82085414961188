.intro {
  padding: 1.5625rem 0;
  border-bottom: 0.0625rem solid var(--ttui_grey-600);
  box-shadow: 0 0.125rem 0.125rem 0 #222;
  background: linear-gradient(
    0deg,
    var(--ttui_common-1000) 0%,
    var(--ttui_grey-900) 50%,
    var(--ttui_common-500) 100%
  );
}

.intro.dark {
  background: var(--theme-background);
}

.summary {
  margin-bottom: 0.9375rem;
  text-align: center;
}

.avatar {
  margin: 0 auto 1.25rem;
  border-radius: 0.25rem;
  border: 0.1875rem solid var(--theme-color);
  width: 8.125rem;
  height: 8.125rem;
}

.characterName {
  padding: 0rem 1.5625rem 0rem;
  min-height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.editName {
  flex-direction: column;
}

.characterName:hover {
  background-color: #2b2c30;
}

.characterName svg {
  height: 0.875rem;
  margin-left: 0.5rem;
  flex: none;
}

.playerNameLabel {
  color: var(--character-muted-color);
}

.description {
  padding: 0 1.5625rem;
}

.summaryText {
  font-size: 0.75rem;
}

.description :global(.ddbc-xp-bar__item),
.description :global(.ddbc-character-progression-summary__xp-data) {
  font-size: 0.625rem;
}

.featureCallout {
  display: flex;
  justify-content: center;
  padding: 0 1.5625rem;
}

.decorateButton {
  font-size: 13px;
  font-family: var(--font-condensed);
  font-weight: 700;
  width: 17.75rem;
}

.classList {
  margin-bottom: 1.5625rem;
  padding: 0 1.5625rem;
}

.classItem {
  display: flex;
  align-items: center;
}

.classItem + .classItem {
  margin-top: 0.625rem;
}

.classImage {
  position: relative;
  background: transparent center center no-repeat;
  background-size: cover;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 0.25rem;
  filter: grayscale(40%);
}

.classLevel {
  position: absolute;
  bottom: -0.375rem;
  right: -0.5rem;
  background: #333;
  height: 1.125rem;
  width: 1.125rem;
  text-align: center;
  border-radius: 0.1875rem;
  border: 0.0625rem solid var(--theme-color);
}

.classSummary {
  margin-left: 1rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.classMeta {
  color: var(--character-muted-color);
  font-family: var(--font-condensed);
  font-size: 0.75rem;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.toggleButton {
  font-size: 0.8125rem;
  font-family: var(--font-condensed);
  font-weight: 700;
  width: 17.75rem;
  background-color: var(--ttui_pink-600);
}

.popoverToggle {
  transform: none;
  max-width: 17.75rem !important;
}
