.confirmationDialog {
  border: none;
  border-radius: 0.5rem;
  max-height: calc(100% - 4rem);
  max-width: 36rem;
  min-width: 18.75rem;
  padding: 1rem;
}

.confirmationDialog::backdrop {
  background: var(--ttui_grey-900);
  opacity: 0.5;
}

.container {
  font-size: 1rem;
}

.container,
.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.text {
  margin: 0;
}

.alert {
  background: color-mix(in srgb, var(--ttui_orange-50), transparent 50%);
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--ttui_orange-800);
  font-size: 0.875rem;
}

.alertIcon {
  width: 1.2rem;
  fill: var(--ttui_color-warning--main);
}

.input {
  padding: 1rem 0.875rem;
  font-size: 1rem;
  width: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

@media screen and (min-width: 37.5rem) {
  .confirmationDialog {
    min-width: 31.25rem;
  }
}
