.builder-navigation {
  background-color: #333333;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  z-index: 10;
  left: 0;
  right: 0;

  & &-link {
    padding: 10px 20px;
    color: #fff;
    text-transform: uppercase;
    font-family: $condensed;
    font-size: 14px;
    border-left: 2px solid #585858;
    font-weight: bold;

    &:hover,
    &:active,
    &:visited {
      color: #fff;
    }

    &-disabled {
      color: #888;

      &:hover,
      &:active,
      &:visited {
        color: #888;
      }
    }
  }

  &-large {
    &-action {
      position: fixed;
      top: 174px;
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: $builder-default-color;
      border-radius: 3px;
      opacity: 0.4;
      transition: all 200ms;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;

        .ddbc-chevron-svg {
          width: 15px;
          height: 24px;
        }
      }

      &-prev {
        margin-right: 340px;
        right: 50%;
      }

      &-next {
        margin-left: 340px;
        left: 50%;
      }

      &-text {
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        max-width: 0;
        overflow: hidden;
        transition: all 200ms;
        color: #fff;
        box-sizing: border-box;
      }

      &:hover {
        opacity: 1;
      }

      &:hover &-text {
        max-width: 200px;
      }

      &-prev:hover &-text {
        margin-right: 10px;
      }

      &-next:hover &-text {
        margin-left: 10px;
      }

      &-disabled {
        background-color: #eaeaea;
        cursor: default;
      }

      &-disabled &-text {
        color: #777;
      }
    }
  }

  @media (min-width: 1024px) {
    &-large {
      &-action {
        top: 245px;
      }
    }
  }
}
