.ct-component-carousel {
  &__placeholders {
    overflow: hidden;
    position: fixed;
    z-index: 11;
    left: 0;
    right: 0;
    top: 119px;
    bottom: 0;
    background-color: #fff;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms;
    will-change: opacity;

    &--changing {
      pointer-events: all;
      opacity: 1;
    }
  }

  &__placeholder {
    min-width: 100%;
    flex: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    will-change: transform;
  }

  @media (min-width: $bp-sheet-tablet-transition) {
    &__placeholders {
      top: 48px;
    }
  }
}
