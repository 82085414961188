$xp-bar--progress-inner-bg-color: $ct--theme-color !default;
$xp-bar--progress-marker-bg-color: $ct--theme-color !default;

.ddbc-xp-bar {
  display: flex;
  align-items: center;

  &__item {
    font-size: 7px;

    &--progress {
      flex-grow: 1;
      padding: 0 5px;
    }
  }

  &__progress {
    background-color: #5f6473;
    display: block;

    &-inner {
      background-color: $xp-bar--progress-inner-bg-color;
      height: 1px;
      display: block;
      position: relative;
    }

    &-marker {
      position: absolute;
      right: 0;
      top: 0;

      &::before {
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        background-color: $xp-bar--progress-marker-bg-color;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        left: -3px;
      }

      &-amount {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 100%;
        margin-bottom: 4px;
        font-size: 12px;
      }
    }
  }
}
