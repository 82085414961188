$collapsible--meta-color: $ct--theme-de-emphasized-color !default;
$collapsible--meta-font: $font-family !default;
$collapsible--meta-item-sep-content: $html-entity--bull !default;
$collapsible--opened-color: $ct--theme-color !default;

.ddbc-collapsible {
  &__header {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: #f1f1f1;

    &-footer {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
    }

    &-content {
      display: flex;
      align-items: center;
      flex: 1;
      min-width: 0;

      &-primary {
        flex: 1;
        min-width: 0;
      }

      &-callout {
        margin-left: 5px;
      }
    }

    &-meta {
      color: $ct--theme-de-emphasized-color;
      font-size: 11px;
      font-family: $collapsible--meta-font;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1;
      margin-top: 3px;

      &-item {
        & + &::before {
          content: $collapsible--meta-item-sep-content;
          padding: 0 5px;
        }
      }
    }

    &-callout {
      display: flex;
      align-items: center;

      &-extra {
        font-size: 10px;
        padding-right: 4px;
        text-transform: uppercase;
      }

      &-value {
        font-size: 20px;
      }
    }

    &-status {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 18px;
      width: 18px;
      height: 18px;
      margin-left: 9px;
      cursor: pointer;

      .ddbc-chevron-svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__heading {
    font-weight: bold;
    flex: 1;
    display: flex;
    align-items: center;
    line-height: 1.1;
  }

  &--opened > &__header {
    border-left: 3px solid $collapsible--opened-color;
  }

  &__content {
    position: relative;
  }

  &--minimal &__header {
    background: transparent;
    padding: 0;

    &-meta {
      margin-top: 0;
    }
  }

  .ddbc-collapsible--minimal.ddbc-collapsible--opened > &__header {
    //can't do styles easily with sass, so have to repeat
    border: none;
  }
}
