.headingOverride {
  color: var(--character-muted-color);
  font-size: 0.875rem;
  font-weight: normal;
  margin-left: 0.625rem;
  text-transform: none;
  font-family: var(--font-family);
}

.customize,
.description {
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  border-top: 0.063rem solid var(--theme-transparent);
}

.editorBox {
  margin: 0.3125rem 0 0.625rem;
}
