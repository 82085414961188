// #########################################################
// # NOTICE - Make sure to updated both copies of the styles
// #          until we remove the older class
// #########################################################
.character-checkbox {
  display: inline-flex;
  align-items: center;

  &-interactive {
    cursor: pointer;
  }

  &-input {
    display: inline-flex;
    min-width: 20px;
    min-height: 20px;
    background-color: #fff;
    box-shadow: inset 0 0 4px 0 rgba(138, 177, 198, 0.48);
    border: solid 1px #d8dde3;
    font-weight: bold;
    font-size: 16px;
    color: #242527;
    position: relative;
  }

  &-enabled &-input {
    .ddbc-check-svg {
      width: 15px;
      height: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-label {
    margin-left: 5px;
    cursor: pointer;
  }
}

.ddbc-checkbox {
  display: inline-flex;
  align-items: center;

  &--is-interative {
    cursor: pointer;
  }

  &__input {
    display: inline-flex;
    min-width: 20px;
    min-height: 20px;
    background-color: #fff;
    box-shadow: inset 0 0 4px 0 rgba(138, 177, 198, 0.48);
    border: solid 1px #d8dde3;
    font-weight: bold;
    font-size: 16px;
    color: #242527;
    position: relative;
  }

  &--is-enabled &__input {
    .ddbc-check-svg {
      width: 15px;
      height: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__label {
    margin-left: 5px;
    cursor: pointer;
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ddbc-checkbox {
    &__input {
      background-color: $ct--theme-dark-mode-background-color;
      border-color: white;
    }
  }
}
