.builder-message {
  &-warning {
    background-color: #f9f6f0;
    padding: 10px;
    color: #5a5343;
    border: 1px solid #fdd575;
    font-size: 14px;
    border-radius: 3px;
  }
}
