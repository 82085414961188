.ct-section-placeholder {
  background-color: #f2f2f2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid #fff;
  box-shadow: 0 0 0 1px #eaeaea inset;

  &__icon {
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    padding: 30px;
    border-radius: 50%;

    .ddbc-svg {
      width: 60px;
      height: 60px;
      opacity: 0.5;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: bold;
    font-family: $condensed;
    text-transform: uppercase;
  }

  @media (max-height: 459px) {
    &__icon {
      order: 1;
      margin-right: 20px;
    }

    &__name {
      order: 2;
    }
  }

  @media (min-height: 460px) {
    flex-direction: column;

    &__icon {
      padding: 50px;

      .ddbc-svg {
        width: 110px;
        height: 110px;
      }
    }

    &__name {
      margin-top: -20px;
      margin-bottom: 15px;
    }
  }
}

.ct-character-sheet--dark-mode {
  .ct-section-placeholder {
    background-color: $ct--theme-dark-mode-background-color;
    box-shadow: unset;
    border: unset;
    &__icon {
      background-color: unset;
      box-shadow: unset;
      border: 2px solid $ct--theme-color;
    }
  }
}
