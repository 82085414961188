.ddbc-link {
  color: $builder-default-color;
  cursor: pointer;

  &:hover,
  &:active,
  &:visited {
    color: $builder-default-color;
  }
}

.ddbc-theme-link {
  color: $ct--theme-color;
  cursor: pointer;

  &:hover,
  &:active,
  &:visited {
    color: $ct--theme-color;
  }
}

.ddbc-collapsible {
  & + & {
    margin-top: 5px;
  }
}

.ddbc-collapsible-content {
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &-trigger {
    .character-builder & {
      color: $builder-default-color;
    }
  }
}

.ddbc-attunement-slot {
  & + & {
    margin-top: 9px;
  }
}

.ddbc-snippet {
  p {
    margin: 5px 0 !important; // I am so sad I have to do this, but custom styles are forcing me

    &:first-child {
      margin-top: 0;
    }
  }
}
