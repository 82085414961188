.tabList {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabList > menu {
  margin: 0 0 1rem;
}

.tabList button[aria-checked="true"] {
  border-color: var(--theme-color);
  color: var(--theme-contrast);
}
