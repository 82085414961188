.filterButton {
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
}
.buttonText {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.ordinal {
  font-size: 0.4375rem;
  line-height: 1;
  margin-top: 0.3125rem;
}

.ordinalSmall {
  margin-top: 0;
}
