:root {
    --attack-table--font-family: var(--font-condensed);
    --attack-table--default-color: var(--character-muted-color);
    --attack-table--dark-color: var(--theme-contrast);
}

.tableHeader {
    display: flex;
}

.col {
    font-size: 0.6875rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: var(--attack-table--font-family);
    padding: 0.3125rem 0;
}

.icon {
    width: 1.6875rem;
}
.name {
    width: 8.75rem;
}

.range {
    width: 3.4375rem;
}

.tohit {
    width: 3.4375rem;
}

.damage {
    width: 5rem;
}

.notes {
    flex: 1;
}

.darkMode {
    color: white;
}

.default {
    color:var(--attack-table--default-color);
    font-family: var(--attack-table--font-family);
    display: flex;
    align-items: center;
    justify-content: center;
}

.default.dark-mode {
    color: var(--attack-table--dark-color);
}

.attack + .attack {
    margin-top: 0.5rem;
    border-top: 0.0625rem dotted var(--character-light-mode-border-default);
    padding-top: 0.5rem;
}

@media screen and (max-width: 768px) {
    .col.name {
        flex: 1;
        min-width: 90px;
    }
}