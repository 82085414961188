.ct-value-editor {
  &__property {
    display: flex;

    &-value {
      flex: 1;
    }

    &-input {
      &[type="text"],
      &[type="number"] {
        width: 100%;
      }
    }
  }

  &--standard &__property {
    margin-top: 8px;
    flex-wrap: wrap;

    &:first-child {
      margin-top: 0;
    }

    &-label {
      width: 100%;
      font-size: 11px;
      margin-bottom: 2px;
    }

    &-source {
      flex: 3;
      padding-left: 5px;
    }
  }

  &--compact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 10px;
  }

  &--compact &__property {
    padding-right: 5px;
    padding-bottom: 5px;
    width: 33.33333%;
    line-height: 1.3;
    align-items: center;

    &-label {
      order: 2;
      font-size: 10px;
      margin-left: 5px;
      margin-bottom: 0;
    }

    &--number {
      align-items: center;
      flex-wrap: nowrap;

      .ct-value-editor__property-input {
        width: 45px;
      }
    }

    &--text {
      width: 100%;

      .ct-value-editor__property-input {
        width: 100%;
      }
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-value-editor {
    &__property {
      &-input,
      &-input:hover {
        @include darkModeInput();
      }
    }
  }
}
