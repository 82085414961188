.ct-optional-feature {
  display: flex;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 3px;

  &--is-disabled {
    background: #f2f2f2;
    color: #aaa;
  }

  &__selection {
    position: relative;
    font-weight: bold;
    padding-right: 7px;
    line-height: 1;
    min-width: 27px;
  }

  &__primary {
  }

  &__name {
    font-weight: bold;
  }

  &__level {
    color: $ct--theme-de-emphasized-color;
    font-size: 10px;
    font-family: $font-family;
    line-height: 1;
    margin-top: 3px;
  }

  &__description {
    margin-top: 10px;
  }

  &__secondary {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  &__type {
    margin-right: 10px;
    font-style: italic;
  }

  &__select {
    &--todo {
      position: relative;

      .ct-select {
        border-color: $builder-default-color;
        box-shadow: 0 0 3px rgba($builder-default-color, 0.5);
      }

      &::before {
        content: "!";
        position: absolute;
        background: $builder-default-color;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        z-index: 3;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        top: -4px;
        left: -6px;
      }
    }
  }
}
