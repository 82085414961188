﻿@mixin navbarColorBuilder($bg-top, $bg-bottom, $border, $shadow, $parent) {
  .body-#{$parent} &,
  &:hover {
    background-image: linear-gradient(to bottom, $bg-top, $bg-bottom);
    border-top: 1px solid $border;
    box-shadow: 0 -3px 24px 0 $shadow;
  }
}

@mixin allLinkStates($color: null) {
  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    @if ($color != null) {
      color: $color;
    }
    @content;
  }
}

@mixin setUserColorByRole($role) {
  &-role-#{$role} {
    color: map-get($user-roles, $role);
  }
}

@mixin colStatusBg($bg-src) {
  .col-status {
    background: white !important;

    .state {
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "" !important;
        background-image: url($bg-src);
        background-size: cover;
        background-repeat: no-repeat;
        height: 16px;
        width: 18px;
        display: inline-block;
      }
    }
  }
}

@mixin pageHeading() {
  font-size: $site-heading-mobile-h1-font-size;
  font-family: $condensed;
  font-weight: normal;

  @media (min-width: 1024px) {
    font-size: $site-heading-nonmobile-h1-font-size;
  }
}

@mixin simulatedPageHeadings() {
  @include pageHeading;
  z-index: 5;
  color: $black;
  padding: 0 !important;
  line-height: 1.3;
  font-weight: bold;
}

@mixin standardTable() {
  table {
    border-collapse: collapse;
    margin: 20px 0;

    &.compendium-left-aligned-table {
      th {
        text-align: left;
      }
    }

    &,
    th,
    td {
      border: 1px solid #edeae8;
    }

    th,
    td {
      font-size: 14px;

      &.left-align {
        text-align: left !important;
      }

      &.uppercase {
        text-transform: uppercase !important;
      }
    }

    td {
      text-align: center;
      padding: 10px 20px;

      p {
        font-size: 14px;
      }
    }

    thead {
      th {
        font-size: 14px;
        font-weight: bold;
        //text-transform: uppercase;
        padding: 10px 20px;
        border-bottom: 2px solid rgb(208, 202, 197);
        text-align: center;
        background: #fff;
        color: $black;
      }
    }

    tbody {
      tr {
        background: #fff;

        td {
          min-width: 100px;
          padding: 20px;
          background: none;
          &.left-align {
            text-align: left !important;
          }

          &.uppercase {
            text-transform: uppercase !important;
          }
        }

        &:nth-child(odd) {
          background: #faf8f7;
        }
      }
    }
  }
}

@mixin standardUL() {
  ul {
    list-style: initial;
    padding: 15px;
  }
}

@mixin standardOL() {
  ol {
    list-style: decimal;
    padding: 15px;
  }
}

@mixin standardBlockquote() {
  blockquote {
    font-size: 14px;
    margin: 5px 0;

    p {
      font-size: 14px;
    }
  }
}

@mixin standardHeadings($line: false, $line-color: $color-default) {
  h1,
  h2,
  h3 {
    font-weight: normal;
  }

  h1 {
    font-size: 32px;

    @if ($line == true) {
      &::after {
        content: "";
        width: 100%;
        max-width: 1200px;
        margin: 5px auto 20px;
        height: 3px;
        background-color: $line-color;
        display: block;
      }
    }
  }

  h2,
  h3 {
    @if ($line == true) {
      &::after {
        content: "";
        width: 100%;
        margin: 5px auto 20px;
        height: 1px;
        background-color: $line-color;
        display: block;
      }
    }
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 20px;
  }

  h5,
  h6 {
    font-size: 18px;
  }
}

@mixin standardA() {
  a {
    @include allLinkStates($color-compendium);
    text-decoration: none;
    cursor: pointer;
  }
}

@mixin standardContent() {
  @include standardTable;
  @include standardUL;
  @include standardOL;
  @include standardBlockquote;
  @include standardHeadings;
  @include standardA;
  // articles are divergent and pull in only specific parts of this, if you add any more items to this, consider
  // pulling in to articles as well
}

@mixin scoreIconAsBefore($score, $size: 16px) {
  &::before {
    content: "";
    background-image: url(#{$imageURL-local}/icons/abilities/#{$score}.svg);
    background-size: cover;
    background-position: center center;
    display: inline-block;
    margin-right: 5px;
    height: $size;
    width: $size;
    @content;
  }
}

@mixin contextualColors($bg-color, $border-color: lighten($bg-color, 20%)) {
  background-color: $bg-color;
  border-color: $border-color;
}

@mixin tooltipLinks($color: $color-compendium) {
  font-weight: bold !important;
  @include allLinkStates {
    color: $color !important;
  }

  &.spell-tooltip {
    @include allLinkStates {
      color: $color-spells !important;
      font-style: italic;
    }
  }

  &.monster-tooltip {
    @include allLinkStates {
      color: $color-monsters !important;
    }
  }

  &.magic-item-tooltip {
    @include allLinkStates {
      color: $color-items !important;
      font-style: italic;
    }
  }

  &.vehicles-tooltip {
    @include allLinkStates {
      color: $color-default !important;
    }
  }
}

@mixin cardBlockquote() {
  background: white;
  padding: 20px;
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e0dcdc;
  border-right: 1px solid #e0dcdc;
  border-top: none;
  border-bottom: none;
  overflow: visible;
  border-radius: 0;
  color: $black;
  margin: 40px 0;
  line-height: 1.6;

  &::before,
  &::after {
    content: "";
    background-image: url("#{$imageURL-local}/border_texture_wide.png");
    background-size: 100% 100%;
    height: 20px;
    position: absolute;
    left: -1px;
    right: -1px;
  }

  &::before {
    transform: rotateY(180deg);
    top: -13px;
  }

  &::after {
    transform: rotateX(180deg);
    bottom: -10px;
  }

  .compendium-blockquote-footer {
    width: 100%;
    text-align: right;
  }

  @media all and (max-width: 1023px) {
    body.responsive-enabled & {
      margin: 40px 0;
    }
  }

  @media print {
    border: 1px solid #e0dcdc !important;

    &::before,
    &::after {
      display: none;
    }
  }
}

@mixin readAloudBlock() {
  background-color: #e6e7ec !important;
  border-color: #979aa4 !important;

  &::before,
  &::after {
    background-image: url("#{$imageURL-local}/icons/dot.svg"),
      url("#{$imageURL-local}/icons/dot.svg") !important;
    background-position: left, right;
    background-size: contain !important;
    background-repeat: no-repeat;
    height: 8px !important;
    left: -4px !important;
    right: -4px !important;
  }

  &::after {
    bottom: -4px !important;
  }

  &::before {
    top: -4px !important;
  }
}

@mixin forceWidth($width, $minwidth: $width, $maxwidth: $width) {
  min-width: $minwidth;
  width: $width;
  max-width: $maxwidth;
}

@mixin button(
  $bg: $color-default,
  $color: #fff,
  $hover-bg: lighten($bg, 10%),
  $transition: 100ms linear all
) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: $condensed;
  font-weight: bold;
  font-size: 16px;
  background: $bg;
  height: 50px;
  width: 100%;
  max-width: 300px;
  min-width: 250px;
  box-shadow: none;
  border: none;
  transition: $transition;
  text-transform: uppercase;
  text-shadow: none;

  @if ($hover-bg == null) {
    transition: none;
  } @else {
    &:hover {
      background: $hover-bg;
    }
  }

  @include allLinkStates() {
    color: $color;
  }

  @media (min-width: 1024px) {
    height: 35px;
    font-size: 12px;
    min-width: 180px;
  }

  &:hover {
    transition: $transition;
    background: $hover-bg;
  }
}

@mixin button-alt(
  $b-color: $color-default,
  $color: $b-color,
  $hover-b-color: lighten($b-color, 10%),
  $transition: 100ms linear all
) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: $condensed;
  font-weight: bold;
  font-size: 16px;
  background: rgba(0, 0, 0, 0);
  height: 50px;
  width: auto;
  max-width: 300px;
  //min-width: 250px;
  box-shadow: none;
  border: 2px solid $b-color;
  transition: $transition;
  text-transform: uppercase;
  text-shadow: none;

  @if ($hover-b-color == null) {
    transition: none;
  } @else {
    &:hover {
      border-color: $hover-b-color;
    }
  }

  @include allLinkStates() {
    color: $color !important;
  }

  @media (min-width: 1024px) {
    height: 35px;
    font-size: 12px;
    min-width: 180px;
  }

  &:hover {
    color: $hover-b-color !important;
    transition: $transition;
    border-color: $hover-b-color;
    background-color: rgba(0, 0, 0, 0);
  }
}

@mixin standardInputText() {
  height: 50px;
  width: 100%;
  padding: 10px;
  border: 1px solid #d8dde3;
  background-color: #fff;
  box-shadow: inset 0 0 4px 0 rgba(139, 178, 199, 0.48);
  border-radius: 0;
  font-size: 15px;
}

@mixin standardInputSelect() {
  height: 50px;
  width: 100%;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 0 4px 0 rgba(139, 178, 199, 0.48);
  border: 1px solid #d8dde3;
  font-size: 15px;
}

@mixin standardSelect2Select() {
  .select2-choice {
    border-radius: 0 !important;
    border-color: #d8dde3 !important;
    background-image: none !important;
    background-color: #fff;
    box-shadow: inset 0 0 4px 0 rgba(139, 178, 199, 0.48);
    display: table;
    height: 50px;
    width: 100%;

    .select2-chosen {
      height: 100%;
      vertical-align: middle;
      display: table-cell;
    }

    .select2-arrow {
      background: none;
      border: none;
      top: 10px;
    }

    @at-root {
      .select2-drop {
        border-radius: 0 !important;
        border-color: #d8dde3 !important;
      }
    }
  }
}

@mixin standardSelect2MultiSelect() {
  .select2-choices {
    background-color: #fff;
    background-image: none !important;
    border-color: #d8dde3 !important;
    box-shadow: inset 0 0 4px 0 rgba(139, 178, 199, 0.48);
  }
}

@mixin articleTag() {
  border: 1px solid #cbc6c3;
  background-color: #fff;
  box-shadow: inset 0 0 4px 0 #dcd1c7;
  padding: 4px 8px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 2px;

  &:hover {
    background: #242527;
    color: white;
    text-decoration: none;
  }
}

@mixin borderThemeColor() {
  .body-home-index & {
    border-color: #e40712;
  }

  .body-mycontent &,
  .section-posts &,
  .body-post & {
    border-color: $color-default;
  }

  .body-rpgspell & {
    border-color: $color-spells;
  }

  .body-rpgmonster & {
    border-color: $color-monsters;
  }

  .body-rpgitem &,
  .body-rpgigear &,
  .body-rpgmagicitem & {
    border-color: $color-items;
  }

  .body-rpgcharacter &,
  .body-rpgfeat &,
  .body-rpgrace &,
  .body-rpgirace &,
  .body-rpgsubrace &,
  .body-rpgclass &,
  .body-rpgbackground & {
    border-color: $color-characters;
  }

  .body-forum & {
    border-color: $color-forums;
  }

  .body-marketplace &,
  .body-noncebilling &,
  .body-productkey & {
    border-color: $color-marketplace;
  }

  .body-rpghomebrew & {
    border-color: $hb-blue;
  }

  .section-compendium &,
  .section-adventures &,
  .section-rules &,
  &.section-compendium {
    border-color: $color-compendium;
  }

  .body-search &,
  .body-rpgcampaign & {
    border-color: $color-default;
  }
}

%base-icon {
  height: 35px;
  width: 35px;
  border-radius: 4px;
}

%spell-button {
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  display: block;

  span {
    line-height: 50px;
    display: block;
    font-family: $condensed;
    font-size: 16px;
    font-weight: bold;
    color: white !important;
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #9877fa, #704cd9);
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
  }
}

%monster-button {
  @extend %spell-button;

  span {
    line-height: 35px;
    background-image: linear-gradient(to bottom, #e81212, #8c0b0b);
  }
}

%item-button {
  @extend %spell-button;

  span {
    background-image: linear-gradient(to bottom, #4273e8, #143a7c);
  }
}

%button-alt {
  width: 100%;
  max-width: 255px;
  background: black;
  border: 2px solid $color-default;
  border-radius: 3px;
  margin: 0 auto;
  display: block;

  &.item {
    border-color: $color-items;
  }

  span {
    display: block;
    font-family: $condensed;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    color: white;
    margin: 0 auto;
    border-radius: 3px;
    text-transform: uppercase;
  }
}

%keyonei {
  &::before {
    content: "";
    height: 15px;
    width: 15px;
    background-color: $hb-blue;
    background-image: url(../../images/icons/homebrew.svg);
    background-size: 8px 8px;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #f4f4f4;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: calc(-50% + 15px);
  }
}

%stevanus-aurelius {
  display: none;
}

%create-icon-before {
  &::before {
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    background: url(../../images/sprites/plus_minus-white.svg) no-repeat;
    background-size: cover;
    margin-right: 10px;
    transform: translateY(1px);
  }
}

%homebrew-icon-before {
  &::before {
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    background: url(../../images/icons/homebrew.svg) no-repeat;
    background-size: cover;
    margin-right: 10px;
    transform: translateY(1px);
  }
}
