:root {
  /* Colors */
  --character-default-color: #96bf6b;
  --character-builder-blue: #1c9aef;
  --character-builder-green: #96bf6b;
  --character-builder-black: #262e37;
  --character-muted-color: var(--ttui_grey-500);
  --character-dark-mode-text: var(--ttui_grey-400);
  --character-content-bg-dark: #202b33;
  --character-creature-block-red: #822000;
  --positive-color: #40d250;
  --dark-mode-positive-color: #00c797;
  --negative-color: #d24040;
  --dark-mode-negative-color: #c53131;
  --character-light-mode-border-default: #eaeaea;

  /* TEXT SHADOW */
  --text-shadow-light: 0.0625rem 0.0625rem 0 var(--ttui_grey-100),
    -0.0625rem -0.0625rem 0 var(--ttui_grey-100),
    -0.0625rem 0.0625rem 0 var(--ttui_grey-100),
    0.0625rem -0.0625rem 0 var(--ttui_grey-100);
  --text-shadow-dark: 0.0625rem 0.0625rem 0 var(--ttui_common-1000),
    -0.0625rem -0.0625rem 0 var(--ttui_common-1000),
    -0.0625rem 0.0625rem 0 var(--ttui_common-1000),
    0.0625rem -0.0625rem 0 var(--ttui_common-1000);

  /* Outline */
  --outline: 0.313rem auto var(--character-builder-blue);
  --outline-radius: 0.375rem;

  /* Fonts */
  --font-family: Roboto, Helvetica, sans-serif;
  --font-condensed: "Roboto Condensed", Roboto, Helvetica, sans-serif;
  --font-scala-sans-offc: "Scala Sans Offc", var(--font-family);
  --font-scala-sans-sc-offc: "Scala Sans Sc Offc", var(--font-scala-sans-offc);
  --font-mrs-eaves-small-caps: MrsEavesSmallCaps, var(--font-family);
}
