.ct-suggestions-table {
  & &__table {
    margin-top: 10px;
  }

  &__col {
    &--action {
      text-align: center;
    }
  }
}
