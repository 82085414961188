.hasSeparator {
  border-top: 1px solid var(--theme-transparent);
  margin-top: 0.625rem;
  padding-top: 0.625rem;
}

.editor {
  margin: 0.3125rem 0 0.625rem;
}

.adjuster {
  padding-top: 0.9375rem;
}

.adjusterGroups {
  margin: 0.625rem 0;
  display: flex;
  text-align: center;
  font-family: var(--font-condensed);
}

.adjusterGroup {
  position: relative;
  flex: 1;
}
.adjusterLabel {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.1875rem;
}
.adjusterValue {
  font-size: 1.75rem;
}

.hasTempHp {
  color: var(--character-builder-green);
}
.valueSep {
  margin: 0 0.3125rem;
}

.adjusterInput[type="number"] {
  max-width: 3.125rem;
  vertical-align: top;
  margin-top: 0.25rem;
  text-align: center;
  font-size: 0.875rem;
  padding: 0 0.3125rem;
  line-height: 1.75rem;
  border-radius: 0;
}

.adjuster :global(.ct-health-adjuster) {
  margin: 0.9375rem 0;
}

.adjuster :global(.ddbc-collapsible__header-callout-extra) {
  font-size: 0.8125rem;
  font-weight: bold;
}

.block {
  margin-top: 0.9375rem;
}

.description {
  margin-top: 0.625rem;
}

.img {
  max-width: 18.75rem;
  margin: 0.9375rem auto;
  display: block;
}

.removeButton {
  display: flex;
  justify-content: center;
}

.wildshapeInfo {
  text-align: center;
  text-wrap: balance;
  margin: 0;
}

.deathSummary {
  min-height: 5.563rem;
  cursor: pointer;
}

.hitPointsContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 0.625rem;
}
