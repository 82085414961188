.ct-sidebar {
  // Hiding the table provided by database and recreating for tracking exhaustion levels
  .exhaustion-levels {
    display: none;
  }

  //additional spell description
  .ddbc-html-content.ct-spell-detail__additional-description {
    color: red;
  }

  // Compendium table styles too
  .table-overflow-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table.table-compendium {
    text-align: center;

    thead tr {
      background-color: var(--sb-table-header-bg, #fdfdfd);
    }

    thead th {
      background: inherit;
      text-align: center;

      &.table-column-width {
        &--10pct {
          width: 10%;
        }

        &--15pct {
          width: 15%;
        }

        &--20pct {
          width: 20%;
        }
      }
    }

    tr {
      transition: 0.3s;
    }

    td {
      line-height: 22px;
    }

    tbody th {
      border-bottom: none;
    }

    tr:nth-child(odd) :is(td, th),
    :is(td, th) {
      background-color: inherit;
      background: inherit;
    }

    tbody tr {
      &:nth-child(odd) {
        background-color: var(--sb-table-row-bg-light, #faf8f7);
      }

      &:nth-child(even) {
        background-color: var(--sb-table-row-bg-dark, #fdfdfd);
      }

      &:hover {
        background-color: var(--sb-table-row-bg-hover, #e3f4eb);
      }
    }

    // Equipment/Weapon table options
    &.table--weapons :is(th, td):nth-child(odd) {
      text-align: left;
    }

    &.table--equipment :is(th, td):first-child {
      width: 50%;
      text-align: left;
    }

    // Rollable table rules

    &.table--generic-dice :is(th, td):first-child,
    &.table--generic-dice-first :is(th, td):first-child,
    &.table--generic-dice-col2 :is(th, td):nth-child(2),
    &.table--generic-dice-col3 :is(th, td):nth-child(3),
    &.table--generic-dice-col4 :is(th, td):nth-child(4),
    &.table--generic-dice-col5 :is(th, td):nth-child(5),
    &.table--generic-dice-last :is(th, td):last-child {
      width: 12%;
      font-weight: bold;
    }

    &.table--generic-dice :is(th, td):nth-child(2) {
      text-align: left;
    }
    // Left aligned table rules
    &.table--left-all :is(th, td),
    &.table--left-first :is(th, td):first-child,
    &.table--left-col2 :is(th, td):nth-child(2),
    &.table--left-col3 :is(th, td):nth-child(3),
    &.table--left-col4 :is(th, td):nth-child(4),
    &.table--left-col5 :is(th, td):nth-child(5),
    &.table--left-last :is(th, td):last-child {
      text-align: left;
    }

    &.table--sub-features {
      thead th:first-of-type {
        width: 15%;
      }

      :is(thead, tbody) :is(th, td):nth-child(2) {
        text-align: left;
      }
    }

    // Table Captions
    caption {
      text-align: left;
      font-size: 1rem;
      font-weight: 700;
      margin: 8px 0;
      font-family: Roboto, Helvetica, sans-serif;
      line-height: 1.3;

      :is(h2, h3, h4, h5) {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    a:hover {
      text-decoration: underline;
    }
  }

  // Compendium Rules Sidebars - example is the item Spelljamming Helm - its the scroll on the bottom of the description
  .text--rules-sidebar {
    display: flex;
    background-color: var(--sb-rules-sidebar, #dae4c1);
    position: relative;
    width: auto;
    flex-direction: column;
    overflow: visible;
    margin: 30px 5px;
    line-height: 1.6;
    font-size: 0.875rem;
    padding: 20px 10px 25px 15px;
    border-radius: 0;
    border-top: 3px solid #231f20;
    border-bottom: 3px solid #231f20;
    border-left: 1.5px solid #b3b3b3;
    border-right: 1.5px solid #b3b3b3;
    filter: drop-shadow(0px 5px 8px #ccc);

    p:first-of-type {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
    }

    p {
      margin: 0;
    }

    p + p {
      margin: 12px 0 0;
    }

    &::before {
      content: "";
      background-image: url("https://media.dndbeyond.com/compendium-images/components/--right-rules.svg"),
        url("https://media.dndbeyond.com/compendium-images/components/--left-rules.svg");
      background-position: left, right;
      background-size: contain;
      background-repeat: no-repeat;
      height: 11px;
      position: absolute;
      top: -13px;
      right: 0.1px;
      left: 0.1px;
      z-index: -1;
    }

    &::after {
      content: "";
      background-image: url("https://media.dndbeyond.com/compendium-images/components/--right-rules.svg"),
        url("https://media.dndbeyond.com/compendium-images/components/--left-rules.svg");
      background-position: left, right;
      background-size: contain;
      background-repeat: no-repeat;
      height: 11px;
      position: absolute;
      bottom: -13px;
      right: -0.1px;
      left: 0.1px;
      z-index: -1;
      transform: scaleY(-1);
    }
  }

  //Hides text wrapped in a div spoiler from appearing on the character sheet
  .spoiler {
    display: none;
  }

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    color: #000;
  }

  &__header {
    margin-bottom: 10px;

    &-primary {
      display: flex;
      align-items: center;
    }

    &-preview {
      align-self: flex-start;
      min-width: 0;

      &-image {
        align-self: flex-start;
        width: 32px;
        height: 32px;
        display: inline-flex;
        vertical-align: top;
        background: transparent center center no-repeat;
        background-size: cover;
        margin-right: 5px;
        border: 1px solid #d8d8d8;
        border-radius: 3px;
      }
    }

    &-callout {
      align-self: flex-start;
      min-width: 0;
    }
  }

  &__heading {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    font-family: $condensed;
    line-height: 1.2;
  }

  &__subheading {
    margin: 10px 0;
    font-weight: bold;
    font-size: 14px;
    font-family: $condensed;
    text-transform: uppercase;
  }

  $table-cell--padding: 5px 10px;
  $table-cell--line-height: 1.3;

  ul {
    list-style: disc;
    padding-left: 30px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h1 {
    font-family: $condensed;
  }
  h2 {
    font-size: 1.5em;
    font-family: $condensed;
  }
  h3 {
    font-size: 1.17em;
    font-family: $condensed;
  }
  h4,
  h5,
  h6 {
    font-size: 0.875rem;
    font-family: $condensed;
  }

  table {
    border-collapse: collapse;
    margin: 20px 0;
    border: 1px solid #edeae8;
  }

  th,
  td {
    border: 1px solid #edeae8;

    &.left-align {
      text-align: left;
    }

    &.uppercase {
      text-transform: uppercase;
    }
  }

  td {
    text-align: center;
    padding: $table-cell--padding;
    background-color: #fff;

    p {
      margin: 0;
    }
  }

  tr:nth-child(odd) {
    td {
      background: #faf8f7;
    }
  }

  thead {
    th,
    td {
      font-size: 0.875rem;
      font-weight: bold;
      font-family: $condensed;
      padding: 5px 10px;
      line-height: $table-cell--line-height;
      border-bottom: 3px solid #d0cac5;
      text-align: center;
      background-color: #fff;
      color: $black;
    }
  }

  // This sucks, but has to be done, until we remove all the important code
  .summoning-creatures-table,
  .compendium-left-aligned-table {
    th {
      &:nth-child(1) {
        width: auto !important;
      }
    }

    td {
      text-align: left !important;
      padding: 8px 20px !important;

      &:nth-child(1) {
        vertical-align: middle !important;
        padding: $table-cell--padding !important;
        line-height: $table-cell--line-height !important;
      }
    }
  }

  .line {
    background-color: #eaeaea;
    margin: 15px 0;
    height: 1px;
  }

  blockquote {
    font-size: 0.8125rem !important;
    padding: 12px 14px 2px;
    margin: 8px 2px 4px;
    border-bottom: 4px solid #89929973;
    border-top: 4px solid #89929973;
    background-color: #fafafb;
    border-left: 1px solid #f4f2f2;
    border-right: 1px solid #f4f2f2;
  }
}

//Sidebar DarkMode overrides
.ct-sidebar--is-dark-mode {
  .ct-sidebar__heading {
    color: #fff;
  }

  .ct-sidebar__subheading {
    color: $ct--theme-dark-mode-text;
  }

  .ct-filter__query[type="search"],
  input[type="search"] {
    background-color: unset;
    border: 1px solid $ct--theme-dark-mode-text;
    color: $ct--theme-dark-mode-text;
    &:active,
    &:focus {
      border-color: white;
      background-color: unset;
      color: $ct--theme-dark-mode-text;
    }
  }

  input[type="number"] {
    @include darkModeInput();
  }

  // Sidebar links
  a {
    color: $ct--theme-dark-mode-text;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  //Sidebar Tables
  table,
  th,
  td {
    border: 1px solid #394b59;
  }

  td {
    background-color: $ct--theme-dark-mode-bg;
  }

  tr:nth-child(odd) {
    td {
      background: lighten($ct--theme-dark-mode-bg, 5%);
    }
  }

  thead {
    th,
    td {
      border-bottom: 3px solid #394b59;
      background-color: $ct--theme-dark-mode-background-color;
      color: #fff;
    }
  }

  .ct-vehicle-block {
    color: $ct--theme-text-color;
  }

  //Keeping this here so that builder uses of Collapsible are not targeted yet
  .ddbc-collapsible__header {
    background-color: #394b59;
  }

  .ddbc-collapsible--minimal {
    .ddbc-collapsible__header {
      background-color: unset;
    }
  }

  //TODO - move to appropriate locations
  .ct-remove-button {
    background-color: unset;
  }

  .ct-customize-data-editor__property-value {
    input,
    input:hover {
      @include darkModeInput();
    }
    textarea,
    textarea:hover {
      @include darkModeInput();
    }
  }

  table.table-compendium {
    thead tr {
      background-color: var(--dark-sb-table-header-bg, #10161a);
    }

    tbody tr {
      &:nth-child(odd) {
        background-color: var(--dark-sb-table-row-bg-light, #1a232adb);
      }

      &:nth-child(even) {
        background-color: var(--dark-sb-table-row-bg-dark, #10161adb);
      }

      &:hover {
        background-color: var(--dark-sb-table-row-bg-hover, #1b2b23);
      }
    }

    a {
      text-decoration: underline;
    }
  }

  .text--rules-sidebar {
    background-color: var(--dark-sb-rules-sidebar, #202416);
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    border-left: 1.5px solid #121212;
    border-right: 1.5px solid #121212;
    filter: none;
    color: #ffffffde;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    &::before,
    &::after {
      z-index: 2;
      filter: brightness(0) saturate(100%);
    }

    a {
      text-decoration: underline;
    }
  }
}
