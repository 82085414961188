.ct-spell-slot-manager {
  &__group {
    & + & {
      margin-top: 5px;
    }

    &-summary {
      display: flex;
      text-align: center;
      padding-right: 5px;
    }

    &-level {
      padding: 0 2px;

      &-name {
        font-size: 8px;
      }

      &-available {
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  &__levels {
    padding: 10px;
  }

  &__level {
    display: flex;
    align-items: center;

    & + & {
      margin-top: 6px;
    }

    &-name {
      text-transform: uppercase;
      font-weight: bold;
      font-family: $condensed;
      flex: 1;
    }
  }
}
