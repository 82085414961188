.ct-feats-detail {
  &__default {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 310px;
    text-align: center;
    margin: 0 auto;
    color: $ct--theme-de-emphasized-color;
    font-family: $condensed;
    &--dark-mode {
      color: white;
    }
  }
}
