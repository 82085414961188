.container {
  display: flex;
  margin-right: 1.25rem;

  @media (max-width: 1023px) {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 767px) {
    margin: 0 0 0.625rem 0;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    padding-bottom: 0.625rem;
    display: flex;
    justify-content: center;
    border-bottom: 0.0625rem solid var(--theme-transparent);
  }
}

.campaignSummary {
  border: 0.0625rem solid var(--theme-color);
  border-radius: 0.1875rem;
  padding: 0.3125rem 0.5rem 0.25rem;
  cursor: pointer;
  background-color: transparent;
  transition: all 100ms;
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 25rem;
    background-color: var(--theme-background);
  }

  @media (max-width: 767px) {
    width: 100%;
    background-color: var(--theme-background);
  }
}

.campaignSummary:hover {
  background-color: #111;

  @media (max-width: 1023px) {
    background-color: var(--theme-background);
  }
}

.summaryLabel {
  font-weight: bold;
  margin-right: 5px;
  text-transform: uppercase;
  font-family: var(--font-condensed);

  @media (max-width: 1023px) {
    color: var(--theme-contrast);
  }
}

.summaryName {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9.375rem;
  white-space: nowrap;
  flex: 1;
  font-family: var(--font-condensed);

  @media (max-width: 1023px) {
    max-width: 5.5rem;
    font-family: var(--font-family);
    color: var(--theme-contrast);
  }
}

.campaignButtonGroup {
  height: 100%;
  width: 100%;
  justify-content: center;
  border-radius: 0;
  border: 0.0625rem solid var(--theme-color);
  border-left: 0;
  padding: 0.3125rem 13px 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--font-condensed);
  white-space: nowrap;

  @media (max-width: 1023px) {
    background-color: var(--theme-background);
  }
}

.campaignButtonGroup:hover {
  background-color: #111;

  @media (max-width: 1023px) {
    background-color: var(--theme-background);
  }
}

.campaignButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaignButtonIcon {
  width: 1rem;
  height: 1rem;
  fill: #fff;
  @media (max-width: 1023px) {
    fill: var(--theme-contrast);
  }
}

.campaignPlayButtonIcon > path {
  fill: #fff;
  @media (max-width: 1023px) {
    fill: var(--theme-contrast);
  }
}
