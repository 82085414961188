.default {
  color: var(--character-muted-color);
  font-family: var(--font-condensed);
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 13.125rem;
  margin: 0 auto;
  font-size: 0.9375rem;
}

.maxHeight {
  height: 100%;
}
