.ct-combat-mobile {
  border-top: 1px solid $ct--theme-de-emphasized-color;
  border-bottom: 2px solid $ct--theme-color;
  background-color: #404040;
  color: #fff;
  &--dark-mode {
    background-color: $ct--theme-dark-mode-bg-mobile;
  }

  &__extras {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 5px 5px;
  }

  &__extra {
    position: relative;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &--statuses {
      align-self: center;
    }

    &-label {
      text-transform: uppercase;
      font-family: $condensed;
      font-weight: bold;
      font-size: 11px;
    }

    &-subheading {
      color: $ct--theme-de-emphasized-color;
      font-size: 10px;
      text-transform: uppercase;
      font-family: $condensed;
      font-weight: bold;
    }

    &-heading {
      font-size: 12px;
    }

    &-value {
      font-size: 26px;
      font-weight: 500;
      line-height: 27px;
    }

    &-footer {
      font-size: 13px;
    }
  }

  &__ctas {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__cta {
    margin-bottom: 6px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &-button {
      background-color: #333;
      border: 1px solid $ct--theme-color;
      padding: 5px 8px;
      border-radius: 3px;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-family: $condensed;
      font-size: 10px;
      cursor: pointer;
    }
  }

  .ct-mobile-divider__edge {
    display: none;
  }

  @media (min-width: 375px) {
    &__extras {
      padding: 5px 8px;
    }
  }
}
