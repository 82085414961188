.ct-vehicle-block {
  $color--stat-block-red: #822000;

  &__block {
    padding: 15px 10px;
    font-family: $font--scala-sans-offc;
    font-size: 15px;

    &--ship {
      background: #f9fafa;
      a {
        color: #7c1911;
        &:hover,
        &:visited,
        &:active {
          color: #7c1911;
        }
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    &--infernal {
      background: #e3e9e5;
    }
  }

  &__shell-cap {
    margin: 0 -2px;

    &-ship {
      background: #dcdfe1;
      height: 4px;
    }

    &-infernal {
      display: block;
    }

    &--invert-y {
      transform: rotate(180deg);
    }

    &--invert-x {
      //flip on x axis
    }
  }

  &__separator {
    &-ship {
      margin: 8px 0;

      &-border {
        background: #9f1118;
        height: 1px;
        max-width: 100%;
      }
    }

    &-infernal {
      margin: 0 -2px;
    }
  }

  &__header {
    line-height: 1.1;
  }

  &__section-header {
    border-bottom: 1px solid #9f1118;
    color: #8e1818;
    font-size: 20px;
    font-weight: normal;
    font-family: $font--scala-sans-sc-offc;
    line-height: 1.4;
    margin-bottom: 10px;

    &-content {
      display: flex;
      align-items: center;
      flex: 1;
      min-width: 0;

      &-primary {
        flex: 1;
        min-width: 0;
      }

      &-callout {
        margin-left: 5px;
      }
    }
  }

  &__name {
    font-weight: bold;
    font-size: 30px;
    line-height: 0.9;
    font-family: $font--mrs-eaves-small-caps;
    color: #7c1911;
    & &-link {
      color: #7c1911;
      &:hover,
      &:visited,
      &:active {
        color: #7c1911;
      }
    }
  }

  &__meta {
    font-style: italic;
    margin: 3px 0;
  }

  &__attributes {
    margin: 5px 0;
    color: #7c1911;
  }

  &__attribute {
    margin: 5px 0;
    line-height: 1.2;

    &-label {
      font-weight: bold;
      margin-right: 5px;
    }

    &-data {
      &-extra {
        margin-left: 5px;
      }
    }
  }

  &__abilities {
    color: $color--stat-block-red;
    font-family: $font--scala-sans-offc;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
  }

  &__ability {
    &-stat {
      width: percentage(1/3);
      padding: 5px 0;
      text-align: center;
    }

    &-heading {
      font-weight: bold;
    }

    &-modifier {
      margin-left: 2px;
    }
  }

  &__tidbit {
    margin: 5px 0;
    color: #7c1911;
    line-height: 1.2;

    &-label {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  &__features {
    margin-top: 15px;
    margin-bottom: 15px;

    &-feature {
      & + & {
        margin-top: 8px;
      }

      &-name {
        font-weight: bold;
        font-style: italic;
      }
    }
  }

  &__action-summaries {
    margin-top: 15px;
    margin-bottom: 15px;

    &-content {
      & + & {
        margin-top: 12px;
      }
    }
  }

  &__action-summary {
    & + & {
      margin-top: 8px;
    }

    &-label {
      font-weight: bold;
    }
  }

  &__action-stations {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &__action {
    & + & {
      margin-top: 8px;
    }

    &-name {
      font-weight: bold;
      font-style: italic;
    }

    &-ammo {
      &-label {
        font-style: italic;
      }
    }

    &-description {
      display: inline;
      //these are action descriptions with formatted html strings, will eventually be factored out
      p {
        display: inline;
      }
    }
  }

  .ct-vehicle-block-component,
  .ct-vehicle-block-action-station + .ct-vehicle-block-action-station {
    margin-top: 15px;
  }
}
