.ct-spell-manager {
  &__spell {
    & + & {
      margin-top: 5px;
    }

    &-always {
      font-size: 8px;
      color: $ct--theme-color;
      text-transform: uppercase;
      max-width: 47px;
      text-align: center;
      line-height: 1.2;
    }

    > .ddbc-collapsible__content {
      border: 1px solid #fff;
      padding: 10px;
      margin-top: 5px;
      background: #f9f9f9;
      box-shadow: 0 0 0 1px #e0e0e0;
      margin-bottom: 15px;
    }

    .ct-theme-button {
      width: 47px;
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-spell-manager__spell > .ddbc-collapsible__content {
    background-color: $ct--theme-dark-mode-background-color;
  }
}
