@import "../../styles/vars";
@import "../../styles/reset";
@import "../../styles/mixins";
@import "../../styles/manual-one-off-copies";
@import "../../styles/campaigns-character-card";

.hide-ads
  .ddb-campaigns-character-card-header-upper-details-link[aria-hidden="true"] {
  display: block !important;
  visibility: visible !important;
}

.ddb-campaigns-character-card-footer-links {
  &.is-unlocking {
    justify-content: flex-start;
    height: auto; // use padding instead so the lock/unlock button can be seen
    padding-top: 8px;
    padding-bottom: 12px;
    gap: 0.5rem;
  }
}

.ddb-campaigns-character-card {
  position: relative;
}
