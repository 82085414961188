.ct-spells-level {
  &__empty {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: $ct--theme-de-emphasized-color;
  }

  &__spells {
    &-row {
      &-header {
        display: flex;
        &--dark-mode {
          color: $ct--theme-dark-mode-text;
        }
      }
    }

    &-row-header &-col {
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: $condensed;
      padding: 5px 0;
    }

    &-col {
      &--action {
        width: 35px;
      }

      &--name {
        width: 135px;
      }

      &--activation {
        width: 35px;
      }

      &--range {
        width: 55px;
      }

      &--tohit {
        width: 45px;
      }

      &--damage {
        width: 85px;
      }

      &--notes {
        flex: 1;
      }
    }

    &-content {
      padding-top: 5px;
      padding-bottom: 8px;
    }
  }
}
