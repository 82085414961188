.dialog {
  border: none;
  background: none;
}

.maxCharactersDialog::backdrop {
  background: var(--ttui_grey-900);
  opacity: 0.5;
}

.spinner {
  height: 2.5rem;
  fill: var(--ttui_color-text--secondary);
  animation: rotate 1s linear infinite;
}

.alert {
  padding: 0.75rem 2rem;
  background: color-mix(
    in srgb,
    var(--ttui_color-warning--light),
    transparent 90%
  );
  color: color-mix(
    in srgb,
    var(--ttui_color-warning--main),
    var(--ttui_color-text--primary) 70%
  );
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.alert a {
  color: inherit;
  font-weight: bold;
}

.alertTitle {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 0.5rem;
}

.bottomBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  position: fixed;
  inset: auto 0 0 0;
  z-index: 2;
  background: var(--ttui_color-bg--paper);
  box-shadow: var(--ttui_box-shadow-2);
}

.bottomContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.bottomText {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.bottomText strong {
  font-weight: 500;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
