.sync-blocker {
  z-index: 12;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  color: #fff;
  background-color: rgba(#232323, 0.96);
  transition: all 150ms;
  transform: translate3d(0, 0, 0);

  &-active {
    display: flex;
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    visibility: visible;
  }

  &-transition-in-out {
    display: flex;
    opacity: 0;
    visibility: visible;
    transform: scale(0.95) translate3d(0, 0, 0);
  }

  &-inactive {
    display: none;
  }

  &-finished {
    right: 100%;
  }

  &-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &-logo {
    background: transparent center center
      url("#{$imageURL-local}/dnd-beyond-logo.svg") no-repeat;
    background-size: auto 70px;
    height: 90px;
    width: 100%;
    margin-bottom: 10px;
  }

  &-anim {
    height: 100px;
    width: 100px;
  }

  @media (min-width: $bp-builder-mobile-transition) {
    background-color: rgba(#fff, 0.01);
    position: absolute;
    transform: none !important;
    z-index: 10;

    &-group {
      background: #232323;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      border-radius: 7px;
      max-width: 310px;
      padding: 40px 20px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
