.mobileNav {
  position: fixed;
  bottom: 0.625rem;
  right: 0;
  z-index: 99999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.5rem;
}

.sidebarToggle,
.navToggle {
  cursor: pointer;
  width: 2.813rem;
  height: 2.813rem;
  background: var(--theme-color);
  border: none;
  box-shadow: 0.125rem 0.125rem 0.375rem rgba(0, 0, 0, 0.6);
  border-radius: 50% 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navToggle {
  border-radius: 50%;
}

.icon {
  fill: var(--ttui_common-0);
  font-size: 1.25rem;
}

.icon * {
  fill: var(--ttui_common-0);
}

@media screen and (min-width: 1200px) {
  .mobileNav {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .navToggle {
    display: none;
  }
}
