.editableName {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.button {
  background: none;
  border: none;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  color: white;
}

.icon {
  height: 1rem;
  fill: var(--theme-color);
}
