.sectionButton {
  font-size: 0.75rem;
  justify-content: center;
  border: 0.125rem solid var(--theme-color);
  background-color: var(--ttui_grey-800);
  transition: border-color 0.3s;
  gap: 0.125rem;
}

.sectionButton:hover {
  border-color: color-mix(
    in srgb,
    var(--theme-color),
    var(--ttui_grey-800) 25%
  );
  background-color: var(--ttui_grey-900);
}

.sectionButton svg,
.sectionButton svg * {
  fill: var(--ttui_common-0);
}
