$item-preview-meta-color: $ct--theme-de-emphasized-color;
$item-preview-meta-font: $condensed;

.ddbc-item-preview {
  display: flex;
  align-items: center;

  &__avatar {
    align-self: flex-start;
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: inline-flex;
    vertical-align: top;
    background: transparent center center no-repeat;
    background-size: cover;
    margin-right: 5px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
  }

  &__name {
  }

  &__meta {
    font-size: 10px;
    color: $item-preview-meta-color;
    font-family: $item-preview-meta-font;

    &-item {
      & + &::before {
        content: "\2022";
        padding: 0 3px;
      }
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ddbc-item-preview {
    &__meta {
      color: $ct--theme-dark-mode-text;
    }
  }
}
