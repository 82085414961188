.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalTitle {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 1.5em;
}

.modalDescription {
  font-family: var(--font-family);
  font-size: 1em;
}

.content {
  width: 33rem;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  border-radius: 0.5rem;
  padding: 0 1rem 1rem;
}
