@import "@dndbeyond/ttui/components/Tooltip/Tooltip.module.css";

[class^="Tooltip_container"] {
  display: inline-block;
}

.tooltip {
  --rt-opacity: 1;
  --rt-color-dark: var(--ttui_grey-800);
  --rt-color-white: var(--ttui_grey-100);
  max-width: 18.75rem;
  display: inline-block;
  font-style: normal;
}

[data-theme="dark"] .tooltip {
  --rt-color-dark: var(--ttui_grey-100);
  --rt-color-white: var(--ttui_grey-800);
}
