.ct-theme-button-with-menu {
  position: relative;

  &__button-icon {
    width: 8px;
    height: 8px;
  }

  &__menu {
    position: absolute;
    bottom: 0px;
    right: 40px;
    background-color: white;
    border: 1px solid $ct--theme-border-color;
    z-index: 5;
    box-shadow: 0px 4px 6px 0px #0000000f;
    max-width: 300px;

    &-option {
      padding: 10px 14px;
      &:hover {
        cursor: pointer;
        background-color: $ct--theme-hover-color;
      }
    }
  }
}
