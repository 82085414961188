.starting-equipment {
  .ddbc-select {
    height: 40px;
  }

  &-ui {
    margin: 20px 0;
  }

  &-choices {
    font-family: $condensed;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
    line-height: 1;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;

    &-label {
      font-size: 14px;
      text-align: center;
    }
  }

  &-choice {
    cursor: pointer;

    & + & {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid #333;
    }

    &-sep {
      padding: 0 10px;
      font-weight: normal;
      font-size: 12px;
    }

    &-active {
      color: $builder-default-color;
    }
  }

  &-slot {
    margin-bottom: 5px;
  }

  &-rule-slots {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 3px;
  }

  &-rule-slot {
    padding: 10px;
    display: flex;
    position: relative;
    cursor: pointer;

    & + & {
      border-top: 1px dotted #f2f2f2;

      &::before {
        content: "or";
        position: absolute;
        top: -11px;
        left: 33px;
        color: #979a9e;
        background: #fff;
        line-height: 1;
        padding: 5px;
        text-transform: uppercase;
        font-size: 11px;
      }
    }

    &-selection {
      position: relative;
      font-weight: bold;
      padding-right: 7px;
      line-height: 1;
      min-width: 27px;
    }

    &-checkbox {
    }

    &-identifier {
      font-weight: bold;
      margin-right: 8px;
    }

    &-selected {
      .starting-equipment-rule-slot-name {
        font-weight: bold;
      }
    }

    &-inactive {
      background: #f2f2f2;
      color: #aaa;
    }

    &-disabled {
      cursor: default;
    }

    &-instruction {
      cursor: default;
    }
  }

  &-rules {
  }

  &-rule {
    margin-top: 5px;

    &-content {
      flex: 1;
      line-height: 1.4;
    }
  }

  &-custom {
    &-add {
      display: flex;
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #f2f2f2;
      align-items: center;
      line-height: 1;
      font-size: 10px;

      &-label {
        font-size: 12px;
      }
    }
  }

  &-packs {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #f2f2f2;
    line-height: 1.3;
    font-size: 12px;
  }

  &-pack {
    & + & {
      margin-top: 10px;
    }

    &-name {
      font-weight: bold;
    }
  }

  &-requirements-missing {
    margin: 20px 0;
    text-align: center;
  }

  &-disabled-message {
    font-size: 12px;
    color: red;
  }

  &-gear-actions {
    text-align: center;
    margin-top: 15px;

    .character-button {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }

    .ddbc-link {
      vertical-align: middle;
      font-size: 13px;
    }
  }

  &-gold {
    text-align: center;

    &-explanation {
      margin: 15px 0;
      font-weight: bold;
    }

    &-entry {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .ddbc-select {
        width: 80px;
        max-width: none;
      }

      &-actions {
        user-select: none;
      }

      &-input {
        padding: 0 10px;
      }

      &-total {
        padding-left: 5px;
      }
    }
  }

  @media (min-width: $bp-builder-mobile-transition) {
    .ddbc-select {
      max-width: 50%;
    }

    &-gold {
      &-entry {
        .ddbc-select {
          max-width: none;
        }
      }
    }
  }
}
