.numberDisplay {
  color: var(--theme-contrast);
}

.modified {
  color: var(--ttui_blue-400);
}

.signed {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
}

.sign {
  font-size: 0.75rem;
  margin-right: 0.063rem;
}

.label {
  font-size: 0.625rem;
  margin-left: 0.188rem;
}

.labelSignColor {
  color: color-mix(in srgb, var(--theme-contrast) 60%, transparent);
}

.large {
  position: relative;
}

.largeDistance {
  font-size: 1.625rem;
  font-weight: 500;
}

.largeLabel {
  font-size: 1rem;
  position: absolute;
  bottom: 0.125rem;
}

.largeSign {
  font-size: 1.25rem;
}
