.ct-spell-pane {
  &__heading {
    &-preview {
      min-width: 0;
      align-self: flex-start;
      width: 32px;
      height: 32px;
      display: inline-flex;
      background: center center transparent no-repeat;
      background-size: cover;
      margin-right: 5px;
      border: 1px solid #d8d8d8;
      border-radius: 3px;

      @each $school in $spell-schools {
        &--school-#{$school} {
          background-image: url("#{$imageURL-local}/spell-schools/35/#{$school}.png");
        }
      }
    }
  }
}
