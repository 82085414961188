.pane {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 0;
}

.border {
  display: none;
}

.bottom {
  transform: rotate(180deg);
}

.gap {
  border-left: 0.1875rem solid var(--theme-color);
  border-right: 0.1875rem solid var(--theme-color);
  background: var(--theme-background-solid);
  height: 2rem;
}
.gap.dark {
  background: var(--ttui_grey-900);
}

.content {
  --border: 0.188rem solid var(--theme-color);

  background: var(--theme-background-solid);
  color: var(--theme-contrast);
  border-left: var(--border);
  border-right: var(--border);
  flex: 1 1;
  min-width: 20rem;
  overflow-y: auto;
  padding: 0 0.625rem 3.438rem;
}

.content.dark {
  background: var(--ttui_grey-900);
}

.fullWidth {
  padding: 0 0 3.438rem;
}

@media screen and (min-width: 340px) {
  .content {
    min-width: 21.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .fullWidth {
    padding: 0.625rem 0;
  }
}

@media screen and (min-width: 1200px) {
  .gap {
    height: 0.625rem;
  }
  .border {
    display: block;
    height: 1.125rem;
  }

  .border.dark [class*="borderBg"] {
    fill: var(--ttui_grey-900);
  }
}
