.sidebar {
  position: fixed;
  top: 0;
  z-index: 10001;
  height: auto;
  bottom: 0;
  min-width: 20rem;
  display: flex;
  transition: left 150ms, right 150ms, box-shadow 150ms;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  opacity: 0;
  transition: opacity 150ms;
  background-color: var(--theme-background);
  pointer-events: none;
}

.mask.visible {
  opacity: 0.9;
  pointer-events: all;
}

.syncBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.01);
}

@media screen and (min-width: 768px) {
  .mask {
    display: none;
  }
}

@media screen and (min-width: 340px) {
  .sidebar {
    min-width: 21.25rem;
  }
}

@media screen and (min-width: 768px) {
  .sidebar {
    box-shadow: 0 0 0.625rem #242424;
  }

  .sidebar.hidden {
    box-shadow: 0 0 0 transparent;
  }
}

@media screen and (min-width: 1200px) {
  .sidebar {
    position: absolute;
    top: 7.5rem;
    bottom: auto;
    height: 60.375rem;
    box-shadow: none;
    z-index: 1;
  }

  .sidebar.hidden {
    min-width: 1.5625rem;
  }
  .sidebar.right.hidden {
    right: 0 !important;
  }
  .sidebar.left.hidden {
    left: 0 !important;
  }
}
