.feat + .feat {
  margin-top: 0.3125rem;
  padding-top: 0.3125rem;
  border-top: 0.0625rem solid var(--theme-transparent);
}

.feat :global(.ddbc-todo-icon) {
  margin-left: 0.3125rem;
}

.failures {
  color: var(--negative-color);
  font-size: 0.6875rem;
}

.button {
  min-width: 2.5rem;
}

.origin {
  min-height: 1.375rem;
  font-family: var(--font-condensed);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;
  min-width: 2.5rem;
}

.originLabel {
  font-size: 0.625rem;
  color: var(--character-muted-color);
}

.originName {
  font-size: 0.6875rem;
}

.detail {
  margin-top: 0.3125rem;
  margin-bottom: 0.9375rem;
  border: 0.0625rem solid var(--ttui_common-0);
  padding: 0.625rem;
  background: var(--ttui_grey-50);
  box-shadow: 0 0 0 0.0625rem var(--ttui_grey-200);
}

.detail.dark {
  background-color: var(--character-content-bg-dark);
  border-color: var(--character-content-bg-dark);
  box-shadow: unset;
}

.source {
  color: var(--character-muted-color);
  font-size: 0.6875rem;
}
