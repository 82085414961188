.ct-defense-manage-pane {
  &__group {
    & + & {
      margin-top: 15px;
    }
  }

  &__heading {
    font-family: $condensed;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__items {
  }

  &__item {
    display: flex;

    & + & {
      margin-top: 10px;
    }

    &-value {
      width: 30px;
      font-family: $condensed;
      font-size: 14px;
      font-weight: bold;
    }

    &-icon {
      vertical-align: top;
    }

    &-label {
      flex: 1;
    }

    &-extra {
      margin-left: 5px;
      color: $ct--theme-de-emphasized-color;
    }
  }

  &__custom {
    @include sidebarSeparator();

    &-group {
      margin-top: 15px;
    }

    &-items {
      margin: 15px 0;
    }

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      line-height: 1.3;

      &-label {
        flex: 1;
        align-self: center;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &-fields {
      display: flex;
      margin-top: 10px;
    }

    &-field {
      flex: 1;
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .character-select {
    height: 40px;
  }

  .ct-item-name {
    padding-right: 0;
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-defense-manage-pane {
    &__custom-item {
      &-input,
      &-source {
        input,
        input:hover {
          @include darkModeInput();
        }
      }
    }
  }
}
