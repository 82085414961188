.builder-sections {
  &-small {
    background-color: #333;
    position: fixed;
    top: $site-interactions-mobile-height;
    left: 0;
    right: 0;
    z-index: 11;
  }

  &-view {
    display: flex;
    justify-content: center;
  }

  &-sheet {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 10px;
    background: linear-gradient(to left, #222 50%, rgba(0, 0, 0, 0));
    z-index: 10;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-icon {
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-image: url("#{$imageURL-local}/character-sheet/sheet-active.svg");
      background-size: 30px;
    }

    &-disabled {
      background: linear-gradient(to left, #222 70%, rgba(0, 0, 0, 0));
      cursor: default;
    }

    &-disabled &-icon {
      background-image: url("#{$imageURL-local}/character-sheet/sheet-inactive.svg");
    }
  }

  & &-link {
    color: #fff;
    padding: 20px 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $condensed;

    &:hover,
    &:active,
    &:visited {
      color: #fff;
    }

    &::after {
      content: "";
      display: block;
      border-bottom: 3px solid transparent;
      margin-top: 2px;
    }

    &-active {
      &::after {
        border-color: $builder-default-color;
      }
    }
  }

  &-section-home {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      background-image: url("#{$imageURL-local}/icons/cog.svg");
      background-size: 12px;
      right: 100%;
      margin-top: 2px;
    }
  }

  &-section-whats_next {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      border-left: 5px solid #fff;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      width: 0;
      height: 0;
      left: 100%;
      margin-top: 4px;
    }
  }

  &-large {
    position: relative;
    top: 0;
    z-index: 2;
    left: 0;
    right: 0;
    padding: 0 10px;
    margin-bottom: 20px;

    &-routes {
      display: flex;
      justify-content: center;
      margin: 0 10px;
    }

    .builder-sections-view {
      padding: 0 5px;
    }

    .builder-sections-link {
      font-size: 14px;
      font-weight: bold;
    }
  }

  @media (min-width: $bp-builder-mobile-transition) {
    &-sheet {
      background: none;
      left: 100%;
      right: auto;
      padding: 0;
    }

    &-section-home {
      &::before {
        margin-top: 4px;
      }
    }

    &-section-whats_next {
      &::before {
        margin-top: 6px;
      }
    }
  }
}
