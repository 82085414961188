.ct-senses-desktop {
  .ct-senses {
    &__summary {
      overflow: hidden;
      font-size: 12px;
      margin-top: 6px;
      line-height: 1.2;
      height: calc(3.6em);
    }
  }
}
