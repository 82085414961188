.callout {
  align-self: center;
}

.heading {
  margin: 0;
}

.interactive {
  cursor: pointer;
}
