/* All Elements are missing this */
*,
*::after,
*::before {
  box-sizing: inherit;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  /* box-sizing: inherit; */
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

body {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 13px;
  margin: 0;
  line-height: 1.4;
}

/* The inputs and button style is needed since this is coming from the compiled waterdeep style
    This affect the Search input in the Inventory tab, and also when an Item has a quantity input it comes with the incorrect style
*/
// Now that we are including the waterdeep styles, we dont need this anymore but leaving it for reference as we clean up.
// .ct-character-sheet {
//     input[type='text'],
//     input[type='number'],
//     input[type='search'],
//     input[type='password'],
//     input[type='email'],
//     textarea,
//     select {
//         background-color: #fff;
//         background: #fff none;
//         border: 1px solid #ddd;
//         border-radius: 4px;
//         box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
//         color: #000;
//         padding: 4px;
//         width: 180px;
//         -webkit-transition: 0.2s linear border, 0.2s linear box-shadow;
//         -moz-transition: 0.2s linear border, 0.2s linear box-shadow;
//         transition: 0.2s linear border, 0.2s linear box-shadow;
//     }
// }

// input[type='search'] {
//     outline: none;
// }

// button,
// input,
// select,
// textarea {
//     font-size: 100%;
//     margin: 0;
//     vertical-align: baseline;
// }

// a {
//     color: #242527;
//     text-decoration: none;
// }

// label {
//     display: block;
// }

// input,
// select {
//     font-family: Roboto, Helvetica, sans-serif !important;
// }
// input,
// button,
// select,
// textarea {
//     font-family: Roboto, Helvetica, sans-serif;
// }

/**
BUILDER
*/
@media (min-width: 768px) {
  /* .body-rpgcharacterbuilder .character-builder-inner { */ /* REMOVE THE BODY DEPS?*/
  .character-builder-inner {
    max-width: 680px;
    margin: 0 auto;
  }
}

.character-builder-inner {
  position: relative;
  padding-top: 5px;
}

/**
 Custom CSS from waterdeep CP
 */

.summoning-creatures-table td:nth-child(1),
.compendium-left-aligned-table td:nth-child(1) {
  text-align: center !important;
}

table {
  border-spacing: 0;
  width: 100%;
}

#site-main {
  padding-top: 0 !important;
}

@media (max-width: ($bp-sheet-desktop-transition - 1)) {
  header.main {
    position: fixed;
  }
  #site-main {
    padding-top: 47px !important;
  }
}

li.user-details__menu-item .user-details__link,
li.user-details__menu-item .user-details__link:hover,
li.user-details__menu-item .user-details__link:active,
li.user-details__menu-item .user-details__link:visited {
  color: #fff;
}

// overrides for compiled.css
.site-bar {
  input[type="text"]:hover,
  input[type="text"]:focus {
    background-color: unset;
  }
  form {
    margin: unset;
  }
}

a:visited,
a:hover {
  color: unset;
}

.spell-list-item .collapsible-header-info {
  max-height: 32px;
}
