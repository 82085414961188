.ct-equipment-overview {
  display: flex;
  align-items: center;
  line-height: 1;

  &__sep {
    flex: 1;
  }

  &__currency {
    border-radius: 3px;
    &:hover,
    &:focus {
      background-color: $ct--theme-border-color;
    }

    &:hover {
      cursor: pointer;
    }

    &-items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      padding: 6px;
    }

    &-item {
      display: flex;
      align-items: flex-end;

      & + & {
        margin-left: 5px;
      }

      &-preview {
        width: 16px;
        height: 16px;
      }

      &-count {
        font-family: $condensed;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        margin-right: 3px;
      }
    }

    &-empty {
      color: $ct--theme-de-emphasized-color;
    }
  }

  &__weight {
    cursor: pointer;

    &-carried {
      &-label {
        font-weight: bold;
        font-family: $condensed;
        text-transform: uppercase;
        margin-right: 5px;
      }
      &--dark-mode {
        color: white;
      }
    }

    &-speed {
      color: $ct--theme-de-emphasized-color;
      font-weight: bold;
      font-family: $condensed;
      text-transform: uppercase;
      font-size: 10px;

      &--encumbered,
      &--heavily-encumbered,
      &--push-drag-lift,
      &--over-carrying-capacity {
        color: $ct--negative-color;
      }
    }
  }

  &__manage {
    margin-left: 20px;

    &-icon {
      width: 16px;
      height: 16px;
      background: center center transparent
        url("#{$imageURL-local}/icons/gear-grey.svg");
      background-size: 16px 16px;
      display: inline-flex;
      vertical-align: top;
      cursor: pointer;

      &:hover {
        background-image: url("#{$imageURL-local}/icons/gear.svg");
      }
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-equipment-overview {
    &__weight-speed--unencumbered {
      color: $ct--theme-dark-mode-text;
    }
    &__currency {
      &:hover,
      &:focus {
        background-color: $ct--theme-dark-mode-secondary-bg;
      }
    }
  }
}
