@mixin frame() {
  .ddbc-character-avatar__frame {
    @content;
  }
}
@mixin frame-extra1() {
  .ddbc-character-avatar__frame-extra1 {
    @content;
  }
}
@mixin frame-extra2() {
  .ddbc-character-avatar__frame-extra2 {
    @content;
  }
}
@mixin frame-extra3() {
  .ddbc-character-avatar__frame-extra3 {
    @content;
  }
}
@mixin frame-extra1-content() {
  .ddbc-character-avatar__frame-extra1-content {
    @content;
  }
}
@mixin frame-extra2-content() {
  .ddbc-character-avatar__frame-extra2-content {
    @content;
  }
}
@mixin frame-extra3-content() {
  .ddbc-character-avatar__frame-extra3-content {
    @content;
  }
}

@mixin portrait() {
  .ddbc-character-avatar__portrait {
    @content;
  }
}

@mixin animated-frame($animationName, $animationDuration) {
  animation-name: $animationName;
  animation-iteration-count: infinite;
  animation-duration: $animationDuration;
  animation-timing-function: steps(1);
}

@mixin frame-hover() {
  &:hover .ddbc-character-avatar__frame {
    @content;
  }
}

.ddbc-character-avatar {
  position: relative;
  width: 44px;
  height: 44px;

  &__frame {
    top: 0;
    position: absolute;
    overflow: hidden;
    width: 44px;
    height: 44px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__portrait {
    position: relative;
  }

  // XGtE - gold circle
  &--61562 {
    @include frame() {
      width: 65px;
      height: 59px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -15px;
      top: -11px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: -4px;
      left: 2px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 85px;
        height: 81px;
        left: -22px;
        top: -13px;
      }

      @include portrait() {
        left: 0;
      }
    }
  }

  // XGtE - clear fishbowl with clear edges
  &--65142 {
    @include frame() {
      width: 57px;
      height: 57px;
      left: -6px;
      top: -7px;
    }
    @include portrait() {
      border-radius: 50%/42%;
      border: none;
      width: 55px;
      height: 53px;
      left: -5px;
      top: -4px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 72px;
        height: 72px;
      }

      @include portrait() {
        width: 70px;
        height: 68px;
      }
    }
  }

  // XGtE - many eyes
  &--63923 {
    @include frame() {
      height: 73px;
      width: 63px;
      top: -20px;
      left: -11px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        height: 95px;
        width: 84px;
        top: -25px;
        left: -11px;
      }
    }
  }

  // XGtE - animated fish
  &--65140 {
    @include frame() {
      width: 57px;
      height: 57px;
      left: -6px;
      top: -7px;
    }
    @include frame-extra1() {
      background: transparent
        url("#{$imageURL-local}/character-sheet/frames/Sylgar.png") no-repeat;
      background-size: contain;
      animation-name: fishSwim;
      animation-iteration-count: infinite;
      animation-duration: 9s;
      animation-timing-function: linear;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 22px;
    }
    @include portrait() {
      border-radius: 50%/42%;
      border: none;
      width: 55px;
      height: 53px;
      left: -5px;
      top: -4px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 72px;
        height: 72px;
      }
      @include frame-extra1() {
        width: 30px;
        height: 30px;
        top: 24px;
      }
      @include portrait() {
        width: 70px;
        height: 68px;
      }
    }
  }

  // XGtE - animated many eyes
  &--65249 {
    @include frame() {
      height: 73px;
      width: 63px;
      top: -20px;
      left: -11px;
    }
    @include frame-extra1() {
      background: 0 0 transparent
        url("#{$imageURL-local}/character-sheet/frames/animated-eye.png")
        no-repeat;
      animation-name: eyeballBlink;
      animation-iteration-count: infinite;
      animation-duration: 12s;
      animation-timing-function: steps(1);
      position: absolute;
      height: 73px;
      width: 63px;
      top: -20px;
      left: -11px;
      background-size: 311px;
    }
    @include frame-extra2() {
      background: 0 0 transparent
        url("#{$imageURL-local}/character-sheet/frames/animated-eye2.png")
        no-repeat;
      animation-name: eyeballBlink2;
      animation-iteration-count: infinite;
      animation-duration: 14.5s;
      animation-timing-function: steps(1);
      position: absolute;
      height: 73px;
      width: 63px;
      top: -20px;
      left: -11px;
      background-size: 311px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        height: 95px;
        width: 84px;
        top: -25px;
        left: -11px;
      }
      @include frame-extra1() {
        height: 95px;
        width: 84px;
        top: -25px;
        left: -11px;
        background-size: 415px;
      }
      @include frame-extra2() {
        height: 95px;
        width: 84px;
        top: -25px;
        left: -11px;
        background-size: 415px;
      }
    }
  }

  // MToF - Planar - gold stargate looking circle
  &--316645 {
    @include frame() {
      width: 65px;
      height: 59px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -7px;
      top: -8px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 0;
      left: 1px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 85px;
        height: 81px;
        left: -11px;
        top: -13px;
      }

      @include portrait() {
        left: 0;
        top: -2px;
        width: 58px;
        height: 58px;
      }
    }
  }

  // MToF - blue gem 1 - small gem ornate frame
  &--316650 {
    @include frame() {
      width: 52px;
      height: 59px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: -4px;
      margin-top: -7px;
    }
    @include portrait() {
      border-radius: 0;
      border: none;
    }
    @include frame-extra1() {
      width: 44px;
      height: 44px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 0 8px black inset;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 74px;
        height: 81px;
        margin-left: -7px;
        margin-top: -12px;
      }

      @include frame-extra1() {
        width: 60px;
        height: 60px;
      }
    }
  }

  // MToF - blue gem 2 - large gem ornate frame
  &--316652 {
    @include frame() {
      width: 55px;
      height: 59px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: -4px;
      margin-top: -8px;
    }
    @include portrait() {
      border-radius: 0;
      border: none;
      left: 1px;
    }
    @include frame-extra1() {
      width: 44px;
      height: 44px;
      position: absolute;
      top: 0;
      left: 1px;
      box-shadow: 0 0 8px black inset;
    }

    @include frame-extra2() {
      background: 0 0 transparent
        url("#{$imageURL-local}/character-sheet/frames/mtof/blue-gem-2-gem.png")
        no-repeat;
      background-size: 55px auto;
      position: absolute;
      top: -8px;
      left: -4px;
      width: 44px;
      height: 44px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 75px;
        height: 81px;
        margin-left: -6px;
        margin-top: -11px;
      }

      @include frame-extra1() {
        width: 60px;
        height: 60px;
      }

      @include frame-extra2() {
        background-size: 75px auto;
        width: 60px;
        height: 60px;
        top: -11px;
        left: -6px;
      }
    }
  }

  // MToF - Gear Cogs
  &--316664,
  &--316663 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 0;
      width: 42px;
      height: 42px;
    }
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      width: 46px;
      height: 46px;
      left: -2px;
      top: -2px;
    }
    @include frame-extra1() {
      background: 0 0 transparent
        url("#{$imageURL-local}/character-sheet/frames/mtof/cog-1.png")
        no-repeat;
      background-size: 20px auto;
      width: 22px;
      height: 44px;
      position: absolute;
      top: 0;
      left: 34px;
    }

    @include frame-extra2() {
      background: 0 0 transparent
        url("#{$imageURL-local}/character-sheet/frames/mtof/cog-2.png")
        no-repeat;
      background-size: 18px auto;
      position: absolute;
      top: -6px;
      left: -10px;
      width: 18px;
      height: 54px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        left: 0;
        top: -2px;
        width: 58px;
        height: 58px;
      }
      @include frame() {
        width: 63px;
        height: 63px;
        left: -3px;
        top: -4px;
      }
      @include frame-extra1() {
        background-size: 30px auto;
        width: 32px;
        height: 64px;
        top: -8px;
        left: 44px;
      }
      @include frame-extra2() {
        background-size: 25px auto;
        top: -13px;
        left: -12px;
        width: 26px;
        height: 64px;
      }
    }
  }

  // MToF - Animated Gear Cogs - inherits from previous cogs
  &--316664 {
    @include frame() {
      animation-name: mtofGears;
      animation-iteration-count: infinite;
      animation-duration: 30s;
      animation-timing-function: linear;
    }
  }

  // MToF - Orrery - planetarium looking circles
  &--316655,
  &--316653 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 0;
      left: -1;
      width: 42px;
      height: 42px;
    }
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      width: 95px;
      height: 73px;
      left: -26px;
      top: -9px;
    }
    @include frame-extra1() {
      background: 0 0 transparent
        url("#{$imageURL-local}/character-sheet/frames/mtof/orrery-planet-2.png")
        no-repeat;
      background-size: 8px auto;
      position: absolute;
      top: -3px;
      left: -3px;
      width: 15px;
      height: 15px;
      transform: rotate(60deg);
      transform-origin: 24px 24px;
    }
    @include frame-extra2() {
      background: 0 0 transparent
        url("#{$imageURL-local}/character-sheet/frames/mtof/orrery-planet-1.png")
        no-repeat;
      background-size: 20px auto;
      position: absolute;
      top: -8px;
      left: -8px;
      width: 20px;
      height: 20px;
      transform-origin: 29px 29px;
    }
    @include frame-extra3() {
      background: 0 0 transparent
        url("#{$imageURL-local}/character-sheet/frames/mtof/orrery-ring.png")
        no-repeat;
      background-size: contain;
      position: absolute;
      top: 16px;
      left: -26px;
      width: 95px;
      height: 24px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 135px;
        height: 81px;
        left: -37px;
        top: -14px;
      }

      @include portrait() {
        width: 58px;
        height: 58px;
      }
      @include frame-extra1() {
        background-size: 14px auto;
        top: -5px;
        left: -5px;
        width: 20px;
        height: 20px;
        transform-origin: 34px 34px;
      }
      @include frame-extra2() {
        background-size: 35px auto;
        top: -15px;
        left: -15px;
        width: 40px;
        height: 40px;
        transform-origin: 42px 42px;
      }
      @include frame-extra3() {
        top: 20px;
        left: -37px;
        width: 132px;
        height: 24px;
      }
    }
  }

  // MToF - Animated Orrery - inherits from previous orrery - planetarium looking circles
  &--316655 {
    @include frame-extra1() {
      animation-name: mtofPlanet2;
      animation-iteration-count: infinite;
      animation-duration: 40s;
      animation-timing-function: linear;
    }
    @include frame-extra2() {
      animation-name: mtofPlanet1;
      animation-iteration-count: infinite;
      animation-duration: 50s;
      animation-timing-function: linear;
    }
  }

  // WDH - knave's hat
  &--4518245 {
    @include frame() {
      width: 115px;
      height: 55px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -28px;
      top: -14px;
      transform: rotate(-19deg);
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 1px;
      left: 1px;
      width: 41px;
      height: 41px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 165px;
        height: 65px;
        left: -44px;
        top: -20px;
      }

      @include portrait() {
        left: 0;
        top: 0;
        width: 60px;
        height: 60px;
      }
    }
  }

  // WDH - gold circle
  &--4518281 {
    @include frame() {
      width: 65px;
      height: 65px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -17px;
      top: -13px;
      z-index: 1;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 1px;
      left: 7px;
      width: 41px;
      height: 41px;
      z-index: 2;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 95px;
        height: 95px;
        left: -27px;
        top: -21px;
      }

      @include portrait() {
        left: 8px;
        top: 0;
        width: 60px;
        height: 60px;
      }
    }
  }

  // DDB - Pride - 18435714
  &--18435714,
  &--ddb-pride {
    @include frame() {
      width: 65px;
      height: 65px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -12px;
      top: -9px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 2px;
      left: -1px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 95px;
        height: 95px;
        left: -21px;
        top: -16px;
      }

      @include portrait() {
        left: -3px;
        top: 0;
        width: 58px;
        height: 58px;
      }
    }
  }

  // WDH - platinum - silver circle
  &--4518282 {
    @include frame() {
      width: 65px;
      height: 65px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -12px;
      top: -9px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 2px;
      left: -1px;
      width: 42px;
      height: 42px;
    }

    @include frame-extra1 {
      box-shadow: 0 0 3px 4px #000 inset;
      position: absolute;
      z-index: 1;
      left: -2px;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 95px;
        height: 95px;
        left: -21px;
        top: -16px;
      }

      @include portrait() {
        left: -3px;
        top: 0;
        width: 58px;
        height: 58px;
      }

      @include frame-extra1 {
        box-shadow: 0 0 3px 5px #000 inset;
        left: -4px;
      }
    }
  }

  // GGtR guild circle
  &--4804075, // Briv special - admin role
    &--4804109, // Azorius
    &--4804110, // Boros
    &--4804112, // Dimir
    &--4804115, // Golgari
    &--4804118, // Gruul
    &--4804121, // Izzet
    &--4804122, // Orzhov
    &--4804127, // Rakdos
    &--4804131, // Selesnya
    &--4804134 /* Simic */ {
    @include frame() {
      width: 85px;
      height: 62px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -27px;
      top: -10px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 3px;
      left: -6px;
      width: 46px;
      height: 46px;
    }
    @include frame-extra1 {
      box-shadow: 0 0 3px 3px #000 inset;
      position: absolute;
      z-index: 1;
      top: 3px;
      left: -6px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 115px;
        height: 82px;
        left: -40px;
        top: -16px;
      }

      @include portrait() {
        left: -11px;
        top: 3px;
        width: 58px;
        height: 58px;
      }

      @include frame-extra1 {
        box-shadow: 0 0 3px 3px #000 inset;
        left: -11px;
        top: 2px;
        width: 100%;
        height: 100%;
      }
    }
  }

  // WDotMM - Spider Scarab
  &--4804103 {
    @include frame() {
      width: 60px;
      height: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -6px;
      top: -10px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 7px;
      left: 3px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 85px;
        height: 85px;
        left: -13px;
        top: -16px;
      }

      @include portrait() {
        left: 0;
        top: 10px;
        width: 58px;
        height: 58px;
      }
    }
  }

  // WDotMM - Map Star - Dungeon Grid
  &--4804092 {
    @include frame() {
      width: 75px;
      height: 75px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -16px;
      top: -17px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 2px;
      left: -1px;
      width: 42px;
      height: 42px;
    }

    @include frame-extra1 {
      box-shadow: 0 0 3px 5px #000 inset;
      position: absolute;
      z-index: 1;
      left: -2px;
      top: 0px;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 105px;
        height: 105px;
        left: -25px;
        top: -21px;
      }

      @include portrait() {
        left: -3px;
        top: 4px;
        width: 58px;
        height: 58px;
      }

      @include frame-extra1 {
        box-shadow: 0 0 3px 6px #000 inset;
        left: -4px;
        top: 4px;
      }
    }
  }

  // GoS - Lizard head - Lizard
  &--6087895 {
    @include frame() {
      width: 65px;
      height: 75px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -12px;
      top: -17px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 6px;
      left: 2px;
      width: 39px;
      height: 39px;
    }

    @include frame-extra1 {
      box-shadow: 0 0 3px 5px rgba(0, 0, 0, 0.8) inset;
      position: absolute;
      z-index: 1;
      left: -1px;
      top: 7px;
      width: 100%;
      height: 40px;
      border-radius: 50% 50% 41px 50px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 95px;
        height: 105px;
        left: -19px;
        top: -21px;
      }

      @include portrait() {
        left: 2px;
        top: 13px;
        width: 54px;
        height: 54px;
      }

      @include frame-extra1 {
        box-shadow: 0 2px 3px 6px rgba(0, 0, 0, 0.8) inset;
        left: -2px;
        top: 11px;
        height: 55px;
      }
    }
  }

  // GoS - Jellyfish
  &--6087892 {
    @include frame() {
      width: 70px;
      height: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -8px;
      top: -3px;
      z-index: 2;
      transform: rotate(31deg);
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 7px;
      left: 3px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 102px;
        height: 88px;
        left: -16px;
        top: -6px;
      }

      @include portrait() {
        left: -1px;
        top: 6px;
        width: 64px;
        height: 64px;
      }
    }
  }

  // GoS - Compass
  &--6087885 {
    @include frame() {
      width: 70px;
      height: 70px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -13px;
      top: -11px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 4px;
      left: -1px;
      width: 42px;
      height: 42px;
    }

    @include frame-extra1 {
      box-shadow: 0 0 3px 6px rgba(0, 0, 0, 0.8) inset;
      position: absolute;
      z-index: 1;
      left: -1px;
      top: 2px;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 100px;
        height: 100px;
        left: -21px;
        top: -20px;
      }

      @include portrait() {
        left: 3px;
        top: 7px;
        width: 48px;
        height: 48px;
      }

      @include frame-extra1 {
        box-shadow: 0 2px 5px 7px rgba(0, 0, 0, 0.8) inset;
        left: -3px;
        top: -1px;
      }
    }
  }

  // AI - amber
  &--6282585 {
    @include frame() {
      width: 62px;
      height: 62px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -13px;
      top: -9px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: -1px;
      left: 1px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 92px;
        height: 92px;
        left: -23px;
        top: -11px;
      }

      @include portrait() {
        left: 3px;
        top: 4px;
        width: 56px;
        height: 56px;
      }
    }
  }

  // AI - amulet
  &--6282641 {
    @include frame() {
      width: 70px;
      height: 70px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -13px;
      top: -11px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 4px;
      left: -1px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 100px;
        height: 100px;
        left: -21px;
        top: -20px;
      }

      @include portrait() {
        left: -4px;
        top: 8px;
        width: 52px;
        height: 52px;
      }
    }
  }

  //AI - mechanical beholder
  &--6282606 {
    @include frame() {
      width: 70px;
      height: 70px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -13px;
      top: -13px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: -1px;
      left: 5px;
      width: 42px;
      height: 42px;
    }
    @include frame-extra1 {
      box-shadow: 7px 7px 3px rgba(0, 0, 0, 0.7) inset;
      position: absolute;
      z-index: 1;
      left: 9px;
      top: -2px;
      width: 36px;
      height: 100%;
      border-radius: 50%;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 93px;
        height: 104px;
        left: -21px;
        top: -20px;
      }

      @include portrait() {
        left: 9px;
        top: 0;
        width: 55px;
        height: 55px;
      }

      @include frame-extra1 {
        box-shadow: 14px 7px 4px rgba(0, 0, 0, 0.7) inset;
        left: 8px;
        top: -1px;
        width: 55px;
      }
    }
  }

  // AI - wax seal
  &--6282627 {
    @include frame() {
      width: 64px;
      height: 64px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -13px;
      top: -9px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 0;
      left: -1px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 94px;
        height: 94px;
        left: -21px;
        top: -12px;
      }

      @include portrait() {
        left: -4px;
        top: 1px;
        width: 62px;
        height: 62px;
      }
    }
  }

  // Critical Role 2019 D&D Beyond Election
  &--6576987,
  &--6577000 {
    @include frame() {
      width: 50px;
      height: 50px;
      background-size: contain;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
      z-index: 2;
    }
    @include portrait() {
      top: 0;
      left: -1px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 66px;
        height: 66px;
        left: 0;
        top: 7px;
      }

      @include portrait() {
        left: -4px;
        top: 1px;
        width: 62px;
        height: 62px;
      }
    }
  }

  // BGDiA - Filigree - gold symbol
  &--7169879 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 78px;
      height: 78px;
      left: -17px;
      top: -14px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 3px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 110px;
        height: 110px;
        left: -26px;
        top: -22px;
      }
    }
  }

  // BGDiA - Grinder - Infernal Vehicle Wheel
  &--7169908 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 74px;
      height: 74px;
      left: -19px;
      top: -14px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 2px;
      left: -3px;
      width: 42px;
      height: 42px;
    }
    @include frame-extra1() {
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.7) inset;
      border: 1px solid #f6f6f6;
      border-radius: 50%;
      left: -4px;
      top: 1px;
      position: absolute;
      width: 44px;
      height: 44px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 101px;
        height: 101px;
        left: -27px;
        top: -18px;
      }

      @include portrait() {
        top: 2px;
        left: -7px;
        width: 61px;
        height: 61px;
      }
      @include frame-extra1() {
        left: -7px;
        top: 2px;
        width: 61px;
        height: 61px;
      }
    }
  }

  // BGDiA - Cultist Mask
  &--7169920 {
    @include frame() {
      width: 70px;
      height: 42px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -13px;
      top: -12px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 7px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 98px;
        height: 58px;
        left: -29px;
        top: -18px;
      }

      @include portrait() {
        left: -9px;
        top: 12px;
        width: 58px;
        height: 58px;
      }
    }
  }

  // BGDiA - Soul Coin - Black and red ring
  &--7169937 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 73px;
      height: 73px;
      left: -13px;
      top: -14px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      width: 47px;
      height: 47px;
    }
    @include frame-extra1() {
      box-shadow: 0 0 7px 2px rgba(152, 16, 15, 0.8);
      border-radius: 50%;
      position: absolute;
      width: 57px;
      height: 57px;
      top: -6px;
      left: -5px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 94px;
        height: 94px;
        left: -18px;
        top: -17px;
      }
      @include portrait() {
        width: 60px;
        height: 60px;
      }
      @include frame-extra1() {
        width: 73px;
        height: 73px;
        top: -6px;
        left: -7px;
      }
    }
  }

  // BGDiA - Chin on Fire - blue/purple flame on bottom
  &--7169981,
  &--7170027 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: -2px;
    }
    @include frame() {
      position: absolute;
      top: -33px;
      left: -35px;
      overflow: hidden;
      width: 110px;
      height: 107px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -24px;
        left: -27px;
      }
    }
  }

  // BGDiA - Animated Chin on Fire - blue/purple flame on bottom - inherits from static Chin on Fire
  &--7170027 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -33px;
      left: -35px;
      overflow: hidden;
      width: 110px;
      height: 107px;
    }
    @include frame-extra1-content() {
      width: 2750px;
      height: 100%;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/6/566/f4-bgdia.png) no-repeat;
      animation-name: animatedFrameWidth110Frame25;
      animation-iteration-count: infinite;
      animation-duration: 800ms;
      animation-timing-function: steps(1);
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -24px;
        left: -27px;
      }
    }
  }

  //// BGDiA - Embers
  &--7169947,
  &--7169990 {
    @include portrait() {
      border-radius: 50%;
      border: 1px solid #ca4c06;
      box-shadow: 0 0 10px 2px rgba(152, 16, 15, 0.8);
    }
    @include frame() {
      position: absolute;
      top: -33px;
      left: -35px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -29px;
        left: -26px;
      }
    }
  }

  // BGDiA - Animated Embers - inherits from static Embers
  &--7169990 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -33px;
      left: -35px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }
    @include frame-extra1-content() {
      width: 2800px;
      height: 100%;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/6/579/f3-bgdia.png) no-repeat;
      animation-name: animatedFrameWidth112Frame25;
      animation-iteration-count: infinite;
      animation-duration: 800ms;
      animation-timing-function: steps(1);
    }
    @include frame-extra2() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/6/588/f3-rocks-bgdia-1.png)
        no-repeat;
      position: absolute;
      overflow: hidden;
      width: 60px;
      height: 26px;
      top: 25px;
      left: -11px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
      @include frame-extra2() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -24px;
        left: -27px;
      }
      @include frame-extra2() {
        top: 43px;
        left: -5px;
      }
    }
  }

  // BGDiA - Nine Hells - ring of fire
  &--7169957,
  &--7170001 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      width: 44px;
      height: 44px;
    }
    @include frame() {
      position: absolute;
      top: -35px;
      left: -33px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -27px;
        left: -26px;
      }
      @include portrait() {
        width: 62px;
        height: 62px;
      }
    }
  }

  // BGDiA - Animated Nine Hells - ring of fire - inherits from Static Nine Hells
  &--7170001 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -33px;
      left: -34px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }
    @include frame-extra1-content() {
      width: 2800px;
      height: 100%;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/6/584/f2-bgdia.png) no-repeat;
      animation-name: animatedFrameWidth112Frame25;
      animation-iteration-count: infinite;
      animation-duration: 800ms;
      animation-timing-function: steps(1);
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -25px;
        left: -26px;
      }
    }
  }

  // BGDiA - Hair on Fire - fire on top
  &--7169972,
  &--7170019 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 8px;
    }
    @include frame() {
      position: absolute;
      top: -38px;
      left: -40px;
      overflow: hidden;
      width: 110px;
      height: 107px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -32px;
        left: -36px;
      }
      @include portrait() {
        top: 11px;
      }
    }
  }

  // BGDiA - Animated Hair on Fire - fire on top - inherits from Static Hair on Fire
  &--7170019 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -38px;
      left: -40px;
      overflow: hidden;
      width: 110px;
      height: 107px;
    }
    @include frame-extra1-content() {
      width: 2750px;
      height: 100%;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/6/586/f1-bgdia.png) no-repeat;
      animation-name: animatedFrameWidth110Frame25;
      animation-iteration-count: infinite;
      animation-duration: 800ms;
      animation-timing-function: steps(1);
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -32px;
        left: -36px;
      }
    }
  }

  //ERftLW - Ambit - Compass Points
  &--7607553 {
    @include frame() {
      width: 70px;
      height: 70px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -13px;
      top: -13px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 100px;
        height: 100px;
        left: -21px;
        top: -20px;
      }
    }
  }

  //ERftLW - Sprocket - Fancy Gear w/ gold details
  &--7607584 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 74px;
      height: 74px;
      left: -19px;
      top: -14px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 4px;
      height: 35px;
      width: 35px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 103px;
        height: 103px;
        left: -29px;
        top: -20px;
      }

      @include portrait() {
        left: -2px;
        height: 50px;
        width: 50px;
      }
    }
  }

  //ERftLW - Cog - Cog/Gear
  &--7607609 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 74px;
      height: 74px;
      left: -19px;
      top: -14px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 2px;
      left: -3px;
      width: 42px;
      height: 42px;
    }
    @include frame-extra1() {
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.7) inset;
      border-radius: 50%;
      left: -4px;
      top: 1px;
      position: absolute;
      width: 44px;
      height: 44px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 101px;
        height: 101px;
        left: -27px;
        top: -18px;
      }

      @include portrait() {
        top: 2px;
        left: -7px;
        width: 61px;
        height: 61px;
      }
      @include frame-extra1() {
        left: -7px;
        top: 2px;
        width: 61px;
        height: 61px;
      }
    }
  }

  //ERftLW - Brass Thorns - brass spikey wheel
  //ERftLW - Valenor Thorns - greenish spikey wheel
  &--7607614,
  &--7607620 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 74px;
      height: 74px;
      left: -19px;
      top: -14px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 0;
      left: -4px;
      width: 45px;
      height: 45px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 101px;
        height: 101px;
        left: -27px;
        top: -18px;
      }

      @include portrait() {
        top: 1px;
        left: -7px;
        width: 62px;
        height: 62px;
      }
    }
  }

  //ERftLW - Buzz Saw - silver circular saw
  &--7607630,
    &--7607723,
        //ERftLW - Miter's Touch - gold circular saw
    &--7607637,
    &--7607728 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      width: 46px;
      height: 46px;
      left: -6px;
    }
    @include frame() {
      position: absolute;
      top: -34px;
      left: -39px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }
    @include frame-extra2() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/300/gear_reflection.png)
        no-repeat;
      overflow: hidden;
      position: absolute;
      top: -34px;
      left: -40px;
      width: 112px;
      height: 112px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
      @include frame-extra2() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -25px;
        left: -34px;
      }
      @include portrait() {
        width: 62px;
        height: 62px;
        left: -7px;
      }
      @include frame-extra2() {
        top: -25px;
        left: -32px;
      }
    }
  }

  //ERftLW - Animated Buzz Saw - silver circular saw - inherits from Static Buzz Saw
  &--7607723,
        //ERftLW - Animated Miter's Touch - gold circular saw - inherits from Static Mitar's Touch
    &--7607728 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -34px;
      left: -39px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }
    @include frame-extra1-content() {
      width: 1120px;
      height: 100%;
      @include animated-frame(
        $animationName: animatedFrameWidth112Frame10,
        $animationDuration: 0.35s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -25px;
        left: -32px;
      }
    }
  }

  //ERftLW - Animated Buzz Saw - silver circular saw - inherits from Static Buzz Saw
  &--7607723 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/312/animatedsilvergear.png)
        no-repeat;
    }
  }

  //ERftLW - Animated Miter's Touch - gold circular saw - inherits from Static Mitar's Touch
  &--7607728 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/301/animatedgoldgear.png)
        no-repeat;
    }
  }

  //Special - admin role - Prismatic Haze/Prismatic Flux - rainbow smoke ring
  &--7607805,
    &--7607814,
        //ERftLW - Spore Cloud - green smoke ring
    &--7607697,
    &--7607790,
        //ERftLW - Electric Vortex - blue smoke ring
    &--7607707,
    &--7607794,
        //ERftLW - Arcane Mist - purple smoke ring
    &--7607693,
    &--7607784 {
    @include portrait() {
      border-radius: 50%;
      border: none;
    }
    @include frame() {
      position: absolute;
      top: -33px;
      left: -34px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -25px;
        left: -26px;
      }
    }
  }

  //Special - admin role - Prismatic Flux - rainbow smoke ring - inherits from Static Prismatic
  &--7607814,
        //ERftLW - Animated Spore Cloud - green smoke ring - inherits from Static Spore Cloud
    &--7607790,
        //ERftLW - Animated Electric Vortex - blue smoke ring - inherits from Static Electric Vortex
    &--7607794,
        //ERftLW - Animated Arcane Mist - purple smoke ring - inherits from Static Arcane Mist
    &--7607784 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -33px;
      left: -34px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }
    @include frame-extra1-content() {
      width: 1120px;
      height: 1120px;
      @include animated-frame(
        $animationName: animatedFrameWidth112Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -25px;
        left: -26px;
      }
    }
  }

  //ERftLW - Animated Spore Cloud - green smoke ring - inherits from Static Spore Cloud
  &--7607790 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/361/green32.png) no-repeat;
    }
  }
  //ERftLW - Animated Electric Vortex - blue smoke ring - inherits from Static Electric Vortex
  &--7607794 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/360/blue32.png) no-repeat;
    }
  }
  //ERftLW - Animated Arcane Mist - purple smoke ring - inherits from Static Arcane Mist
  &--7607784 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/362/purple32.png) no-repeat;
    }
  }
  //Special - admin role - Prismatic Flux - rainbow smoke ring - inherits from Static Prismatic
  &--7607814 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/359/rainbow32.png) no-repeat;
    }
  }

  //EGtW - Myriad - beige five point star
  &--9109625 {
    @include frame() {
      width: 79px;
      height: 77px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -14px;
      top: -13px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 8px;
      left: 4px;
      width: 38px;
      height: 38px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 104px;
        height: 103px;
        left: -27px;
        top: -22px;
      }

      @include portrait() {
        left: -2px;
        top: 7px;
        width: 49px;
        height: 49px;
      }
    }
  }

  //EGtW - Clovis Concord - gold frame
  &--9109429 {
    @include frame() {
      height: 65px;
      width: 53px;
      top: -9px;
      left: -5px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        height: 91px;
        width: 75px;
        top: -12px;
        left: -8px;
      }
    }
  }

  //EGtW - Wild Mother - ring of flowers
  &--9109386 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 59px;
      height: 58px;
      left: -7px;
      top: -7px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      width: 48px;
      height: 48px;
      left: -2px;
      top: -3px;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 74px;
        height: 73px;
        left: -9px;
        top: -9px;
      }
      @include portrait() {
        width: 60px;
        height: 60px;
      }
    }
  }

  //EGtW - To The Seas - circular blue wave
  &--9109572,
  &--9109643 {
    @include portrait() {
      border-radius: 50%;
      border: none;
    }
    @include frame() {
      position: absolute;
      top: -33px;
      left: -36px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -26px;
        left: -29px;
      }
    }
  }
  //EGtW - Animated To The Seas - circular blue wave - inherits from Static To The Seas
  &--9109643 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -33px;
      left: -34px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }
    @include frame-extra1-content() {
      width: 1120px;
      height: 1120px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/794/totheseas-frame-sprite.png)
        no-repeat;
      @include animated-frame(
        $animationName: animatedFrameWidth112Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -26px;
        left: -27px;
      }
    }
  }

  //EGtW - Luxon - Glass Dodecahedron
  &--9109602,
  &--9109668 {
    @include frame() {
      width: 130px;
      height: 128px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -47px;
      top: -41px;
      z-index: 2;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      left: -8px;
      top: 3px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        left: -41px;
        top: -34px;
      }

      @include portrait() {
        left: -11px;
      }
    }
  }
  //EGtW - Animated Luxon - Glass Dodecahedron with misty mist - inherits from Static Luxon
  &--9109668 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 130px;
      height: 128px;
      left: -47px;
      top: -41px;
    }
    @include frame-extra1-content() {
      width: 3250px;
      height: 100%;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/795/luxon_animated_frame_sprite_sized.png)
        no-repeat;
      animation-name: animatedFrameWidth130Frame25;
      animation-iteration-count: infinite;
      animation-duration: 800ms;
      animation-timing-function: steps(1);
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        left: -41px;
        top: -34px;
      }
    }
  }

  //EGtW - Gods & Dieties - Left aligned charm representing each god and circular matching border
  &--9109699, //Asmodeus
    &--9109719, //Avandra
    &--9109735, //Bahamut
    &--9109743, //Bane
    &--9109772, //Corellon
    &--9109794, //Erathis
    &--9109854, //Gruumsh
    &--9109878, //Ioun
    &--9109889, //Kord
    &--9109900, //Lolth
    &--9109919, //Melora
    &--9109990, //Moradin
    &--9109998, //Pelor
    &--9110018, //Raei
    &--9110070, //The Raven Queen
    &--9110187, //Sehanine
    &--9110202, //Tharizdun
    &--9110213, //Tiamat
    &--9110224, //Torog
    &--9110296, //Vecna
    &--9110320 //Zehir
  {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 95px;
      height: 66px;
      left: -30px;
      top: -10px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
      top: 1px;
      left: 4px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 130px;
        height: 90px;
        left: -43px;
        top: -14px;
      }
    }
  }

  //MOoT - Mask of Varyas - Gold Mask w/ hover state
  &--10781709 {
    @include portrait() {
      border-radius: 50%;
      left: 3px;
    }
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 55px;
      height: 55px;
      left: -2px;
      top: -1px;
      transition: all 300ms;
    }
    @include frame-hover() {
      left: -25px;
      top: 6px;
      transform: rotate(-30deg);
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        left: 0;
      }
      @include frame() {
        width: 78px;
        height: 78px;
        left: -9px;
        top: -5px;
      }
      @include frame-hover() {
        left: -40px;
        top: 7px;
      }
    }
  }

  //MOoT - Odyssey - Square temple with columns
  &--10781729 {
    @include frame() {
      width: 70px;
      height: 70px;
      top: -12px;
      left: -11px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    @include portrait() {
      border-radius: 0;
      border: none;
      left: 2px;
      top: 1px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 100px;
        height: 100px;
        top: -18px;
        left: -20px;
      }

      @include portrait() {
        width: 63px;
        height: 63px;
        left: -1px;
        top: 0px;
      }
    }
  }

  //MOoT - Meandros - Greek Pattern
  &--10781758 {
    @include frame() {
      width: 67px;
      height: 67px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -14px;
      top: -11px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 2px;
      left: -1px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 99px;
        height: 99px;
        left: -25px;
        top: -18px;
      }

      @include portrait() {
        left: -5px;
        top: 2px;
        width: 60px;
        height: 60px;
      }
    }
  }

  //MOoT - Meletis - Greek Pattern
  &--10781774 {
    @include frame() {
      width: 67px;
      height: 67px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -14px;
      top: -11px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 2px;
      left: -2px;
      width: 42px;
      height: 42px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 95px;
        height: 95px;
        left: -21px;
        top: -15px;
      }

      @include portrait() {
        left: -4px;
        top: 3px;
        width: 60px;
        height: 60px;
      }
    }
  }

  //MOoT - Vitruvian Wave - Greek Pattern
  &--10781826 {
    @include frame() {
      width: 70px;
      height: 70px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -15px;
      top: -13px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 2px;
      left: 0px;
      width: 40px;
      height: 40px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 104px;
        height: 104px;
        left: -27px;
        top: -20px;
      }

      @include portrait() {
        left: -5px;
        top: 2px;
        width: 60px;
        height: 60px;
      }
    }
  }

  //MOoT - Labyrinthine - Greek Pattern
  &--10781852 {
    @include frame() {
      width: 72px;
      height: 72px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -16px;
      top: -13px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 4px;
      left: 1px;
      width: 38px;
      height: 38px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 108px;
        height: 108px;
        left: -29px;
        top: -22px;
      }

      @include portrait() {
        left: -3px;
        top: 4px;
        width: 56px;
        height: 56px;
      }
    }
  }

  //MOoT - Theros - Greek Pattern
  &--10781792 {
    @include frame() {
      width: 72px;
      height: 72px;
      background-size: contain;
      background-repeat: no-repeat;
      left: -16px;
      top: -13px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 3px;
      left: 0px;
      width: 40px;
      height: 40px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 106px;
        height: 106px;
        left: -29px;
        top: -21px;
      }

      @include portrait() {
        left: -6px;
        top: 2px;
        width: 60px;
        height: 60px;
      }
    }
  }

  //MOot - Tempest - circular storm clouds
  &--10781509,
    &--10781889,
        //MOot - Stardust - blue circular star galaxy
    &--10781586,
    &--10781903,
        //MOot - Nyxborn - green circular circular star galaxy
    &--10781617,
    &--10781926,
        //MOot - Solar - red-orange circular circular star galaxy
    &--10781642,
    &--10781945 {
    @include portrait() {
      border-radius: 50%;
      border: none;
    }
    @include frame() {
      position: absolute;
      top: -34px;
      left: -34px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -26px;
        left: -26px;
      }
    }
  }
  &--10781889, //MOot - Animated Tempest - circular storm clouds - inherits from static Tempest
    &--10781903, //MOot - Animated Stardust - blue circular star galaxy - inherits from static Stardust
    &--10781926, //MOot - Animated Nyxborn - green circular star galaxy - inherits from static Nyxborn
    &--10781945 //MOot - Animated Solar - red-orange circular circular star galaxy - inherits from static Solar
  {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -34px;
      left: -34px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }
    @include frame-extra1-content() {
      width: 1120px;
      height: 1120px;
      @include animated-frame(
        $animationName: animatedFrameWidth112Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -26px;
        left: -26px;
      }
    }
  }
  //MOot - Animated Tempest - Circular storm clouds - inherits from static Tempest
  &--10781889 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/969/tempest_animated_10x10_112x112size.png)
        no-repeat;
    }
  }
  //MOot - Animated Stardust - blue circular star galaxy - inherits from static Stardust
  &--10781903 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/971/galaxy_blue_animated_10x10_112x112size.png)
        no-repeat;
    }
  }
  //MOot - Animated Nyxborn - green circular star galaxy - inherits from static Stardust
  &--10781926 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/972/galaxy_green_animated_10x10_112x112size.png)
        no-repeat;
    }
  }
  //MOot - Animated Solar - red-orange circular star galaxy - inherits from static Solar
  &--10781945 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/7/973/galaxy_red_animated_10x10_112x112size.png)
        no-repeat;
    }
  }

  //MOot - Gods - Left aligned charm representing each god and constellation/nebula
  &--10819356, //Athreos
    &--10819384, //Ephara
    &--10819396, //Erebos
    &--10819408, //Heliod
    &--10819425, //Iroas
    &--10819438, //Karametra
    &--10819459, //Keranos
    &--10819478, //Klothys
    &--10819497, //Kruphix
    &--10819514, //Mogis
    &--10819548, //Nylea
    &--10819566, //Pharika
    &--10819582, //Phenax
    &--10819600, //Purphoros
    &--10819606 //Thassa
  {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      width: 80px;
      height: 78px;
      left: -18px;
      top: -17px;
    }
    @include portrait() {
      border-radius: 18px;
      border: none;
      left: 1px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 115px;
        height: 113px;
        left: -33px;
        top: -25px;
      }
      @include portrait() {
        top: 0px;
        left: -7px;
      }
    }
  }

  //IDRotF - Snow Flake & Flurry - icy blue bold snowflake & icy blue pointy snowflake
  &--12029944,
  &--12029974 {
    @include portrait() {
      border-radius: 50%;
      border: 2px solid #a3e5ff;
      z-index: 1;
      opacity: 0.9;
      left: -1px;
      top: 2px;
      height: 40px;
      width: 40px;
    }
    @include frame() {
      position: absolute;
      top: -17px;
      left: -20px;
      overflow: hidden;
      width: 77px;
      height: 77px;
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        border-width: 3px;
        left: -4px;
        top: 2px;
        width: 57px;
        height: 57px;
      }
      @include frame() {
        width: 112px;
        height: 112px;
        top: -26px;
        left: -32px;
      }
    }
  }
  //Flurry
  &--12029974 {
    @include portrait() {
      left: -2px;
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        left: -5px;
      }
    }
  }

  //IDRotF - Frozen - icy blob
  &--12030007 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      z-index: 1;
      opacity: 0.9;
      left: 2px;
      top: -2px;
    }
    @include frame() {
      position: absolute;
      top: -19px;
      left: -17px;
      overflow: hidden;
      width: 80px;
      height: 80px;
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 112px;
        height: 112px;
        top: -25px;
        left: -25px;
      }
    }
  }

  //IDRotF - Winter Fur - furry circle
  &--12030052 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      width: 85px;
      height: 85px;
      left: -21px;
      top: -19px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 1px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 112px;
        height: 112px;
        left: -25px;
        top: -28px;
      }
      @include portrait() {
        left: 3px;
        width: 58px;
        height: 58px;
      }
    }
  }

  //IDRotF - Chill - chin icicles
  &--12030025 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      box-shadow: 0px -3px 4px 2px inset #222222bd;
    }
    @include frame() {
      position: absolute;
      top: -34px;
      left: -35px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -25px;
        left: -27px;
      }
    }
  }

  //IDRotF - Tundra - icy sphere
  &--12030140,
    &--12030219,
    // Snow Globe - snow falling around portrait
    &--12030078,
    &--12030198 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      opacity: 0.9;
      z-index: 1;
      height: 41px;
      width: 41px;
      left: 3px;
      top: 2px;
    }
    @include frame() {
      position: absolute;
      top: -34px;
      left: -32px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -27px;
        left: -26px;
      }

      @include portrait() {
        height: 56px;
        width: 56px;
        left: 2px;
        top: 2px;
      }
    }
  }
  // Snow Globe - snow falling around portrait
  &--12030078,
  &--12030198 {
    @include portrait() {
      z-index: 0;
      opacity: 1;
    }
  }
  //IDRotF - Animated Tundra - inherits from static Snow Globe
  &--12030219,
    // Animated Snow Globe - inherits from static Snow Sphere
    &--12030198 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -34px;
      left: -32px;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }
    @include frame-extra1-content() {
      width: 1120px;
      height: 1120px;
      @include animated-frame(
        $animationName: animatedFrameWidth112Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -27px;
        left: -26px;
      }
    }
  }

  //IDRotF - Animated Tundra - inherits from static Tundra
  &--12030219 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/125/snowglobe_10x10.png)
        no-repeat;
    }
  }
  // Animated Snow Globe - inherits from static Snow Globe
  &--12030198 {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/126/snowsphere_10x10.png)
        no-repeat;
    }
  }

  //IDRotF - Aurora - vertical bands of northern lights
  &--12030168,
  &--12030251 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      z-index: 1;
      left: 2.5px;
      top: 2px;
      width: 44.5px;
    }
    @include frame() {
      position: absolute;
      top: -52px;
      left: -32px;
      overflow: hidden;
      width: 112px;
      height: 150px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -45px;
        left: -24px;
      }

      @include portrait() {
        left: 3px;
        top: 1.5px;
        width: 60.5px;
      }
    }
  }
  //IDRotF - Animated Aurora - inherits from static Aurora
  &--12030251 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -51.5px;
      left: -32px;
      overflow: hidden;
      width: 112px;
      height: 150px;
    }
    @include frame-extra1-content() {
      width: 1120px;
      height: 1500px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/134/aurora_new150.png) no-repeat;
      @include animated-frame(
        $animationName: animatedFrameWidth112Height150Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -44px;
        left: -24px;
      }
    }
  }

  //TCoE - Homunculi - orange ring with homunculi surrounding portrait
  &--13825561 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 80px;
      height: 80px;
      left: -22px;
      top: -18px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
      top: 4px;
      left: -3px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 111px;
        height: 111px;
        left: -35px;
        top: -25px;
      }
      @include portrait() {
        top: 6px;
        left: -10px;
      }
    }
  }

  //TCoE - FourByFour - grey square four by four blocks
  &--13825505 {
    @include frame() {
      width: 66px;
      height: 66px;
      top: -10px;
      left: -13px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    @include portrait() {
      z-index: 1;
      border-radius: 3px;
      border: none;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
      left: -7px;
      top: 4px;
      width: 45px;
      height: 45px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 88px;
        height: 88px;
        top: -12px;
        left: -16px;
      }

      @include portrait() {
        width: 61px;
        height: 61px;
        left: -8px;
        top: 7px;
      }
    }
  }

  //TCoE - Dragon Tattoo - red dragon
  &--13825530 {
    @include frame() {
      height: 80px;
      width: 80px;
      top: -16px;
      left: -19px;
    }
    @include portrait() {
      border-radius: 50%;
      //border: 1px solid rgba(92, 1, 1, 0.78);
      border: none;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
      top: 12px;
      left: 3px;
      height: 37px;
      width: 37px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        height: 112px;
        width: 112px;
        top: -23px;
        left: -30px;
      }
      @include portrait() {
        top: 16px;
        left: 2px;
        width: 51px;
        height: 51px;
      }
    }
  }

  //TCoE - Construct - mechanical construct with avatar as head
  &--13825541 {
    @include frame() {
      height: 80px;
      width: 80px;
      top: -11px;
      left: -24px;
    }
    @include portrait() {
      border-radius: 50%;
      border: 1px solid #8b6d85;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
      top: -9px;
      left: 1px;
      height: 37px;
      width: 37px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        height: 112px;
        width: 112px;
        top: -19px;
        left: -31px;
      }
      @include portrait() {
        top: -15px;
        left: 3px;
        width: 50px;
        height: 50px;
      }
    }
  }

  //TCoE - Crystal Ball - glowing crystal ball with ornate holder
  &--13825551 {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      width: 76px;
      height: 76px;
      left: -21px;
      top: -14px;
    }
    @include frame-extra1() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/325/crystalballtop.png)
        no-repeat;
      position: absolute;
      height: 76px;
      width: 76px;
      top: -14px;
      left: -21px;
      background-size: 76px;
      z-index: 3;
    }

    @include portrait() {
      border-radius: 50%;
      border: none;
      z-index: 1;
      top: -3px;
      left: -6px;
      height: 43px;
      width: 43px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 103px;
        height: 103px;
        left: -24px;
        top: -22px;
      }
      @include frame-extra1() {
        height: 105px;
        width: 105px;
        top: -23px;
        left: -25px;
        background-size: 105px;
      }
      @include portrait() {
        top: -9px;
        left: -4px;
        width: 59px;
        height: 59px;
      }
    }
  }

  //TCoE - Cauldron - green bubbling cauldron
  &--13825571,
  &--13825585 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 10px;
      left: -2px;
      height: 49px;
      width: 49px;
    }
    @include frame() {
      position: absolute;
      top: -46px;
      left: -43px;
      overflow: hidden;
      width: 130px;
      height: 130px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -42px;
        left: -35px;
      }
      @include portrait() {
        top: 10px;
        left: -2px;
        height: 66px;
        width: 66px;
      }
    }
  }

  //TCoE - Animated Cauldron - inherits from static Cauldron
  &--13825585 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -46px;
      left: -43px;
      overflow: hidden;
      width: 130px;
      height: 130px;
    }
    @include frame-extra1-content() {
      width: 1300px;
      height: 1300px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/333/cauldronsprite.png)
        no-repeat;
      background-size: 1300px;
      @include animated-frame(
        $animationName: animatedFrameWidth130Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -42px;
        left: -35px;
      }
    }
  }

  //TCoE - Spirit Shroud
  &--13825579,
  &--13825597 {
    @include portrait() {
      border-radius: 50%;
      border: none;
      top: 10px;
      left: -2px;
      height: 49px;
      width: 49px;
    }
    @include frame() {
      position: absolute;
      top: -46px;
      left: -43px;
      overflow: hidden;
      width: 130px;
      height: 130px;
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        top: -42px;
        left: -35px;
      }
      @include portrait() {
        top: -6px;
        left: -2px;
        height: 66px;
        width: 66px;
      }
    }
  }

  //TCoE - Animated Spirit Shroud - inherits from static Spirit Shroud
  &--13825597 {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      top: -46px;
      left: -43px;
      overflow: hidden;
      width: 130px;
      height: 130px;
    }
    @include frame-extra1-content() {
      width: 1300px;
      height: 1300px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/331/spiritshroudsprite.png)
        no-repeat;
      background-size: 1300px;
      @include animated-frame(
        $animationName: animatedFrameWidth130Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        top: -42px;
        left: -35px;
      }
    }
  }

  //TCoE - Classes - blue and grey with class icon in upper right corner
  &--13825630, //Artificer
    &--13825646, //Barbarian
    &--13825654, //Bard
    &--13825664, //Cleric
    &--13825675, //Druid
    &--13825688, //Fighter
    &--13825694, //Monk
    &--13825702, //Paladin
    &--13825711, //Ranger
    &--13825719, //Rogue
    &--13825736, //Sorcerer
    &--13825740, //Warlock
    &--13825747 //Wizard
  {
    @include frame() {
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;
      width: 74px;
      height: 74px;
      left: -19px;
      top: -14px;
    }
    @include portrait() {
      border-radius: 50%;
      border: none;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
      top: 4px;
      left: -5px;
      height: 45px;
      width: 45px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 100px;
        height: 100px;
        left: -28px;
        top: -19px;
      }
      @include portrait() {
        top: 6px;
        left: -8px;
        height: 60px;
        width: 60px;
      }
    }
  }

  // Candlekeep Mysteries - CM

  // cm-corrupted (Corrupted)
  &--16425801,
  &--16425913,
  &--cm-corrupted,
  &--cm-animated-corrupted {
    @include frame() {
      width: 72px;
      height: 72px;
      top: -15px;
      left: -14px;
    }

    @include portrait() {
      border: none;
      border-radius: 0px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 100px;
        height: 100px;
        top: -22px;
        left: -20px;
      }
    }
  }

  // cm-animated-corrupted (Animated Corrupted)
  &--16425913,
  &--cm-animated-corrupted {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 100px;
      height: 100px;
      top: -22px;
      left: -20px;
    }
    @include frame-extra1-content() {
      width: 1000px;
      height: 1000px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/476/ck_squareframe1_mot_sprtsht_d.png)
        no-repeat;
      background-size: 1000px;
      @include animated-frame(
        $animationName: animatedFrameWidth100Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.74);
        top: -30px;
        left: -28px;
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        width: 100px;
        height: 100px;
        top: -22px;
        left: -20px;
      }
    }
  }

  // cm-orcus-statue (Orcus Statue)
  &--16425674,
  &--cm-orcus-statue {
    @include frame() {
      width: 80px;
      height: 80px;
      top: -18px;
      left: -17px;
    }

    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 31px;
      height: 31px;
      top: 18px;
      left: 9px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 118px;
        height: 118px;
        top: -28px;
        left: -32px;
      }

      @include portrait() {
        width: 46px;
        height: 46px;
        top: 25px;
        left: 5px;
      }
    }
  }

  // cm-book-swarm (Book Swarm)
  &--16425860,
  &--16425964,
  &--cm-book-swarm,
  &--cm-animated-book-swarm {
    @include frame() {
      width: 95px;
      height: 95px;
      top: -25px;
      left: -25px;
    }

    @include portrait() {
      border-radius: 50%;
      border: none;
      left: 5px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 128px;
        height: 128px;
        top: -34px;
        left: -35px;
      }
    }
  }

  // cm-animated-book-swarm (Animated Book Swarm)
  &--16425964,
  &--cm-animated-book-swarm {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 128px;
      height: 128px;
      top: -34px;
      left: -35px;
    }
    @include frame-extra1-content() {
      width: 1280px;
      height: 1280px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/477/ck_bookstack_mot_spritesheet_a.png)
        no-repeat;
      background-size: 1280px;
      @include animated-frame(
        $animationName: animatedFrameWidth128Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.75);
        top: -43px;
        left: -41px;
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        width: 128px;
        height: 128px;
        top: -34px;
        left: -35px;
      }
    }
  }

  // cm-extra-dimensional-spaces (Extra Dimensional Spaces)
  &--16425830,
  &--16425942,
  &--cm-extra-dimensional-spaces {
    @include frame() {
      width: 90px;
      height: 90px;
      top: -20px;
      left: -20px;
    }

    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 41px;
      height: 41px;
      top: -2px;
      left: 5px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 130px;
        height: 130px;
        top: -35px;
        left: -35px;
      }

      @include portrait() {
        top: -10px;
        left: 0px;
        width: 60px;
        height: 60px;
      }
    }
  }

  // cm-animated-extra-dimensional-spaces (Animated Extra Dimensional Spaces)
  &--16425942,
  &--cm-animated-extra-dimensional-spaces {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 130px;
      height: 130px;
      top: -35px;
      left: -35px;
    }
    @include frame-extra1-content() {
      width: 1300px;
      height: 1300px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/481/ck_book1_mot_spritesheet_a.png)
        no-repeat;
      background-size: 1300px;
      @include animated-frame(
        $animationName: animatedFrameWidth130Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.6);
        top: -45px;
        left: -45px;
      }

      @include portrait() {
        width: 30px;
        height: 30px;
        left: 7px;
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        width: 130px;
        height: 130px;
        top: -35px;
        left: -35px;
      }

      @include portrait() {
        width: 58px;
        height: 58px;
        left: 2px;
      }
    }
  }

  // cm-price-of-beauty (Price of Beauty)
  &--16425731,
  &--cm-price-of-beauty {
    @include frame() {
      height: 80px;
      width: 80px;
      top: -16px;
      left: -17px;
    }

    @include portrait() {
      border-radius: 50%;
      border: none;
      width: 38px;
      height: 38px;
      top: -2px;
      left: 4px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 120px;
        height: 120px;
        left: -35px;
        top: -28px;
      }

      @include portrait() {
        top: -4px;
        width: 54px;
        height: 54px;
        left: -2px;
      }
    }
  }

  // cm-bedtime-rhyme (Bedtime Rhyme)
  &--16425752,
  &--cm-bedtime-rhyme {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      left: 10px;
    }

    @include frame() {
      width: 70px;
      height: 70px;
      top: -15px;
      left: -10px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 135px;
        height: 135px;
        top: -35px;
        left: -45px;
      }

      @include portrait() {
        width: 45px;
        height: 45px;
        left: -4px;
        top: -4px;
      }
    }
  }

  // cm-nether (Nether)
  &--16425773,
  &--cm-nether {
    @include portrait() {
      border: none;
    }

    @include frame() {
      top: -11px;
      left: -11px;
      width: 66px;
      height: 66px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame() {
        width: 90px;
        height: 90px;
        top: -15px;
        left: -15px;
      }
    }
  }

  // cm-lattice (Lattice)
  &--16425883,
  &--16425981,
  &--cm-lattice,
  &--cm-animated-lattice {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: 8px;
      left: 4px;
    }

    @include frame() {
      width: 85px;
      height: 85px;
      top: -23px;
      left: -19px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: 13px;
        left: -5px;
        width: 58px;
        height: 58px;
      }

      @include frame() {
        width: 130px;
        height: 130px;
        top: -35px;
        left: -41px;
      }
    }
  }

  // cm-animated-lattice (Animated Lattice)
  &--16425981,
  &--cm-animated-gold-lattice {
    @include frame() {
      display: none;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 130px;
      height: 130px;
      top: -35px;
      left: -41px;
    }
    @include frame-extra1-content() {
      width: 1300px;
      height: 1300px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/478/ck_decorativeframe_mot_spritesheet_a.png)
        no-repeat;
      background-size: 1300px;
      @include animated-frame(
        $animationName: animatedFrameWidth130Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include frame-extra1() {
        transform: scale(0.7);
        top: -46px;
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        width: 130px;
        height: 130px;
        top: -35px;
        left: -41px;
      }
    }
  }

  // Van Richten's Guide to Ravenloft

  // vrgtr-you-in-a-jar
  // vrgtr-animated-you-in-a-jar
  &--17683665,
  &--17683719,
  &--vrgtr-you-in-a-jar,
  &--vrgtr-animated-you-in-a-jar {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      top: 3px;
      left: 8px;
    }

    @include frame() {
      width: 85px;
      height: 85px;
      top: -23px;
      left: -19px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: 10px;
        left: 0px;
        width: 50px;
        height: 50px;
      }

      @include frame() {
        width: 130px;
        height: 130px;
        top: -30px;
        left: -40px;
      }
    }
  }

  // vrgtr-animated-you-in-a-jar
  &--17683719,
  &--vrgtr-animated-you-in-a-jar {
    @include frame() {
      display: none;
    }
    @include portrait() {
      top: 0px;
      left: -7px;
      width: 60px;
      height: 60px;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 130px;
      height: 130px;
      top: -35px;
      left: -41px;
    }
    @include frame-extra1-content() {
      width: 1300px;
      height: 1300px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/608/ravlof_motframe_jarv1sheet.png)
        no-repeat;
      background-size: 1300px;
      @include animated-frame(
        $animationName: animatedFrameWidth130Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include portrait() {
        top: -2px;
        left: 3px;
        width: 45px;
        height: 45px;
      }
      @include frame-extra1() {
        transform: scale(0.7);
        top: -46px;
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        width: 130px;
        height: 130px;
        top: -35px;
        left: -41px;
      }
    }
  }

  // vrgtr-bite
  &--17683680,
  &--vrgtr-bite,
  &--17683732,
  &--vrgtr-animated-bite {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: 0px;
      left: 4px;
    }

    @include frame() {
      width: 85px;
      height: 85px;
      top: -23px;
      left: -19px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: 7px;
        left: -7px;
        width: 60px;
        height: 60px;
      }

      @include frame() {
        width: 130px;
        height: 130px;
        top: -28px;
        left: -42px;
      }
    }
  }

  // vrgtr-animated-bite
  &--17683732,
  &--vrgtr-animated-bite {
    @include frame() {
      display: none;
    }
    @include portrait() {
      top: 0px;
      left: -7px;
      width: 60px;
      height: 60px;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 130px;
      height: 130px;
      top: -35px;
      left: -41px;
    }
    @include frame-extra1-content() {
      width: 1300px;
      height: 1300px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/609/ravlof_motframe_teethv1sheet.png)
        no-repeat;
      background-size: 1300px;
      @include animated-frame(
        $animationName: animatedFrameWidth130Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include portrait() {
        top: -2px;
        left: 3px;
        width: 45px;
        height: 45px;
      }
      @include frame-extra1() {
        transform: scale(0.7);
        top: -46px;
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        width: 130px;
        height: 130px;
        top: -35px;
        left: -41px;
      }
    }
  }

  // vrgtr-eclipse
  &--17683693,
  &--vrgtr-eclipse,
  &--17683741,
  &--vrgtr-animated-eclipse {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: 0px;
      left: 4px;
    }

    @include frame() {
      width: 85px;
      height: 85px;
      top: -23px;
      left: -19px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: 7px;
        left: -7px;
        width: 60px;
        height: 60px;
      }

      @include frame() {
        width: 130px;
        height: 130px;
        top: -28px;
        left: -42px;
      }
    }
  }

  // vrgtr-animated-eclipse
  &--17683741,
  &--vrgtr-animated-eclipse {
    @include frame() {
      display: none;
    }
    @include portrait() {
      top: 0px;
      left: -7px;
      width: 60px;
      height: 60px;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 130px;
      height: 130px;
      top: -35px;
      left: -41px;
    }
    @include frame-extra1-content() {
      width: 1300px;
      height: 1300px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/606/ravlof_motframe_moonv1sheet.png)
        no-repeat;
      background-size: 1300px;
      @include animated-frame(
        $animationName: animatedFrameWidth130Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include portrait() {
        top: -2px;
        left: 1px;
        width: 45px;
        height: 45px;
      }
      @include frame-extra1() {
        transform: scale(0.7);
        top: -46px;
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        width: 130px;
        height: 130px;
        top: -35px;
        left: -41px;
      }
    }
  }

  // vrgtr-vampiric-mist
  &--17683705,
  &--vrgtr-vampiric-mist,
  &--17683758,
  &--vrgtr-animated-vampiric-mist {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: 0px;
      left: 4px;
    }

    @include frame() {
      width: 85px;
      height: 85px;
      top: -23px;
      left: -19px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: 7px;
        left: -7px;
        width: 60px;
        height: 60px;
      }

      @include frame() {
        width: 130px;
        height: 130px;
        top: -28px;
        left: -42px;
      }
    }
  }

  // vrgtr-animated-vampiric-mist
  &--17683758,
  &--vrgtr-animated-vampiric-mist {
    @include frame() {
      display: none;
    }
    @include portrait() {
      top: 0px;
      left: -7px;
      width: 60px;
      height: 60px;
    }
    @include frame-extra1() {
      position: absolute;
      overflow: hidden;
      width: 130px;
      height: 130px;
      top: -35px;
      left: -41px;
    }
    @include frame-extra1-content() {
      width: 1300px;
      height: 1300px;
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/607/ravlof_motframe_mistv1sheet.png)
        no-repeat;
      background-size: 1300px;
      @include animated-frame(
        $animationName: animatedFrameWidth130Frame10x10,
        $animationDuration: 3.33s
      );
    }

    @media (max-width: $bp-sheet-tablet-transition - 1) {
      @include portrait() {
        top: -2px;
        left: 1px;
        width: 45px;
        height: 45px;
      }
      @include frame-extra1() {
        transform: scale(0.7);
        top: -46px;
      }
    }
    @media (min-width: $bp-sheet-tablet-transition) {
      @include frame-extra1() {
        width: 130px;
        height: 130px;
        top: -35px;
        left: -41px;
      }
    }
  }

  // vrgtr-ravenkind
  &--17683614,
  &--vrgtr-ravenkind {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: 0px;
      left: 4px;
    }

    @include frame() {
      width: 80px;
      height: 80px;
      top: -20px;
      left: -17px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: 4px;
        left: -3px;
        width: 55px;
        height: 55px;
      }

      @include frame() {
        width: 120px;
        height: 120px;
        top: -28px;
        left: -36px;
      }
    }
  }

  // vrgtr-horror
  &--17683627,
  &--vrgtr-horror {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: 0px;
      left: 4px;
    }

    @include frame() {
      width: 80px;
      height: 80px;
      top: -20px;
      left: -17px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: 4px;
        left: -3px;
        width: 55px;
        height: 55px;
      }

      @include frame() {
        width: 120px;
        height: 120px;
        top: -28px;
        left: -36px;
      }
    }
  }

  // vrgtr-spirit-board
  &--17683637,
  &--vrgtr-spirit-board {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: 0px;
      left: 9px;
    }

    @include frame() {
      width: 110px;
      height: 110px;
      top: -28px;
      left: -24px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: 4px;
        left: 10px;
        width: 55px;
        height: 55px;
      }

      @include frame() {
        width: 160px;
        height: 160px;
        top: -38px;
        left: -42px;
      }
    }
  }

  // vrgtr-ankhtepot
  &--17683647,
  &--vrgtr-ankhtepot {
    @include portrait() {
      border: none;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      top: 2px;
      left: 6px;
    }

    @include frame() {
      width: 85px;
      height: 85px;
      top: -23px;
      left: -19px;
    }

    @media (min-width: $bp-sheet-tablet-transition) {
      @include portrait() {
        top: -1px;
        left: -5px;
        width: 55px;
        height: 55px;
      }

      @include frame() {
        width: 130px;
        height: 130px;
        top: -39px;
        left: -42px;
      }
    }
  }

  /////subs-202108

  // subs-202108-flamestriker
  &--19686235, // prod
    &--subs-202108-flamestriker,
    // subs-202108-dark-eldritch
    &--19686280, // prod
    &--subs-202108-dark-eldritch,
    // subs-202108-radiant-corona
    &--19686341, // prod
    &--subs-202108-radiant-corona,
    // subs-202108-heartseeker
    &--19686400, // prod
    &--subs-202108-heartseeker,
    
    // subs-202108-animated-flamestriker
    &--19686254, // prod
    &--subs-202108-animated-flamestriker,
    // subs-202108-animated-animated-dark-eldritch
    &--19686304, // prod
    &--subs-202108-animated-dark-eldritch,
    // subs-202108-animated-radiant-corona
    &--19686360, // prod
    &--subs-202108-animated-radiant-corona,
    // subs-202108-animated-heartseeker
    &--19686431, // prod
    &--subs-202108-animated-heartseeker {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
    }
    .ddbc-character-avatar__frame {
      top: -22px;
      left: -22px;
      width: 90px;
      height: 90px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -35px;
        left: -35px;
        width: 130px;
        height: 130px;
      }
    }
  }
  // subs-202108-animated-flamestriker
  &--19686254, // prod
    &--subs-202108-animated-flamestriker {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/849/flamestriker_sprite.png)
        no-repeat;
    }
  }
  // subs-202108-animated-dark-eldritch
  &--19686304, // prod
    &--subs-202108-animated-dark-eldritch {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/852/dark-eldritch_sprite.png)
        no-repeat;
    }
  }
  // subs-202108-animated-radiant-corona
  &--19686360, // prod
    &--subs-202108-animated-radiant-corona {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/851/radiant-corona_sprite.png)
        no-repeat;
    }
  }
  // subs-202108-animated-heartseeker
  &--19686431, // prod
    &--subs-202108-animated-heartseeker {
    @include frame-extra1-content() {
      background: 0 0 transparent
        url(#{$ct--ddb-image-url}/attachments/8/850/heartseeker_sprite.png)
        no-repeat;
    }
  }
  // subs-202108-animated-flamestriker
  &--19686254, // prod
    &--subs-202108-animated-flamestriker,
    // subs-202108-animated-dark-eldritch
    &--19686304, // prod
    &--subs-202108-animated-dark-eldritch,
    // subs-202108-animated-radiant-corona
    &--19686360, // prod
    &--subs-202108-animated-radiant-corona,
    // subs-202108-animated-heartseeker
    &--19686431, // prod
    &--subs-202108-animated-heartseeker {
    .ddbc-character-avatar__frame {
      display: none;
    }
    .ddbc-character-avatar__frame-extra1 {
      position: absolute;
      overflow: hidden;
      top: -43px;
      left: -43px;
      width: 130px;
      height: 130px;
      transform: scale(0.75);
    }
    .ddbc-character-avatar__frame-extra1-content {
      width: 1300px;
      height: 1300px;

      animation: animatedFrameWidth130Frame10x10 3.33s infinite;
      animation-timing-function: steps(1);
      background-size: 1300px 1300px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame-extra1 {
        top: -35px;
        left: -35px;
        transform: scale(1);
      }
    }
  }
  // heart needed these....
  // subs-202108-animated-heartseeker
  &--19686431, // prod
    &--subs-202108-animated-heartseeker {
    .ddbc-character-avatar__frame {
      top: -23px;
      left: -23px;
    }

    .ddbc-character-avatar__frame-extra1 {
      top: -45px;
      left: -43px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame-extra1 {
        top: -38px;
        left: -35px;
      }
    }
  }

  //// Witchlight TWBtW
  &--20478744,/*prod id*/
    &--twbtw-bunny-ears,
    &--20478843,/*prod id*/
    &--twbtw-elephant-circus,
    &--20478903,/*prod id*/
    &--twbtw-feywild-portal,
    &--20478940,/*prod id*/
    &--twbtw-light-circle {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
      top: -2px;
      left: -2px;
      width: 50px;
      height: 50px;
    }
    .ddbc-character-avatar__frame {
      top: -34px;
      left: -33px;
      width: 110px;
      height: 110px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__portrait {
        top: 0px;
        left: 0px;
        width: 60px;
        height: 60px;
      }
      .ddbc-character-avatar__frame {
        top: -38px;
        left: -36px;
        width: 132px;
        height: 132px;
      }
    }
  }
  &--20478940,/*prod id*/
    &--twbtw-light-circle {
    .ddbc-character-avatar__portrait {
      width: 52px;
      height: 52px;
    }
    .ddbc-character-avatar__frame {
      top: -33px;
      left: -31px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__portrait {
        width: 62px;
        height: 62px;
      }
      .ddbc-character-avatar__frame {
        top: -36px;
        left: -35px;
      }
    }
  }

  &--20478794,/*prod id*/
    &--twbtw-butterfly-wings,
    &--20478962,/*prod id*/
    &--twbtw-animated-butterfly-wings,
    &--20478818,/*prod id*/
    &--twbtw-carnival-ticket {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
      top: 0px;
      left: 0px;
    }
    .ddbc-character-avatar__frame {
      top: -38px;
      left: -38px;
      width: 120px;
      height: 120px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -52px;
        left: -52px;
        width: 163px;
        height: 163px;
      }
    }
  }

  &--20478818,/*prod id*/
    &--twbtw-carnival-ticket {
    .ddbc-character-avatar__frame {
      left: -35px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        left: -48px;
      }
    }
  }

  &--20478883,/*prod id*/
    &--twbtw-fairy-lights,
    &--20478988,/*prod id*/
    &--twbtw-animated-fairy-lights {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
      top: 0px;
      left: 0px;
    }
    .ddbc-character-avatar__frame {
      top: -17px;
      left: -26px;
      width: 90px;
      height: 90px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__portrait {
        border-radius: 50%;
        top: 0px;
        left: 0px;
      }
      .ddbc-character-avatar__frame {
        top: -27px;
        left: -37px;
        width: 130px;
        height: 130px;
      }
    }
  }

  &--20478924,/*prod id*/
    &--twbtw-giant-snail {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
      top: -2px;
      left: -2px;
      width: 48px;
      height: 48px;
    }
    .ddbc-character-avatar__frame {
      top: -46px;
      left: -38px;
      width: 122px;
      height: 122px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__portrait {
        border-radius: 50%;
        top: 1px;
        left: 1px;
        width: 57px;
        height: 57px;
      }
      .ddbc-character-avatar__frame {
        top: -60px;
        left: -49px;
        width: 163px;
        height: 163px;
      }
    }
  }

  .ddbc-character-tidbits__avatar &--20478794,/*prod id*/
    .ddbc-character-tidbits__avatar &--twbtw-butterfly-wings,
    .ddbc-character-tidbits__avatar &--20478962,/*prod id*/
    .ddbc-character-tidbits__avatar &--twbtw-animated-butterfly-wings,
    .ddbc-character-tidbits__avatar &--20478818,/*prod id*/
    .ddbc-character-tidbits__avatar &--twbtw-carnival-ticket,
    .ddbc-character-tidbits__avatar &--20478843,/*prod id*/
    .ddbc-character-tidbits__avatar &--twbtw-elephant-circus,
    .ddbc-character-tidbits__avatar &--20478924,/*prod id*/
    .ddbc-character-tidbits__avatar &--twbtw-giant-snail {
    margin-right: 50px;
    left: 15px;

    @media screen and (min-width: 768px) {
      margin-right: 60px;
    }
  }
  &--20478962,/*prod id*/
    &--twbtw-animate-butterfly-wings {
    .ddbc-character-avatar__frame {
      display: none;
    }
    .ddbc-character-avatar__frame-extra1 {
      position: absolute;
      overflow: hidden;
      top: -59px;
      left: -59px;
      width: 163px;
      height: 163px;
      transform: scale(0.57);
    }
    .ddbc-character-avatar__frame-extra1-content {
      width: 1630px;
      height: 1630px;

      animation: animatedFrameWidth163Frame10x10 3.33s infinite;
      animation-timing-function: steps(1);
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/8/943/wbtw_butterflywings.png)
        no-repeat;
      background-size: 1630px 1630px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame-extra1 {
        top: -52px;
        left: -52px;
        transform: scale(0.78);
      }
    }
  }
  &--20478988,/*prod id*/
    &--twbtw-animated-fairy-lights {
    .ddbc-character-avatar__frame {
      display: none;
    }
    .ddbc-character-avatar__frame-extra1 {
      position: absolute;
      overflow: hidden;
      top: -43px;
      left: -43px;
      width: 130px;
      height: 130px;
      transform: scale(0.7);
    }
    .ddbc-character-avatar__frame-extra1-content {
      width: 1300px;
      height: 1300px;

      animation: animatedFrameWidth130Frame10x10 3.33s infinite;
      animation-timing-function: steps(1);
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/8/944/wbtw_fairylights.png)
        no-repeat;
      background-size: 1300px 1300px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame-extra1 {
        top: -35px;
        left: -35px;
        transform: scale(0.95);
      }
    }
  }

  /* ELEPHANT ANIMATED ONE TODO:when it gets updated
        .some-crazy-selector-list-animated {

            .ddbc-character-avatar__frame {
                display: none;
            }
            .ddbc-character-avatar__frame-extra1 {
                position: absolute;
                overflow: hidden;
                top: -45px;
                left: -45px;
                width: 132px;
                height: 132px;
                transform: scale(0.75);
            }
            .ddbc-character-avatar__frame-extra1-content {
                width: 1320px;
                height: 1320px;
                
                animation: animatedFrameWidth132Frame10x10 3.33s infinite;
                animation-timing-function: steps(1);
                background: 0 0 transparent
                    url(blob:https://61392b8be8cc1100cb661bfe--framie.netlify.app/53678343-18d6-4a72-8469-3afac1e2113a)
                    no-repeat;
                background-size: 1320px 1320px;
            }
                    

            @media screen and (min-width: 768px ) {
                .ddbc-character-avatar__frame-extra1 {
                    top: -36px;
                    left: -36px;
                    transform: scale(1);
                }
            }
                    
        }
    */

  // October sub frames static only
  &--subs-202110-slime-cube,
  &--20520510,
  &--subs-202110-rib-cage,
  &--20520494 {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
    }
    .ddbc-character-avatar__frame {
      top: -42px;
      left: -38px;
      width: 122px;
      height: 122px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -58px;
        left: -52px;
        width: 166px;
        height: 166px;
      }
    }
  }
  &--subs-202110-rib-cage,
  &--20520494 {
    .ddbc-character-avatar__frame {
      top: -35px;
      left: -39px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -47px;
        left: -52px;
      }
    }
  }
  &--subs-202110-hag-hair,
  &--20520478,
  &--subs-202110-candy,
  &--20520465 {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
    }
    .ddbc-character-avatar__frame {
      top: -20px;
      left: -23px;
      width: 90px;
      height: 90px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -26px;
        left: -30px;
        width: 122px;
        height: 122px;
      }
    }
  }

  &--subs-202110-candy,
  &--20520465 {
    .ddbc-character-avatar__frame {
      top: -23px;
      left: -23px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -31px;
        left: -31px;
      }
    }
  }

  // FISBAN'S (FToD)
  &--ftod-canary,
  &--20932644,
  &--ftod-gem-horns,
  &--20932919 {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
    }
    .ddbc-character-avatar__frame {
      top: -40px;
      left: -40px;
      width: 124px;
      height: 124px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -54px;
        left: -54px;
        width: 168px;
        height: 168px;
      }
    }
  }

  &--ftod-dragon-egg,
  &--20932754,
  &--ftod-dragon-tail,
  &--20932780,
  &--ftod-fire-breath,
  &--20932818,
  &--ftod-animated-fire-breath,
  &--20933060,
  &--ftod-ice-breath,
  &--20932959,
  &--ftod-animated-ice-breath,
  &--20933106,
  &--ftod-lightning-breath,
  &--20933024,
  &--ftod-animated-lightning-breath,
  &--20933145,
  &--ftod-ornate-frame,
  &--20933048 {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
    }
    .ddbc-character-avatar__frame {
      top: -23px;
      left: -23px;
      width: 90px;
      height: 90px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -33px;
        left: -33px;
        width: 126px;
        height: 126px;
      }
    }
  }

  &--ftod-animated-fire-breath,
  &--20933060 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/8/989/ftod_fire_frame.png)
        no-repeat;
    }
  }
  &--ftod-animated-ice-breath,
  &--20933106 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/8/990/ftod_ice_frame.png)
        no-repeat;
    }
  }
  &--ftod-animated-lightning-breath,
  &--20933145 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/8/988/ftod_electric_frame.png)
        no-repeat;
    }
  }

  &--ftod-animated-fire-breath,
  &--20933060,
  &--ftod-animated-ice-breath,
  &--20933106,
  &--ftod-animated-lightning-breath,
  &--20933145 {
    .ddbc-character-avatar__frame {
      display: none;
    }
    .ddbc-character-avatar__frame-extra1 {
      position: absolute;
      overflow: hidden;
      top: -41px;
      left: -41px;
      width: 126px;
      height: 126px;
      transform: scale(0.75);
    }
    .ddbc-character-avatar__frame-extra1-content {
      width: 1260px;
      height: 1260px;

      animation: animatedFrameWidth126Frame10x10 3.33s infinite;
      animation-timing-function: steps(1);
      background-size: 1260px 1260px;
    }
    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame-extra1 {
        top: -32px;
        left: -32px;
        transform: scale(1);
      }
    }
  }
  ////

  // SACoC start
  // SACoC Central Campus Stones
  &--sacoc_central_campus_stones,
    &--22066043,
    // SACoC Animated Central Campus Stones
    &--sacoc_animated_central_campus_stones,
    &--22066150,
    // SACoC Elemental Transport Stones
    &--sacoc_elemental_transport_stones,
    &--22066062,
    // SACoC Animated Elemental Transport Stones
    &--sacoc_animated_elemental_transport_stones,
    &--22066171,
    // SACoC School Dance
    &--sacoc_school_dance,
    &--22066107,
    // SACoC Animated School Dance
    &--sacoc_animated_school_dance,
    &--22066185,
    // SACoC Spell Book
    &--sacoc_spell_book,
    &--22066132 {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
    }
    .ddbc-character-avatar__frame {
      top: -24px;
      left: -24px;
      width: 92px;
      height: 92px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -31px;
        left: -31px;
        width: 122px;
        height: 122px;
      }
    }
  }

  // SACoC Runestone
  &--sacoc_runestone,
    &--22157265,
    // SACoC Guide
    &--sacoc_guide,
    &--22066075,
    // SACoC Owl
    &--sacoc_owl,
    &--22066084,
    // SACoC Scroll
    &--sacoc_scroll,
    &--22066116 {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
    }
    .ddbc-character-avatar__frame {
      top: -40px;
      left: -40px;
      width: 124px;
      height: 124px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -54px;
        left: -54px;
        width: 168px;
        height: 168px;
      }
    }
  }

  // SACoC Guide
  &--sacoc_guide,
  &--22066075 {
    .ddbc-character-avatar__frame {
      top: -44px;
    }
    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -59px;
      }
    }
  }

  // SACoC Scroll
  &--sacoc_scroll,
  &--22066116 {
    .ddbc-character-avatar__frame {
      top: -35px;
      left: -47px;
    }
    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -47px;
        left: -64px;
      }
    }
  }

  .ddbc-character-tidbits__avatar &--sacoc_runestone,
  .ddbc-character-tidbits__avatar &--22157265,
  .ddbc-character-tidbits__avatar &--sacoc_guide,
  .ddbc-character-tidbits__avatar &--22066075,
  .ddbc-character-tidbits__avatar &--sacoc_owl,
  .ddbc-character-tidbits__avatar &--22066084,
  .ddbc-character-tidbits__avatar &--sacoc_scroll,
  .ddbc-character-tidbits__avatar &--22066116 {
    margin-right: 70px;
    left: 30px;

    @media screen and (min-width: 768px) {
      margin-right: 70px;
    }
  }

  // SACoC Animated Central Campus Stones
  &--sacoc_animated_central_campus_stones,
  &--22066150 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/9/232/sacoc_animated_central_campus_stones.png)
        no-repeat;
    }
  }
  // SACoC Animated Elemental Transport Stones
  &--sacoc_animated_elemental_transport_stones,
  &--22066171 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/9/233/sacoc_animated_elemental_transport_stones.png)
        no-repeat;
    }
  }
  // SACoC Animated School Dance
  &--sacoc_animated_school_dance,
  &--22066185 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/9/234/sacoc_animated_school_dance.png)
        no-repeat;
    }
  }

  // SACoC start
  // SACoC Animated Central Campus Stones
  &--sacoc_animated_central_campus_stones,
    &--22066150,
    // SACoC Animated Elemental Transport Stones
    &--sacoc_animated_elemental_transport_stones,
    &--22066171,
    // SACoC Animated School Dance
    &--sacoc_animated_school_dance,
    &--22066185 {
    .ddbc-character-avatar__frame {
      display: none;
    }
    .ddbc-character-avatar__frame-extra1 {
      position: absolute;
      overflow: hidden;
      top: -39px;
      left: -39px;
      width: 122px;
      height: 122px;
      transform: scale(0.75);
    }
    .ddbc-character-avatar__frame-extra1-content {
      width: 1220px;
      height: 1220px;

      animation: animatedFrameWidth122Frame10x10 3.33s infinite;
      animation-timing-function: steps(1);
      background-size: 1220px 1220px;
    }
    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame-extra1 {
        top: -31px;
        left: -31px;
        transform: scale(1);
      }
    }
  }

  // SACoC end

  //subs-202110 start
  &--subs-202203-ioun-stones,
  &--24156892,
  &--subs-202203-luck-feat,
  &--24156912,
  &--subs-202203-powder-bursts,
  &--24157159,
  &--subs-202203-spring-festival,
  &--24172636 {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
      top: 0px;
      left: 0px;
    }
    .ddbc-character-avatar__frame {
      top: -23px;
      left: -23px;
      width: 90px;
      height: 90px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -32px;
        left: -32px;
        width: 123px;
        height: 123px;
      }
    }
  }

  //subs-202110 end

  //CotN start

  &--cotn-crimson-veins,
  &--24157775,
  &--cotn-animated-crimson-veins,
  &--24158674,
  &--cotn-crystal,
  &--24157909,
  &--cotn-fish,
  &--24157930,
  &--cotn-animated-fish,
  &--24158704,
  &--cotn-golden-sun,
  &--24158088,
  &--cotn-animated-golden-sun,
  &--24158795,
  &--cotn-red-hood,
  &--24158294,
  &--cotn-teeth,
  &--24158410,
  &--cotn-tentacles,
  &--24158553,
  &--cotn-bloodfin,
  &--24338124 {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
      border: none;
      top: 0px;
      left: 0px;
    }
    .ddbc-character-avatar__frame {
      top: -23px;
      left: -23px;
      width: 90px;
      height: 90px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -32px;
        left: -32px;
        width: 123px;
        height: 123px;
      }
    }
  }
  &--cotn-crystal,
  &--24157909 {
    .ddbc-character-avatar__portrait {
      border-radius: 0;
    }
  }

  &--cotn-animated-fish,
  &--24158704 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/9/599/cotn-animated-fish.png)
        no-repeat;
    }
  }
  &--cotn-animated-crimson-veins,
  &--24158674 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/9/598/cotn-animated-corrupted-veins.png)
        no-repeat;
    }
  }
  &--cotn-animated-golden-sun,
  &--24158795 {
    .ddbc-character-avatar__frame-extra1-content {
      background: 0 0 transparent
        url(https://www.dndbeyond.com/attachments/9/600/cotn-animated-golden-sun.png)
        no-repeat;
    }
  }

  &--cotn-animated-fish,
  &--24158704,
  &--cotn-animated-crimson-veins,
  &--24158674,
  &--cotn-animated-golden-sun,
  &--24158795 {
    .ddbc-character-avatar__frame {
      display: none;
    }
    .ddbc-character-avatar__frame-extra1 {
      position: absolute;
      overflow: hidden;
      top: -39px;
      left: -39px;
      width: 123px;
      height: 123px;
      transform: scale(0.75);
    }
    .ddbc-character-avatar__frame-extra1-content {
      width: 1230px;
      height: 1230px;

      animation: animatedFrameWidth123Frame10x10 3.33s infinite;
      animation-timing-function: steps(1);
      background-size: 1230px 1230px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame-extra1 {
        top: -31px;
        left: -31px;
        transform: scale(1);
      }
    }
  }

  //CotN end

  &--circle,
  &--square {
    .ddbc-character-avatar__portrait {
      border: none;
      top: 0px;
      left: 0px;
    }
    .ddbc-character-avatar__frame {
      top: -24px;
      left: -24px;
      width: 92px;
      height: 92px;
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame {
        top: -32px;
        left: -32px;
        width: 124px;
        height: 124px;
      }
    }
  }
  &--circle {
    .ddbc-character-avatar__portrait {
      border-radius: 50%;
    }
  }

  &--animated {
    .ddbc-character-avatar__frame {
      display: none;
    }
    .ddbc-character-avatar__frame-extra1 {
      position: absolute;
      overflow: hidden;
      top: -40px;
      left: -40px;
      width: 124px;
      height: 124px;
      transform: scale(0.75);
    }
    .ddbc-character-avatar__frame-extra1-content {
      width: 1240px;
      height: 1240px;

      animation: animatedFrameWidth124Frame10x10 3.33s infinite;
      animation-timing-function: steps(1);
    }

    @media screen and (min-width: 768px) {
      .ddbc-character-avatar__frame-extra1 {
        top: -32px;
        left: -32px;
        transform: scale(1);
      }
    }
  }
  //jttrc end

  //DEFAULT
  @media (min-width: $bp-sheet-tablet-transition) {
    width: 60px;
    height: 60px;

    &__frame {
      width: 60px;
      height: 60px;
    }
  }
}

@keyframes eyeballBlink {
  0% {
    background-position: 100% 0;
  }
  8% {
    background-position: 75% 0;
  }
  9% {
    background-position: 0% 0;
  }
  10% {
    background-position: 25% 0;
  }
  11% {
    background-position: 75% 0;
  }
  12% {
    background-position: 100% 0;
  }
  69% {
    background-position: 50% 0;
  }
  70% {
    background-position: 25% 0;
  }
  84% {
    background-position: 25% 0;
  }
  85% {
    background-position: 75% 0;
  }
  86% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes eyeballBlink2 {
  0% {
    background-position: 100% 0;
  }
  38% {
    background-position: 75% 0;
  }
  39% {
    background-position: 0% 0;
  }
  40% {
    background-position: 25% 0;
  }
  41% {
    background-position: 75% 0;
  }
  42% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes fishSwim {
  0% {
    transform: translate3d(0, 0, 0) scale(1) rotateY(180deg);
  }
  10% {
    transform: translate3d(20%, 8%, 0) scale(1.05) rotateY(180deg);
  }
  20% {
    transform: translate3d(50%, 10%, 0) scale(1.1) rotateY(180deg);
  }
  38% {
    transform: translate3d(90%, -1%, 0) scale(1.05) rotateY(180deg);
  }
  51% {
    transform: translate3d(108%, -14%, 0) scale(1) rotateY(180deg);
  }
  53% {
    transform: translate3d(110%, -16%, 0) scale(1) rotateY(0deg);
  }
  65% {
    transform: translate3d(68%, -18%, 0) scale(0.95) rotateY(0deg);
  }
  75% {
    transform: translate3d(45%, -20%, 0) scale(0.9) rotateY(0deg);
  }
  84% {
    transform: translate3d(20%, -16%, 0) scale(0.9) rotateY(0deg);
  }
  92% {
    transform: translate3d(5%, -8%, 0) scale(1) rotateY(0deg);
  }
  98% {
    transform: translate3d(1%, -2%, 0) scale(1) rotateY(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1) rotateY(180deg);
  }
}

@keyframes mtofGears {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes mtofPlanet1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes mtofPlanet2 {
  0% {
    transform: rotate(60deg);
  }
  100% {
    transform: rotate(420deg);
  }
}

@keyframes animatedFrameWidth112Frame10 {
  0% {
    transform: translate(0px, 0);
  }
  10% {
    transform: translate(-112px, 0);
  }
  20% {
    transform: translate(-224px, 0);
  }
  30% {
    transform: translate(-336px, 0);
  }
  40% {
    transform: translate(-448px, 0);
  }
  50% {
    transform: translate(-560px, 0);
  }
  60% {
    transform: translate(-672px, 0);
  }
  70% {
    transform: translate(-784px, 0);
  }
  80% {
    transform: translate(-896px, 0);
  }
  90% {
    transform: translate(-1008px, 0);
  }
  100% {
    transform: translate(-1120px, 0);
  }
}

@keyframes animatedFrameWidth110Frame25 {
  0% {
    transform: translate(0px, 0);
  }
  4% {
    transform: translate(-110px, 0);
  }
  8% {
    transform: translate(-220px, 0);
  }
  12% {
    transform: translate(-330px, 0);
  }
  16% {
    transform: translate(-440px, 0);
  }
  20% {
    transform: translate(-550px, 0);
  }
  24% {
    transform: translate(-660px, 0);
  }
  28% {
    transform: translate(-770px, 0);
  }
  32% {
    transform: translate(-880px, 0);
  }
  36% {
    transform: translate(-990px, 0);
  }
  40% {
    transform: translate(-1100px, 0);
  }
  44% {
    transform: translate(-1210px, 0);
  }
  48% {
    transform: translate(-1320px, 0);
  }
  52% {
    transform: translate(-1430px, 0);
  }
  56% {
    transform: translate(-1540px, 0);
  }
  60% {
    transform: translate(-1650px, 0);
  }
  64% {
    transform: translate(-1760px, 0);
  }
  68% {
    transform: translate(-1870px, 0);
  }
  72% {
    transform: translate(-1980px, 0);
  }
  76% {
    transform: translate(-2090px, 0);
  }
  80% {
    transform: translate(-2200px, 0);
  }
  84% {
    transform: translate(-2310px, 0);
  }
  88% {
    transform: translate(-2420px, 0);
  }
  92% {
    transform: translate(-2530px, 0);
  }
  96% {
    transform: translate(-2640px, 0);
  }
  100% {
    transform: translate(-2750px, 0);
  }
}

@keyframes animatedFrameWidth112Frame25 {
  0% {
    transform: translate(0px, 0);
  }
  4% {
    transform: translate(-112px, 0);
  }
  8% {
    transform: translate(-224px, 0);
  }
  12% {
    transform: translate(-336px, 0);
  }
  16% {
    transform: translate(-448px, 0);
  }
  20% {
    transform: translate(-560px, 0);
  }
  24% {
    transform: translate(-672px, 0);
  }
  28% {
    transform: translate(-784px, 0);
  }
  32% {
    transform: translate(-896px, 0);
  }
  36% {
    transform: translate(-1008px, 0);
  }
  40% {
    transform: translate(-1120px, 0);
  }
  44% {
    transform: translate(-1232px, 0);
  }
  48% {
    transform: translate(-1344px, 0);
  }
  52% {
    transform: translate(-1456px, 0);
  }
  56% {
    transform: translate(-1568px, 0);
  }
  60% {
    transform: translate(-1680px, 0);
  }
  64% {
    transform: translate(-1792px, 0);
  }
  68% {
    transform: translate(-1904px, 0);
  }
  72% {
    transform: translate(-2016px, 0);
  }
  76% {
    transform: translate(-2128px, 0);
  }
  80% {
    transform: translate(-2240px, 0);
  }
  84% {
    transform: translate(-2352px, 0);
  }
  88% {
    transform: translate(-2464px, 0);
  }
  92% {
    transform: translate(-2576px, 0);
  }
  96% {
    transform: translate(-2688px, 0);
  }
  100% {
    transform: translate(-2800px, 0);
  }
}

@keyframes animatedFrameWidth130Frame25 {
  0% {
    transform: translate(0px, 0);
  }
  4% {
    transform: translate(-130px, 0);
  }
  8% {
    transform: translate(-260px, 0);
  }
  12% {
    transform: translate(-390px, 0);
  }
  16% {
    transform: translate(-520px, 0);
  }
  20% {
    transform: translate(-650px, 0);
  }
  24% {
    transform: translate(-780px, 0);
  }
  28% {
    transform: translate(-910px, 0);
  }
  32% {
    transform: translate(-1040px, 0);
  }
  36% {
    transform: translate(-1170px, 0);
  }
  40% {
    transform: translate(-1300px, 0);
  }
  44% {
    transform: translate(-1430px, 0);
  }
  48% {
    transform: translate(-1560px, 0);
  }
  52% {
    transform: translate(-1690px, 0);
  }
  56% {
    transform: translate(-1820px, 0);
  }
  60% {
    transform: translate(-1950px, 0);
  }
  64% {
    transform: translate(-2080px, 0);
  }
  68% {
    transform: translate(-2210px, 0);
  }
  72% {
    transform: translate(-2340px, 0);
  }
  76% {
    transform: translate(-2470px, 0);
  }
  80% {
    transform: translate(-2600px, 0);
  }
  84% {
    transform: translate(-2730px, 0);
  }
  88% {
    transform: translate(-2860px, 0);
  }
  92% {
    transform: translate(-2990px, 0);
  }
  96% {
    transform: translate(-3120px, 0);
  }
  100% {
    transform: translate(-3250px, 0);
  }
}

@keyframes animatedFrameWidth112Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-112px, 0px);
  }
  2% {
    transform: translate(-224px, 0px);
  }
  3% {
    transform: translate(-336px, 0px);
  }
  4% {
    transform: translate(-448px, 0px);
  }
  5% {
    transform: translate(-560px, 0px);
  }
  6% {
    transform: translate(-672px, 0px);
  }
  7% {
    transform: translate(-784px, 0px);
  }
  8% {
    transform: translate(-896px, 0px);
  }
  9% {
    transform: translate(-1008px, 0px);
  }
  10% {
    transform: translate(0px, -112px);
  }
  11% {
    transform: translate(-112px, -112px);
  }
  12% {
    transform: translate(-224px, -112px);
  }
  13% {
    transform: translate(-336px, -112px);
  }
  14% {
    transform: translate(-448px, -112px);
  }
  15% {
    transform: translate(-560px, -112px);
  }
  16% {
    transform: translate(-672px, -112px);
  }
  17% {
    transform: translate(-784px, -112px);
  }
  18% {
    transform: translate(-896px, -112px);
  }
  19% {
    transform: translate(-1008px, -112px);
  }
  20% {
    transform: translate(0px, -224px);
  }
  21% {
    transform: translate(-112px, -224px);
  }
  22% {
    transform: translate(-224px, -224px);
  }
  23% {
    transform: translate(-336px, -224px);
  }
  24% {
    transform: translate(-448px, -224px);
  }
  25% {
    transform: translate(-560px, -224px);
  }
  26% {
    transform: translate(-672px, -224px);
  }
  27% {
    transform: translate(-784px, -224px);
  }
  28% {
    transform: translate(-896px, -224px);
  }
  29% {
    transform: translate(-1008px, -224px);
  }
  30% {
    transform: translate(0px, -336px);
  }
  31% {
    transform: translate(-112px, -336px);
  }
  32% {
    transform: translate(-224px, -336px);
  }
  33% {
    transform: translate(-336px, -336px);
  }
  34% {
    transform: translate(-448px, -336px);
  }
  35% {
    transform: translate(-560px, -336px);
  }
  36% {
    transform: translate(-672px, -336px);
  }
  37% {
    transform: translate(-784px, -336px);
  }
  38% {
    transform: translate(-896px, -336px);
  }
  39% {
    transform: translate(-1008px, -336px);
  }
  40% {
    transform: translate(0px, -448px);
  }
  41% {
    transform: translate(-112px, -448px);
  }
  42% {
    transform: translate(-224px, -448px);
  }
  43% {
    transform: translate(-336px, -448px);
  }
  44% {
    transform: translate(-448px, -448px);
  }
  45% {
    transform: translate(-560px, -448px);
  }
  46% {
    transform: translate(-672px, -448px);
  }
  47% {
    transform: translate(-784px, -448px);
  }
  48% {
    transform: translate(-896px, -448px);
  }
  49% {
    transform: translate(-1008px, -448px);
  }
  50% {
    transform: translate(0px, -560px);
  }
  51% {
    transform: translate(-112px, -560px);
  }
  52% {
    transform: translate(-224px, -560px);
  }
  53% {
    transform: translate(-336px, -560px);
  }
  54% {
    transform: translate(-448px, -560px);
  }
  55% {
    transform: translate(-560px, -560px);
  }
  56% {
    transform: translate(-672px, -560px);
  }
  57% {
    transform: translate(-784px, -560px);
  }
  58% {
    transform: translate(-896px, -560px);
  }
  59% {
    transform: translate(-1008px, -560px);
  }
  60% {
    transform: translate(0px, -672px);
  }
  61% {
    transform: translate(-112px, -672px);
  }
  62% {
    transform: translate(-224px, -672px);
  }
  63% {
    transform: translate(-336px, -672px);
  }
  64% {
    transform: translate(-448px, -672px);
  }
  65% {
    transform: translate(-560px, -672px);
  }
  66% {
    transform: translate(-672px, -672px);
  }
  67% {
    transform: translate(-784px, -672px);
  }
  68% {
    transform: translate(-896px, -672px);
  }
  69% {
    transform: translate(-1008px, -672px);
  }
  70% {
    transform: translate(0px, -784px);
  }
  71% {
    transform: translate(-112px, -784px);
  }
  72% {
    transform: translate(-224px, -784px);
  }
  73% {
    transform: translate(-336px, -784px);
  }
  74% {
    transform: translate(-448px, -784px);
  }
  75% {
    transform: translate(-560px, -784px);
  }
  76% {
    transform: translate(-672px, -784px);
  }
  77% {
    transform: translate(-784px, -784px);
  }
  78% {
    transform: translate(-896px, -784px);
  }
  79% {
    transform: translate(-1008px, -784px);
  }
  80% {
    transform: translate(0px, -896px);
  }
  81% {
    transform: translate(-112px, -896px);
  }
  82% {
    transform: translate(-224px, -896px);
  }
  83% {
    transform: translate(-336px, -896px);
  }
  84% {
    transform: translate(-448px, -896px);
  }
  85% {
    transform: translate(-560px, -896px);
  }
  86% {
    transform: translate(-672px, -896px);
  }
  87% {
    transform: translate(-784px, -896px);
  }
  88% {
    transform: translate(-896px, -896px);
  }
  89% {
    transform: translate(-1008px, -896px);
  }
  90% {
    transform: translate(0px, -1008px);
  }
  91% {
    transform: translate(-112px, -1008px);
  }
  92% {
    transform: translate(-224px, -1008px);
  }
  93% {
    transform: translate(-336px, -1008px);
  }
  94% {
    transform: translate(-448px, -1008px);
  }
  95% {
    transform: translate(-560px, -1008px);
  }
  96% {
    transform: translate(-672px, -1008px);
  }
  97% {
    transform: translate(-784px, -1008px);
  }
  98% {
    transform: translate(-896px, -1008px);
  }
  99% {
    transform: translate(-1008px, -1008px);
  }
}

@keyframes animatedFrameWidth112Height150Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-112px, 0px);
  }
  2% {
    transform: translate(-224px, 0px);
  }
  3% {
    transform: translate(-336px, 0px);
  }
  4% {
    transform: translate(-448px, 0px);
  }
  5% {
    transform: translate(-560px, 0px);
  }
  6% {
    transform: translate(-672px, 0px);
  }
  7% {
    transform: translate(-784px, 0px);
  }
  8% {
    transform: translate(-896px, 0px);
  }
  9% {
    transform: translate(-1008px, 0px);
  }
  10% {
    transform: translate(0px, -150px);
  }
  11% {
    transform: translate(-112px, -150px);
  }
  12% {
    transform: translate(-224px, -150px);
  }
  13% {
    transform: translate(-336px, -150px);
  }
  14% {
    transform: translate(-448px, -150px);
  }
  15% {
    transform: translate(-560px, -150px);
  }
  16% {
    transform: translate(-672px, -150px);
  }
  17% {
    transform: translate(-784px, -150px);
  }
  18% {
    transform: translate(-896px, -150px);
  }
  19% {
    transform: translate(-1008px, -150px);
  }
  20% {
    transform: translate(0px, -300px);
  }
  21% {
    transform: translate(-112px, -300px);
  }
  22% {
    transform: translate(-224px, -300px);
  }
  23% {
    transform: translate(-336px, -300px);
  }
  24% {
    transform: translate(-448px, -300px);
  }
  25% {
    transform: translate(-560px, -300px);
  }
  26% {
    transform: translate(-672px, -300px);
  }
  27% {
    transform: translate(-784px, -300px);
  }
  28% {
    transform: translate(-896px, -300px);
  }
  29% {
    transform: translate(-1008px, -300px);
  }
  30% {
    transform: translate(0px, -450px);
  }
  31% {
    transform: translate(-112px, -450px);
  }
  32% {
    transform: translate(-224px, -450px);
  }
  33% {
    transform: translate(-336px, -450px);
  }
  34% {
    transform: translate(-448px, -450px);
  }
  35% {
    transform: translate(-560px, -450px);
  }
  36% {
    transform: translate(-672px, -450px);
  }
  37% {
    transform: translate(-784px, -450px);
  }
  38% {
    transform: translate(-896px, -450px);
  }
  39% {
    transform: translate(-1008px, -450px);
  }
  40% {
    transform: translate(0px, -600px);
  }
  41% {
    transform: translate(-112px, -600px);
  }
  42% {
    transform: translate(-224px, -600px);
  }
  43% {
    transform: translate(-336px, -600px);
  }
  44% {
    transform: translate(-448px, -600px);
  }
  45% {
    transform: translate(-560px, -600px);
  }
  46% {
    transform: translate(-672px, -600px);
  }
  47% {
    transform: translate(-784px, -600px);
  }
  48% {
    transform: translate(-896px, -600px);
  }
  49% {
    transform: translate(-1008px, -600px);
  }
  50% {
    transform: translate(0px, -750px);
  }
  51% {
    transform: translate(-112px, -750px);
  }
  52% {
    transform: translate(-224px, -750px);
  }
  53% {
    transform: translate(-336px, -750px);
  }
  54% {
    transform: translate(-448px, -750px);
  }
  55% {
    transform: translate(-560px, -750px);
  }
  56% {
    transform: translate(-672px, -750px);
  }
  57% {
    transform: translate(-784px, -750px);
  }
  58% {
    transform: translate(-896px, -750px);
  }
  59% {
    transform: translate(-1008px, -750px);
  }
  60% {
    transform: translate(0px, -900px);
  }
  61% {
    transform: translate(-112px, -900px);
  }
  62% {
    transform: translate(-224px, -900px);
  }
  63% {
    transform: translate(-336px, -900px);
  }
  64% {
    transform: translate(-448px, -900px);
  }
  65% {
    transform: translate(-560px, -900px);
  }
  66% {
    transform: translate(-672px, -900px);
  }
  67% {
    transform: translate(-784px, -900px);
  }
  68% {
    transform: translate(-896px, -900px);
  }
  69% {
    transform: translate(-1008px, -900px);
  }
  70% {
    transform: translate(0px, -1050px);
  }
  71% {
    transform: translate(-112px, -1050px);
  }
  72% {
    transform: translate(-224px, -1050px);
  }
  73% {
    transform: translate(-336px, -1050px);
  }
  74% {
    transform: translate(-448px, -1050px);
  }
  75% {
    transform: translate(-560px, -1050px);
  }
  76% {
    transform: translate(-672px, -1050px);
  }
  77% {
    transform: translate(-784px, -1050px);
  }
  78% {
    transform: translate(-896px, -1050px);
  }
  79% {
    transform: translate(-1008px, -1050px);
  }
  80% {
    transform: translate(0px, -1200px);
  }
  81% {
    transform: translate(-112px, -1200px);
  }
  82% {
    transform: translate(-224px, -1200px);
  }
  83% {
    transform: translate(-336px, -1200px);
  }
  84% {
    transform: translate(-448px, -1200px);
  }
  85% {
    transform: translate(-560px, -1200px);
  }
  86% {
    transform: translate(-672px, -1200px);
  }
  87% {
    transform: translate(-784px, -1200px);
  }
  88% {
    transform: translate(-896px, -1200px);
  }
  89% {
    transform: translate(-1008px, -1200px);
  }
  90% {
    transform: translate(0px, -1350px);
  }
  91% {
    transform: translate(-112px, -1350px);
  }
  92% {
    transform: translate(-224px, -1350px);
  }
  93% {
    transform: translate(-336px, -1350px);
  }
  94% {
    transform: translate(-448px, -1350px);
  }
  95% {
    transform: translate(-560px, -1350px);
  }
  96% {
    transform: translate(-672px, -1350px);
  }
  97% {
    transform: translate(-784px, -1350px);
  }
  98% {
    transform: translate(-896px, -1350px);
  }
  99% {
    transform: translate(-1008px, -1350px);
  }
  100% {
    transform: translate(-1120px, -1350px);
  }
}

@keyframes animatedFrameWidth130Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-130px, 0px);
  }
  2% {
    transform: translate(-260px, 0px);
  }
  3% {
    transform: translate(-390px, 0px);
  }
  4% {
    transform: translate(-520px, 0px);
  }
  5% {
    transform: translate(-650px, 0px);
  }
  6% {
    transform: translate(-780px, 0px);
  }
  7% {
    transform: translate(-910px, 0px);
  }
  8% {
    transform: translate(-1040px, 0px);
  }
  9% {
    transform: translate(-1170px, 0px);
  }
  10% {
    transform: translate(0px, -130px);
  }
  11% {
    transform: translate(-130px, -130px);
  }
  12% {
    transform: translate(-260px, -130px);
  }
  13% {
    transform: translate(-390px, -130px);
  }
  14% {
    transform: translate(-520px, -130px);
  }
  15% {
    transform: translate(-650px, -130px);
  }
  16% {
    transform: translate(-780px, -130px);
  }
  17% {
    transform: translate(-910px, -130px);
  }
  18% {
    transform: translate(-1040px, -130px);
  }
  19% {
    transform: translate(-1170px, -130px);
  }
  20% {
    transform: translate(0px, -260px);
  }
  21% {
    transform: translate(-130px, -260px);
  }
  22% {
    transform: translate(-260px, -260px);
  }
  23% {
    transform: translate(-390px, -260px);
  }
  24% {
    transform: translate(-520px, -260px);
  }
  25% {
    transform: translate(-650px, -260px);
  }
  26% {
    transform: translate(-780px, -260px);
  }
  27% {
    transform: translate(-910px, -260px);
  }
  28% {
    transform: translate(-1040px, -260px);
  }
  29% {
    transform: translate(-1170px, -260px);
  }
  30% {
    transform: translate(0px, -390px);
  }
  31% {
    transform: translate(-130px, -390px);
  }
  32% {
    transform: translate(-260px, -390px);
  }
  33% {
    transform: translate(-390px, -390px);
  }
  34% {
    transform: translate(-520px, -390px);
  }
  35% {
    transform: translate(-650px, -390px);
  }
  36% {
    transform: translate(-780px, -390px);
  }
  37% {
    transform: translate(-910px, -390px);
  }
  38% {
    transform: translate(-1040px, -390px);
  }
  39% {
    transform: translate(-1170px, -390px);
  }
  40% {
    transform: translate(0px, -520px);
  }
  41% {
    transform: translate(-130px, -520px);
  }
  42% {
    transform: translate(-260px, -520px);
  }
  43% {
    transform: translate(-390px, -520px);
  }
  44% {
    transform: translate(-520px, -520px);
  }
  45% {
    transform: translate(-650px, -520px);
  }
  46% {
    transform: translate(-780px, -520px);
  }
  47% {
    transform: translate(-910px, -520px);
  }
  48% {
    transform: translate(-1040px, -520px);
  }
  49% {
    transform: translate(-1170px, -520px);
  }
  50% {
    transform: translate(0px, -650px);
  }
  51% {
    transform: translate(-130px, -650px);
  }
  52% {
    transform: translate(-260px, -650px);
  }
  53% {
    transform: translate(-390px, -650px);
  }
  54% {
    transform: translate(-520px, -650px);
  }
  55% {
    transform: translate(-650px, -650px);
  }
  56% {
    transform: translate(-780px, -650px);
  }
  57% {
    transform: translate(-910px, -650px);
  }
  58% {
    transform: translate(-1040px, -650px);
  }
  59% {
    transform: translate(-1170px, -650px);
  }
  60% {
    transform: translate(0px, -780px);
  }
  61% {
    transform: translate(-130px, -780px);
  }
  62% {
    transform: translate(-260px, -780px);
  }
  63% {
    transform: translate(-390px, -780px);
  }
  64% {
    transform: translate(-520px, -780px);
  }
  65% {
    transform: translate(-650px, -780px);
  }
  66% {
    transform: translate(-780px, -780px);
  }
  67% {
    transform: translate(-910px, -780px);
  }
  68% {
    transform: translate(-1040px, -780px);
  }
  69% {
    transform: translate(-1170px, -780px);
  }
  70% {
    transform: translate(0px, -910px);
  }
  71% {
    transform: translate(-130px, -910px);
  }
  72% {
    transform: translate(-260px, -910px);
  }
  73% {
    transform: translate(-390px, -910px);
  }
  74% {
    transform: translate(-520px, -910px);
  }
  75% {
    transform: translate(-650px, -910px);
  }
  76% {
    transform: translate(-780px, -910px);
  }
  77% {
    transform: translate(-910px, -910px);
  }
  78% {
    transform: translate(-1040px, -910px);
  }
  79% {
    transform: translate(-1170px, -910px);
  }
  80% {
    transform: translate(0px, -1040px);
  }
  81% {
    transform: translate(-130px, -1040px);
  }
  82% {
    transform: translate(-260px, -1040px);
  }
  83% {
    transform: translate(-390px, -1040px);
  }
  84% {
    transform: translate(-520px, -1040px);
  }
  85% {
    transform: translate(-650px, -1040px);
  }
  86% {
    transform: translate(-780px, -1040px);
  }
  87% {
    transform: translate(-910px, -1040px);
  }
  88% {
    transform: translate(-1040px, -1040px);
  }
  89% {
    transform: translate(-1170px, -1040px);
  }
  90% {
    transform: translate(0px, -1170px);
  }
  91% {
    transform: translate(-130px, -1170px);
  }
  92% {
    transform: translate(-260px, -1170px);
  }
  93% {
    transform: translate(-390px, -1170px);
  }
  94% {
    transform: translate(-520px, -1170px);
  }
  95% {
    transform: translate(-650px, -1170px);
  }
  96% {
    transform: translate(-780px, -1170px);
  }
  97% {
    transform: translate(-910px, -1170px);
  }
  98% {
    transform: translate(-1040px, -1170px);
  }
  99% {
    transform: translate(-1170px, -1170px);
  }
  100% {
    transform: translate(-1300px, -1300px);
  }
}

@keyframes animatedFrameWidth100Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-100px, 0px);
  }
  2% {
    transform: translate(-200px, 0px);
  }
  3% {
    transform: translate(-300px, 0px);
  }
  4% {
    transform: translate(-400px, 0px);
  }
  5% {
    transform: translate(-500px, 0px);
  }
  6% {
    transform: translate(-600px, 0px);
  }
  7% {
    transform: translate(-700px, 0px);
  }
  8% {
    transform: translate(-800px, 0px);
  }
  9% {
    transform: translate(-900px, 0px);
  }
  10% {
    transform: translate(0px, -100px);
  }
  11% {
    transform: translate(-100px, -100px);
  }
  12% {
    transform: translate(-200px, -100px);
  }
  13% {
    transform: translate(-300px, -100px);
  }
  14% {
    transform: translate(-400px, -100px);
  }
  15% {
    transform: translate(-500px, -100px);
  }
  16% {
    transform: translate(-600px, -100px);
  }
  17% {
    transform: translate(-700px, -100px);
  }
  18% {
    transform: translate(-800px, -100px);
  }
  19% {
    transform: translate(-900px, -100px);
  }
  20% {
    transform: translate(0px, -200px);
  }
  21% {
    transform: translate(-100px, -200px);
  }
  22% {
    transform: translate(-200px, -200px);
  }
  23% {
    transform: translate(-300px, -200px);
  }
  24% {
    transform: translate(-400px, -200px);
  }
  25% {
    transform: translate(-500px, -200px);
  }
  26% {
    transform: translate(-600px, -200px);
  }
  27% {
    transform: translate(-700px, -200px);
  }
  28% {
    transform: translate(-800px, -200px);
  }
  29% {
    transform: translate(-900px, -200px);
  }
  30% {
    transform: translate(0px, -300px);
  }
  31% {
    transform: translate(-100px, -300px);
  }
  32% {
    transform: translate(-200px, -300px);
  }
  33% {
    transform: translate(-300px, -300px);
  }
  34% {
    transform: translate(-400px, -300px);
  }
  35% {
    transform: translate(-500px, -300px);
  }
  36% {
    transform: translate(-600px, -300px);
  }
  37% {
    transform: translate(-700px, -300px);
  }
  38% {
    transform: translate(-800px, -300px);
  }
  39% {
    transform: translate(-900px, -300px);
  }
  40% {
    transform: translate(0px, -400px);
  }
  41% {
    transform: translate(-100px, -400px);
  }
  42% {
    transform: translate(-200px, -400px);
  }
  43% {
    transform: translate(-300px, -400px);
  }
  44% {
    transform: translate(-400px, -400px);
  }
  45% {
    transform: translate(-500px, -400px);
  }
  46% {
    transform: translate(-600px, -400px);
  }
  47% {
    transform: translate(-700px, -400px);
  }
  48% {
    transform: translate(-800px, -400px);
  }
  49% {
    transform: translate(-900px, -400px);
  }
  50% {
    transform: translate(0px, -500px);
  }
  51% {
    transform: translate(-100px, -500px);
  }
  52% {
    transform: translate(-200px, -500px);
  }
  53% {
    transform: translate(-300px, -500px);
  }
  54% {
    transform: translate(-400px, -500px);
  }
  55% {
    transform: translate(-500px, -500px);
  }
  56% {
    transform: translate(-600px, -500px);
  }
  57% {
    transform: translate(-700px, -500px);
  }
  58% {
    transform: translate(-800px, -500px);
  }
  59% {
    transform: translate(-900px, -500px);
  }
  60% {
    transform: translate(0px, -600px);
  }
  61% {
    transform: translate(-100px, -600px);
  }
  62% {
    transform: translate(-200px, -600px);
  }
  63% {
    transform: translate(-300px, -600px);
  }
  64% {
    transform: translate(-400px, -600px);
  }
  65% {
    transform: translate(-500px, -600px);
  }
  66% {
    transform: translate(-600px, -600px);
  }
  67% {
    transform: translate(-700px, -600px);
  }
  68% {
    transform: translate(-800px, -600px);
  }
  69% {
    transform: translate(-900px, -600px);
  }
  70% {
    transform: translate(0px, -700px);
  }
  71% {
    transform: translate(-100px, -700px);
  }
  72% {
    transform: translate(-200px, -700px);
  }
  73% {
    transform: translate(-300px, -700px);
  }
  74% {
    transform: translate(-400px, -700px);
  }
  75% {
    transform: translate(-500px, -700px);
  }
  76% {
    transform: translate(-600px, -700px);
  }
  77% {
    transform: translate(-700px, -700px);
  }
  78% {
    transform: translate(-800px, -700px);
  }
  79% {
    transform: translate(-900px, -700px);
  }
  80% {
    transform: translate(0px, -800px);
  }
  81% {
    transform: translate(-100px, -800px);
  }
  82% {
    transform: translate(-200px, -800px);
  }
  83% {
    transform: translate(-300px, -800px);
  }
  84% {
    transform: translate(-400px, -800px);
  }
  85% {
    transform: translate(-500px, -800px);
  }
  86% {
    transform: translate(-600px, -800px);
  }
  87% {
    transform: translate(-700px, -800px);
  }
  88% {
    transform: translate(-800px, -800px);
  }
  89% {
    transform: translate(-900px, -800px);
  }
  90% {
    transform: translate(0px, -900px);
  }
  91% {
    transform: translate(-100px, -900px);
  }
  92% {
    transform: translate(-200px, -900px);
  }
  93% {
    transform: translate(-300px, -900px);
  }
  94% {
    transform: translate(-400px, -900px);
  }
  95% {
    transform: translate(-500px, -900px);
  }
  96% {
    transform: translate(-600px, -900px);
  }
  97% {
    transform: translate(-700px, -900px);
  }
  98% {
    transform: translate(-800px, -900px);
  }
  99% {
    transform: translate(-900px, -900px);
  }
  100% {
    transform: translate(-1000px, -1000px);
  }
}

@keyframes animatedFrameWidth128Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-128px, 0px);
  }
  2% {
    transform: translate(-256px, 0px);
  }
  3% {
    transform: translate(-384px, 0px);
  }
  4% {
    transform: translate(-512px, 0px);
  }
  5% {
    transform: translate(-640px, 0px);
  }
  6% {
    transform: translate(-768px, 0px);
  }
  7% {
    transform: translate(-896px, 0px);
  }
  8% {
    transform: translate(-1024px, 0px);
  }
  9% {
    transform: translate(-1152px, 0px);
  }
  10% {
    transform: translate(0px, -128px);
  }
  11% {
    transform: translate(-128px, -128px);
  }
  12% {
    transform: translate(-256px, -128px);
  }
  13% {
    transform: translate(-384px, -128px);
  }
  14% {
    transform: translate(-512px, -128px);
  }
  15% {
    transform: translate(-640px, -128px);
  }
  16% {
    transform: translate(-768px, -128px);
  }
  17% {
    transform: translate(-896px, -128px);
  }
  18% {
    transform: translate(-1024px, -128px);
  }
  19% {
    transform: translate(-1152px, -128px);
  }
  20% {
    transform: translate(0px, -256px);
  }
  21% {
    transform: translate(-128px, -256px);
  }
  22% {
    transform: translate(-256px, -256px);
  }
  23% {
    transform: translate(-384px, -256px);
  }
  24% {
    transform: translate(-512px, -256px);
  }
  25% {
    transform: translate(-640px, -256px);
  }
  26% {
    transform: translate(-768px, -256px);
  }
  27% {
    transform: translate(-896px, -256px);
  }
  28% {
    transform: translate(-1024px, -256px);
  }
  29% {
    transform: translate(-1152px, -256px);
  }
  30% {
    transform: translate(0px, -384px);
  }
  31% {
    transform: translate(-128px, -384px);
  }
  32% {
    transform: translate(-256px, -384px);
  }
  33% {
    transform: translate(-384px, -384px);
  }
  34% {
    transform: translate(-512px, -384px);
  }
  35% {
    transform: translate(-640px, -384px);
  }
  36% {
    transform: translate(-768px, -384px);
  }
  37% {
    transform: translate(-896px, -384px);
  }
  38% {
    transform: translate(-1024px, -384px);
  }
  39% {
    transform: translate(-1152px, -384px);
  }
  40% {
    transform: translate(0px, -512px);
  }
  41% {
    transform: translate(-128px, -512px);
  }
  42% {
    transform: translate(-256px, -512px);
  }
  43% {
    transform: translate(-384px, -512px);
  }
  44% {
    transform: translate(-512px, -512px);
  }
  45% {
    transform: translate(-640px, -512px);
  }
  46% {
    transform: translate(-768px, -512px);
  }
  47% {
    transform: translate(-896px, -512px);
  }
  48% {
    transform: translate(-1024px, -512px);
  }
  49% {
    transform: translate(-1152px, -512px);
  }
  50% {
    transform: translate(0px, -640px);
  }
  51% {
    transform: translate(-128px, -640px);
  }
  52% {
    transform: translate(-256px, -640px);
  }
  53% {
    transform: translate(-384px, -640px);
  }
  54% {
    transform: translate(-512px, -640px);
  }
  55% {
    transform: translate(-640px, -640px);
  }
  56% {
    transform: translate(-768px, -640px);
  }
  57% {
    transform: translate(-896px, -640px);
  }
  58% {
    transform: translate(-1024px, -640px);
  }
  59% {
    transform: translate(-1152px, -640px);
  }
  60% {
    transform: translate(0px, -768px);
  }
  61% {
    transform: translate(-128px, -768px);
  }
  62% {
    transform: translate(-256px, -768px);
  }
  63% {
    transform: translate(-384px, -768px);
  }
  64% {
    transform: translate(-512px, -768px);
  }
  65% {
    transform: translate(-640px, -768px);
  }
  66% {
    transform: translate(-768px, -768px);
  }
  67% {
    transform: translate(-896px, -768px);
  }
  68% {
    transform: translate(-1024px, -768px);
  }
  69% {
    transform: translate(-1152px, -768px);
  }
  70% {
    transform: translate(0px, -896px);
  }
  71% {
    transform: translate(-128px, -896px);
  }
  72% {
    transform: translate(-256px, -896px);
  }
  73% {
    transform: translate(-384px, -896px);
  }
  74% {
    transform: translate(-512px, -896px);
  }
  75% {
    transform: translate(-640px, -896px);
  }
  76% {
    transform: translate(-768px, -896px);
  }
  77% {
    transform: translate(-896px, -896px);
  }
  78% {
    transform: translate(-1024px, -896px);
  }
  79% {
    transform: translate(-1152px, -896px);
  }
  80% {
    transform: translate(0px, -1024px);
  }
  81% {
    transform: translate(-128px, -1024px);
  }
  82% {
    transform: translate(-256px, -1024px);
  }
  83% {
    transform: translate(-384px, -1024px);
  }
  84% {
    transform: translate(-512px, -1024px);
  }
  85% {
    transform: translate(-640px, -1024px);
  }
  86% {
    transform: translate(-768px, -1024px);
  }
  87% {
    transform: translate(-896px, -1024px);
  }
  88% {
    transform: translate(-1024px, -1024px);
  }
  89% {
    transform: translate(-1152px, -1024px);
  }
  90% {
    transform: translate(0px, -1152px);
  }
  91% {
    transform: translate(-128px, -1152px);
  }
  92% {
    transform: translate(-256px, -1152px);
  }
  93% {
    transform: translate(-384px, -1152px);
  }
  94% {
    transform: translate(-512px, -1152px);
  }
  95% {
    transform: translate(-640px, -1152px);
  }
  96% {
    transform: translate(-768px, -1152px);
  }
  97% {
    transform: translate(-896px, -1152px);
  }
  98% {
    transform: translate(-1024px, -1152px);
  }
  99% {
    transform: translate(-1152px, -1152px);
  }
  100% {
    transform: translate(-1280px, -1280px);
  }
}
@keyframes animatedFrameWidth163Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-163px, 0px);
  }
  2% {
    transform: translate(-326px, 0px);
  }
  3% {
    transform: translate(-489px, 0px);
  }
  4% {
    transform: translate(-652px, 0px);
  }
  5% {
    transform: translate(-815px, 0px);
  }
  6% {
    transform: translate(-978px, 0px);
  }
  7% {
    transform: translate(-1141px, 0px);
  }
  8% {
    transform: translate(-1304px, 0px);
  }
  9% {
    transform: translate(-1467px, 0px);
  }
  10% {
    transform: translate(0px, -163px);
  }
  11% {
    transform: translate(-163px, -163px);
  }
  12% {
    transform: translate(-326px, -163px);
  }
  13% {
    transform: translate(-489px, -163px);
  }
  14% {
    transform: translate(-652px, -163px);
  }
  15% {
    transform: translate(-815px, -163px);
  }
  16% {
    transform: translate(-978px, -163px);
  }
  17% {
    transform: translate(-1141px, -163px);
  }
  18% {
    transform: translate(-1304px, -163px);
  }
  19% {
    transform: translate(-1467px, -163px);
  }
  20% {
    transform: translate(0px, -326px);
  }
  21% {
    transform: translate(-163px, -326px);
  }
  22% {
    transform: translate(-326px, -326px);
  }
  23% {
    transform: translate(-489px, -326px);
  }
  24% {
    transform: translate(-652px, -326px);
  }
  25% {
    transform: translate(-815px, -326px);
  }
  26% {
    transform: translate(-978px, -326px);
  }
  27% {
    transform: translate(-1141px, -326px);
  }
  28% {
    transform: translate(-1304px, -326px);
  }
  29% {
    transform: translate(-1467px, -326px);
  }
  30% {
    transform: translate(0px, -489px);
  }
  31% {
    transform: translate(-163px, -489px);
  }
  32% {
    transform: translate(-326px, -489px);
  }
  33% {
    transform: translate(-489px, -489px);
  }
  34% {
    transform: translate(-652px, -489px);
  }
  35% {
    transform: translate(-815px, -489px);
  }
  36% {
    transform: translate(-978px, -489px);
  }
  37% {
    transform: translate(-1141px, -489px);
  }
  38% {
    transform: translate(-1304px, -489px);
  }
  39% {
    transform: translate(-1467px, -489px);
  }
  40% {
    transform: translate(0px, -652px);
  }
  41% {
    transform: translate(-163px, -652px);
  }
  42% {
    transform: translate(-326px, -652px);
  }
  43% {
    transform: translate(-489px, -652px);
  }
  44% {
    transform: translate(-652px, -652px);
  }
  45% {
    transform: translate(-815px, -652px);
  }
  46% {
    transform: translate(-978px, -652px);
  }
  47% {
    transform: translate(-1141px, -652px);
  }
  48% {
    transform: translate(-1304px, -652px);
  }
  49% {
    transform: translate(-1467px, -652px);
  }
  50% {
    transform: translate(0px, -815px);
  }
  51% {
    transform: translate(-163px, -815px);
  }
  52% {
    transform: translate(-326px, -815px);
  }
  53% {
    transform: translate(-489px, -815px);
  }
  54% {
    transform: translate(-652px, -815px);
  }
  55% {
    transform: translate(-815px, -815px);
  }
  56% {
    transform: translate(-978px, -815px);
  }
  57% {
    transform: translate(-1141px, -815px);
  }
  58% {
    transform: translate(-1304px, -815px);
  }
  59% {
    transform: translate(-1467px, -815px);
  }
  60% {
    transform: translate(0px, -978px);
  }
  61% {
    transform: translate(-163px, -978px);
  }
  62% {
    transform: translate(-326px, -978px);
  }
  63% {
    transform: translate(-489px, -978px);
  }
  64% {
    transform: translate(-652px, -978px);
  }
  65% {
    transform: translate(-815px, -978px);
  }
  66% {
    transform: translate(-978px, -978px);
  }
  67% {
    transform: translate(-1141px, -978px);
  }
  68% {
    transform: translate(-1304px, -978px);
  }
  69% {
    transform: translate(-1467px, -978px);
  }
  70% {
    transform: translate(0px, -1141px);
  }
  71% {
    transform: translate(-163px, -1141px);
  }
  72% {
    transform: translate(-326px, -1141px);
  }
  73% {
    transform: translate(-489px, -1141px);
  }
  74% {
    transform: translate(-652px, -1141px);
  }
  75% {
    transform: translate(-815px, -1141px);
  }
  76% {
    transform: translate(-978px, -1141px);
  }
  77% {
    transform: translate(-1141px, -1141px);
  }
  78% {
    transform: translate(-1304px, -1141px);
  }
  79% {
    transform: translate(-1467px, -1141px);
  }
  80% {
    transform: translate(0px, -1304px);
  }
  81% {
    transform: translate(-163px, -1304px);
  }
  82% {
    transform: translate(-326px, -1304px);
  }
  83% {
    transform: translate(-489px, -1304px);
  }
  84% {
    transform: translate(-652px, -1304px);
  }
  85% {
    transform: translate(-815px, -1304px);
  }
  86% {
    transform: translate(-978px, -1304px);
  }
  87% {
    transform: translate(-1141px, -1304px);
  }
  88% {
    transform: translate(-1304px, -1304px);
  }
  89% {
    transform: translate(-1467px, -1304px);
  }
  90% {
    transform: translate(0px, -1467px);
  }
  91% {
    transform: translate(-163px, -1467px);
  }
  92% {
    transform: translate(-326px, -1467px);
  }
  93% {
    transform: translate(-489px, -1467px);
  }
  94% {
    transform: translate(-652px, -1467px);
  }
  95% {
    transform: translate(-815px, -1467px);
  }
  96% {
    transform: translate(-978px, -1467px);
  }
  97% {
    transform: translate(-1141px, -1467px);
  }
  98% {
    transform: translate(-1304px, -1467px);
  }
  99% {
    transform: translate(-1467px, -1467px);
  }
  100% {
    transform: translate(-1630px, -1630px);
  }
}

@keyframes animatedFrameWidth126Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-126px, 0px);
  }
  2% {
    transform: translate(-252px, 0px);
  }
  3% {
    transform: translate(-378px, 0px);
  }
  4% {
    transform: translate(-504px, 0px);
  }
  5% {
    transform: translate(-630px, 0px);
  }
  6% {
    transform: translate(-756px, 0px);
  }
  7% {
    transform: translate(-882px, 0px);
  }
  8% {
    transform: translate(-1008px, 0px);
  }
  9% {
    transform: translate(-1134px, 0px);
  }
  10% {
    transform: translate(0px, -126px);
  }
  11% {
    transform: translate(-126px, -126px);
  }
  12% {
    transform: translate(-252px, -126px);
  }
  13% {
    transform: translate(-378px, -126px);
  }
  14% {
    transform: translate(-504px, -126px);
  }
  15% {
    transform: translate(-630px, -126px);
  }
  16% {
    transform: translate(-756px, -126px);
  }
  17% {
    transform: translate(-882px, -126px);
  }
  18% {
    transform: translate(-1008px, -126px);
  }
  19% {
    transform: translate(-1134px, -126px);
  }
  20% {
    transform: translate(0px, -252px);
  }
  21% {
    transform: translate(-126px, -252px);
  }
  22% {
    transform: translate(-252px, -252px);
  }
  23% {
    transform: translate(-378px, -252px);
  }
  24% {
    transform: translate(-504px, -252px);
  }
  25% {
    transform: translate(-630px, -252px);
  }
  26% {
    transform: translate(-756px, -252px);
  }
  27% {
    transform: translate(-882px, -252px);
  }
  28% {
    transform: translate(-1008px, -252px);
  }
  29% {
    transform: translate(-1134px, -252px);
  }
  30% {
    transform: translate(0px, -378px);
  }
  31% {
    transform: translate(-126px, -378px);
  }
  32% {
    transform: translate(-252px, -378px);
  }
  33% {
    transform: translate(-378px, -378px);
  }
  34% {
    transform: translate(-504px, -378px);
  }
  35% {
    transform: translate(-630px, -378px);
  }
  36% {
    transform: translate(-756px, -378px);
  }
  37% {
    transform: translate(-882px, -378px);
  }
  38% {
    transform: translate(-1008px, -378px);
  }
  39% {
    transform: translate(-1134px, -378px);
  }
  40% {
    transform: translate(0px, -504px);
  }
  41% {
    transform: translate(-126px, -504px);
  }
  42% {
    transform: translate(-252px, -504px);
  }
  43% {
    transform: translate(-378px, -504px);
  }
  44% {
    transform: translate(-504px, -504px);
  }
  45% {
    transform: translate(-630px, -504px);
  }
  46% {
    transform: translate(-756px, -504px);
  }
  47% {
    transform: translate(-882px, -504px);
  }
  48% {
    transform: translate(-1008px, -504px);
  }
  49% {
    transform: translate(-1134px, -504px);
  }
  50% {
    transform: translate(0px, -630px);
  }
  51% {
    transform: translate(-126px, -630px);
  }
  52% {
    transform: translate(-252px, -630px);
  }
  53% {
    transform: translate(-378px, -630px);
  }
  54% {
    transform: translate(-504px, -630px);
  }
  55% {
    transform: translate(-630px, -630px);
  }
  56% {
    transform: translate(-756px, -630px);
  }
  57% {
    transform: translate(-882px, -630px);
  }
  58% {
    transform: translate(-1008px, -630px);
  }
  59% {
    transform: translate(-1134px, -630px);
  }
  60% {
    transform: translate(0px, -756px);
  }
  61% {
    transform: translate(-126px, -756px);
  }
  62% {
    transform: translate(-252px, -756px);
  }
  63% {
    transform: translate(-378px, -756px);
  }
  64% {
    transform: translate(-504px, -756px);
  }
  65% {
    transform: translate(-630px, -756px);
  }
  66% {
    transform: translate(-756px, -756px);
  }
  67% {
    transform: translate(-882px, -756px);
  }
  68% {
    transform: translate(-1008px, -756px);
  }
  69% {
    transform: translate(-1134px, -756px);
  }
  70% {
    transform: translate(0px, -882px);
  }
  71% {
    transform: translate(-126px, -882px);
  }
  72% {
    transform: translate(-252px, -882px);
  }
  73% {
    transform: translate(-378px, -882px);
  }
  74% {
    transform: translate(-504px, -882px);
  }
  75% {
    transform: translate(-630px, -882px);
  }
  76% {
    transform: translate(-756px, -882px);
  }
  77% {
    transform: translate(-882px, -882px);
  }
  78% {
    transform: translate(-1008px, -882px);
  }
  79% {
    transform: translate(-1134px, -882px);
  }
  80% {
    transform: translate(0px, -1008px);
  }
  81% {
    transform: translate(-126px, -1008px);
  }
  82% {
    transform: translate(-252px, -1008px);
  }
  83% {
    transform: translate(-378px, -1008px);
  }
  84% {
    transform: translate(-504px, -1008px);
  }
  85% {
    transform: translate(-630px, -1008px);
  }
  86% {
    transform: translate(-756px, -1008px);
  }
  87% {
    transform: translate(-882px, -1008px);
  }
  88% {
    transform: translate(-1008px, -1008px);
  }
  89% {
    transform: translate(-1134px, -1008px);
  }
  90% {
    transform: translate(0px, -1134px);
  }
  91% {
    transform: translate(-126px, -1134px);
  }
  92% {
    transform: translate(-252px, -1134px);
  }
  93% {
    transform: translate(-378px, -1134px);
  }
  94% {
    transform: translate(-504px, -1134px);
  }
  95% {
    transform: translate(-630px, -1134px);
  }
  96% {
    transform: translate(-756px, -1134px);
  }
  97% {
    transform: translate(-882px, -1134px);
  }
  98% {
    transform: translate(-1008px, -1134px);
  }
  99% {
    transform: translate(-1134px, -1134px);
  }
  100% {
    transform: translate(-1260px, -1260px);
  }
}

@keyframes animatedFrameWidth122Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-122px, 0px);
  }
  2% {
    transform: translate(-244px, 0px);
  }
  3% {
    transform: translate(-366px, 0px);
  }
  4% {
    transform: translate(-488px, 0px);
  }
  5% {
    transform: translate(-610px, 0px);
  }
  6% {
    transform: translate(-732px, 0px);
  }
  7% {
    transform: translate(-854px, 0px);
  }
  8% {
    transform: translate(-976px, 0px);
  }
  9% {
    transform: translate(-1098px, 0px);
  }
  10% {
    transform: translate(0px, -122px);
  }
  11% {
    transform: translate(-122px, -122px);
  }
  12% {
    transform: translate(-244px, -122px);
  }
  13% {
    transform: translate(-366px, -122px);
  }
  14% {
    transform: translate(-488px, -122px);
  }
  15% {
    transform: translate(-610px, -122px);
  }
  16% {
    transform: translate(-732px, -122px);
  }
  17% {
    transform: translate(-854px, -122px);
  }
  18% {
    transform: translate(-976px, -122px);
  }
  19% {
    transform: translate(-1098px, -122px);
  }
  20% {
    transform: translate(0px, -244px);
  }
  21% {
    transform: translate(-122px, -244px);
  }
  22% {
    transform: translate(-244px, -244px);
  }
  23% {
    transform: translate(-366px, -244px);
  }
  24% {
    transform: translate(-488px, -244px);
  }
  25% {
    transform: translate(-610px, -244px);
  }
  26% {
    transform: translate(-732px, -244px);
  }
  27% {
    transform: translate(-854px, -244px);
  }
  28% {
    transform: translate(-976px, -244px);
  }
  29% {
    transform: translate(-1098px, -244px);
  }
  30% {
    transform: translate(0px, -366px);
  }
  31% {
    transform: translate(-122px, -366px);
  }
  32% {
    transform: translate(-244px, -366px);
  }
  33% {
    transform: translate(-366px, -366px);
  }
  34% {
    transform: translate(-488px, -366px);
  }
  35% {
    transform: translate(-610px, -366px);
  }
  36% {
    transform: translate(-732px, -366px);
  }
  37% {
    transform: translate(-854px, -366px);
  }
  38% {
    transform: translate(-976px, -366px);
  }
  39% {
    transform: translate(-1098px, -366px);
  }
  40% {
    transform: translate(0px, -488px);
  }
  41% {
    transform: translate(-122px, -488px);
  }
  42% {
    transform: translate(-244px, -488px);
  }
  43% {
    transform: translate(-366px, -488px);
  }
  44% {
    transform: translate(-488px, -488px);
  }
  45% {
    transform: translate(-610px, -488px);
  }
  46% {
    transform: translate(-732px, -488px);
  }
  47% {
    transform: translate(-854px, -488px);
  }
  48% {
    transform: translate(-976px, -488px);
  }
  49% {
    transform: translate(-1098px, -488px);
  }
  50% {
    transform: translate(0px, -610px);
  }
  51% {
    transform: translate(-122px, -610px);
  }
  52% {
    transform: translate(-244px, -610px);
  }
  53% {
    transform: translate(-366px, -610px);
  }
  54% {
    transform: translate(-488px, -610px);
  }
  55% {
    transform: translate(-610px, -610px);
  }
  56% {
    transform: translate(-732px, -610px);
  }
  57% {
    transform: translate(-854px, -610px);
  }
  58% {
    transform: translate(-976px, -610px);
  }
  59% {
    transform: translate(-1098px, -610px);
  }
  60% {
    transform: translate(0px, -732px);
  }
  61% {
    transform: translate(-122px, -732px);
  }
  62% {
    transform: translate(-244px, -732px);
  }
  63% {
    transform: translate(-366px, -732px);
  }
  64% {
    transform: translate(-488px, -732px);
  }
  65% {
    transform: translate(-610px, -732px);
  }
  66% {
    transform: translate(-732px, -732px);
  }
  67% {
    transform: translate(-854px, -732px);
  }
  68% {
    transform: translate(-976px, -732px);
  }
  69% {
    transform: translate(-1098px, -732px);
  }
  70% {
    transform: translate(0px, -854px);
  }
  71% {
    transform: translate(-122px, -854px);
  }
  72% {
    transform: translate(-244px, -854px);
  }
  73% {
    transform: translate(-366px, -854px);
  }
  74% {
    transform: translate(-488px, -854px);
  }
  75% {
    transform: translate(-610px, -854px);
  }
  76% {
    transform: translate(-732px, -854px);
  }
  77% {
    transform: translate(-854px, -854px);
  }
  78% {
    transform: translate(-976px, -854px);
  }
  79% {
    transform: translate(-1098px, -854px);
  }
  80% {
    transform: translate(0px, -976px);
  }
  81% {
    transform: translate(-122px, -976px);
  }
  82% {
    transform: translate(-244px, -976px);
  }
  83% {
    transform: translate(-366px, -976px);
  }
  84% {
    transform: translate(-488px, -976px);
  }
  85% {
    transform: translate(-610px, -976px);
  }
  86% {
    transform: translate(-732px, -976px);
  }
  87% {
    transform: translate(-854px, -976px);
  }
  88% {
    transform: translate(-976px, -976px);
  }
  89% {
    transform: translate(-1098px, -976px);
  }
  90% {
    transform: translate(0px, -1098px);
  }
  91% {
    transform: translate(-122px, -1098px);
  }
  92% {
    transform: translate(-244px, -1098px);
  }
  93% {
    transform: translate(-366px, -1098px);
  }
  94% {
    transform: translate(-488px, -1098px);
  }
  95% {
    transform: translate(-610px, -1098px);
  }
  96% {
    transform: translate(-732px, -1098px);
  }
  97% {
    transform: translate(-854px, -1098px);
  }
  98% {
    transform: translate(-976px, -1098px);
  }
  99% {
    transform: translate(-1098px, -1098px);
  }
  100% {
    transform: translate(-1220px, -1220px);
  }
}

@keyframes animatedFrameWidth123Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-123px, 0px);
  }
  2% {
    transform: translate(-246px, 0px);
  }
  3% {
    transform: translate(-369px, 0px);
  }
  4% {
    transform: translate(-492px, 0px);
  }
  5% {
    transform: translate(-615px, 0px);
  }
  6% {
    transform: translate(-738px, 0px);
  }
  7% {
    transform: translate(-861px, 0px);
  }
  8% {
    transform: translate(-984px, 0px);
  }
  9% {
    transform: translate(-1107px, 0px);
  }
  10% {
    transform: translate(0px, -123px);
  }
  11% {
    transform: translate(-123px, -123px);
  }
  12% {
    transform: translate(-246px, -123px);
  }
  13% {
    transform: translate(-369px, -123px);
  }
  14% {
    transform: translate(-492px, -123px);
  }
  15% {
    transform: translate(-615px, -123px);
  }
  16% {
    transform: translate(-738px, -123px);
  }
  17% {
    transform: translate(-861px, -123px);
  }
  18% {
    transform: translate(-984px, -123px);
  }
  19% {
    transform: translate(-1107px, -123px);
  }
  20% {
    transform: translate(0px, -246px);
  }
  21% {
    transform: translate(-123px, -246px);
  }
  22% {
    transform: translate(-246px, -246px);
  }
  23% {
    transform: translate(-369px, -246px);
  }
  24% {
    transform: translate(-492px, -246px);
  }
  25% {
    transform: translate(-615px, -246px);
  }
  26% {
    transform: translate(-738px, -246px);
  }
  27% {
    transform: translate(-861px, -246px);
  }
  28% {
    transform: translate(-984px, -246px);
  }
  29% {
    transform: translate(-1107px, -246px);
  }
  30% {
    transform: translate(0px, -369px);
  }
  31% {
    transform: translate(-123px, -369px);
  }
  32% {
    transform: translate(-246px, -369px);
  }
  33% {
    transform: translate(-369px, -369px);
  }
  34% {
    transform: translate(-492px, -369px);
  }
  35% {
    transform: translate(-615px, -369px);
  }
  36% {
    transform: translate(-738px, -369px);
  }
  37% {
    transform: translate(-861px, -369px);
  }
  38% {
    transform: translate(-984px, -369px);
  }
  39% {
    transform: translate(-1107px, -369px);
  }
  40% {
    transform: translate(0px, -492px);
  }
  41% {
    transform: translate(-123px, -492px);
  }
  42% {
    transform: translate(-246px, -492px);
  }
  43% {
    transform: translate(-369px, -492px);
  }
  44% {
    transform: translate(-492px, -492px);
  }
  45% {
    transform: translate(-615px, -492px);
  }
  46% {
    transform: translate(-738px, -492px);
  }
  47% {
    transform: translate(-861px, -492px);
  }
  48% {
    transform: translate(-984px, -492px);
  }
  49% {
    transform: translate(-1107px, -492px);
  }
  50% {
    transform: translate(0px, -615px);
  }
  51% {
    transform: translate(-123px, -615px);
  }
  52% {
    transform: translate(-246px, -615px);
  }
  53% {
    transform: translate(-369px, -615px);
  }
  54% {
    transform: translate(-492px, -615px);
  }
  55% {
    transform: translate(-615px, -615px);
  }
  56% {
    transform: translate(-738px, -615px);
  }
  57% {
    transform: translate(-861px, -615px);
  }
  58% {
    transform: translate(-984px, -615px);
  }
  59% {
    transform: translate(-1107px, -615px);
  }
  60% {
    transform: translate(0px, -738px);
  }
  61% {
    transform: translate(-123px, -738px);
  }
  62% {
    transform: translate(-246px, -738px);
  }
  63% {
    transform: translate(-369px, -738px);
  }
  64% {
    transform: translate(-492px, -738px);
  }
  65% {
    transform: translate(-615px, -738px);
  }
  66% {
    transform: translate(-738px, -738px);
  }
  67% {
    transform: translate(-861px, -738px);
  }
  68% {
    transform: translate(-984px, -738px);
  }
  69% {
    transform: translate(-1107px, -738px);
  }
  70% {
    transform: translate(0px, -861px);
  }
  71% {
    transform: translate(-123px, -861px);
  }
  72% {
    transform: translate(-246px, -861px);
  }
  73% {
    transform: translate(-369px, -861px);
  }
  74% {
    transform: translate(-492px, -861px);
  }
  75% {
    transform: translate(-615px, -861px);
  }
  76% {
    transform: translate(-738px, -861px);
  }
  77% {
    transform: translate(-861px, -861px);
  }
  78% {
    transform: translate(-984px, -861px);
  }
  79% {
    transform: translate(-1107px, -861px);
  }
  80% {
    transform: translate(0px, -984px);
  }
  81% {
    transform: translate(-123px, -984px);
  }
  82% {
    transform: translate(-246px, -984px);
  }
  83% {
    transform: translate(-369px, -984px);
  }
  84% {
    transform: translate(-492px, -984px);
  }
  85% {
    transform: translate(-615px, -984px);
  }
  86% {
    transform: translate(-738px, -984px);
  }
  87% {
    transform: translate(-861px, -984px);
  }
  88% {
    transform: translate(-984px, -984px);
  }
  89% {
    transform: translate(-1107px, -984px);
  }
  90% {
    transform: translate(0px, -1107px);
  }
  91% {
    transform: translate(-123px, -1107px);
  }
  92% {
    transform: translate(-246px, -1107px);
  }
  93% {
    transform: translate(-369px, -1107px);
  }
  94% {
    transform: translate(-492px, -1107px);
  }
  95% {
    transform: translate(-615px, -1107px);
  }
  96% {
    transform: translate(-738px, -1107px);
  }
  97% {
    transform: translate(-861px, -1107px);
  }
  98% {
    transform: translate(-984px, -1107px);
  }
  99% {
    transform: translate(-1107px, -1107px);
  }
  100% {
    transform: translate(-1230px, -1230px);
  }
}

@keyframes animatedFrameWidth124Frame10x10 {
  0% {
    transform: translate(0px, 0px);
  }
  1% {
    transform: translate(-124px, 0px);
  }
  2% {
    transform: translate(-248px, 0px);
  }
  3% {
    transform: translate(-372px, 0px);
  }
  4% {
    transform: translate(-496px, 0px);
  }
  5% {
    transform: translate(-620px, 0px);
  }
  6% {
    transform: translate(-744px, 0px);
  }
  7% {
    transform: translate(-868px, 0px);
  }
  8% {
    transform: translate(-992px, 0px);
  }
  9% {
    transform: translate(-1116px, 0px);
  }
  10% {
    transform: translate(0px, -124px);
  }
  11% {
    transform: translate(-124px, -124px);
  }
  12% {
    transform: translate(-248px, -124px);
  }
  13% {
    transform: translate(-372px, -124px);
  }
  14% {
    transform: translate(-496px, -124px);
  }
  15% {
    transform: translate(-620px, -124px);
  }
  16% {
    transform: translate(-744px, -124px);
  }
  17% {
    transform: translate(-868px, -124px);
  }
  18% {
    transform: translate(-992px, -124px);
  }
  19% {
    transform: translate(-1116px, -124px);
  }
  20% {
    transform: translate(0px, -248px);
  }
  21% {
    transform: translate(-124px, -248px);
  }
  22% {
    transform: translate(-248px, -248px);
  }
  23% {
    transform: translate(-372px, -248px);
  }
  24% {
    transform: translate(-496px, -248px);
  }
  25% {
    transform: translate(-620px, -248px);
  }
  26% {
    transform: translate(-744px, -248px);
  }
  27% {
    transform: translate(-868px, -248px);
  }
  28% {
    transform: translate(-992px, -248px);
  }
  29% {
    transform: translate(-1116px, -248px);
  }
  30% {
    transform: translate(0px, -372px);
  }
  31% {
    transform: translate(-124px, -372px);
  }
  32% {
    transform: translate(-248px, -372px);
  }
  33% {
    transform: translate(-372px, -372px);
  }
  34% {
    transform: translate(-496px, -372px);
  }
  35% {
    transform: translate(-620px, -372px);
  }
  36% {
    transform: translate(-744px, -372px);
  }
  37% {
    transform: translate(-868px, -372px);
  }
  38% {
    transform: translate(-992px, -372px);
  }
  39% {
    transform: translate(-1116px, -372px);
  }
  40% {
    transform: translate(0px, -496px);
  }
  41% {
    transform: translate(-124px, -496px);
  }
  42% {
    transform: translate(-248px, -496px);
  }
  43% {
    transform: translate(-372px, -496px);
  }
  44% {
    transform: translate(-496px, -496px);
  }
  45% {
    transform: translate(-620px, -496px);
  }
  46% {
    transform: translate(-744px, -496px);
  }
  47% {
    transform: translate(-868px, -496px);
  }
  48% {
    transform: translate(-992px, -496px);
  }
  49% {
    transform: translate(-1116px, -496px);
  }
  50% {
    transform: translate(0px, -620px);
  }
  51% {
    transform: translate(-124px, -620px);
  }
  52% {
    transform: translate(-248px, -620px);
  }
  53% {
    transform: translate(-372px, -620px);
  }
  54% {
    transform: translate(-496px, -620px);
  }
  55% {
    transform: translate(-620px, -620px);
  }
  56% {
    transform: translate(-744px, -620px);
  }
  57% {
    transform: translate(-868px, -620px);
  }
  58% {
    transform: translate(-992px, -620px);
  }
  59% {
    transform: translate(-1116px, -620px);
  }
  60% {
    transform: translate(0px, -744px);
  }
  61% {
    transform: translate(-124px, -744px);
  }
  62% {
    transform: translate(-248px, -744px);
  }
  63% {
    transform: translate(-372px, -744px);
  }
  64% {
    transform: translate(-496px, -744px);
  }
  65% {
    transform: translate(-620px, -744px);
  }
  66% {
    transform: translate(-744px, -744px);
  }
  67% {
    transform: translate(-868px, -744px);
  }
  68% {
    transform: translate(-992px, -744px);
  }
  69% {
    transform: translate(-1116px, -744px);
  }
  70% {
    transform: translate(0px, -868px);
  }
  71% {
    transform: translate(-124px, -868px);
  }
  72% {
    transform: translate(-248px, -868px);
  }
  73% {
    transform: translate(-372px, -868px);
  }
  74% {
    transform: translate(-496px, -868px);
  }
  75% {
    transform: translate(-620px, -868px);
  }
  76% {
    transform: translate(-744px, -868px);
  }
  77% {
    transform: translate(-868px, -868px);
  }
  78% {
    transform: translate(-992px, -868px);
  }
  79% {
    transform: translate(-1116px, -868px);
  }
  80% {
    transform: translate(0px, -992px);
  }
  81% {
    transform: translate(-124px, -992px);
  }
  82% {
    transform: translate(-248px, -992px);
  }
  83% {
    transform: translate(-372px, -992px);
  }
  84% {
    transform: translate(-496px, -992px);
  }
  85% {
    transform: translate(-620px, -992px);
  }
  86% {
    transform: translate(-744px, -992px);
  }
  87% {
    transform: translate(-868px, -992px);
  }
  88% {
    transform: translate(-992px, -992px);
  }
  89% {
    transform: translate(-1116px, -992px);
  }
  90% {
    transform: translate(0px, -1116px);
  }
  91% {
    transform: translate(-124px, -1116px);
  }
  92% {
    transform: translate(-248px, -1116px);
  }
  93% {
    transform: translate(-372px, -1116px);
  }
  94% {
    transform: translate(-496px, -1116px);
  }
  95% {
    transform: translate(-620px, -1116px);
  }
  96% {
    transform: translate(-744px, -1116px);
  }
  97% {
    transform: translate(-868px, -1116px);
  }
  98% {
    transform: translate(-992px, -1116px);
  }
  99% {
    transform: translate(-1116px, -1116px);
  }
  100% {
    transform: translate(-1240px, -1240px);
  }
}
