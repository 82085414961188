h2.levelTotal {
  font-size: 0.9375rem !important; /* important overrides the sidebar h2 font size */
  font-weight: bold;
  margin-bottom: 1.25rem;
}

.withDivider {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 0.0625rem solid var(--theme-transparent);
}

.xpBar {
  max-width: 21.875rem;
  margin: 0 auto;
}

.xpBar :global(.ddbc-xp-bar__item--cur),
.xpBar :global(.ddbc-xp-bar__item--next) {
  font-size: 0.875rem;
  font-family: var(--font-condensed);
  font-weight: bold;
}

.xpBar :global(.ddbc-xp-bar__item--progress) {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.xpBar :global(.ddbc-xp-bar__progress-inner) {
  height: 0.1875rem;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  max-width: 21.875rem;
}

.control {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  width: 50%;
}

.control.setXp {
  justify-content: flex-end;
}

.control select {
  width: 3.75rem;
  padding: 0.3125rem 0.625rem;
}
.control input[type="number"] {
  max-width: 5.625rem;
  padding: 0.3125rem 0.625rem;
}

.control .label {
  font-family: var(--font-condensed);
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 0.9375rem;
}

.adjustGroup {
  width: 100%;
  margin-top: 0.9375rem;
}

.adjustTabs {
  display: flex;
  margin-bottom: 0.9375rem;
}

button.tab {
  font-size: 0.75rem;
  border-radius: 0;
  border: none;
  font-weight: bold;
  border-bottom: 0.125rem solid transparent;
  padding: 0.1875rem 0;
  white-space: nowrap;
  margin-right: 0.625rem;
}

button.tab.active {
  border-bottom-color: var(--theme-color);
}
button.tab:not(.active),
button.tab.active:hover,
button.tab:not(.active):hover {
  color: var(--character-muted-color);
}

button.tab:focus-visible {
  border: 0.125rem solid var(--theme-color);
  border-radius: 0.25rem;
}

.adjustGroup input[type="number"] {
  padding: 0.3125rem 0.625rem;
}

.currentLevelAmounts {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  text-transform: uppercase;
  max-width: 21.875rem;
  margin: 0 auto;
}
