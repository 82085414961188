@import "@dndbeyond/ttui/components/Button/styles/Button.module.css";

.card {
  border: 0.063rem solid;
  border-color: color-mix(
    in srgb,
    var(--ttui_color-primary--main),
    transparent 75%
  );
  background: var(--ttui_color-bg--paper);
  position: relative;
}

.name {
  color: var(--ttui_color-primary--contrast);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Roboto Condensed";
  font-size: 1.625rem;
  font-weight: normal;
  letter-spacing: normal;
  margin: 0;
}

.cornerButton {
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.checkIcon {
  fill: var(--ttui_color-primary--contrast);
  width: 1rem;
  margin: 0;
}

.cornerButton.locked {
  background: var(--ttui_grey-300);
  border-color: var(--ttui_grey-300);
}

.cornerButton.locked:hover {
  background: var(--ttui_grey-200);
  border-color: var(--ttui_grey-200);
}

.cornerButton.locked:disabled:hover {
  background: var(--ttui_grey-300);
  border-color: var(--ttui_grey-300);
}

.cornerButton.locked svg:only-child {
  fill: var(--ttui_grey-700);
  width: 1rem;
  margin: 0;
}

.cornerButton.locked:disabled svg:only-child {
  fill: var(--ttui_grey-500);
}
