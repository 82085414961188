.ct-extras {
  &__empty {
    color: $ct--theme-de-emphasized-color;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    max-width: 250px;
    margin: 0 auto;
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-extras__empty {
    color: $ct--theme-dark-mode-text;
  }
}
