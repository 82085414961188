@import "@dndbeyond/ttui/components/LabelChip/LabelChip.module.css";

.unlockText {
  margin: 0;
}

.pills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  justify-content: center;
}

.pill {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
