.ct-character-header-mobile {
  color: #fff;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  font-family: $condensed;
  height: 72px;
  padding: 5px 8px;
  background-color: #333;

  &__group {
    &--gap {
      flex: 1;
    }

    &--summary {
      min-width: 82px;
    }

    &-tidbits {
      min-width: 0;
      margin-right: 8px;
    }
  }

  .ddbc-popout-menu {
    margin-left: 3px;
    margin-top: -21px;
    top: 100%;
    left: 10px;
  }
}
