%menu-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
}

$menu-icons: adventuresleague, dawn, downloadpdf, editcharacter, levelup,
  longrest, managexp, preferences, shortrest, builders, managelevel, backdrop,
  frame, theme, portrait, share;

@each $menu-icon in $menu-icons {
  .i-menu-#{$menu-icon} {
    @extend %menu-icon;
    background-image: url("#{$imageURL-local}/icons/menu_items/#{$menu-icon}.svg");
  }
}

.i-menu-longrest {
  background-size: 14px auto;
}

.i-menu-shortrest {
  position: relative;
  top: -1px;
}
