.ct-extra-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 8px 0;

  & + & {
    border-top: 1px dotted #eaeaea;
  }

  &__action {
    width: 40px;
  }

  &__preview {
    width: 35px;
  }

  &__primary {
    width: 130px;
  }

  &__ac {
    width: 35px;
    font-size: 16px;
    font-weight: bold;
    font-family: $condensed;

    &--dark-mode {
      color: #fff;
    }
  }

  &__hp {
    width: 85px;
    display: flex;
    padding-right: 10px;

    &--has-temp &-value {
      color: $ct--temp-hp-color;
    }

    &-sep {
      margin: 0 5px;
    }

    &-value {
      &--current {
        font-size: 16px;
        font-weight: bold;
        font-family: $condensed;
      }
      &--total {
        font-size: 16px;
        color: $ct--theme-de-emphasized-color;
        font-weight: bold;
        font-family: $condensed;
      }
    }

    &--dark-mode {
      color: #fff;

      .ct-extra-row__hp-value--total {
        color: $ct--theme-dark-mode-text;
      }
    }
  }

  &__speed {
    width: 50px;

    &-value {
      font-size: 16px;
      font-weight: bold;
    }
    &-callout {
      font-size: 10px;
      color: $ct--theme-de-emphasized-color;
      font-family: $condensed;
    }

    &--dark-mode {
      color: #fff;

      .ct-extra-row__speed-callout {
        color: $ct--theme-dark-mode-text;
      }
    }
  }

  &__notes {
    flex: 1;
    font-size: 12px;
    color: $ct--theme-de-emphasized-color;
    font-family: $condensed;
  }

  &__img {
    width: 30px;
    height: 30px;
    border-radius: 3px;
  }

  &__name {
    font-size: 14px;
    font-family: $condensed;

    &--dark-mode {
      color: #fff;
    }
  }

  &__meta {
    font-size: 10px;
    color: $ct--theme-de-emphasized-color;
    font-family: $condensed;

    &--dark-mode {
      color: $ct--theme-dark-mode-text;
    }

    &-item {
      & + &::before {
        content: $html-entity--bull;
        padding: 0 3px;
      }
    }
  }

  &__tooltip {
    font-size: 16px;
    font-weight: normal;
    color: $ct--theme-de-emphasized-color;

    &--dark-mode {
      color: $ct--theme-dark-mode-text;
    }
  }
}
