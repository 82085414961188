//have to overspecify to override defaults
%character-input {
  box-shadow: inset 0 0 4px 0 rgba(139, 178, 199, 0.48);
  border: 1px solid #d8dde3;
  background-color: #fff;
  border-radius: 0;
  font-family: $condensed;
  font-size: 14px;
  padding: 0 5px;
  line-height: 28px;

  &:hover,
  &:focus {
    background-color: #fff;
    border: 1px solid #d8dde3;
    box-shadow: inset 0 0 4px 0 rgba(139, 178, 199, 0.48);
  }
}

.character-sheet,
.ReactModal__Content {
  .character-input {
    @extend %character-input;

    &-block-oversized {
      @extend %character-input;
      padding: 10px;
      display: block;
      width: 100%;
    }
  }
}
