.ct-character-sheet-mobile {
  padding-top: 143px;

  &--dark-mode {
    background-color: $ct--theme-dark-mode-bg;
    min-height: 100vh;
  }

  &__header {
    position: fixed;
    top: 47px;
    left: 0;
    right: 0;
    z-index: 100;

    .ct-character-header-mobile {
      position: relative;
      z-index: 2;
    }

    .ct-combat-mobile {
      position: absolute;
      top: 72px;
      left: 0;
      right: 0;
      z-index: 1;
      transition: all 250ms 200ms;

      .ct-character-sheet--going-down & {
        top: 0;
      }
      .ct-character-sheet--going-up & {
        top: 72px;
      }
      .ct-character-sheet--near-top &,
      .ct-character-sheet--top & {
        top: 72px;
      }
    }
  }

  .ct-subsection {
    display: inline-flex;
    position: relative;
  }
}
