.container {
  display: flex;
  flex-direction: column;
  gap: 0.188rem;
}

.heal {
  color: var(--positive-color) !important;
}

.damage {
  color: var(--negative-color) !important;
}

.input {
  width: 4.375rem;
  text-align: center;
  background-color: var(--theme-background) !important;
  border-color: var(--theme-color) !important;
  color: var(--theme-contrast) !important;
}

.label {
  margin-bottom: 0.375rem;
  color: var(--character-muted-color);
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--font-condensed);
  height: 1em;
}

.content {
  font-size: 1.625rem;
  font-weight: 500;
  position: relative;
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .input {
    width: 3.75rem;
    height: 1.313rem;
  }

  .button {
    max-height: 25%;
  }
}
