//TODO change this bg image removal to <body> once back in main branch
.body-rpgcharacterbuilder {
  .page-header {
    display: none !important;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  .container {
    z-index: 0;
  }

  @media (max-width: ($bp-builder-mobile-transition - 1)) {
    &::before {
      display: none !important;
    }

    #site-main {
      background: #fff !important;

      &::before {
        display: none !important;
      }
    }

    .ddb-footer {
      display: none !important;
    }

    .container {
      position: static !important;
      -webkit-perspective: none !important;
      perspective: none !important;
      -webkit-transform: none !important;
      transform: none !important;
    }

    // Display arrows so that certain mobile keyboards can use them for negatives
    // on modifiers for ability scores
    input[type="number"]:focus {
      -moz-appearance: unset;
      -webkit-appearance: unset;
    }

    // fix problem with aria-hidden in character subsections
    &.hide-ads {
      .react-swipeable-view-container {
        [aria-hidden="true"] {
          display: block !important;
          visibility: visible !important;
        }
      }
    }

    #debug-bar {
      display: none !important;
    }
  }

  #content {
    padding: 0 !important;
    position: static !important;

    .primary-content {
      position: static !important;
    }
  }

  .ddbc-select {
    display: block;
    height: 40px;

    &-todo {
      border-color: $builder-default-color;
    }
  }

  .character-builder,
  .ReactModalPortal {
    font-size: 15px;
    line-height: 1.5;
  }

  .fullscreen-modal-overlay {
    z-index: 10000;
  }

  h4 {
    font-size: 16px;
  }
  h5,
  h6 {
    font-size: 14px;
    font-weight: bold;
  }

  table {
    border: 1px solid #edeae8;
    border-collapse: collapse;
    margin: 20px 0;
    width: 100%;
  }

  th {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    border: 1px solid #edeae8;
    border-bottom: 2px solid #d0cac5;
    text-align: left;
    background: #fff;
    color: #242527;
    line-height: 1.3;
  }

  td {
    border: 1px solid #edeae8;
    background: #fff;
    padding: 8px 20px;
    line-height: 1.3;
  }

  tr:nth-child(odd) {
    td {
      background-color: #faf8f7;
    }
  }

  //compendium table styles

  .table-overflow-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table.table-compendium {
    text-align: center;

    thead tr {
      background-color: var(--sb-table-header-bg, #fdfdfd);
    }

    thead th {
      background: inherit;
      text-align: center;

      &.table-column-width {
        &--10pct {
          width: 10%;
        }

        &--15pct {
          width: 15%;
        }

        &--20pct {
          width: 20%;
        }
      }
    }

    tr {
      transition: 0.3s;
    }

    td {
      line-height: 22px;
    }

    tbody th {
      border-bottom: none;
    }

    tr:nth-child(odd) :is(td, th),
    :is(td, th) {
      background-color: inherit;
      background: inherit;
    }

    tbody tr {
      &:nth-child(odd) {
        background-color: var(--sb-table-row-bg-light, #faf8f7);
      }

      &:nth-child(even) {
        background-color: var(--sb-table-row-bg-dark, #fdfdfd);
      }

      &:hover {
        background-color: var(--sb-table-row-bg-hover, #e3f4eb);
      }
    }

    // Equipment/Weapon table options
    &.table--weapons :is(th, td):nth-child(odd) {
      text-align: left;
    }

    &.table--equipment :is(th, td):first-child {
      width: 50%;
      text-align: left;
    }

    // Rollable table rules

    &.table--generic-dice :is(th, td):first-child,
    &.table--generic-dice-first :is(th, td):first-child,
    &.table--generic-dice-col2 :is(th, td):nth-child(2),
    &.table--generic-dice-col3 :is(th, td):nth-child(3),
    &.table--generic-dice-col4 :is(th, td):nth-child(4),
    &.table--generic-dice-col5 :is(th, td):nth-child(5),
    &.table--generic-dice-last :is(th, td):last-child {
      width: 12%;
      font-weight: bold;
    }

    &.table--generic-dice :is(th, td):nth-child(2) {
      text-align: left;
    }
    // Left aligned table rules
    &.table--left-all :is(th, td),
    &.table--left-first :is(th, td):first-child,
    &.table--left-col2 :is(th, td):nth-child(2),
    &.table--left-col3 :is(th, td):nth-child(3),
    &.table--left-col4 :is(th, td):nth-child(4),
    &.table--left-col5 :is(th, td):nth-child(5),
    &.table--left-last :is(th, td):last-child {
      text-align: left;
    }

    &.table--sub-features {
      thead th:first-of-type {
        width: 15%;
      }

      :is(thead, tbody) :is(th, td):nth-child(2) {
        text-align: left;
      }
    }

    // Table Captions
    caption {
      text-align: left;
      font-size: 1rem;
      font-weight: 700;
      margin: 8px 0;
      font-family: Roboto, Helvetica, sans-serif;
      line-height: 1.3;

      :is(h2, h3, h4, h5) {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    a:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: $bp-builder-mobile-transition) {
    .character-builder-inner {
      max-width: 680px;
      margin: 0 auto;
    }
  }

  @media (min-width: $bp-builder-mobile-transition) {
    background: url(#{$ct--ddb-image-url}/attachments/1/614/builder1k.jpg)
        no-repeat center 47px,
      url(#{$ct--ddb-image-url}/attachments/0/84/background_texture.png) #f9f9f9 !important;
  }

  @media (min-width: 1024px) {
    background: url(#{$ct--ddb-image-url}/attachments/1/614/builder1k.jpg)
        no-repeat center 114px,
      url(#{$ct--ddb-image-url}/attachments/0/84/background_texture.png) #f9f9f9 !important;
  }

  @media (min-width: 1921px) {
    background: url(#{$ct--ddb-image-url}/attachments/1/615/builder2k.jpg)
        no-repeat center 114px,
      url(#{$ct--ddb-image-url}/attachments/0/84/background_texture.png) #f9f9f9 !important;
  }

  @media (min-width: 2561px) {
    background: url(#{$ct--ddb-image-url}/attachments/1/616/builder4k.jpg)
        no-repeat center 114px,
      url(#{$ct--ddb-image-url}/attachments/0/84/background_texture.png) #f9f9f9 !important;
  }

  @media (min-width: $bp-sheet-desktop-transition) {
    #site #site-main .container {
      max-width: none !important;

      #content {
        padding: 0 !important;
      }
    }
  }
}

.character-builder {
  // Hiding ugly un-formatted html
  // this class is applied to creature/vehicle plain text stat blocks that render within compendium pages
  .basic-text-frame {
    display: none;
  }

  blockquote {
    padding: 12px 14px 2px;
    margin: 8px 2px 4px;
    border-bottom: 4px solid #89929973;
    border-top: 4px solid #89929973;
    background-color: #fafafb;
    border-left: 1px solid #f4f2f2;
    border-right: 1px solid #f4f2f2;
  }

  &-inner {
    position: relative;
    padding-top: 5px;
  }

  &-step_by_step {
    padding-top: $builder-sections-height;
    padding-bottom: $todo-nav-height;
  }

  &-page-header {
    display: none;
  }

  &-anon {
    padding: 35px 0;
    border-bottom: 1px solid #d8d8d8;
    margin: 15px;

    &-heading {
      font-size: 36px;
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
    }

    &-content {
      text-align: center;
      max-width: 510px;
      margin: 5px auto 35px;
    }

    &-actions {
      margin: 0 auto 15px;
      text-align: center;
    }
  }

  span[class*="spellName"],
  span[class*="numberDisplay"] {
    color: var(--ttui_grey-900);
  }

  span[class*="spellName"] > span,
  span[class*="numberDisplay"] > [class*="label"] {
    color: var(--ttui_grey-500);
  }

  [class*="InfoItem"][role="listitem"] > p {
    color: var(--ttui_grey-900);
  }

  @media (min-width: $bp-builder-mobile-transition) {
    &-step_by_step {
      padding-top: 0;
      padding-bottom: 0;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 75px;
        background-color: rgba(0, 0, 0, 0.85);
      }
    }

    &-anon {
      padding: 35px;

      &-actions {
        max-width: 320px;
      }
    }
  }

  @media (min-width: 1240px) {
    &-page-header {
      position: absolute;
      z-index: 9;
      color: #fff;
      left: -250px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 210px;

      & &-heading {
        color: #fff;
        font-size: 30px;
        margin: 0;
        line-height: 1;
        font-family: $condensed;
        font-weight: normal;
      }

      &-summary {
        display: flex;
      }

      &-name {
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
        color: #ccc;
      }

      &-avatar {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border: 1px solid #555;
      }
    }
  }
}

.builder-page {
  padding: 15px;

  &-body {
    &-character-name {
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }

  &-header {
    font-family: $font-family;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

  &-subheader {
    font-family: $font-family;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: $bp-builder-mobile-transition) {
    &-header {
      text-align: left;
    }

    &-subheader {
      text-align: left;
    }
  }

  // Compendium Rules Sidebars - example is the item Spelljamming Helm - its the scroll on the bottom of the description
  .text--rules-sidebar {
    display: flex;
    background-color: var(--sb-rules-sidebar, #dae4c1);
    position: relative;
    width: auto;
    flex-direction: column;
    overflow: visible;
    margin: 30px 5px;
    line-height: 1.6;
    font-size: 0.875rem;
    padding: 20px 10px 25px 15px;
    border-radius: 0;
    border-top: 3px solid #231f20;
    border-bottom: 3px solid #231f20;
    border-left: 1.5px solid #b3b3b3;
    border-right: 1.5px solid #b3b3b3;
    filter: drop-shadow(0px 5px 8px #ccc);

    p:first-of-type {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
    }

    p {
      margin: 0;
    }

    p + p {
      margin: 12px 0 0;
    }

    &::before {
      content: "";
      background-image: url("https://media.dndbeyond.com/compendium-images/components/--right-rules.svg"),
        url("https://media.dndbeyond.com/compendium-images/components/--left-rules.svg");
      background-position: left, right;
      background-size: contain;
      background-repeat: no-repeat;
      height: 11px;
      position: absolute;
      top: -13px;
      right: 0.1px;
      left: 0.1px;
      z-index: -1;
    }

    &::after {
      content: "";
      background-image: url("https://media.dndbeyond.com/compendium-images/components/--right-rules.svg"),
        url("https://media.dndbeyond.com/compendium-images/components/--left-rules.svg");
      background-position: left, right;
      background-size: contain;
      background-repeat: no-repeat;
      height: 11px;
      position: absolute;
      bottom: -13px;
      right: -0.1px;
      left: 0.1px;
      z-index: -1;
      transform: scaleY(-1);
    }
  }
}
