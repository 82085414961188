.ct-action-detail {
  &__limited-uses {
    @include sidebarSeparator();
    display: flex;
    align-items: center;

    &-label {
      font-weight: bold;
      font-family: $condensed;
      margin-right: 5px;
    }
  }

  &__customize {
    @include sidebarSeparator();

    .ct-editor-box {
      margin: 5px 0 10px;
    }
  }

  &__properties {
    @include sidebarSeparator();
  }

  &__description {
    @include sidebarSeparator();
  }

  &__marketplace-cta {
    margin-top: 10px;
  }

  &__actions {
    @include sidebarSeparator();
    display: flex;
    justify-content: center;
  }

  &__range {
    &-shape {
      vertical-align: top;
      display: inline-flex;
    }

    &-icon {
      top: 0;
      margin-right: 0;
    }
  }
}
