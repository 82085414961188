.container {
  display: flex;
  flex-direction: column;
}

.label {
  margin: 0;
}

.marks {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.mark {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.063rem solid #cbc6c3;
  box-shadow: inset 0 0 0.25rem 0 #dcd2c7;
}

.mark::before {
  background-size: 4.813rem 1.5rem;
  content: "";
  cursor: pointer;
  height: 1.5rem;
  margin: 0.438rem auto 0;
  width: 1.5rem;
}

.activeMark::before {
  background-position: -3.313rem 0;
  display: block;
}

.willBeActive::before {
  display: block;
  background-position: -3.313rem 0;
  opacity: 0.5;
}

.willBeFail::before {
  background: rgba(188, 16, 16, 0.5);
  border-radius: 50%;
}
