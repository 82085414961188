.ct-actions-mobile {
  .ddbc-combat-attack__name {
    flex: 1;
    min-width: 90px;
  }

  [class*="styles_tabFilter"] {
    padding: 10px;
  }
}
