.ct-feature-snippet {
  cursor: pointer;
  margin-bottom: 13px;

  &__heading {
    font-weight: bold;
    font-family: $condensed;
    line-height: 1.2;
    &--dark-mode {
      color: white;
    }

    &-activation {
      font-weight: normal;
      &--dark-mode {
        color: white;
      }
    }

    &-extra {
      margin-left: 5px;
      font-weight: normal;
      color: $ct--theme-de-emphasized-color;
      font-size: 12px;
      font-family: $font-family;
      &--dark-mode {
        color: $ct--theme-dark-mode-text;
      }
    }
  }

  &__meta {
    &-item {
      font-weight: normal;
      font-family: $font-family;
      font-size: 11px;
      display: inline-block;
      vertical-align: middle;

      &::before {
        content: "\2022";
        padding: 0 3px;
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        color: $ct--theme-de-emphasized-color;
      }

      &--dark-mode {
        color: $ct--theme-dark-mode-text;
      }
    }
  }

  &__content {
    font-size: 12px;
  }

  &__extra {
    @include featureSnippetGrouping();
    font-size: 12px;
  }

  &__options {
    &-extra {
      border-left: 3px solid #d8d8d8;
      padding-left: 8px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left: 4px;
    }
  }

  &__option {
    font-size: 12px;
  }

  &__spell {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;

    &-summary {
      margin-right: 5px;
    }
    &--dark-mode {
      color: white;
    }
  }

  &__action {
    &-limited {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-label {
        margin-right: 5px;
        &--dark-mode {
          color: white;
        }
      }
    }
    &-summary {
      &--dark-mode {
        color: white;
      }
    }
  }

  &__limited-use {
    display: flex;
    align-items: center;

    &-usages {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      margin-right: 5px;
      font-weight: bold;
    }

    &-usage {
      & + & {
        margin-left: 3px;
      }
    }

    &-sep {
      color: $ct--theme-de-emphasized-color;
      margin-right: 5px;
    }

    &-extra {
      width: 100%;
    }

    &--dark-mode {
      color: white;
    }
  }

  &__spells--layout-compact &__spell {
    display: inline-flex;

    &-summary {
      margin-right: 0;
    }

    &-sep {
      margin-right: 5px;
    }
  }

  &__choice {
    &--dark-mode {
      color: white;
    }
  }
}
