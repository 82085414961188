.ct-currency-pane {
  &__subheader {
    font-size: 13px;
    font-family: $condensed;
    font-weight: 700;
    margin: 8px 0;
    padding: 6px 0;
    text-transform: uppercase;
    border-bottom: 1px solid $ct--theme-border-color;
  }

  &__collapsible-callout {
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    &-icon {
      width: 16px;
      margin-left: 6px;
    }
  }

  &__total {
    margin: 10px 0 15px;
    text-align: center;

    @include sidebarSeparatorBottom(10px, 0px);
    border-color: $ct--theme-color;

    &-heading {
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }

    &-count {
      font-size: 20px;
      font-weight: bold;
      font-family: $font-family;
      line-height: 1.1;
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
  }

  &__currency {
    padding: 10px 0;

    & + & {
      border-top: 1px dotted #eaeaea;
    }

    &-row {
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }

    &-info {
      flex: 1;
      min-width: 0;
      line-height: 1;
    }

    &-name {
      font-size: 15px;
      font-family: $font-family;
      line-height: 1.1;
    }

    &-conversion {
      color: $ct--theme-de-emphasized-color;
      font-size: 10px;
      font-family: $font-family;
    }

    &-value {
      // have to override extra properties because of generic site styles :(
      &-input[type="number"] {
        margin: 0;
        padding: 0;
        text-align: right;
        line-height: 28px;
        font-size: 15px;
        width: 100px;
      }

      &-text {
        font-size: 15px;
        font-weight: bold;
        font-family: $font-family;
        line-height: 28px;
        padding: 1px 12px 1px 0;
      }
    }

    &-error {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 10px;

      &-text {
        font-size: 12px;
        color: $ct--negative-color;
      }
    }
  }

  &__adjuster {
    @include sidebarSeparator();
    margin-bottom: 10px;
    margin-top: 0px;

    .ct-sidebar__subheading {
      margin-top: 0;
    }

    &-types {
      display: flex;
    }

    &-type {
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-labels {
        display: flex;
        align-items: center;
      }

      &:last-child {
        margin-right: 0;
      }

      &-value {
        margin-top: 5px;

        &-input[type="number"] {
          width: 100%;
          text-align: center;
        }
      }

      &-name {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        font-family: $condensed;
        margin-top: 3px;
        margin-left: 3px;
        color: $ct--theme-text-color;
      }
    }

    &-actions {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      &-button-content {
        display: flex;
        align-items: center;
        & > svg {
          margin-right: 3px;
        }
      }
    }

    &-action {
      margin: 0 5px;

      .ct-button {
        min-width: 60px;
      }

      &--positive {
        .ct-button {
          background-color: $ct--positive-color;

          &:hover,
          &:active,
          &:focus {
            background-color: darken($ct--positive-color, 10%);
            box-shadow: 0 0 10px 2px darken($ct--positive-color, 20%) inset;
          }
        }
      }
      &--negative {
        .ct-button {
          background-color: $ct--negative-color;

          &:hover,
          &:active,
          &:focus {
            background-color: darken($ct--negative-color, 10%);
            box-shadow: 0 0 10px 2px darken($ct--negative-color, 20%) inset;
          }
        }
      }
    }
  }

  &__lifestyle {
    @include sidebarSeparator();
    margin-bottom: 16px;

    &-detail {
      @include sidebarSeparator();
      @include sidebarSeparatorBottom();

      .ddbc-collapsible__header {
        justify-content: space-between;
      }

      &-header {
        font-weight: 500;
        padding-left: 8px;
      }
    }

    &-description {
      margin-top: 5px;
    }
  }
}

.ct-sidebar--is-dark-mode {
  .ct-currency-pane {
    &__subheader {
      color: $ct--theme-dark-mode-text;
    }
    &__adjuster-type-value-input,
    &__adjuster-type-value-input:hover {
      @include darkModeInput();
    }
    &__adjuster-type-name {
      color: $ct--theme-dark-mode-text;
    }
    &__adjuster-action--positive .ct-button {
      background-color: $ct--dark-mode-positive-color;
      color: $ct--theme-dark-mode-background-color;
      &:hover,
      &:active,
      &:focus {
        background-color: darken($ct--dark-mode-positive-color, 10%);
        box-shadow: 0 0 10px 2px darken($ct--dark-mode-positive-color, 20%)
          inset;
        color: $ct--theme-dark-mode-background-color;
      }
    }
    &__adjuster-action--negative .ct-button {
      background-color: $ct--dark-mode-negative-color;
      &:hover,
      &:active,
      &:focus {
        background-color: darken($ct--dark-mode-negative-color, 10%);
        box-shadow: 0 0 10px 2px darken($ct--dark-mode-negative-color, 20%)
          inset;
      }
    }
  }
}
