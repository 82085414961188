.rpgcharacter-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 760px) {
    justify-content: flex-start;
  }

  .no-results {
    margin: 0 auto;
  }
}

.ddb-campaigns-character-card {
  border: 1px solid #d1cdca;
  background: #fff;
  position: relative;

  &-wrapper {
    padding: 10px;
    width: 100%;
    max-width: 600px;

    @media (min-width: 760px) {
      width: 50%;
      max-width: 50%;
    }

    @media (min-width: 1100px) {
      width: percentage(1/3);
    }
  }

  &-header {
    position: relative;
    padding: 30px 20px;

    &-cover-image {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      &-user-backdrop {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(#000, 0.6);
        backdrop-filter: blur(2px);
      }
    }

    &-upper,
    &-lower {
      position: relative;
      z-index: 1;
    }

    &-upper {
      display: flex;
      align-items: center;

      &-details-link {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
      }

      &-portrait {
        flex: 0 0 auto;

        .image {
          border-radius: 3px;
          height: 60px;
          width: 60px;
          margin-right: 10px;

          &.user-selected-avatar {
            background-position: center center;
            background-size: cover;
          }
        }
      }

      &-character-info {
        flex: 1 1 100%;
        min-width: 0;
        padding-right: 10px;

        &-secondary {
          @include ellipsis();
          display: block;
          font-family: $condensed;
        }

        &-secondary {
          color: #bdbdbd;
        }
      }

      &-hit-points {
        margin-left: auto;
        flex: 0 0 auto;

        .hit-points {
          &-label {
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 9px;
            text-align: center;
          }

          &-wrapper {
            display: flex;
            justify-content: space-around;
            align-items: center;

            > div {
              color: #fff;
              font-size: 18px;
              text-align: center;
            }
          }
        }
      }
    }

    &-lower {
      padding-top: 20px;

      &-statblock {
        display: flex;
        justify-content: space-around;

        &-item {
          flex: 20%;

          &-label {
            color: #fff;
            font-size: 9px;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            max-width: 50px;
            margin: 0 auto;
          }

          &-value {
            color: #fff;
            font-size: 20px;
            text-align: center;

            .small {
              color: $grey;
              font-size: 11px;
              font-weight: bold;
              vertical-align: middle;
              margin: 0 2px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  &-body {
    padding: 20px;

    &-statblock {
      display: flex;
      flex-wrap: wrap;
      margin: -10px 0; //padding of statblock-item

      &-item {
        flex: percentage(1/3);
        padding: 10px 0;

        &-label {
          font-size: 9px;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
        }

        &-value {
          font-size: 20px;
          text-align: center;

          .score,
          .modifier {
            display: inline;
          }
        }

        &-strength {
          &-label {
            @include scoreIconAsBefore(strength, 11px);
          }

          .score {
            color: $color-strength;
          }
        }

        &-dexterity {
          &-label {
            @include scoreIconAsBefore(dexterity, 11px);
          }

          .score {
            color: $color-dexterity;
          }
        }

        &-constitution {
          &-label {
            @include scoreIconAsBefore(constitution, 11px);
          }

          .score {
            color: $color-constitution;
          }
        }

        &-intelligence {
          &-label {
            @include scoreIconAsBefore(intelligence, 11px);
          }

          .score {
            color: $color-intelligence;
          }
        }

        &-wisdom {
          &-label {
            @include scoreIconAsBefore(wisdom, 11px);
          }

          .score {
            color: $color-wisdom;
          }
        }

        &-charisma {
          &-label {
            @include scoreIconAsBefore(charisma, 11px);
          }

          .score {
            color: $color-charisma;
          }
        }
      }
    }
  }

  &-campaign-links {
    z-index: 1;
    position: absolute;
    top: 86px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
    background: #1c1d1e;
    border: 1px solid rgba(151, 154, 164, 0.4);

    &-campaign {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $grey;

      #site &-link {
        @include allLinkStates($color: $color-default);
      }
    }
    &-actions {
      width: 30%;
      margin-left: 3px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      @media (max-width: 400px) {
        flex: 1;
      }
    }
  }

  &-footer {
    border-top: 1px solid #dedede;
    padding: 0 20px;

    &-links {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-unlocking {
        color: #fff;
        font-size: 15px;
        background: #308e52;
        text-align: center;
        padding: 3px 15px;
        border-radius: 4px;
      }

      &-more {
        z-index: 10;

        &::before {
          background-image: url("#{$imageURL-local}/icons/sheet-icon-dark.svg");
        }

        .links {
          visibility: visible !important;
          display: flex !important;
          align-items: flex-start;
          flex-direction: row;
          flex: 0 1 auto;
          background: none;
          width: auto;
          z-index: 999;
          padding: 0;
          margin-left: auto;
          position: static;

          a {
            margin: 0;
            width: auto !important;
            padding: 0 15px;
            display: table !important;

            span {
              width: 100%;
              padding: 0;
            }

            &:not(:first-of-type) {
              margin: 0 0 0 10px !important;
            }
          }

          .button-alt-group-item {
            display: block !important;
            margin: 0 auto !important;
            padding: 0 15px !important;
            max-width: 250px;
            background: #242527;
            border-bottom: 1px solid #333;
            color: #979aa4;
            line-height: 33px;
            font-family: $condensed;
            font-weight: bold;
            font-size: 13px;
            text-transform: uppercase;
            white-space: nowrap;

            &:hover {
              color: #fff;
            }

            &:not(:first-of-type) {
              margin: 0 !important;
            }
          }
        }
      }

      #site &-item {
        text-transform: uppercase;
        font-family: $condensed;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
        @include allLinkStates($color: $color-default);

        &-edit,
        &-view,
        &-claim,
        &-more {
          display: flex;
          align-items: flex-start;

          &::before {
            content: "";
            height: 14px;
            width: 14px;
            display: inline-block;
            background-size: cover;
            margin-right: 3px;
          }
        }

        &-edit {
          &::before {
            background-image: url("#{$imageURL-local}/icons/menu_items/dark/builders.svg");
          }
        }

        &-view {
          &::before {
            background-image: url("#{$imageURL-local}/icons/sheet-icon-dark.svg");
          }
        }

        &-claim {
          &::before {
            background-image: url("#{$imageURL-local}/icons/flag-grey.svg");
          }
        }

        &-remove,
        &-delete,
        &-unclaim {
          @include allLinkStates($color: #c65854);
        }

        &-more {
          &::before {
            background-image: url("#{$imageURL-local}/icons/chevron-down-grey.svg");
          }
        }
      }
    }
  }

  .ddb-campaigns-detail-body-listing-inactive & {
    &-header,
    &-body {
      filter: saturate(0);
    }
  }

  &::after {
    // To override the default border-bottom of the waterdeep
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    z-index: -1;
  }
}
