.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.heading {
  align-items: center;
  justify-content: space-between;
}

.heading :global(.integrated-dice__container) {
  align-items: center;
  background: var(--theme-color);
  border: 0.063rem solid var(--theme-color);
  border-radius: 0.25rem;
  color: #fff;
  display: flex;
  font-size: 0.875rem;
  gap: 0.25rem;
  padding: 0.313rem 0.75rem;
  text-transform: uppercase;
}

.heading :global(.integrated-dice__container svg) {
  height: 1.125rem;
  width: 1.125rem;
}

.deathSavesGroups {
  align-items: center;
  justify-content: space-between;
  margin-top: 0.313rem;
}

.diceAdjustments {
  margin-top: 1rem;
}
