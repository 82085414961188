@import "@dndbeyond/ttui/components/Button/styles/ButtonVariants.module.css";

.searchSort {
  display: flex;
  border-top: 0.063rem solid var(--ttui_grey-400);
  border-radius: 0 0 0.188rem 0.188rem;
  background: var(--ttui_grey-100);
  padding: 1rem;
  margin-bottom: 0.75rem;
  flex-direction: column;
  gap: 1rem;
}

.search {
  position: relative;
  flex: 1;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.063rem 1.063rem;
  border: 0.063rem solid var(--ttui_grey-300);
  border-radius: 0.25rem;
  background: var(--ttui_common-0);
  box-sizing: border-box;
}

.search:hover {
  border-color: var(--ttui_grey-700);
}

.search:has(.searchInput:focus) {
  border: 0.125rem solid var(--ttui_grey-700);
  padding: 0 1rem;
}

.search svg {
  width: 1rem;
  height: auto;
  fill: var(--ttui_grey-500);
}

.searchLabel {
  display: block;
  position: absolute;
  top: -0.188rem;
  left: -0.625rem;
  padding: 0 0.25rem;
  font-size: 1rem;
  letter-spacing: 0.035rem;
  color: var(--ttui_grey-500);
  background: linear-gradient(
    to bottom,
    var(--ttui_grey-100) 50%,
    var(--ttui_common-0) 50%
  );
  transform: matrix(0.75, 0, 0, 0.75, 14, -9);
}

.search:has(.searchInput:focus) .searchLabel {
  top: -0.25rem;
  left: -0.6375rem;
}

.searchInput {
  font-size: 1rem;
  padding: 1rem 0;
  background: none;
  border: none;
  flex: 1;
}

.clearButton {
  background: none;
  border: none;
  padding: 0.25rem;
  margin: 0;
  cursor: pointer;
  line-height: 0;
}

.search .clearIcon {
  width: 1.25rem;
  height: auto;
}

.sort > label {
  display: block;
  position: absolute;
  top: 0;
  left: -0.625rem;
  padding: 0 0.25rem;
  font-size: 1rem;
  letter-spacing: 0.035rem;
  color: var(--ttui_grey-500);
  background: linear-gradient(
    to bottom,
    var(--ttui_grey-100) 50%,
    var(--ttui_common-0) 50%
  );
  transform: matrix(0.75, 0, 0, 0.75, 14, -9);
}

.sort > button {
  padding: 1.063rem;
  border: 0.063rem solid var(--ttui_grey-300);
}

.sort > button:hover {
  border-color: var(--ttui_grey-700);
}

.sort > button:focus {
  border: 0.125rem solid var(--ttui_grey-700);
  padding: 1rem;
}

.sort > div[role="group"] label {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.settingsButton,
.settingsButton:hover {
  background: var(--ttui_common-0);
  text-transform: unset;
  flex-shrink: 0;
}

.settingsButton {
  border-color: var(--ttui_grey-300);
}

.settingsButton:hover {
  border-color: var(--ttui_grey-700);
}

.settingsButton svg {
  width: 1.375rem;
}

.settingsButton path {
  fill: var(--ttui_color-primary--light);
}

.settingsButtonText {
  color: var(--ttui_color-primary--main);
}

@media screen and (min-width: 600px) {
  .searchSort {
    flex-direction: row;
  }
}
