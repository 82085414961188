.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  container-type: inline-size;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

.item {
  display: flex;
  flex-direction: column;
  position: sticky;
  align-items: center;
}

.spacer {
  min-height: 0.813rem;
  margin: 0 0 0.375rem;
}

.label {
  font-size: 0.813rem;
  color: var(--character-muted-color);
  font-family: var(--font-condensed);
  font-weight: 700;
  height: 1em;
  margin: 0 0 0.375rem;
  text-transform: uppercase;
}

.input {
  color: var(
    --theme-contrast
  ) !important; /* TODO: Re-evaluate the !importants here and refactor how input styles are being managed throughout the sheet. */
  border-color: var(--theme-color) !important;
  background-color: var(--ttui-grey-900) !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 4rem;
  padding: 0 0 0 0.188rem !important;
  font-size: 1.5rem;
  border-radius: 0 !important;
  text-align: center;
}

.input:focus,
.input:hover,
.input:active,
.input:focus-visible {
  box-shadow: 0 0 0.188rem 0.063rem var(--theme-color) !important;
}

.number {
  color: var(--theme-contrast);
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1.2;
  padding: 0;
}

.maxContainer {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.originalMax {
  font-size: 1.0625rem;
  font-weight: 400;
}

.positive {
  color: var(--positive-color);
}

.negative {
  color: var(--negative-color);
}

.slash {
  color: var(--character-muted-color);
  align-content: center;
}

.valueButton {
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;
}

.valueButton:focus-visible {
  outline: 0.063rem solid var(--theme-color);
  border-radius: 0.188rem;
}

.temp {
  width: 25%;
}

.tempEmpty {
  color: var(--character-muted-color);
}

@container (max-width: 184px) {
  .number {
    font-size: 1.375rem;
  }

  .label,
  .spacer {
    margin-bottom: 0.25rem;
  }

  .innerContainer {
    gap: 0.25rem;
  }

  .input {
    width: 3rem;
    font-size: 1.375em;
  }
}
