.ct-vehicle-fuel-tracker {
  &__summary {
    font-size: 13px;
    font-weight: bold;
    text-transform: none;
  }

  &__primary {
    margin: 15px 0;
    text-align: center;
    font-family: $condensed;

    &-label {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $ct--theme-de-emphasized-color;
    }

    &-input[type="number"] {
      background-color: #fff;
      text-align: center;
      font-family: $condensed;
      font-size: 28px;
      width: 100px;
    }
  }

  &__adjuster {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0;

    .ct-simple-quantity {
      margin: 0;
    }

    &-actions {
      display: flex;
      margin-left: 18px;
    }

    &-button {
      width: 50px;

      &--add {
        margin-left: 8px;
      }
    }
  }

  &__quick-actions {
    @include sidebarSeparator(20px);
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    &-group {
      flex: 1;
    }

    &-label {
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
      color: $ct--theme-de-emphasized-color;
    }

    &-button {
      font-size: 14px;
      letter-spacing: 1px;

      &-plus-svg {
        width: 18px;
        height: 18px;
      }

      &-label {
        vertical-align: top;
      }

      &-icon {
        &::before {
          content: "";
          display: inline-block;
          height: 16px;
          width: 16px;
          background-image: url("#{$imageURL-local}/sprites/plus_minus-white.svg");
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 8px;
        }
      }
      & + & {
        margin-top: 8px;
      }
    }
  }

  .ct-collapsible__header-callout-extra {
    font-size: 13px;
    font-weight: bold;
  }
}

.ct-sidebar--is-dark-mode {
  .ct-vehicle-fuel-tracker {
    &__primary-input,
    &__primary-input:hover {
      @include darkModeInput();
    }
  }
}
