.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0.063rem solid var(--ttui_grey-300);
  background-color: var(--ttui_common-0);
  padding: 0.5rem 0.625rem;
  margin: 0.8rem 0;
}

.detailContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem 0 1rem;
}

.text {
  font-size: 1rem;
  align-self: center;
}

.button {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
  }

  .detailContainer {
    padding: 0;
  }

  .button {
    width: initial;
    min-width: 14rem;
  }
}
