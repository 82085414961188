.ct-primary-box {
  width: 517px;
  height: 660px;
  position: relative;
  padding: 13px 20px;

  &__tab {
    &--actions,
    &--spells,
    &--equipment,
    &--features,
    &--description,
    &--notes,
    &--extras {
      min-height: 0;
      max-height: 100%;
      height: 100%;
    }
  }

  .ct-extras,
  .ct-creatures,
  .ct-equipment,
  .ct-spells,
  .ct-actions,
  .ct-features,
  .ct-description,
  .ct-notes {
    height: 600px;
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
    }

    &--dark-mode {
      & .dark-mode-secondary {
        color: $ct--theme-dark-mode-text;
      }
    }
  }

  .ct-extras-filter,
  .ct-creature-filter,
  .ct-spells-filter,
  .ct-inventory-filter {
    &--show-advanced {
      height: 550px;
      display: flex;
      flex-direction: column;
    }

    &__adv-filters {
      flex: 1;
      overflow-y: auto;
    }
  }

  @media (min-width: $bp-sheet-desktop-transition) and (max-width: ($bp-sheet-desktop-large-transition - 1px)) {
    .ct-attunement__group--items {
      padding-left: 10px;
    }

    .ct-spells-level__spells-col--name,
    .ct-spells-spell__name {
      width: 123px;
    }
  }

  @media (min-width: $bp-sheet-desktop-large-transition) {
    width: 623px;
  }
}
