.ct-character-header-desktop {
  color: #fff;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 300px;
  font-family: $condensed;
  position: relative;
  padding: 15px 5px 30px;
  height: 110px;

  &__group {
    &--gap {
      flex: 1;
    }

    &-tidbits {
      min-width: 0;
      margin-right: 8px;
    }

    &--campaign {
      display: flex;
      margin-right: 20px;
      .ct-character-header__group--game-log:hover {
        background-color: #111;
      }
    }
  }

  &__button {
    border: 1px solid $ct--theme-color;
    border-radius: 3px;
    padding: 5px 13px 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    font-family: $condensed;
    margin-right: 20px;
    white-space: nowrap;

    &:hover {
      background-color: #111;
    }

    &-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: middle;

      .ddbc-svg {
        width: 100%;
        height: 100%;
      }

      .ddbc-long-rest-svg {
        width: 14px;
      }

      .ddbc-short-rest-svg {
        position: relative;
        top: -1px;
      }

      .ddbc-gamelog-svg {
        fill: #fff;
      }
    }

    &-label {
      margin-left: 6px;
    }
  }

  &__builder {
    &-link {
      display: inline-flex;

      .ddbc-builder-svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .ddbc-popout-menu {
    margin-left: 3px;
    margin-top: -21px;
    top: 100%;
    left: 10px;
  }

  .ddbc-campaign-summary {
    border-radius: 3px 0 0 3px;
    &:hover {
      background-color: #111;
    }

    &__name {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 150px;
      white-space: nowrap;
      flex: 1;
    }
  }

  @media (min-width: $bp-sheet-desktop-large-transition) {
    height: 100px;
    padding: 10px 10px 15px;
  }
}
