@import "../../styles/vars";
@import "../../styles/mixins";

.my-characters-wrapper {
  position: relative;
  z-index: 0;
}

.ddbcl-my-characters-listing {
  .listing__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Things that never were buttons but always should've been
  // Still don't want them to actually _look_ like buttons though
  .ddb-characters-listing-actions__search-clear,
  .ddb-campaigns-character-card-footer-links-item {
    appearance: none;
    background: transparent;
    border: none;
  }

  .ddb-campaigns-character-card-footer-links-item {
    padding: 0;
  }

  .ddb-characters-listing__loading-indicator-wrapper {
    display: flex;
    justify-content: center;
  }

  &__version {
    font-size: 0.625rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .ddb-characters-listing-count-unlock-message__subscribe-link {
    @include button;

    display: inline-flex;
    padding: 6px 10px;
    width: auto;
    min-width: 0 !important;
    line-height: 1;
    height: auto !important;
  }

  .ddb-characters-listing-count-unlock__count {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 12px 0;
    font-size: 16px;
    font-weight: bold;
  }

  .ddb-characters-listing-count-unlock__finalize-button {
    margin: 12px auto;
  }

  .ddb-characters-listing-count-unlock__api-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px 12px;
  }

  ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
    &.listing {
      margin-bottom: 19.5px;
    }
  }
}

.ddb-characters-listing {
  &-header-secondary {
    justify-content: center;
  }
}

html body.body-mycontent-characterindex .container {
  max-width: 1200px !important;
}

html body.body-mycontent-characterindex header.page-header {
  display: none !important;
}

// mimic some waterdeep styles
html body.body-mycontent-characterindex .container {
  width: auto;
  margin: 0 auto;
}

#site-main section.atf {
  width: unset;
}

@media (min-width: 1024px) {
  html body.body-mycontent-characterindex header.page-header {
    display: block !important;
  }
}

html body.body-mycontent-characterindex .container .content {
  padding: 20px 10px !important;
}

@media (min-width: 1024px) {
  html body.body-mycontent-characterindex .container .content {
    padding: 15px 10px !important;
  }
}
