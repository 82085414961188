.ct-spells {
  &__casting {
    .ct-spells-level-casting {
      @include contentGroupSeparatorBottom();
    }
  }

  &__empty-spell-level {
    display: flex;
    align-items: center;
    text-align: center;
    color: $ct--theme-de-emphasized-color;
    max-width: 450px;
    margin: 0 auto;
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-spells__empty-spell-level {
    color: $ct--theme-dark-mode-text;
  }
}
