.prereq {
  margin-bottom: 0.625rem;
  padding-bottom: 0.625rem;
  border-bottom: 0.0625rem solid var(--theme-transparent);
  font-style: italic;
}

.choices :global(.detail-choice + .detail-choice) {
  margin-top: 0.3125rem;
}
.footer {
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  border-top: 0.0625rem solid var(--theme-transparent);
  display: flex;
  align-items: center;
  justify-content: center;
}
