.situationalBonus {
  margin-top: 0.5rem;
}

.situationalBonusIcon {
  display: inline-flex;
  vertical-align: middle;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.125rem;
  margin-top: -0.125rem;
}

.situationalBonusValue {
  font-weight: bold;
}

.situationalBonusRestriction {
  margin-left: 0.3125rem;
}

.icon {
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  margin-right: 0.3125rem;
}

.modifier {
  margin-left: 0.3125rem;
}

.editorBox {
  margin: 0.3125rem 0 0.625rem;
}

.description,
.customize,
.situational {
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  border-top: 0.063rem solid var(--theme-transparent);
}
