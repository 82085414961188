.ct-decoration-manager {
  &__group {
    & + & {
      margin-top: 20px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    overflow: hidden;
    width: 25%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;

    &--theme {
      width: percentage(1/3);
    }

    &--default {
      border: 2px solid $ct--theme-color;
      background-color: #ccc;
      border-radius: 3px;
      min-width: 60px;
      min-height: 60px;
      width: 100%;
      height: 100%;
    }

    &-inner {
      margin: 5px;
      cursor: pointer;
    }

    .ddbc-character-avatar__portrait {
      border: none;
    }

    .LazyLoad,
    &-img {
      width: 60px;
      height: 60px;
      border-radius: 3px;
      display: flex;
      align-items: center;
    }

    &-frame {
      max-width: 60px;
      max-height: 60px;
    }

    &-label {
      font-size: 12px;
      text-align: center;
      padding: 0 5px;
      line-height: 1.2;
    }

    &--current {
      position: relative;

      &::before {
        content: "";
        border: 2px solid $ct--builder-color;
        border-radius: 4px;
        box-shadow: 0 0 10px 2px lighten($ct--builder-color, 20%) inset;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
      &::after {
        content: "Current";
        background: $builder-default-color;
        color: #fff;
        font-size: 10px;
        position: absolute;
        top: 6px;
        left: -22px;
        width: 100%;
        padding: 2px;
        text-align: center;
        line-height: 1;
        transform: rotate(-40deg);
      }

      &-theme {
        &::after {
          top: 10px;
          left: -30px;
        }
      }
    }

    &--current &-label {
      font-weight: bold;
    }

    &--selected &-img {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.8),
        0 0 0 2px rgba(0, 0, 0, 0.3), 0 0 0 5px $character-default-color;
    }
  }

  // //TODO use LoadingPlaceholder instead of background img once we update to https://github.com/twobin/react-lazyload
  // .LazyLoad {
  //     background: #333 center center url('#{$imageURL-local}/character-sheet/loading-ring.svg') no-repeat;
  //     background-size: 35px;
  //     filter: none;

  //     &.is-visible {
  //         background: none;
  //     }
  // }

  &__upload {
    margin-bottom: 10px;

    &-heading {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 1.2;
      margin-bottom: 10px;

      &-rules {
        font-size: 10px;
        color: $ct--theme-de-emphasized-color;
      }
    }

    &-form {
      margin-bottom: 10px;
    }

    &-confirm {
      display: flex;
      align-items: center;
    }

    &-image {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      &-heading {
        background-color: $character-default-color;
        color: #fff;
        font-size: 10px;
        text-align: center;
        padding-top: 2px;
        line-height: 1;
        margin-bottom: 3px;
        border-radius: 3px 3px 0 0;
      }

      &-preview {
        height: 70px;
        width: 70px;
        border: none;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.8),
          0 0 0 2px rgba(0, 0, 0, 0.3), 0 0 0 5px $character-default-color;
      }
    }

    &-action {
      margin-left: 15px;
    }

    &-warning {
      background: #fffae1;
      padding: 10px;
      border: 1px solid #fbdf6d;
      text-align: center;
      color: #ccab00;
      font-weight: bold;
      align-items: center;
      line-height: 1.3;
      font-size: 14px;
    }
  }

  &__custom {
    margin-bottom: 10px;

    &-preview {
      width: 70px;
      height: 70px;
    }
  }
}
