.container {
  margin-top: 0.6rem !important;
}

.containerSidebar {
  margin-top: 0.6rem !important;
  padding-top: 0.8rem !important;
  border-top: 1px solid var(--theme-transparent) !important;
}

.containerBuilder {
  margin-top: 0 !important;
}

.heading {
  text-transform: uppercase;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 1.2em;
}

.headingBuilder {
  font-family: var(--font-condensed);
}

.headingSidebar {
  font-family: var(--font-condensed);
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
}

.headingBuilder {
  text-transform: none;
}

.headingSidebarDark {
  color: var(--ttui_grey-400);
}

.radioGroup {
  display: flex;
  flex-direction: column;
  margin-left: 0.4rem;
}

.radioGroup > label > span {
  align-self: flex-start;
}

.radioInfoGroup {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-top: 0.6rem;
}

.radioHeading {
  font-family: var(--font-default);
}

.radioHeadingBuilder {
  font-family: var(--font-family);
}

.radioHeadingSidebar {
  font-family: var(--font-family);
  font-size: 0.85em;
}

.radioDescription {
  font-family: var(--font-family);
  font-size: 0.8em;
  margin: 0;
  color: var(--ttui_grey-600);
}

.radioDescriptionSidebar {
  font-family: var(--font-family);
  font-size: 0.8em;
  color: var(--ttui_grey-600);
}

.radioDescriptionDark {
  color: var(--ttui_grey-400);
}

.buttonChecked > span {
  color: var(--ttui_color-info--main);
}

.buttonCheckedBuilder > span {
  color: var(--ttui_red-500);
}

.buttonChecked.themed > span {
  color: var(--theme-color);
}
