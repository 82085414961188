.ct-vehicle-health-adjuster {
  &__summary {
    font-size: 13px;
    font-weight: bold;

    &-sep {
      margin: 0 5px;
    }
  }

  &__groups {
    margin: 10px 0;
    display: flex;
    text-align: center;
    font-family: $condensed;
  }

  &__group {
    position: relative;
    flex: 1;

    &-label {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 3px;
    }

    &-value {
      font-size: 28px;
    }
  }

  .ct-health-adjuster {
    margin: 15px 0;
  }

  .ct-collapsible__header-callout-extra {
    font-size: 13px;
    font-weight: bold;
  }
}
