.ct-dice-adjustment-summary {
  margin: 5px 0;

  &__icon {
    width: 18px;
    height: 18px;
    display: inline-flex;
    vertical-align: middle;
    margin-top: -2px;
  }

  &__value {
    margin-left: 2px;
  }

  &__description {
    margin-left: 2px;

    &--ability {
      font-weight: bold;
      &--dark-mode {
        color: $ct--theme-dark-mode-text;
      }
    }
  }

  &__restriction {
    margin-left: 2px;
  }

  &__data-origin {
    margin-left: 5px;

    .ct-item-name {
      padding-right: 0;
    }
  }
}
