.ct-tablet-group {
  & + & {
    margin-top: 30px;
  }

  &__header {
    @include tabletContentSeparatorBottom(0);
    display: flex;
    align-items: center;

    &-content {
      font-family: $condensed;
      color: $ct--theme-color;
      font-weight: bold;
      text-transform: uppercase;
      flex: 1;
      min-width: 0;
    }

    &-extra {
    }
  }
}
