.ct-slot-manager {
  display: flex;
  flex-wrap: wrap;

  &__slot {
    height: 25px;
    width: 25px;
    min-width: 25px;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: inset 0 0 4px 0 #d8d8d8;
    margin: 4px;

    &--interactive {
      @include interactiveContent();
    }

    &--used {
      &::before {
        content: "";
        display: block;
        height: 13px;
        width: 13px;
        margin: 5px auto 0;
        background: $ct--theme-color;
      }
    }

    &--over-used {
      opacity: 0.5;
    }
  }

  &--size-small &__slot {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin: 2px;

    &--used {
      &::before {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        margin-top: 4px;
      }
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-slot-manager {
    &__slot {
      border-color: $ct--theme-dark-mode-text;
    }
  }
}
