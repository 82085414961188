.ct-filter {
  &__query[type="search"] {
    padding: 10px 10px 10px 35px;
    width: 100%;
    box-sizing: border-box;
    background: 10px center #fff url("#{$imageURL-local}/icons/search-grey.svg")
      no-repeat;
    background-size: 15px;
  }
}

.filter {
  &-constraints {
    margin-bottom: 10px;
  }

  &-constraint {
    &-query[type="search"] {
      padding: 10px 10px 10px 35px;
      width: 100%;
      box-sizing: border-box;
      background: 10px center #fff
        url("#{$imageURL-local}/icons/search-grey.svg") no-repeat;
      background-size: 15px;
    }
  }
}
