.ct-saving-throws-box {
  $sel--root: &;

  width: 281px;
  height: 200px;
  position: relative;
  padding: 13px 20px;

  &__abilities {
    position: relative;
  }

  &__modifiers {
    position: relative;
    font-size: 11px;
    height: calc(3.6em + 6px);
    overflow: hidden;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .ct-dice-adjustment-summary {
      margin: 0;
    }

    .ct-dice-adjustment-summary__icon {
      width: 14px;
      height: 14px;
      margin-right: 2px;
      margin-top: -2px;
    }

    &--empty {
      color: $ct--theme-de-emphasized-color;
      text-align: center;
      font-family: $condensed;
      font-size: 12px;
    }

    &--dark-mode {
      color: $ct--theme-dark-mode-text;
    }

    &--multi {
      #{$sel--root}__modifier {
        overflow: hidden;
      }

      .ct-dice-adjustment-summary {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 17px;
      }
    }
  }
}
