.ct-condition-manage-pane {
  &__condition {
    display: flex;
    align-items: center;
    width: 100%;

    &--special {
      @include sidebarSeparator();
    }

    &-heading {
      display: flex;
      align-items: center;
      padding: 10px 5px;
    }

    &-name {
      flex: 1;
      font-size: 14px;
    }

    &-preview {
      margin-right: 10px;
    }

    &-icon {
      vertical-align: top;
      margin-right: 0;
      opacity: 0.2;
    }

    &-toggle {
    }

    &-content {
      @include sidebarContentBox();
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }

  &__condition--active &__condition {
    &-name,
    &-level {
      font-weight: bold;
    }

    &-icon {
      opacity: 1;
    }
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-condition-manage-pane {
    &__condition {
      &-content {
        background-color: $ct--theme-dark-mode-secondary-bg;
        border-color: $ct--theme-dark-mode-secondary-bg;
        box-shadow: unset;
      }
      &-icon {
        opacity: 1;
      }
      &-name,
      &-level {
        color: #fff;
      }
    }
  }
}
