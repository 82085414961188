.physical {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr;
}

.physical [role] > p {
  opacity: 1;
}

.physical [role] > p:first-child {
  text-transform: uppercase;
  font-size: 0.7rem;
}

@media screen and (min-width: 768px) {
  .physical {
    grid-template-columns: 25% 20% 20% 20% 15%;
  }
}
