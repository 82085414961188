.container {
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.inputsContainer {
  max-width: 7.5rem;
  gap: 1rem;
}

.inputContainer {
  border-radius: 0.1875rem;
  font-family: Roboto Condensed, Roboto, Helvetica, sans-serif;
  margin: 0 auto;
  padding: 0.125rem 0.3125rem 0.3125rem;
  position: relative;
  text-align: center;
  width: 5.625rem;
}

.healingContainer {
  border: 0.0625rem solid var(--positive-color);
}

.damageContainer {
  border: 0.0625rem solid var(--negative-color);
}

.input {
  background: none !important;
  color: var(--theme-contrast) !important;
  border: none !important;
  box-shadow: none !important;
  font-family: var(--font-condensed) !important;
  font-size: 1.75rem;
  height: 1.625rem;
  padding: 0 !important;
  text-align: center;
  width: 100%;
}

.newValues {
  justify-content: center;
  gap: 1rem;
}

.modifyButtons {
  align-items: center;
  gap: 4rem;
}

.modifyButtons > button {
  padding: 0.5625rem;
}

.inputLabel {
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
}

.positiveColor,
.positiveColor > h2 {
  color: var(--positive-color) !important;
}

.negativeColor,
.negativeColor > h2 {
  color: var(--negative-color) !important;
}

.label {
  font-size: 0.813rem !important;
  color: var(--character-muted-color);
  font-family: var(--font-condensed);
  font-weight: 700;
  height: 1em;
  margin: 0 0 0.375rem;
  text-transform: uppercase;
}

.value {
  font-size: 1.75rem;
  font-family: var(--font-condensed);
  text-align: center;
  margin: 0;
}

.increase::before {
  background-image: url(https://www.dndbeyond.com/Content/Skins/Waterdeep/images/sprites/plus_minus-white.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
}

.decrease::before {
  background-image: url(https://www.dndbeyond.com/Content/Skins/Waterdeep/images/sprites/plus_minus-white.svg);
  background-position: -17px 0;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
}

.applyButtons {
  gap: 0.5rem;
  justify-content: center;
}

.applyButtons > button {
  font-size: 0.625rem !important;
  padding: 0.5625rem 0.9375rem !important;
}

.protectionNotice {
  text-align: center;
  display: flex;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.625rem;
}
