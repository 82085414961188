.ct-other-possessions {
  cursor: pointer;
  white-space: pre-wrap;

  &--no-content {
    color: $ct--theme-de-emphasized-color;
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-other-possessions--no-content {
    color: $ct--theme-dark-mode-text;
  }
}
