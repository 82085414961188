@import "@dndbeyond/ttui/components/Button/styles/ButtonVariants.module.css";

.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1rem;
}

.h1 {
  font-size: 2.5rem;
  line-height: 1.4;
  flex: 1;
  margin: 0;
  font-family: "Tiamat Condensed SC";
  letter-spacing: 0.063rem;
}

.button,
.button:visited {
  width: 100%;
  color: var(--ttui_color-primary--contrast);
  font-size: 1rem;
}

.subheader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.slots {
  flex: 1 1;
  margin: 0.75rem 0;
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 1.5rem;
}

.count {
  color: var(--ttui_color-info--main);
}

.noResultsTitle {
  text-align: center;
  color: var(--ttui_color-text--secondary);
  font-family: "Roboto Condensed";
  font-size: 1.625rem;
  letter-spacing: normal;
  margin: 0;
}

.noResultsText {
  font-size: 1rem;
  text-align: center;
  color: var(--ttui_color-text--secondary);
  margin: 0;
}

.deactivatedHeading {
  font-family: "Tiamat Condensed SC";
  font-size: 1.625rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.063rem solid var(--ttui_grey-200);
  margin-bottom: 1rem;
}

.deactivatedText {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  font-size: 1rem;
}

.deactivatedButton {
  padding: 0 0.25rem;
}

.pdfLinkWrapper {
  width: 212px;
  font-size: 0.75rem;
  text-align: center;
  margin: 0 0 1rem;
}

.pdfLink {
  text-decoration: underline;
  padding-right: 4px;
}

.pdfLinkSvg {
  width: 15px;
  height: 13px;
  margin-bottom: -3px;
}

@media screen and (min-width: 600px) {
  .header,
  .subheader {
    flex-direction: row;
  }

  .pdfLinkWrapper {
    margin: 0;
  }

  .button {
    width: auto;
    padding: 0.4rem 1.25rem;
  }

  .slots {
    margin-top: 0;
    text-align: left;
  }
}
