.listing {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.notFound {
  background: var(--ttui_grey-100);
  border: 0.125rem dashed var(--ttui_grey-200);
  color: var(--ttui_grey-500);
  font-weight: 700;
  padding: 0.625rem;
  text-align: center;
}
