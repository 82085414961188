.ct-equipment {
  &__overview {
    @include contentGroupSeparatorBottom();
  }

  &__container {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-name {
      color: $ct--theme-text-color;
    }

    &-quantity {
      color: $ct--theme-de-emphasized-color;
    }

    &-weight {
      display: flex;
      align-items: center;
      width: 135px;
      text-transform: none;
      color: $ct--theme-text-color;
      white-space: nowrap;
      font-family: $font-family;

      &-capacity {
        margin-left: 4px;
        font-weight: 400;
        color: $ct--theme-de-emphasized-color;
      }
    }

    &-equipped {
      color: $ct--theme-de-emphasized-color;
      font-weight: 400;
      font-family: $condensed;
      text-transform: none;
    }

    &-coin {
      display: flex;
      width: 185px;
      flex-direction: row-reverse;
    }
  }

  &__builder {
    &-link {
      &-text {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        font-family: $condensed;
      }

      .ddbc-builder-svg {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        vertical-align: text-top;
      }
    }
  }

  .ct-attunement {
    display: flex;

    &__group {
      &-items {
        padding-bottom: 8px;
      }

      &-empty {
        max-width: 250px;
      }

      &--equipped .ct-attunement__group-items {
        padding-top: 8px;
      }
    }
  }

  .ct-equipment-overview {
    margin-top: 7px;
  }

  .ct-other-possessions {
    font-size: 12px;
  }
}

.ct-character-sheet--dark-mode {
  .ct-equipment {
    &__container {
      &-name {
        color: white;
      }
      &-quantity,
      &-equipped {
        color: $ct--theme-dark-mode-text;
      }
    }
  }
}
