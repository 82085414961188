@import "@dndbeyond/ttui/components/Button/styles/Button.module.css";

.page {
  padding: 0.75rem 0.938rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}

.buttons {
  margin: 0.675rem 0;
  display: flex;
  align-items: center;
}

.navigateButton {
  margin-left: auto;
  font-size: 0.625rem;
  padding: 0.5625rem 0.9375rem;
  text-transform: uppercase;
  font-family: Roboto Condensed, Roboto, Helvetica, sans-serif;
  line-height: normal;
}

.search {
  margin-bottom: 0.5rem;
}

.accordion > div {
  padding: 0 1rem 1rem;
}

.accordion > summary > div > svg {
  fill: var(--ttui_grey-700);
  margin: unset;
  width: 1.25rem;
  height: 100%;
}

.accordion[class*="_text"] {
  border-bottom: 0.0625rem solid var(--ttui_grey-200);
  border-radius: 0;
  margin-bottom: 0;
}

.accordion > summary:focus-visible {
  border: 0.125rem solid var(--ttui_blue-400);
  border-radius: 0.5rem;
}

.heading {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.text {
  margin: 0;
  color: var(--ttui_grey-600);
}

.error {
  margin: 0;
  color: var(--ttui_red-500);
}

.multiclassMessage {
  margin-top: 0.5rem;
  color: var(--ttui_red-500);
}

.notFound {
  background: var(--ttui_grey-100);
  border: 0.125rem dashed var(--ttui_grey-200);
  color: var(--ttui_grey-500);
  font-weight: 700;
  padding: 0.625rem;
  text-align: center;
}

.divider {
  border: 0.0313rem solid var(--ttui_grey-200);
  margin: 1.25rem 0;
}

.collapseExpand {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.3125rem;
}

.collapseExpandButton {
  padding: 0.1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: var(--font-condensed);
}
