$campgaign-summary--border-color: $ct--theme-color !default;
$campaign-summary--font-family: $condensed !default;

.ddbc-campaign-summary {
  border: 1px solid $campgaign-summary--border-color;
  border-radius: 3px;
  padding: 5px 8px 4px;
  cursor: pointer;
  background-color: transparent;
  transition: all 100ms;
  display: flex;

  &__label {
    font-weight: bold;
    margin-right: 5px;
    text-transform: uppercase;
    font-family: $campaign-summary--font-family;
  }
}
