//This file is the legacy _buttons.scss file moved from CharacterToolsClient
//with all original classNames preserved
//rules for the newer RemoveButton from this lib under the .ddbc- selector

//need to update eventually and fix usages in ToolsClient stylesheets

@mixin disabled() {
  opacity: 1;
  color: #777;
  background-color: #eaeaea;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: #777;
    background-color: #eaeaea;
    box-shadow: none;
  }
}

%character-button {
  display: inline-block;
  border-radius: 3px;
  background-color: $character-default-color;
  color: #fff;
  font-family: $condensed;
  font-size: 10px;
  border: 1px solid transparent;
  text-transform: uppercase;
  padding: 9px 15px;
  transition: all 50ms;

  &:disabled {
    @include disabled;
  }
  &:active {
    background-color: darken($character-default-color, 10%);
    box-shadow: 0 0 10px 2px darken($character-default-color, 20%) inset;
    color: #fff;
  }
  &:focus {
    color: #fff;
  }
  &:visited {
    color: #fff;
  }
}

@mixin buttonBlock() {
  width: 100%;
  display: block;
}

@mixin buttonSizeSmall() {
  font-size: 8px;
  padding: 5px;
}

@mixin buttonSizeMedium() {
  padding: 6px 10px;
}

@mixin buttonSizeLarge() {
  padding: 10px 15px;
  border-width: 2px;
  font-size: 13px;
}

@mixin buttonSizeOversized() {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 15px;
}

.builder-button {
  background-color: $builder-default-color;

  &:visited {
    background-color: $builder-default-color;
    color: #fff;
  }

  &:focus,
  &:hover {
    background-color: darken($builder-default-color, 10%);
    color: #fff;
  }

  &:active {
    background-color: darken($builder-default-color, 10%);
    box-shadow: 0 0 10px 2px darken($builder-default-color, 20%) inset;
    color: #fff;
  }

  &-disabled {
    background-color: #7f7f7f;

    &:hover,
    &:focus,
    &:visited,
    &:active {
      background-color: #7f7f7f;
      color: #fff;
      box-shadow: none;
    }
  }
}

.character {
  &-button {
    @extend %character-button;

    &-block {
      @extend %character-button;
      @include buttonBlock;
    }

    &-block-small {
      @extend %character-button;
      @include buttonBlock;
      @include buttonSizeSmall;
    }

    &-block-medium {
      @include buttonBlock;
      @include buttonSizeMedium;
    }

    &-block-large {
      @extend %character-button;
      @include buttonBlock;
      @include buttonSizeLarge;
    }

    &-block-oversized {
      @extend %character-button;
      @include buttonBlock;
      @include buttonSizeOversized;
    }

    &-small {
      @extend %character-button;
      @include buttonSizeSmall;
      border-color: transparent;
      min-height: 22px;
    }

    &-medium {
      @extend %character-button;
      @include buttonSizeMedium;
    }

    &-large {
      @extend %character-button;
      @include buttonBlock;
      @include buttonSizeLarge;
    }

    &-oversized {
      @extend %character-button;
      @include buttonBlock;
      @include buttonSizeOversized;
    }

    &-outline {
      @extend %character-button;
      background-color: #fff;
      border-color: $character-default-color;
      color: $character-default-color;

      &:focus,
      &:hover,
      &:active {
        color: $character-default-color;
        background-color: #fff;
        box-shadow: 0 0 4px 2px darken(#fff, 10%) inset;
      }
      &:disabled {
        border-color: #eaeaea;
        color: #aaa;
        background-color: #f9f9f9;
      }
    }

    &-modal,
    &-modal-cancel {
      @extend %character-button;
      display: block;
      width: 100%;
      padding: 15px;
      border-radius: 0;
      font-size: 14px;
      font-weight: bold;
      line-height: 15px;
    }

    &-modal-cancel {
      background-color: #fff;
      color: $ct--theme-de-emphasized-color;

      &:focus,
      &:hover,
      &:active {
        color: $ct--theme-de-emphasized-color;
        background-color: darken(#fff, 10%);
        box-shadow: 0 0 10px 2px darken(#fff, 20%) inset;
      }
    }

    &-items {
      background-color: $color-items;
      &:hover,
      &:focus,
      &:active {
        background-color: darken($color-items, 10%);
        box-shadow: 0 0 10px 2px darken($color-items, 20%) inset;
      }
    }
    &-spells {
      background-color: $color-spells;
      &:hover,
      &:focus,
      &:active {
        background-color: darken($color-spells, 10%);
        box-shadow: 0 0 10px 2px darken($color-spells, 20%) inset;
      }
    }
    &-description {
      background-color: $color-description;
      &:hover,
      &:focus,
      &:active {
        background-color: darken($color-description, 10%);
        box-shadow: 0 0 10px 2px darken($color-description, 20%) inset;
      }
    }
    &-dark {
      background-color: rgba(#000, 0.6);
      border-color: $character-default-color;

      &-active {
        background-color: $character-default-color;
        color: #fff;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: $character-default-color;
        box-shadow: none;
        color: #fff;
      }
    }

    &-remove {
      background-color: #c42e2d;
      &:hover,
      &:focus,
      &:active {
        background-color: darken(#c42e2d, 10%);
        box-shadow: 0 0 10px 2px darken(#c42e2d, 20%) inset;
      }
    }

    &.character-button--disabled,
    &-disabled {
      @include disabled;
    }

    &--hurricane {
      border: 1px solid #37404580;

      &.character-button-modal,
      &.character-button-modal-cancel {
        background-color: #fff;
        color: #374045;
        padding: 5px 24px;
        border-radius: 4px;
        width: initial;
        line-height: 24px;
      }

      &.character-button-modal {
        &:focus,
        &:hover,
        &:active {
          color: $ct--theme-de-emphasized-color;
          background-color: darken(#fff, 15%);
        }
      }

      &.character-button-modal-cancel {
        border-color: transparent;

        &:focus,
        &:hover,
        &:active {
          color: $ct--theme-de-emphasized-color;
          box-shadow: none;
          background-color: darken(#fff, 5%);
        }
      }
    }
  }
}

.ct-button {
  &--confirm {
    position: relative;
  }

  &--confirm .ct-button__content {
    opacity: 1;
    transition: all 200ms;
  }

  &--confirm .ct-button__confirming,
  &--confirm .ct-button__confirmed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 200ms;
    opacity: 0;
  }

  &--confirm .ct-button__confirmed {
    &::before {
      content: $html-entity--checkmark;
      display: inline-flex;
      vertical-align: top;
    }
  }

  &--is-confirming,
  &--is-confirmed {
    background-color: $ct--button-positive-color !important;
    color: #fff !important;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($ct--button-positive-color, 10%) !important;
      box-shadow: 0 0 10px 2px darken($ct--button-positive-color, 20%) inset !important;
      color: #fff !important;
    }
  }

  &--is-confirming .ct-button__content,
  &--is-confirmed .ct-button__content {
    opacity: 0;
  }

  &--is-confirming .ct-button__confirming {
    opacity: 1;
  }

  &--is-confirmed .ct-button__confirmed {
    opacity: 1;
  }
}

//New rules for character-components RemoveButton
.ddbc-remove-button {
  background-color: $ct--negative-color;

  &:hover,
  &:active,
  &:focus {
    background-color: darken($ct--negative-color, 10%);
    box-shadow: 0 0 10px 2px darken($ct--negative-color, 20%) inset;
  }

  &.character-button-outline {
    background-color: #fff;
    border-color: #c42e2d;
    color: #c42e2d;

    &:focus,
    &:hover,
    &:active {
      color: #c42e2d;
      background-color: #fff;
      box-shadow: 0 0 4px 2px darken(#fff, 10%) inset;
    }
    &:disabled {
      border-color: #eaeaea;
      color: #aaa;
      background-color: #f9f9f9;
    }
  }
}

.button {
  &-action {
    &-increase,
    &-decrease {
      padding: 6px 8px;

      &::before {
        content: "";
        display: block;
        height: 16px;
        width: 16px;
        background-image: url("#{$imageURL-local}/sprites/plus_minus-white.svg");
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &--small {
        padding: 4px;

        &::before {
          height: 12px;
          width: 12px;
          background-size: auto 12px;
        }
      }
    }
    &-increase {
      &::before {
        background-position: 0 0;
      }
    }
    &-decrease {
      &::before {
        background-position: -17px 0;
      }

      &--small {
        &::before {
          background-position: -13px 0;
        }
      }
    }
  }
}
