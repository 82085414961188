.siteStyles a:visited,
.siteStyles a:hover {
  color: var(--ttui_color-megamenu--contrast);
}

.devContainer {
  position: relative;
}

.siteStyles > header {
  height: 4rem;
}

@media screen and (max-width: 1023px) {
  /* mimics the header styles for smaller screens in appshell */
  .siteStyles {
    height: 2.9375rem;
  }
  /* mimics the sitebar styles for small desktop and below */
  .siteStyles > header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 2.9375rem;
    background: #090809;
    box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.25);
    padding: 0;
    border-top: 0.0625rem solid #353535;
    border-bottom: 0.0625rem solid #e40712;
    transition: 0.25s transform cubic-bezier(0.16, 0.68, 0.43, 0.99);
    z-index: 10001;
  }

  /* mimics logo size */
  .siteStyles > header > div > a > svg {
    height: 1.9375rem;
    width: 6.0625rem;
    margin: 0.5rem;
  }
}
