.hitPointsBox {
  width: 19.813rem;
  height: 5.563rem;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: row;
  position: inherit;
  padding: 0.5rem 0.25rem 0.5rem 1rem;
  max-height: 100%;
}

.label {
  text-transform: uppercase;
  font-family: var(--font-condensed);
  font-weight: bold;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.375rem;
  color: var(--theme-contrast);
  padding: 0;
  margin: 0;
  font-size: 0.813rem;
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .hitPointsBox {
    width: 16.25rem;
    height: 4.563rem;
  }

  .hitPointsBox :global(.character-button-block-small) {
    padding-top: 0.188rem;
    padding-bottom: 0.188rem;
  }

  .content {
    padding-left: 0.75rem;
    padding-bottom: 0;
  }

  .label {
    font-size: 0.75rem;
  }
}
