$armor-class-box--label-color: $ct--theme-de-emphasized-color !default;

.ddbc-armor-class-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 79px;
  height: 90px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  position: relative;

  &__label {
    color: $armor-class-box--label-color;
    font-size: 10px;
    position: relative;
    &--dark-mode {
      color: $ct--theme-dark-mode-text;
    }
  }

  &__value {
    font-size: 26px;
    letter-spacing: -1px;
    position: relative;
    &--dark-mode {
      color: white;
    }
  }

  .ddbc-armor-class-box-svg {
    width: 100%;
    height: 100%;
  }
}
