.header {
  background-color: #242528;
  padding: 0.625rem;
  display: flex;
  align-items: center;
}

.name {
  color: #fff;
  font-weight: bold;
  font-family: var(--font-condensed);
  text-transform: uppercase;
  line-height: 1.1;
}

.icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem;
}

.table {
  display: table;
  width: 100%;
  border: 0.0625rem solid #edeae8;
  border-collapse: collapse;
}

.row {
  display: table-row;
  font-weight: 500;
}

.row.hasSuppliers .label,
.row.hasSuppliers .value {
  border-bottom: none;
}

.label {
  display: table-cell;
  vertical-align: middle;
  border: 0.0625rem solid #edeae8;
  padding: 0.3125rem 0.625rem;
  line-height: 1;
}

.value {
  display: table-cell;
  background-color: var(--theme-background-solid);
  border-left: 0.125rem solid #d0cac5;
  padding: 0.3125rem;
  text-align: center;
  border-bottom: 0.0625rem solid #edeae8;
  width: 4.375rem;
}

.row.highlight {
  background: var(--ttui_blue-200);
}

.row.highlight .value {
  background: var(--ttui_blue-100);
}

.suppliers {
  display: table-row-group;
  border-bottom: 0.0625rem solid #edeae8;
}

.supplier {
  display: table-row;
  font-size: 0.75rem;
}

.supplier.builder {
  font-size: 0.8125rem;
}

.supplier .label {
  padding-left: 1.125rem;
  color: var(--character-muted-color);
  border: none;
}

.supplier .value,
.supplier .value .number {
  border-bottom: none;
  color: var(--character-muted-color);
}

.suppliers :global(.ddbc-data-origin-name) {
  cursor: pointer;
}
.builder:global(.ddbc-data-origin-name) {
  cursor: auto;
}

.overrides {
  display: table;
  width: 100%;
  margin: 0.3125rem 0;
}

.override {
  display: table-row;
}

.override .label {
  border: none;
}

.override .value {
  border: none;
  padding: 0.1875rem 0;
  background: none;
}

.value input[type="number"] {
  width: 100%;
  text-align: center;
  padding: 0.3125rem 0;
  background: var(--theme-background-solid);
  color: var(--theme-contrast);
  border-color: var(--ttui_grey-400);
}
.value input[type="number"]:focus {
  border-color: var(--theme-contrast);
  background: var(--theme-background-solid);
}
