.ct-spells-level-casting {
  display: flex;
  width: 100%;

  &__info {
    flex: 1;
    display: flex;
    justify-content: center;

    &-label {
      color: $ct--theme-de-emphasized-color;
      text-transform: uppercase;
      font-size: 10px;
    }

    &-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $condensed;
      font-weight: bold;
      line-height: 1;

      & + & {
        margin-left: 15px;
      }
    }

    &-items {
      display: flex;
    }

    &-item {
      font-size: 16px;

      & + & {
        &::before {
          margin-left: 7px;
          padding-left: 6px;
          border-left: 2px solid #d8d8d8;
          content: "";
          display: inline-flex;
          height: 11px;
        }
      }

      .ddbc-signed-number {
        &__sign {
          font-size: 14px;
        }
      }
    }
  }

  &__slot {
    &-groups {
      display: flex;
    }

    &-group {
      display: flex;
      align-items: center;

      & + & {
        margin-left: 10px;
      }

      &-label {
        margin-left: 5px;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $condensed;
      }
    }
  }
  &--dark-mode {
    color: white;
  }
}

.ct-character-sheet--dark-mode,
.ct-sidebar--is-dark-mode {
  .ct-spells-level-casting__info {
    &-item {
      color: white;
    }
    &-label {
      color: $ct--theme-dark-mode-text;
    }
  }
}
