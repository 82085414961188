.borderBg {
  fill: var(--theme-background-solid);
}

.borderStroke {
  stroke: var(--theme-color);
}

.borderFill {
  fill: var(--theme-color);
}
