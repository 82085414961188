@import "@dndbeyond/ttui/components/Button/styles/Button.module.css";

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.75rem 1.5rem;
  min-height: 3rem;
  position: relative;
  overflow: hidden;
  background-repeat: repeat;
  background-size: 5.25rem;
  background-image: url("../../../../images/player-app-banner-background-tile.png");
  width: 100%;
  margin: 0 auto 1rem;
}

.text {
  color: var(--ttui_common-0);
  font-family: "Tiamat Condensed SC";
  font-size: 1.5rem;
  letter-spacing: 0.063rem;
  text-align: center;
  margin: 0;
  flex: 1;
}

.image {
  display: none;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin: 0;
  flex: 1;
}

.storeLink {
  line-height: 0;
}

.closeButton {
  background: var(--ttui_grey-900);
  border-color: var(--ttui_grey-900);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.525rem 0.468rem;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.closeIcon {
  fill: var(--ttui_common-0);
  width: 1rem;
}

@media screen and (min-width: 37.5rem) {
  .text {
    font-size: 1.815rem;
  }
}

@media screen and (min-width: 56.25rem) {
  .banner {
    flex-direction: row;
    padding: 0.1375rem 1.5rem;
  }

  .text {
    text-align: left;
  }

  .image {
    display: block;
  }

  .closeButton {
    position: unset;
  }
}
