.filterGroup {
  margin: 0.625rem 0;
}

.filterLabel {
  font-weight: bold;
  font-size: 0.875rem;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
}

.button button {
  min-width: 1.875rem;
}

.sourceCategoryButton {
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.sourceCategoryButton.builder button {
  font-size: 0.6875rem;
}

.checkboxGroup {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.625rem;
}

.checkbox.builder {
  flex-basis: 20%;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-basis: 50%;
  padding: 0 0 0.625rem 0;
}

.checkbox :global(.ddbc-checkbox__label.character-checkbox-label) {
  font-weight: bold;
  font-size: 0.6875rem;
}

.checkbox.builder :global(.ddbc-checkbox__label.character-checkbox-label) {
  font-size: 0.8125rem;
}

.accordionLabel {
  color: var(--character-muted-color);
}

.accordion {
  margin: 0;
}

.accordion summary,
.accordion summary + div {
  padding: 0;
}

.accordion summary svg {
  margin: unset;
}
