.ct-skills-box {
  width: 281px;
  height: 765px;
  position: relative;
  padding: 13px 20px;

  .ct-skills {
    position: relative;
  }
}
